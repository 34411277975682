import styled from "styled-components";

export const Container = styled.tbody<{
  striped?: boolean;
}>`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.deepDark};

  &:before {
    content: "@";
    display: block;
    line-height: 8px;
    opacity: 0;
  }

  ${({ striped, theme }) =>
    striped
      ? `& > :nth-child(odd) {
    background-color: ${theme.colors.mediumBlue}0D;
  }`
      : `& > * {
        border-bottom: 1px solid ${theme.colors.blue};
        margin-bottom: -1px;
      }`}
`;
