import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchemaProject, ProjectFormValues } from "../schema";
import { cleanObject } from "@/utils/common";

export const useProjectForm = (props: UseFormProps<ProjectFormValues> = {}) => {
  const { defaultValues, ...restFormProps } = props;
  const form = useForm({
    resolver: zodResolver(formSchemaProject),
    defaultValues: {
      name: "",
      companyId: undefined,
      costPerDistance: "",
      startDate: new Date(),
      endDate: new Date(),
      dmaIds: [],
      levelODL: 2,
      targetDistance: "",
      targetLeakage: "",
      projectId: undefined,
      gisUploadId: undefined,
      gisUploadCompanyId: undefined,
      ...cleanObject(defaultValues)
    },
    ...restFormProps
  });

  return form;
};
