import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC, useState } from "react";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import * as Styled from "./style";

interface GraphToggleProps {
  audioUrls?: string[] | null;
  stftUrls?: string[] | null;
  psdUrls?: string[] | null;
  psdTimestamps?: string[] | null;
}

const GraphToggle: FC<GraphToggleProps> = ({
  audioUrls,
  stftUrls,
  psdUrls,
  psdTimestamps
}) => {
  const initialImageType = stftUrls && stftUrls.length > 0 ? "stft" : "psd";
  const [imageType, setImageType] = useState(initialImageType);

  const { t } = useCustomTranslation("");
  const onClickHandler = (image: string) => setImageType(image);

  return (
    <Styled.Container data-testid="graph-toggle">
      <Styled.btnContainerTitle>
        {t("COMPONENTS.GRAPH_TOGGLE.SPECTRAL_PROFILE")}
      </Styled.btnContainerTitle>
      {stftUrls && stftUrls.length > 0 && psdUrls && psdUrls.length > 0 && (
        <Styled.btnContainer>
          <Styled.Button onClick={() => onClickHandler("stft")}>
            STFT
          </Styled.Button>
          <Styled.Button onClick={() => onClickHandler("psd")}>
            PSD
          </Styled.Button>
        </Styled.btnContainer>
      )}
      <Styled.Wrapper>
        <Styled.ItemContainer>
          {audioUrls &&
            audioUrls.map((audioUrl: string, key) => (
              <Styled.AudioAndImage key={key}>
                {imageType === "stft" && stftUrls && stftUrls[key] && (
                  <Styled.Image
                    alt={"stft Graph image"}
                    src={stftUrls[key]}
                    key={key}
                  />
                )}
                {imageType === "psd" && psdUrls && psdUrls[key] && (
                  <figure>
                    {psdTimestamps && psdTimestamps[key] && (
                      <Styled.FigCaption data-testid="PSD_timestamp">
                        {new Date(psdTimestamps[key]).toUTCString()}
                      </Styled.FigCaption>
                    )}
                    <Styled.Image
                      alt={"psd Graph image"}
                      src={psdUrls[key]}
                      key={key}
                    />
                  </figure>
                )}
                <AudioPlayer audioKey={key} audioUrl={audioUrl} />
              </Styled.AudioAndImage>
            ))}
        </Styled.ItemContainer>
      </Styled.Wrapper>
    </Styled.Container>
  );
};
export default GraphToggle;
