import { FC, useState } from "react";
import * as Styled from "../style";
import { Button, Loader, Modal } from "@/components";
import BackArrow from "@/assets/icons/back-arrow.png";
import { ButtonVariant } from "@/components/Button/Button";
import { AllowedType, goBackStep } from "../../utils";
import {
  useGISUploadContext,
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { Link } from "react-router-dom";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { usePostUploadGISDataMutation } from "@/redux/gisUpload/gisUpload.api";

interface FileHeaderMapPreviewProps {
  lengthOfFiles: Record<AllowedType, number> | undefined;
}

const FileHeaderMapPreview: FC<FileHeaderMapPreviewProps> = ({
  lengthOfFiles
}) => {
  // const [uploadGISData, { isSuccess, isError, isLoading }] =
  //   usePostUploadGISDataMutation();
  const { prefixedT } = useCustomTranslation("GIS_UPLOAD.FILE_HEADER");
  const [open, setOpen] = useState(false);
  const { uploadId } = useGISUploadContext();

  const { companyId, data } = useGISUploadValues();
  const { setUploadValues, resetUpload } = useGISUploadMethods();

  const previousStep = () => {
    setUploadValues({
      data: {
        currentStep: goBackStep(data.currentStep),
        fileIndex: (lengthOfFiles?.asset && lengthOfFiles?.asset - 1) || 0
      }
    });
  };

  const nextStep = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const reset = () => {
    resetUpload();
  };

  // const uploadGIS = async () => {
  //   if (companyId && uploadId)
  //     await uploadGISData({
  //       params: {
  //         company_id: companyId,
  //         upload_id: uploadId
  //       }
  //     });
  // };

  // console.log("[header] render: ", { data });

  return (
    <Styled.Wrapper data-testid="file-header-map-preview">
      <Modal
        styles={{ maxWidth: "300px" }}
        isOpen={open}
        onClose={closeModal}
        title={prefixedT("FINAL_STEP")}
      >
        {/* {isLoading && <Loader />} */}
        {/* {!isSuccess && !isError && !isLoading && (
          <div>
            <Styled.TextContainer>
              <p>{prefixedT("TEXT.UPLOAD_PARAGRAPH")}</p>
            </Styled.TextContainer>
            <Styled.ModalBtnContainer>
              <Button
                onClick={() => {
                  void (async () => {
                    await uploadGIS();
                  })();
                }}
              >
                {prefixedT("BTN.UPLOAD")}
              </Button>
              <Button onClick={closeModal}>
                {prefixedT("BTN.CLOSE_MODAL")}
              </Button>
            </Styled.ModalBtnContainer>
          </div>
        )} */}
        <div>
          <Styled.TextContainer>
            {/* <p>{prefixedT("SUCCESS.PARAGRAPH")}</p> */}
            <p>GIS data is uploaded</p>
          </Styled.TextContainer>
          <Button onClick={closeModal} variant={ButtonVariant.outline}>
            {data.returnToProject && data.returnToCompany ? (
              <Link
                style={{ textDecoration: "none" }}
                to={`/app/user-management/project/${data.returnToCompany}/${data.returnToProject}?autoSelectGISUploadId=${uploadId}`}
              >
                Back to project creation
              </Link>
            ) : (
              <Link style={{ textDecoration: "none" }} to={`/app/gis-admin`}>
                {prefixedT("BTN.LINK.GIS_ADMIN")}
              </Link>
            )}
          </Button>
        </div>
        {/* {isError && (
          <div>
            <Styled.TextContainer>
              <p>{prefixedT("ERROR.PARAGRAPH")}</p>
            </Styled.TextContainer>
            <Styled.ModalBtnContainer>
              <Button
                onClick={() => {
                  void (async () => {
                    await uploadGIS();
                  })();
                }}
              >
                {prefixedT("BTN.UPLOAD")}
              </Button>
              <Button onClick={closeModal}>
                {prefixedT("BTN.CLOSE_MODAL")}
              </Button>
            </Styled.ModalBtnContainer>
          </div>
        )} */}
      </Modal>
      <Styled.Button onClick={reset}>
        <Styled.Img src={BackArrow as string} />
        {prefixedT("RESET")}
      </Styled.Button>
      <Styled.Container>
        <Styled.DetailContainer>
          <p>{prefixedT("TITLE.MAP_PREVIEW")}</p>
        </Styled.DetailContainer>
        <Styled.ButtonContainer>
          <Button onClick={previousStep} variant={ButtonVariant.outline}>
            {prefixedT("PREVIOUS")}
          </Button>
          <Button onClick={nextStep}>{prefixedT("NEXT")}</Button>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default FileHeaderMapPreview;
