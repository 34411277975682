import { useMemo } from "react";

// Types
import type { FormUserValues } from "@/redux/portalUsers/portalUsers.api";

// Constants
import { MeasurementSystem } from "@/constants/Measurement.constant";
import { CountryCode } from "@/constants/Country.constant";
import { Currency } from "@/constants/Currency.constant";

// Utils
import { getDefaultUnits } from "@/utils/unit";

export const useInitialValuesUser = (
  initialValues?: Partial<FormUserValues>
) => {
  const values = useMemo<FormUserValues>(
    () => ({
      first_name: initialValues?.first_name || "",
      last_name: initialValues?.last_name || "",
      email: initialValues?.email || "",
      partner_id: initialValues?.partner_id || undefined,
      company_id: initialValues?.company_id || undefined,
      role_id: initialValues?.role_id || undefined,

      phone: initialValues?.phone || "",
      metric_system: initialValues?.metric_system || MeasurementSystem.METRIC,
      currency: initialValues?.currency || Currency.GBP.code,
      country_code: initialValues?.country_code || CountryCode.GBR,
      units: initialValues?.units || getDefaultUnits(MeasurementSystem.METRIC)
    }),
    [initialValues]
  );
  // console.log('[initialValues]', { initialValues, values });

  return values;
};
