import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding: 40px;
  min-height: 60vh;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  margin: 10px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-weight: normal;
`;

export const MapContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-align: center;
  height: 500px;
  position: relative;
`;

export const H2Heading = styled.h2`
  font-weight: normal;
`;

export const LoadingText = styled.span`
  &:after {
    display: inline-block;
    animation: dotty steps(1, end) 1s infinite;
    content: "";
  }

  @keyframes dotty {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
    100% {
      content: "";
    }
  }
`;

export const ModalContainer = styled.dialog`
  width: 80%;
  max-width: 80%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

export const PartnerContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  padding: 20px;
`;

export const CompanyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap 20px;
  margin-top: 20px;
`;
