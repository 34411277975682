/*
 * Websocket server API
 */

export type WebsocketMessageServer = {
  type: WebsocketMessageTypeBase | WebsocketMessageTypeGISUpload | "";
  data: Record<string, any>;
};

export const enum WebsocketMessageTypeBase {
  PENDING = "PENDING",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE"
}

// *NB: special case on upload (should be refactored at some point to match WebsocketMessageTypeBase)
export const enum WebsocketMessageTypeGISUpload {
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
  UPLOAD_FAILURE = "UPLOAD_FAILURE"
}

/*
 * Redux state
 */

export const enum WebsocketType {
  GIS_UPLOAD = "gis_upload",
  ODL_GENERATION = "odl_generation"
}

export const enum WebsocketStatus {
  UNINITIALIZED = "uninitialized", // Websocket should start setting up and connecting
  CLOSING = "closing" // Websocket should be closing
}

export type WebsocketItem = {
  id: string;
  status: WebsocketStatus;
  type: WebsocketType;
  url: string;
};

export type WebsocketState = {
  websockets: WebsocketItem[];
};
