import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import { Field, Formik } from "formik";
import { DropEvent, FileRejection } from "react-dropzone";

// Styles
import * as Styled from "./style";

// Components
import { Button, DropDown, Loader } from "@/components";
import FileUpload, {
  FileUploadStatus
} from "@/components/FileUpload/FileUpload";
import { ButtonVariant } from "@/components/Button/Button";
import { DropDownOption } from "@/components/DropDown/DropDown";

// HOCs
import withPageLoader from "@/HOCs/withPageLoader";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";

// Redux
import { useAppDispatch, useAppSelector } from "@/redux";
import { useGetCompanyListQuery } from "@/redux/portalCompanies/portalCompanies.api";
import { usePostUploadGISFilesMutation } from "@/redux/gisUpload/gisUpload.api";

// Types
import {
  useGISUploadContext,
  useGISUploadMethods,
  // useGISUploadContext,
  // useGISUploadContext,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { GISUploadProps } from "../types/types";

// Utils
import { goForwardStep } from "../utils";

// Validation
import { ValidationSchema } from "./ValidationSchema";
import { createWSConnection } from "@/redux/websocket/websocket.slice";
import { WebsocketType } from "@/types/websocket.type";
import { useNavigate, useSearchParams } from "react-router-dom";

// TODO: GIS finish - when error: show error toast (use ShadcnUI toaster)

const UploadShapefile: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const returnProjectId = searchParams.get("project_id") ?? null;
  const returnCompanyId = searchParams.get("company_id") ?? null;

  const token = useAppSelector((state) => state.auth.token);

  const { uploadId, setUploadId } = useGISUploadContext();
  const { data, uploadStatus, isUploading } = useGISUploadValues();
  const { setUploadValues, resetUpload } = useGISUploadMethods();

  // TODO: add last step redirect back to project
  // TODO: add existing project route by :projectId

  const [
    uploadGISFiles,
    {
      data: fileUploadId,
      isSuccess: isUploadRequestSuccess,
      isError,
      isLoading: isUploadRequestLoading,
      reset: resetUploadGISFiles
    }
  ] = usePostUploadGISFilesMutation();

  const { prefixedT } = useCustomTranslation("GIS_UPLOAD");
  const { data: companyList, isLoading: areCompaniesLoading } =
    useGetCompanyListQuery();

  const isFormLoading = areCompaniesLoading;

  // TODO: Refactor - redo with useForm hook?
  const formikValuesRef = useRef<GISUploadProps | null>(null);

  const initialValues = useMemo<GISUploadProps>(
    () => ({
      company_id: "",
      company_name: "",
      file: []
    }),
    []
  );

  const onSubmit = async (values: GISUploadProps) => {
    formikValuesRef.current = values;
    await uploadGISFiles({
      params: {
        company_id: Number(values.company_id)
      },
      file: values.file[0]
    });
  };

  // useEffect(() => {
  //   setIsLoading(isLoading);
  // }, [isLoading]);

  useEffect(() => {
    // console.log('[upload] useeffect: ', {
    //   isUploadRequestSuccess,
    //   fileUploadId,
    //   formikValuesRef,
    // });
    if (
      (!uploadId || uploadStatus === "uninitialized") &&
      isUploadRequestSuccess &&
      typeof fileUploadId === "number" &&
      formikValuesRef.current
    ) {
      setUploadValues({
        uploadId: fileUploadId,
        uploadStatus: "uploading",
        data: {
          // currentStep: goForwardStep(0),
          companyId: Number(formikValuesRef.current.company_id),
          returnToProject: returnProjectId,
          returnToCompany: returnCompanyId
        }
      });
      dispatch(
        createWSConnection({
          token,
          type: WebsocketType.GIS_UPLOAD,
          id: fileUploadId.toString()
        })
      );
      setUploadId(fileUploadId);
      // navigate(`/app/gis-upload/${fileUploadId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUploadRequestSuccess,
    fileUploadId,
    returnProjectId,
    returnCompanyId,
    uploadId,
    token
  ]);

  const isUploadSuccess = uploadStatus === "success";
  const isUploadError = uploadStatus === "error";

  // useEffect(() => {
  //   if (typeof uploadId === 'number') {

  //   }
  // }, [uploadId]);

  // useEffect(() => {
  //   if (isUploadSuccess && uploadId && formikValuesRef.current) {
  //     // setCurrentStep((prevStep: number) => goForwardStep(prevStep));
  //     // setCurrentStep(goForwardStep(currentStep));
  //     setUploadValues({
  //       data: {
  //         currentStep: goForwardStep(0),
  //         companyId: Number(formikValuesRef.current.company_id),
  //       },
  //     });
  //   }
  // }, [isUploadSuccess, uploadId]);

  useEffect(() => {
    if (isUploadSuccess && typeof fileUploadId === "number") {
      setUploadValues({
        uploadId: fileUploadId,
        data: {
          currentStep: goForwardStep(0)
        }
      });
      navigate(`/app/gis-upload/${fileUploadId}`);
      resetUploadGISFiles();
    }
  }, [isUploadSuccess, fileUploadId]);

  useEffect(() => {
    if (isUploadError && typeof fileUploadId === "number") {
      resetUpload();
      resetUploadGISFiles();
    }
  }, [isUploadError, fileUploadId]);

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      _fileRejections: FileRejection[],
      _event: DropEvent,
      setValues: (values: GISUploadProps) => void,
      values: GISUploadProps
    ) => {
      setValues({
        ...values,
        file: acceptedFiles
      });
    },
    []
  );

  // TODO: GIS - dispatch event to establish connection here after file is uploaded and upload_id is returned (the connection should be somewhere at the Root in order to stay connected, since we want to notify user when it's done)

  // console.log("[upload] render: ", {
  //   data,
  //   fileUploadId,
  //   isUploadRequestSuccess,
  //   isError,
  //   isUploadRequestLoading,
  //   uploadStatus
  // });

  return (
    <Styled.GISWrapper>
      <Styled.WrapperTitle>
        <h3>{prefixedT("PAGE_TITLE")}</h3>
      </Styled.WrapperTitle>
      {isFormLoading ? (
        <Loader label="Loading companies data..." />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            setValues,
            setFieldValue,
            submitForm,
            resetForm
          }) => {
            useEffect(() => {
              if (returnCompanyId) {
                const selectedCompany = companyList.find(
                  (c) => c.id === +returnCompanyId
                );

                if (selectedCompany) {
                  void setFieldValue("company_id", selectedCompany.id);
                  void setFieldValue("company_name", selectedCompany.name);
                }
              }
            }, [companyList]);

            return (
              <Fragment>
                <Styled.CompanyDropDownContainer>
                  <Field
                    as={DropDown}
                    required={true}
                    disabled={returnCompanyId}
                    styles={{ border: true, width: "50%" }}
                    options={companyList?.map((company) => ({
                      label: company.name,
                      value: company.id
                    }))}
                    label={prefixedT("INPUT.COMPANY.LABEL")}
                    placeholder={
                      values?.company_name ||
                      prefixedT("INPUT.COMPANY.PLACEHOLDER")
                    }
                    onChange={(option: DropDownOption) => {
                      const companyId = parseInt(option.value);
                      void setFieldValue("company_id", companyId);
                      void setFieldValue("company_name", option.label);
                    }}
                    countOptions={4}
                    error={errors.company_id?.toString()}
                    value={values.company_id?.toString()}
                    testId="company"
                  />
                </Styled.CompanyDropDownContainer>
                {isUploading ? (
                  <Loader label="Uploading a file..." />
                ) : (
                  <FileUpload
                    label={prefixedT("UPLOAD.LABEL")}
                    required={true}
                    onDrop={(acceptedFiles, fileRejections, event) =>
                      onDrop(
                        acceptedFiles,
                        fileRejections,
                        event,
                        setValues,
                        values
                      )
                    }
                    files={values.file}
                    fileTypes={[".zip"]}
                    error={errors.file as unknown as FileUploadStatus | null}
                    disabled={isUploadRequestLoading}
                  />
                )}
                {isError && (
                  <Styled.ErrorLabel>
                    {prefixedT("API.GENERIC_ERROR")}
                  </Styled.ErrorLabel>
                )}
                <Styled.ButtonWrapper>
                  <Button
                    variant={ButtonVariant.outline}
                    disabled={
                      !values.file.length ||
                      isUploadRequestLoading ||
                      isUploading
                    }
                    onClick={() =>
                      resetForm({
                        values: {
                          ...values,
                          file: []
                        }
                      })
                    }
                  >
                    {prefixedT("BUTTON.DISCARD")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isUploadRequestLoading || isUploading}
                    onClick={() => {
                      void (async () => {
                        await submitForm();
                      })();
                    }}
                  >
                    {prefixedT("BUTTON.NEXT")}
                  </Button>
                </Styled.ButtonWrapper>
              </Fragment>
            );
          }}
        </Formik>
      )}
    </Styled.GISWrapper>
  );
};

export default UploadShapefile;
