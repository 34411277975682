import { Loader } from "@/components";
import Button from "@/components/Button/Button";
import GenerateMap from "@/components/ReportLayout/widgets/MapView/GenerateMap";
import withPageLoader from "@/HOCs/withPageLoader";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { FilterContext, FilterName } from "@/Providers/FilterProvider";
import {
  useGetDropdownQuery,
  useGetWaypointInformationQuery
} from "@/redux/reports/reports.api";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import AddJob from "./Components/AddJob/AddJob";
import EditWaypoint from "./Components/EditWaypoint/EditWaypoint";
import HistoryContainer from "./Components/HistoryContainer/HistoryContainer";
import ImageContainer from "./Components/ImageContainer/ImageContainer";
import MergeWaypoint from "./Components/MergeWaypoint/MergeWaypoint";
import WaypointHeadings from "./Components/WaypointHeadings/WaypointHeadings";
import WaypointTickboxContainer from "./Components/WaypointTickboxContainer/WaypointTickboxContainer";
import * as Styled from "./style";
import { formatMapData } from "./utils";
import { MapProvider } from "@/Providers/MapProvider";
import JobTable from "./Components/JobTable/JobTable";

const WaypointContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation("WAYPOINT_PAGE.WAYPOINT_INFO");
  const { mapFilters, setFilterMapValues } = useContext(FilterContext);
  const queryParams = new URLSearchParams(location.search);
  const waypointID = queryParams.get("waypoint") as string;

  const {
    data: dropDownData,
    isLoading: dropdownLoading,
    isSuccess: dropdownSuccess,
    isError: dropdownError
  } = useGetDropdownQuery(waypointID, {
    refetchOnMountOrArgChange: true,
    skip: !waypointID
  });

  const pipes = mapFilters.filters.includes("PIPES");
  const assets = mapFilters.filters.includes("ASSETS");

  const {
    data,
    isLoading: waypointInfoLoading,
    isSuccess: waypointInfoSuccess,
    isError: waypointInfoError
  } = useGetWaypointInformationQuery(
    {
      id: waypointID,
      query: { pipes: pipes ? "yes" : "no", assets: assets ? "yes" : "no" }
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const requestError = dropdownError || waypointInfoError;
  const requestSuccess = dropdownSuccess && waypointInfoSuccess;
  const isLoading = waypointInfoLoading || dropdownLoading;

  const [isMergeOpen, setIsMergeOpen] = useState(false);
  const [isAddJobOpen, setIsAddJobOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    setFilterMapValues(["PIPES", "ASSETS"] as FilterName[]);
  }, []);

  const mapData = useMemo(() => {
    return formatMapData(data?.map_info);
  }, [data?.map_info]);

  const handleEdit = () => {
    setEditMode(!isEditMode);
  };

  const handleAddJobOpen = () => {
    setIsAddJobOpen(!isAddJobOpen);
  };

  const handleMergeOpen = () => {
    setIsMergeOpen(!isMergeOpen);
  };

  // TODO: Refactor - use invalidating tags instead of manual refetch

  const onUpdateSuccessful = () => {
    setEditMode(false);
    setIsAddJobOpen(false);
    setIsMergeOpen(false);
  };

  return (
    <div style={{ padding: "40px" }}>
      {isLoading && <Loader />}
      {requestSuccess && (
        <>
          {dropDownData && (
            <WaypointHeadings
              correlations={dropDownData.correlations}
              consumptionProfileIds={dropDownData.consumption_profile_ids}
              topSoundIds={dropDownData.topsound_ids}
              waypointID={waypointID}
              sessions={dropDownData.sessions}
              jobs={dropDownData.jobs}
            />
          )}

          <Styled.InfoAndMapWrapper>
            <Styled.InfoWrapper>
              <Styled.EditContainer>
                <Styled.InfoWrapperTitle>
                  <h3>{prefixedT("TITLE")}</h3>
                </Styled.InfoWrapperTitle>
                <Styled.BtnContainer>
                  {!isEditMode && (
                    <Button onClick={handleAddJobOpen}>
                      {prefixedT("ADD_JOB")}
                    </Button>
                  )}
                  {!isEditMode && (
                    <Button onClick={handleEdit}>
                      {prefixedT("EDIT_FIELDS")}
                    </Button>
                  )}
                  {!isEditMode && (
                    <Button onClick={handleMergeOpen}>
                      {prefixedT("BTN_MERGE")}
                    </Button>
                  )}
                </Styled.BtnContainer>
              </Styled.EditContainer>
              {data && (
                <EditWaypoint
                  waypointId={waypointID}
                  hideEdit={handleEdit}
                  onSuccess={onUpdateSuccessful}
                  isEditMode={isEditMode}
                  data={data}
                />
              )}
              <MergeWaypoint
                waypointId={Number(waypointID)}
                onSuccess={onUpdateSuccessful}
                closeOverlay={handleMergeOpen}
                isOpen={isMergeOpen}
              />
              {isAddJobOpen && (
                <AddJob
                  data={data}
                  waypointId={Number(waypointID)}
                  onSuccess={onUpdateSuccessful}
                  closeOverlay={handleAddJobOpen}
                />
              )}
              <WaypointTickboxContainer
                overnight={data?.overnight}
                topSound={data?.top_sound}
                correlation={data?.correlation}
                scheduled={data?.scheduled}
                markedUp={data?.marked_up}
                repairScheduled={data?.repair_scheduled}
                repaired={data?.repaired}
                validated={data?.validated}
              />
            </Styled.InfoWrapper>

            {data && (
              <div className="flex-1 max-h-[650px]">
                <MapProvider>
                  <GenerateMap
                    fullMapData={mapData}
                    viewportMapData={mapData}
                    showMapFilters={true}
                    readonly={true}
                    doNotAllowClickThrough={true}
                  />
                </MapProvider>
              </div>
            )}
          </Styled.InfoAndMapWrapper>

          <JobTable key={`${isAddJobOpen}`} waypointId={waypointID} />

          <Styled.TickBoxAndHistoryWrapper>
            {data?.history_log && (
              <HistoryContainer historyLog={data?.history_log} />
            )}
          </Styled.TickBoxAndHistoryWrapper>

          <ImageContainer linkToAssets={data?.link_to_assets} />
        </>
      )}
      {requestError && (
        <Styled.ErrorContainer>
          <h3>{t("ERROR.REQUEST")}</h3>
        </Styled.ErrorContainer>
      )}
    </div>
  );
};

export default withPageLoader(WaypointContainer);
