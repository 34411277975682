import { ContentLoader } from "@/components";
import { useForgotPasswordMutation } from "@/redux/authorization/authorization.api";
import { getRTKError } from "@/utils/rtk";
import { QueryStatus } from "@reduxjs/toolkit/query";
import React, { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword, { ForgotPasswordValues } from "./ForgotPassword";

const initialValues = {
  email: ""
};

const ForgotPasswordContainer: FC = () => {
  const navigate = useNavigate();
  const [onForgotPassword, { error, status, isLoading }] =
    useForgotPasswordMutation();
  const forgotError = useMemo(() => getRTKError(error), [error]);

  const handleSubmit = ({ email }: ForgotPasswordValues) => {
    onForgotPassword({
      email,
      redirect_url: `${window.location.origin}/auth/recovery`
    });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (status === QueryStatus.fulfilled) {
      timeout = setTimeout(() => navigate("/auth/login"), 5000);
    }
    return () => clearTimeout(timeout);
  }, [status, navigate]);

  return (
    <>
      <ContentLoader active={isLoading} />
      <ForgotPassword
        onSubmit={handleSubmit}
        error={forgotError}
        initialValues={initialValues}
        status={status}
      />
    </>
  );
};

export default ForgotPasswordContainer;
