import useOutsideClick from "@/hooks/useOutsideClick";
import useOutsideScroll from "@/hooks/useOutsideScroll";
import useRect from "@/hooks/useRect";
import { Styles } from "@/types/styles.type";
import { AnimatePresence } from "framer-motion";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import DropDownList from "./DropDownList";
import * as Styled from "./style";

// TODO: Refactor - add generic to value (refactor component somehow so that you don't need to import and specify it everytime)
// TODO: also mb remove the restriction of value being only string
export type DropDownOption = {
  value: string;
  label: string;
};

type Props = {
  options: DropDownOption[] | undefined;
  countOptions?: number;
  initialOpen?: boolean;
  placeholder: string;
  value: string | string[];
  onChange: (value: DropDownOption | DropDownOption[]) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  testId?: string;
  styles?: Styles;
  multiple?: boolean;
  blockRef?: React.RefObject<HTMLDivElement>;
  active?: boolean;
};

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 }
};

const DropDown: FC<Props> = ({
  options = [],
  active,
  initialOpen,
  placeholder,
  value,
  onChange,
  required,
  label = "",
  error = "",
  disabled = false,
  testId,
  countOptions = 6,
  styles,
  multiple,
  blockRef
}) => {
  const activeLabel = useMemo(() => {
    if (!value) {
      return placeholder;
    }

    if (multiple) {
      const optionValues = options.filter((el: DropDownOption) =>
        value.includes(el.value)
      );
      return optionValues.map((el) => el.label).join(", ") || placeholder;
    }

    const optionValue = options.find(
      (el: DropDownOption) => el.value === value
    );
    return optionValue?.label || placeholder;
  }, [value, options, placeholder, multiple]);

  const _disabled = options.length === 0 || disabled;

  const [open, setOpen] = useState<boolean>(Boolean(initialOpen));
  const [isError, setIsError] = useState<boolean>(!!error);
  const ref = useRef(null);
  const listRef = useRef(null);
  const elementRef = useRef(null);
  const rectConfig = useRect(open, listRef, elementRef, blockRef);
  const isOverflow = rectConfig?.isPortal ? rectConfig.isOverflow : false;

  const toggleOpen = () => {
    if (!_disabled) {
      setOpen(!open);
    }
  };

  const onSelect = (option: DropDownOption) => {
    if (!_disabled) {
      if (multiple) {
        const _value = (value as unknown as DropDownOption[]) || [];
        const isExist = _value.find((el) => el.value === option.value);
        onChange(
          isExist
            ? _value.filter((el) => el.value !== option.value)
            : [..._value, option]
        );
        setIsError(false);
      } else {
        onChange(option);
        setIsError(false);
        toggleOpen();
      }
    }
  };

  useOutsideClick(listRef, setOpen, open, elementRef);
  useOutsideScroll(blockRef, setOpen, open);

  useEffect(() => {
    setIsError(!!error);
  }, [error]);

  return (
    <Styled.Container data-testid={`${testId ? `${testId}-` : ""}dropdown`}>
      {label && (
        <Styled.Label
          labelStyle={styles?.label}
          data-testid={`dropdown-label${testId ? `-${testId}` : ""}`}
          error={isError}
        >
          {label} {required && <Styled.Required>*</Styled.Required>}
        </Styled.Label>
      )}
      <Styled.Wrapper border={styles?.border} error={isError} ref={ref}>
        <Styled.ClickableArea
          data-testid={`dropdown-clickable-area${testId ? `-${testId}` : ""}`}
          error={isError}
          disabled={_disabled}
          open={open}
          onClick={toggleOpen}
          activeElement={active || false}
          ref={elementRef}
          styles={{
            isOverflow,
            background: styles?.background,
            center: styles?.center
          }}
        >
          <Styled.ClickableAreaText
            data-testid={`dropdown-clickable-text${testId ? `-${testId}` : ""}`}
            disabled={_disabled}
            isPlaceholder={activeLabel === placeholder}
            styles={{
              isOverflow,
              background: styles?.background,
              center: styles?.center,
              textColor: styles?.textColor
            }}
          >
            {activeLabel}
          </Styled.ClickableAreaText>
          <Styled.ClickableAreaIndicator disabled={_disabled} open={open} />
        </Styled.ClickableArea>
        {isError && (
          <Styled.Error
            data-testid={`dropdown-error${testId ? `-${testId}` : ""}`}
          >
            {error}
          </Styled.Error>
        )}
        <AnimatePresence>
          <DropDownList
            open={open}
            countOptions={countOptions}
            isOverflow={isOverflow}
            animationProps={animationProps}
            options={options}
            onSelect={onSelect}
            value={value}
            multiple={multiple}
            rectConfig={rectConfig}
            listRef={listRef}
            isPortal={rectConfig?.isPortal || false}
            styles={styles}
          />
        </AnimatePresence>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default React.memo(DropDown);
