import { AuthLayout } from "@/layouts";
import useCustomTranslation from "@/localization/useCustomTranslation";
import * as Styled from "./style";
import { Trans } from "react-i18next";

const RecoveryExpired = () => {
  const { prefixedT } = useCustomTranslation("RECOVERY_EXPIRED_PAGE");

  return (
    <AuthLayout>
      <Styled.Wrapper data-testid="recoveryExpired">
        <Styled.LogoContainer />
        <Styled.Title>{prefixedT("TITLE")}</Styled.Title>
        <Styled.SubTitle>
          <Trans
            i18nKey={"RECOVERY_EXPIRED_PAGE.SUBTITLE"}
            components={[
              <a
                href={`mailto:support@fido.tech?subject=${encodeURIComponent("PRIORITY USER: Re-set link for account set-up")}`}
                className="underline"
              />
            ]}
            values={{
              mail: "support@fido.tech"
            }}
          />
        </Styled.SubTitle>
      </Styled.Wrapper>
    </AuthLayout>
  );
};

export default RecoveryExpired;
