import styled from "styled-components";
import { Label as CommonLabel } from "@/styles/Label";
import { Error as CommonError } from "@/styles/Error";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* // TODO: Refactor - use gap and display flex on parent */
  margin-top: 30px;
`;

// TODO: Refactor - come up with a better column handling
export const FormGroup = styled.div<{
  gridCols?: string;
  cols?: number | string;
  width?: string;
}>`
  display: grid;
  grid-template-columns: ${({ gridCols, cols = "auto-fit", width = "1fr" }) =>
    gridCols
      ? gridCols
      : cols === "auto-fit"
        ? `repeat(${cols}, minmax(25ch, ${width}))`
        : `repeat(auto-fit, minmax(min(100%/2, max(250px, 100%/${+cols + 1})), 1fr))`};

  column-gap: 16px;
  row-gap: 16px;
  max-width: 100%;
  /* display: flex;
  gap: 16px; */
`;

export const Wrapper = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-size: 20px;
`;

export const ReturnContainer = styled.div``;

export const ReturnText = styled.p``;

export const ModalContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

export const ModalText = styled.p`
  margin-bottom: 10px;
`;

export const ModalButton = styled.div`
  width: 200px;
  align-self: center;
`;

export const Label = styled(CommonLabel)`
  display: block;
  margin-bottom: 8px;
`;

export const Error = styled(CommonError)`
  margin-top: 6px;
`;
