import useOutsideClick from "@/hooks/useOutsideClick";
import React, { FC, useEffect, useRef, useState } from "react";
import * as Styled from "./style";

// Components
import { Input } from "@fido/ui-lib";

interface SearchBarProps {
  onSearch: (searchText: string) => void;
  placeholder: string;
  ids?: string[];
}

// TODO: Refactor - redo icon as position: relative as opposed to absolute (so it's better for layout styling)

const SearchBar: FC<SearchBarProps> = ({ onSearch, placeholder, ids }) => {
  const [searchText, setSearchText] = useState("");
  const [defaultPlaceholder, setDefaultPlaceholder] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  const containerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      setContainerWidth(width);
    }
  }, []);

  const preventEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    if (newText.length >= 3) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    setSearchText(newText);
    onSearch(newText);
  };

  const filteredItems = ids
    ? ids.filter((item) =>
        item.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
      )
    : [];

  const handleItemClick = (item: string) => {
    setSearchText(item);
    onSearch(item);
    setDefaultPlaceholder(item);
    setShowDropdown(false);
  };

  const ref = useRef(null);
  useOutsideClick(ref, setShowDropdown);

  return (
    <Styled.Wrapper>
      <div className="flex gap-4">
        <Input
          type="text"
          ref={containerRef}
          id="search-bar"
          placeholder={placeholder}
          defaultValue={defaultPlaceholder}
          key={defaultPlaceholder}
          onChange={handleSearch}
          onKeyDown={preventEnterKeyPress}
          autoComplete="off"
        />
        {/* <div>
          <FaSearch className="h-full w-full" />
        </div> */}
      </div>
      {ids && showDropdown && filteredItems.length > 0 && (
        <Styled.DropdownContainer
          ref={ref}
          containerWidth={containerWidth}
          data-testid="dropdown-container"
        >
          {filteredItems.map((item, index) => (
            <Styled.DropdownItem
              data-testid="dropdown-item"
              key={index}
              onClick={() => handleItemClick(item)}
            >
              {item}
            </Styled.DropdownItem>
          ))}
        </Styled.DropdownContainer>
      )}
    </Styled.Wrapper>
  );
};

export default SearchBar;
