import useCustomTranslation from "@/localization/useCustomTranslation";
import { FilterAttributes, FilterContext } from "@/Providers/FilterProvider";
import { Form, Formik, FormikHelpers } from "formik";
import React, { FC, useContext } from "react";
import FilterGroup from "./FilterGroup/FilterGroup";
import * as Styled from "./style";
import { SelectOption } from "@/types/report.type";

type FilterFormProps = {
  onClose: () => void;
  filters: SelectOption | undefined;
};

const FilterForm: FC<FilterFormProps> = ({ filters, onClose }) => {
  const { t } = useCustomTranslation("");
  const { setFilterAttributes, filterAttributes } = useContext(FilterContext);

  const initialStatus =
    filters?.status.map((filterStatus) => ({
      id: filterStatus.id,
      name: filterStatus.status,
      selected: filterAttributes.status.some(
        (status) => status.id === filterStatus.id && status.selected
      )
    })) || [];

  const initialValues: FilterAttributes = {
    ...filterAttributes,
    status: initialStatus
  };

  const onSubmit = (
    values: FilterAttributes,
    { setSubmitting }: FormikHelpers<FilterAttributes>
  ) => {
    setFilterAttributes(values);
    setSubmitting(false);
    onClose();
  };

  return (
    <Styled.Container data-testid="filterForm">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {() => (
          <Form>
            <Styled.TitlesContainer>
              <h3>{t("REPORT.FILTERS.FILTER.BUTTON")}</h3>
              <Styled.SubmitBtn type="submit">
                {t("REPORT.FILTERS.SAVE.BUTTON")}
              </Styled.SubmitBtn>
            </Styled.TitlesContainer>
            <FilterGroup
              title={t("REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE")}
              filterKey="predicted_size"
              filterOptions={[
                {
                  label: t("REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.SMALL"),
                  formName: "Small"
                },
                {
                  label: t("REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.MEDIUM"),
                  formName: "Medium"
                },
                {
                  label: t("REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.LARGE"),
                  formName: "Large"
                }
              ]}
            />
            <FilterGroup
              title={t("REPORT.FILTERS.DROPDOWN.STATUS")}
              filterKey="status"
              filterOptions={filters?.status.map((filterStatus) => ({
                label: t(`REPORT.FILTERS.DROPDOWN.STATUS.${filterStatus.id}`),
                formName: filterStatus.status,
                id: filterStatus.id
              }))}
            />
          </Form>
        )}
      </Formik>
    </Styled.Container>
  );
};

export default FilterForm;
