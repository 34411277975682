import { useState } from "react";
import { useQueryGISUploadsQuery } from "@/redux/GISAdmin/GISAdmin.api";
import { GISAdminPageResponse } from "@/types/GISAdmin.type";
import useCustomTranslation from "@/localization/useCustomTranslation";
import DeleteModal from "./Components/DeleteModal/DeleteModal";
import PreviewModal from "./Components/PreviewModal/PreviewModal";
import { FilterProvider } from "@/Providers/FilterProvider";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { ITablePaginate } from "@/components/Table/types";
import { useGISUploadTableState } from "../../hooks/table/useGISUploadTableState";
import { GISUploadsTable } from "../../components/tables/GISUploadsTable";

// Data
import {
  getRowStructureGISAdmin,
  headersGISAdmin
} from "../../components/tables/GISUploadsTable/data";

const defaultPageCount = 1;

const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];

export const GISAdmin = () => {
  const { t, prefixedT } = useCustomTranslation("GIS_ADMIN");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const {
    searchInput,
    setSearchInput,
    searchQuery,
    setSearchQuery,
    companyId,
    setCompanyId,
    orderBy,
    uploadId,
    setUploadId,
    page,
    setPage,
    perPage,
    setPerPage
  } = useGISUploadTableState();

  const { data, isFetching, isError, refetch } = useQueryGISUploadsQuery(
    {
      page,
      per_page: perPage,
      search: searchQuery,
      order_type: orderBy
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const handleRowAmountChange = (value: DropDownOption) => {
    setPerPage(Number(value.value));
    setPage(1);
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleDeleteClick = (idx: number) => {
    setUploadId(idx);
    setIsDeleteModalOpen(true);
  };

  const handlePreviewClick = (idx: number, companyId: number) => {
    setUploadId(idx);
    setCompanyId(companyId);
    setIsPreviewModalOpen(true);
  };

  const handleSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  const handleSearchClick = () => {
    setSearchQuery(searchInput);
    setPage(1);
  };

  const closeDeleteModal = async (shouldRefetch: boolean) => {
    if (shouldRefetch) {
      await refetch();
    }
    setUploadId(null);
    setIsDeleteModalOpen(false);
  };

  const closePreviewModal = () => {
    setUploadId(null);
    setCompanyId(null);
    setIsPreviewModalOpen(false);
  };

  return (
    <div className="min-h-[100vh-var(--header-height)] p-10">
      {uploadId && (
        <DeleteModal
          open={isDeleteModalOpen}
          uploadId={uploadId}
          closeModal={closeDeleteModal}
        />
      )}
      {uploadId && companyId && (
        <FilterProvider>
          <PreviewModal
            open={isPreviewModalOpen}
            uploadId={uploadId}
            companyId={companyId}
            closeModal={closePreviewModal}
          />
        </FilterProvider>
      )}

      <div className="mb-2">
        <div className="font-medium text-[var(--mediumBlue)]">
          {prefixedT("TITLE")}
        </div>
      </div>

      <GISUploadsTable
        onSearchInputChange={handleSearchInputChange}
        onSearchClick={handleSearchClick}
        // onDeleteClick={handleDeleteClick}
        // onPreviewClick={handlePreviewClick}
        headers={headersGISAdmin()}
        getRowStructure={(row: GISAdminPageResponse, idx: number) =>
          getRowStructureGISAdmin(
            row,
            idx,
            handleDeleteClick,
            handlePreviewClick
          )
        }
        onRowAmountChange={handleRowAmountChange}
        onPageChange={onPageChange}
        title={prefixedT("TABLE.TITLE")}
        data={data?.data}
        isLoading={isFetching}
        error={isError ? t("SERVER_ERRORS.-1") : undefined}
        currentPage={page}
        perPage={perPage}
        totalCount={data?.count}
      />
      {/* <div className="p-2">
        <div className="font-medium text-[var(--mediumBlue)]">
          {prefixedT("TITLE")}
        </div>
      </div>
      <div className="flex mb-4 gap-10">
        <SearchBar
          placeholder={prefixedT("SEARCH.PLACEHOLDER")}
          onSearch={handleSearchInputChange}
        />
        <Button onClick={handleSearchClick}>{prefixedT("SEARCH.TEXT")}</Button>
      </div>
      <div className="p-2">
        <div className="font-medium">{prefixedT("TABLE.TITLE")}</div>
      </div>
      <Table data={data?.data || []} headers={headers()}>
        <Table.Head getHeaderStructure={(header) => header.title} />
        {isFetching && <Loader label="Fetching GIS Uploads..." />}
        {isError && <p>{t("SERVER_ERRORS.-1")}</p>}
        {!isFetching && !isError && (
          <Table.Body
            getRowStructure={(row: GISAdminPageResponse, idx: number) =>
              getRowStructure(row, idx, handleDeleteClick, handlePreviewClick)
            }
            striped
          />
        )}
      </Table>
      <div className="flex items-baseline justify-end mt-2">
        <div className="flex items-baseline">
          <div className="flex whitespace-nowrap items-center text-sm gap-2 mr-2">
            <p>{t("VIEW_USERS.PAGINATION.TITLE")}</p>
            <DropDown
              value={perPage.toString()}
              options={pageValues}
              onChange={(value) =>
                handleRowAmountChange(value as DropDownOption)
              }
              placeholder={"Number of rows"}
            />
          </div>
          <Table.Pagination
            pages={currentPageCount}
            onChange={onPageChange}
            initialPage={page}
          />
        </div>
      </div> */}
    </div>
  );
};
