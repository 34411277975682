import React, { FC } from "react";
import * as Styled from "./style";
import ProcessA from "@/assets/gifs/processA.gif";
import ProcessB from "@/assets/gifs/processB.gif";
import { Button } from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { ErrorModalText } from "@/types/gisUpload.type";

const ErrorModal: FC = () => {
  const { errors, isErrorModalOpen } = useGISUploadValues();
  const { setErrorModalOpen, setErrors } = useGISUploadMethods();
  const { prefixedT } = useCustomTranslation("GIS_UPLOAD.ERROR_MODAL");

  const closeOverlay = () => {
    setErrors([]);
    setErrorModalOpen(false);
  };

  return (
    <>
      {isErrorModalOpen && (
        <>
          <Styled.Underlay data-testid="overlay" onClick={closeOverlay} />
          <Styled.DialogBox data-testid="error-modal" open={true}>
            <Styled.Wrapper>
              {errors?.includes(ErrorModalText.DEFAULT_ERROR_MESSAGE) && (
                <>
                  <Styled.Bold>{prefixedT("TITLE.A")}</Styled.Bold>
                  <Styled.Normal>{prefixedT("TITLE.B")}</Styled.Normal>
                  <Styled.Normal>{prefixedT("TITLE.C")}</Styled.Normal>
                </>
              )}
              {errors?.includes(
                ErrorModalText.COLUMNS_NOT_SELECTED ||
                  ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED
              ) && <Styled.Bold>{prefixedT("TITLE.D")}</Styled.Bold>}
              {errors?.includes(ErrorModalText.COLUMNS_NOT_SELECTED) && (
                <Styled.Container>
                  <Styled.Normal>{prefixedT("DESC.A")}</Styled.Normal>
                  <Styled.GIF
                    src={ProcessA as string}
                    alt={prefixedT("ALT.GIF_A")}
                  />
                </Styled.Container>
              )}
              {errors?.includes(ErrorModalText.COLUMNS_NOT_SELECTED) &&
                errors?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED) && (
                  <Styled.Container>
                    <Styled.Bold>{prefixedT("DESC.C")}</Styled.Bold>
                  </Styled.Container>
                )}
              {errors?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED) && (
                <Styled.Container>
                  <Styled.Normal>{prefixedT("DESC.B")}</Styled.Normal>
                  <Styled.GIF
                    src={ProcessB as string}
                    alt={prefixedT("ALT.GIF_B")}
                  />
                </Styled.Container>
              )}
              <Styled.ButtonContainer>
                <Button onClick={closeOverlay}>
                  {prefixedT("BUTTON.CLOSE")}
                </Button>
              </Styled.ButtonContainer>
            </Styled.Wrapper>
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default ErrorModal;
