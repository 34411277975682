import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Placeholder = keyframes`
  0% { background-position: -600px 0 }
  100% { background-position: 600px 0 }
`;

export const AnimatedBackground = styled.div<{
  mainColor?: string;
  primaryColor?: string;
}>`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${Placeholder};
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    ${({ primaryColor }) => primaryColor} 8%,
    ${({ mainColor }) => mainColor} 18%,
    ${({ primaryColor }) => primaryColor} 33%
  );
  background-size: 1200px 100px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;
