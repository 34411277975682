import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC } from "react";
import packageJson from "../../../package.json";
import Wave from "../Wave/Wave";
import SidebarNav from "./Components/SidebarNav";
import * as Styled from "./style";
import useLogout from "./useLogout";

interface SidebarProps {
  sidebarOpen: boolean;
  sidebarClose: () => void;
  mobileSidebarOpen: boolean;
  mobileSidebarClose: () => void;
}

const Sidebar: FC<SidebarProps> = ({
  sidebarOpen,
  mobileSidebarOpen,
  mobileSidebarClose
}) => {
  const onLogout = useLogout();
  const { prefixedT } = useCustomTranslation("SIDEBAR");

  return (
    <Styled.Wrapper open={sidebarOpen} openMobile={mobileSidebarOpen}>
      <Styled.Container
        open={sidebarOpen}
        openMobile={mobileSidebarOpen}
        data-testid="sidebar"
      >
        <Wave />
        <Styled.MainSection>
          <Styled.HeadBlock>
            <Styled.CloseSidebarIcon onClick={mobileSidebarClose} />
            <Styled.LogoContainer className="sidebar__logo" />
            <Styled.Title className="sidebar__title">
              {prefixedT("TITLE")}
            </Styled.Title>
          </Styled.HeadBlock>
          <Styled.MenuBlock>
            <SidebarNav />
          </Styled.MenuBlock>
        </Styled.MainSection>
        <Styled.LogoutSection>
          <Styled.SeparatorWrapper>
            <Styled.Separator />
          </Styled.SeparatorWrapper>
          <Styled.ButtonWrapper onClick={onLogout} data-testid="logoutAction">
            <Styled.Icon />
            <Styled.Text>{prefixedT("LOGOUT")}</Styled.Text>
          </Styled.ButtonWrapper>
          <Styled.VersionText>
            {"Version " + packageJson.version}
          </Styled.VersionText>
        </Styled.LogoutSection>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Sidebar;
