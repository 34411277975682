import CheckIcon from "@/assets/icons/check.svg?react";

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    min-width: 600px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
`;

export const Title = styled.h3`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.mediumDark};
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.gray};
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const ErrorText = styled.p`
  font-size: 14px;
  margin-top: 10px;
`;

export const SvgContainer = styled.div`
  position: absolute;
  top: -85px;
  left: 0;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  right: 0;
`;

export const AcknowledgementTitle = styled.h3`
  padding: 40px 0 20px 0;
  font-size: 20px;
  color: #676767;
`;

export const CheckPostiveIcon = styled(CheckIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;

  path {
    fill: white;
  }
`;
