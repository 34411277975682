// Types
import type { MapElement } from "@/components/ReportLayout/widgets/MapView/utils/types";
import type { MapFilters } from "@/Providers/FilterProvider";
import type { PreviewValuesResponse } from "@/types/gisUpload.type";

export const formatMapData = (
  data: PreviewValuesResponse["map"] | undefined,
  mapFilters: MapFilters
) => {
  const mapData: MapElement[] = [];

  if (data) {
    const filterMap = {
      DMAS: "dmas",
      PIPES: "pipes",
      ASSETS: "assets"
    } as const;

    for (const filter of mapFilters.filters) {
      const key = filterMap[
        filter as keyof typeof filterMap
      ] as keyof PreviewValuesResponse["map"];

      if (key && data[key]?.data) {
        mapData.push(...data[key].data);
      }
    }
  }

  return mapData;
};
