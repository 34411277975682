import theme from "@/theme";

import styled from "styled-components";
import CrossIcon from "@/assets/icons/chatbot/cross.svg?react";

export const Wrapper = styled.div``;

export const SubmitBtnContainer = styled.div`
  margin-top: 20px;
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const DialogBox = styled.dialog<{
  scroll: boolean;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  min-width: 800px;
  max-height: 80vh;
  overflow-y: ${({ scroll }) => (scroll ? "auto" : "unset")};

  path {
    /* Adjust the width of the SVG itself */
    width: 100%; /* This will scale the SVG to fit the container */
    fill: white;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const FormCol = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ButtonContainerOuter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const ButtonContainerInner = styled.div`
  width: 240px;
`;

export const Error = styled.p`
  color: ${theme.colors.coral};
  font-size: 0.8rem;
  margin-top: 5px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CrossImg = styled(CrossIcon)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;

  path {
    fill: ${({ theme }) => theme.colors.coral};
  }
`;

export const ImageContainer = styled.div`
  margin: 10px;
  width: fit-content;
  max-width: 200px;
`;

export const Image = styled.img`
  width: 100%;
`;
