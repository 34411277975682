import { MeasurementSystem } from "./Measurement.constant";

export const UnitCategory = {
  FLOW_RATE_UNIT: "FlowRateUnit",
  LONG_DISTANCE_UNIT: "LongDistanceUnit",
  VOLUME_UNIT: "VolumeUnit",
  SHORT_DISTANCE_UNIT: "ShortDistanceUnit",
  WEIGHT_UNIT: "WeightUnit",
  DIAMETER_UNIT: "DiameterUnit"
} as const;

export const Unit = {
  // FlowRateUnit
  CUBIC_METERS_PER_HOUR: "CUBIC_METERS_PER_HOUR",
  CUBIC_METERS_PER_DAY: "CUBIC_METERS_PER_DAY",
  LITERS_PER_SECOND: "LITERS_PER_SECOND",
  MEGALITERS_PER_HOUR: "MEGALITERS_PER_HOUR",
  MEGALITERS_PER_DAY: "MEGALITERS_PER_DAY",
  GALLONS_US_PER_MINUTE: "GALLONS_US_PER_MINUTE",
  GALLONS_US_PER_HOUR: "GALLONS_US_PER_HOUR",
  GALLONS_US_PER_DAY: "GALLONS_US_PER_DAY",
  MEGAGALLONS_US_PER_HOUR: "MEGAGALLONS_US_PER_HOUR",
  MEGAGALLONS_US_PER_DAY: "MEGAGALLONS_US_PER_DAY",

  // VolumeUnit
  CUBIC_METERS: "CUBIC_METERS",
  LITERS: "LITERS",
  MEGALITERS: "MEGALITERS",
  GALLONS_US: "GALLONS_US",
  MEGAGALLONS_US: "MEGAGALLONS_US",

  // WeightUnit
  KILOGRAMS: "KILOGRAMS",
  POUNDS: "POUNDS",

  // LongDistanceUnit
  KILOMETERS: "KILOMETERS",
  MILES: "MILES",

  // ShortDistanceUnit
  METERS: "METERS",
  FEET: "FEET",
  YARDS: "YARDS",
  CENTIMETERS: "CENTIMETERS",

  // DiameterUnit
  MILLIMETERS: "MILLIMETERS",
  INCHES: "INCHES"
} as const;

export const UnitSymbol = {
  // FlowRateUnit
  [Unit.CUBIC_METERS_PER_HOUR]: "m3/h",
  [Unit.CUBIC_METERS_PER_DAY]: "m3/d",
  [Unit.LITERS_PER_SECOND]: "l/s",
  [Unit.MEGALITERS_PER_HOUR]: "ML/h",
  [Unit.MEGALITERS_PER_DAY]: "ML/d",
  [Unit.GALLONS_US_PER_MINUTE]: "G/m",
  [Unit.GALLONS_US_PER_HOUR]: "G/h",
  [Unit.GALLONS_US_PER_DAY]: "G/d",
  [Unit.MEGAGALLONS_US_PER_HOUR]: "MG/h",
  [Unit.MEGAGALLONS_US_PER_DAY]: "MG/d",

  // VolumeUnit
  [Unit.CUBIC_METERS]: "m3",
  [Unit.LITERS]: "l",
  [Unit.MEGALITERS]: "ML",
  [Unit.GALLONS_US]: "G",
  [Unit.MEGAGALLONS_US]: "MG",

  // WeightUnit
  [Unit.KILOGRAMS]: "kg",
  [Unit.POUNDS]: "lb",

  // LongDistanceUnit
  [Unit.KILOMETERS]: "km",
  [Unit.MILES]: "mi",

  // ShortDistanceUnit
  [Unit.METERS]: "m",
  [Unit.FEET]: "ft",
  [Unit.YARDS]: "yd",
  [Unit.CENTIMETERS]: "cm",

  // DiameterUnit
  [Unit.MILLIMETERS]: "mm",
  [Unit.INCHES]: "in"
} as const;

export const UnitCategoryData = {
  [UnitCategory.FLOW_RATE_UNIT]: {
    default: {
      [MeasurementSystem.IMPERIC]: Unit.GALLONS_US_PER_MINUTE,
      [MeasurementSystem.METRIC]: Unit.CUBIC_METERS_PER_HOUR
    },
    options: [
      Unit.CUBIC_METERS_PER_HOUR,
      Unit.CUBIC_METERS_PER_DAY,
      Unit.LITERS_PER_SECOND,
      Unit.MEGALITERS_PER_HOUR,
      Unit.MEGALITERS_PER_DAY,
      Unit.GALLONS_US_PER_MINUTE,
      Unit.GALLONS_US_PER_HOUR,
      Unit.GALLONS_US_PER_DAY,
      Unit.MEGAGALLONS_US_PER_HOUR,
      Unit.MEGAGALLONS_US_PER_DAY
    ]
  },
  [UnitCategory.VOLUME_UNIT]: {
    default: {
      [MeasurementSystem.IMPERIC]: Unit.GALLONS_US,
      [MeasurementSystem.METRIC]: Unit.CUBIC_METERS
    },
    options: [
      Unit.CUBIC_METERS,
      Unit.LITERS,
      Unit.MEGALITERS,
      Unit.GALLONS_US,
      Unit.MEGAGALLONS_US
    ]
  },
  [UnitCategory.WEIGHT_UNIT]: {
    default: {
      [MeasurementSystem.IMPERIC]: Unit.POUNDS,
      [MeasurementSystem.METRIC]: Unit.KILOGRAMS
    },
    options: [Unit.KILOGRAMS, Unit.POUNDS]
  },
  [UnitCategory.LONG_DISTANCE_UNIT]: {
    default: {
      [MeasurementSystem.IMPERIC]: Unit.MILES,
      [MeasurementSystem.METRIC]: Unit.KILOMETERS
    },
    options: [Unit.KILOMETERS, Unit.MILES]
  },
  [UnitCategory.SHORT_DISTANCE_UNIT]: {
    default: {
      [MeasurementSystem.IMPERIC]: Unit.FEET,
      [MeasurementSystem.METRIC]: Unit.METERS
    },
    options: [Unit.METERS, Unit.FEET, Unit.YARDS, Unit.CENTIMETERS]
  },
  [UnitCategory.DIAMETER_UNIT]: {
    default: {
      [MeasurementSystem.IMPERIC]: Unit.INCHES,
      [MeasurementSystem.METRIC]: Unit.MILLIMETERS
    },
    options: [Unit.MILLIMETERS, Unit.INCHES]
  }
} as const;
