import { createSelector } from "reselect";
import { RootState } from "..";
import { DEFAULT_UPLOAD_VALUES, UploadId } from "./gisUpload.slice";

export const mainSelector = (state: RootState) => state.gisUpload;

export const makeUploadValuesSelector = () =>
  createSelector(
    [mainSelector, (_: RootState, uploadId: UploadId) => uploadId],
    ({ gisUpload }, uploadId) => {
      if (typeof uploadId !== "number") return DEFAULT_UPLOAD_VALUES;

      let gisUploadValues = DEFAULT_UPLOAD_VALUES;

      if (gisUpload[uploadId]) {
        gisUploadValues = gisUpload[uploadId];
      }

      return gisUploadValues;
    }
  );
