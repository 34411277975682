import { Notification } from "@/components";
import { appRouter } from "@/routes/appRouter";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "@fido/config-tw/index.css";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { LocalizationProvider } from "./localization";
import { persistor, store } from "./redux";

import theme from "./theme";

const loadingText = "Loading...";

const App = () => (
  <Provider store={store}>
    <PersistGate loading={loadingText} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider>
          <Notification />
          <RouterProvider
            router={appRouter}
            future={{
              // @ts-expect-error - remove after updating to router v7
              v7_relativeSplatPath: true,
              v7_startTransition: true
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
export default App;
