import moment from "moment";
import React, { FC, useEffect, useState } from "react";

import {
  Button,
  ContentLoader,
  DatePicker,
  DropDown,
  Loader,
  Table
} from "@/components";
import { ITablePaginate, TableSortOrders } from "@/components/Table/types";
import { DropDownOption } from "@/components/DropDown/DropDown";
import {
  getRowStructure,
  headers,
  isFilterColumn,
  isQueryEqual,
  isSortColumn
} from "./JobTableUtils";
import * as Styled from "./style";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { WaypointJob, WaypointJobRequestBody } from "@/types/report.type";
import {
  useLazyPostWaypointJobsQuery,
  useGetDistinctJobValuesQuery
} from "@/redux/reports/reports.api";
import FilterColumn from "./FilterColumn/FilterColumn";
import { IJobFilter } from "@/types/rtk.type";

export interface JobTableProps {
  waypointId: string;
}

const JobTable: FC<JobTableProps> = ({ waypointId }) => {
  const [trigger, { data, isLoading, isFetching }] =
    useLazyPostWaypointJobsQuery();

  const { data: distinctValues } = useGetDistinctJobValuesQuery();

  const { prefixedT } = useCustomTranslation("WAYPOINT_PAGE.JOB_TABLE");

  const dateRangeValues = [
    { value: "created_at", label: prefixedT("CREATED_DATE") },
    { value: "repair_date", label: prefixedT("REPAIR_DATE") },
    { value: "updated_at", label: prefixedT("UPDATED_DATE") }
  ];

  const tableHeaders = headers();

  const [isInitialFetch, setIsInitialFetch] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(20);

  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.desc);
  const [orderBy, setOrderBy] = useState<string>("created_at");

  const [dateType, setDateType] = useState("created_at");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [isDateError, setIsDateError] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filter, setFilter] = useState<IJobFilter>({
    job_status: [],
    leak_type: []
  });

  const [lastQuery, setLastQuery] = useState<WaypointJobRequestBody>({
    id: waypointId,
    page,
    per_page: perPage,
    sorted_by: orderBy,
    sorted_order: order,
    search: searchQuery,
    filter: {
      job_status: filter.job_status,
      leak_type: filter.leak_type
    },
    date_type: dateType,
    date_to: dateTo ? moment(dateTo).format("YYYY-MM-DD") : "",
    date_from: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : ""
  });

  const currentQuery: WaypointJobRequestBody = {
    id: waypointId,
    page,
    per_page: perPage,
    sorted_by: orderBy,
    sorted_order: order,
    search: searchQuery,
    filter: {
      job_status: filter.job_status,
      leak_type: filter.leak_type
    },
    date_type: dateType,
    date_to: dateTo ? moment(dateTo).format("YYYY-MM-DD") : "",
    date_from: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : ""
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const refetchWithArgs = () => {
    if (
      dateTo !== null &&
      dateFrom !== null &&
      moment(dateTo).isBefore(dateFrom)
    ) {
      setIsDateError(true);
    } else {
      setIsDateError(false);

      const body = {
        id: waypointId,
        page,
        per_page: perPage,
        sorted_by: orderBy,
        sorted_order: order,
        search: searchQuery,
        filter: {
          job_status: filter.job_status,
          leak_type: filter.leak_type
        },
        date_type: dateType,
        date_to: dateTo !== null ? moment(dateTo).format("YYYY-MM-DD") : "",
        date_from:
          dateFrom !== null ? moment(dateFrom).format("YYYY-MM-DD") : ""
      };

      setLastQuery(body);

      void trigger(body);
    }
  };

  useEffect(() => {
    if ((data?.data || []).length > 0) {
      setIsInitialFetch(false);
    }
  }, [data]);

  useEffect(() => {
    refetchWithArgs();
  }, [page, perPage]);

  return (
    <Styled.Wrapper>
      <Styled.TableTitle>{prefixedT("TITLE")}</Styled.TableTitle>
      <ContentLoader active={data?.data && isFetching} />
      {isLoading ? (
        <Loader />
      ) : isInitialFetch && data?.data.length === 0 ? (
        <p>{prefixedT("NO_DATA_EXISTS")}</p>
      ) : data?.data !== undefined ? (
        <div data-testid="job-table">
          <Styled.TableHeader>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {prefixedT("FILTERS")}
              </Styled.TableHeaderTitle>
              <Styled.SearchBarContainer>
                <Styled.SearchBarContainer>
                  {prefixedT("DATE_TYPE")}:
                  <div style={{ width: 200 }}>
                    <DropDown
                      options={dateRangeValues}
                      placeholder={prefixedT("DATE_TYPE.PLACEHOLDER")}
                      value={dateType}
                      onChange={(e) => {
                        const newValue = e as DropDownOption;
                        setDateType(newValue.value as string);
                      }}
                      multiple={false}
                    />
                  </div>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateFrom}
                      onChange={(value) => {
                        setDateFrom(value);
                      }}
                      placeholder={prefixedT("START_DATE")}
                    />
                  </Styled.DatePickerContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateTo}
                      onChange={(value) => {
                        setDateTo(value);
                      }}
                      placeholder={prefixedT("END_DATE")}
                    />
                  </Styled.DatePickerContainer>
                </Styled.SearchBarContainer>
                <Styled.SearchBarContainerInner>
                  <Styled.Input
                    placeholder={prefixedT("SEARCH.PLACEHOLDER")}
                    value={searchQuery}
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setPage(1);
                        refetchWithArgs();
                      }
                    }}
                  />
                </Styled.SearchBarContainerInner>
                <Button
                  onClick={() => {
                    setPage(1);
                    refetchWithArgs();
                  }}
                  disabled={isQueryEqual(lastQuery, currentQuery)}
                >
                  {prefixedT("SEARCH.BUTTON")}
                </Button>
              </Styled.SearchBarContainer>
              {isDateError && (
                <Styled.Error>{prefixedT("DATE_ERROR")}</Styled.Error>
              )}
            </Styled.TableHeaderSection>
          </Styled.TableHeader>
          <Table data={data.data} headers={tableHeaders} minHeight="350px">
            <Table.Head
              getHeaderStructure={(header) => {
                return (
                  <FilterColumn
                    header={header}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showFilterOption={isFilterColumn(header.id)}
                    showSortOption={isSortColumn(header.id)}
                    filterOptions={distinctValues?.[header.id] || []}
                    filter={filter}
                    setFilter={setFilter}
                    isLastColumn={header.id === "comment"}
                  />
                );
              }}
            />
            {data !== undefined && data.count > 0 ? (
              <Table.Body
                getRowStructure={(job: WaypointJob, index: number) =>
                  getRowStructure(job, job.waypoint_id, index)
                }
                striped
              />
            ) : (
              <h3>{prefixedT("NO_DATA")}</h3>
            )}
          </Table>
          <Styled.PaginationWrapper key={page}>
            <Table.Pagination
              pages={Math.ceil(data.count / perPage)}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PaginationWrapper>
        </div>
      ) : null}
    </Styled.Wrapper>
  );
};

export default JobTable;
