// Types
import {
  GetWaypointsFromWaypointParams,
  GetWaypointsFromWaypointResponse,
  PatchUpdateProjectBody,
  PatchUpdateWaypointPortalBody,
  PostAssignWaypointsParams,
  PostMergeWaypointsParams
} from "@/types/highlandPortal";

// Constants
import { Tag } from "../tag";

// Utils
import { buildQueryParams } from "@/utils/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "../api";
import { reportApi } from "../reports/reports.api";

export const highlandPortalApi = createApi({
  reducerPath: "highlandPortalQuery",
  baseQuery: apiBaseQuery,
  tagTypes: [
    Tag.WAYPOINT,
    "PostAssignWaypoints",
    "GetWaypointsFromWaypoint",
    "PatchUpdateProject"
  ],
  endpoints: (builder) => ({
    postAssignWaypoints: builder.mutation<
      void,
      { query: PostAssignWaypointsParams; body: number[] }
    >({
      query: ({ query, body }) => ({
        url: `/highland_portal/assign_waypoints/?${buildQueryParams(query || {})}`,
        body,
        method: "POST"
      }),
      invalidatesTags: ["PostAssignWaypoints"]
    }),
    patchUpdateWaypoint: builder.mutation<
      void,
      { query: number | string; body: PatchUpdateWaypointPortalBody }
    >({
      query: ({ query, body }) => ({
        url: `/highland_portal/update_waypoint_portal/${query}`,
        body,
        method: "PATCH"
      }),
      invalidatesTags: [{ type: Tag.WAYPOINT }],
      // TODO: Refactor redux - use injectEndpoints as opposed to having a bunch of createApi instances (for invalidation purposes between the same baseQuery)
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(reportApi.util.invalidateTags([{ type: Tag.WAYPOINT }]));
      }
    }),
    postMergeWaypoints: builder.mutation<
      void,
      { params: PostMergeWaypointsParams; body: string }
    >({
      query: ({ params, body }) => ({
        url: `/highland_portal/waypoint/merge_waypoints/?${buildQueryParams(params || {})}`,
        body,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        method: "POST"
      }),
      invalidatesTags: [{ type: Tag.WAYPOINT }]
    }),
    getWaypointsFromWaypoint: builder.query<
      GetWaypointsFromWaypointResponse[],
      GetWaypointsFromWaypointParams
    >({
      query: (params: GetWaypointsFromWaypointParams) =>
        `/highland_portal/waypoint/drop_down/?${buildQueryParams(params || {})}`,
      providesTags: ["GetWaypointsFromWaypoint"]
    }),
    patchUpdateProject: builder.mutation<
      void,
      { id: number | string; body: PatchUpdateProjectBody }
    >({
      query: ({ id, body }) => ({
        url: `/highland_portal/update_project/${id}`,
        body,
        method: "PATCH"
      }),
      invalidatesTags: ["PatchUpdateProject"]
    })
  })
});

export const {
  usePostAssignWaypointsMutation,
  usePatchUpdateWaypointMutation,
  useGetWaypointsFromWaypointQuery,
  usePostMergeWaypointsMutation,
  usePatchUpdateProjectMutation
} = highlandPortalApi;
