import { useMemo } from "react";

// Types
import { GISRoutes } from "@/types/gisUpload.type";
import { GISType } from "../ProcessFiles/ProcessFiles";

// Hooks
import {
  useGISUploadContext,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { extractTables, groupDataByType } from "../utils";

// Redux
import { useGetColumnAndSampleDataQuery } from "@/redux/gisUpload/gisUpload.api";

export const useCurrentStepData = () => {
  const { uploadId } = useGISUploadContext();
  const { currentStep, companyId } = useGISUploadValues();

  const {
    data,
    isLoading: isQueryLoading,
    isFetching: isQueryFetching
  } = useGetColumnAndSampleDataQuery(
    { companyId, uploadId },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyId
    }
  );

  const isLoading = isQueryLoading || isQueryFetching;

  const { dmaColumns, assetColumns, pipeColumns } = extractTables(
    data?.table_columns
  );
  const { dmaSampleData, assetSampleData, pipeSampleData } = groupDataByType(
    data?.sample_data
  );

  const currentStepData = useMemo(() => {
    switch (currentStep) {
      case GISRoutes.PROCESS_DMA:
        return {
          sampleData: dmaSampleData,
          tableColumns: dmaColumns,
          type: GISType.DMA
        };
      case GISRoutes.PROCESS_PIPES:
        return {
          sampleData: pipeSampleData,
          tableColumns: pipeColumns,
          type: GISType.PIPES
        };
      case GISRoutes.PROCESS_ASSETS:
        return {
          sampleData: assetSampleData,
          tableColumns: assetColumns,
          type: GISType.ASSETS
        };
      case GISRoutes.MAP_PREVIEW:
        return {
          sampleData: null,
          tableColumns: null,
          type: null
        };
      default:
        return {
          sampleData: null,
          tableColumns: null,
          type: null
        };
    }
  }, [currentStep, data]);

  return { ...currentStepData, isLoading };
};
