import { Loader } from "@/components";
import {
  useGISUploadContext,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { useContext, useEffect, useMemo } from "react";
import { FC } from "react";
import GenerateMap from "@/components/ReportLayout/widgets/MapView/GenerateMap";
import { useGetPreviewGISQuery } from "@/redux/gisUpload/gisUpload.api";
import { formatMapData } from "./utils";
import { FilterContext, FilterName } from "@/Providers/FilterProvider";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { MapProvider } from "@/Providers/MapProvider";

const MapPreview: FC = () => {
  const { prefixedT } = useCustomTranslation("GIS_UPLOAD.MAP_PREVIEW");
  const { uploadId } = useGISUploadContext();
  const { companyId } = useGISUploadValues();

  const { setFilterMapValues, setMapFilters, mapFilters } =
    useContext(FilterContext);

  const defaultFilterMapValues: FilterName[] = ["DMAS", "PIPES", "ASSETS"];

  const { data, isFetching, isError } = useGetPreviewGISQuery(
    {
      companyId,
      uploadId
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    setFilterMapValues(defaultFilterMapValues);
    setMapFilters({ filters: ["DMAS", "PIPES", "ASSETS"] });
  }, []);

  const mapData = useMemo(
    () => formatMapData(data, mapFilters),
    [data, mapFilters]
  );

  return (
    <div
      data-testid="gis-map-preview"
      className="w-full overflow-x-auto min-h-0"
    >
      {isError && <p>{prefixedT("API.GENERAL_ERROR")}</p>}
      {isFetching && (
        <div>
          <Loader />
        </div>
      )}
      {data && (
        <div className="h-[600px]" data-testid="map-preview">
          <MapProvider>
            <GenerateMap
              fullMapData={mapData}
              readonly={false}
              viewportMapData={mapData}
              showMapFilters={true}
              isLoading={isFetching}
            />
          </MapProvider>
        </div>
      )}
    </div>
  );
};

export default MapPreview;
