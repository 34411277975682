import useCustomTranslation from "@/localization/useCustomTranslation";
import { UserRoles } from "@/types/user.type";
import AddCompanyIconSVG from "@/assets/icons/add_company.svg";
import CompanyIconSVG from "@/assets/icons/company.svg";
import projectIcon from "@/assets/icons/project.svg";
import addProjectIcon from "@/assets/icons/add_project.svg";
import PartnerIcon from "@/assets/icons/partner.svg";
import AddPartnerIcon from "@/assets/icons/add_partner.svg";
import AddUserIcon from "@/assets/icons/add_user.svg";
import ViewUserIcon from "@/assets/icons/users.svg";

export const CreateUserManagementDropDownRoutes = (role: UserRoles) => {
  const { prefixedT } = useCustomTranslation("USER_MANAGEMENT.ROUTE_TITLES");

  const routes = [
    {
      name: prefixedT("PARTNER"),
      role: [UserRoles.SUPER_ADMIN],
      values: [
        {
          name: prefixedT("VIEW_PARTNERS"),
          url: "view-partners",
          image: PartnerIcon as string
        },
        {
          name: prefixedT("ADD_PARTNER"),
          url: "add-partner",
          image: AddPartnerIcon as string
        }
      ]
    },
    {
      name: prefixedT("COMPANY"),
      role: [UserRoles.SUPER_ADMIN, UserRoles.PARTNER_ADMIN],
      values: [
        {
          name: prefixedT("VIEW_COMPANIES"),
          url: "view-companies",
          image: CompanyIconSVG as string
        },
        {
          name: prefixedT("ADD_COMPANY"),
          url: "add-company",
          image: AddCompanyIconSVG as string
        }
      ]
    },
    {
      name: prefixedT("PROJECT"),
      role: [
        UserRoles.SUPER_ADMIN,
        UserRoles.PARTNER_ADMIN,
        UserRoles.COMPANY_ADMIN
      ],
      values: [
        {
          name: prefixedT("VIEW_PROJECTS"),
          url: "view-projects",
          image: projectIcon as string
        },
        {
          name: prefixedT("ADD_PROJECT"),
          url: "project/new",
          image: addProjectIcon as string
        }
      ]
    },
    {
      name: prefixedT("USER"),
      role: [
        UserRoles.SUPER_ADMIN,
        UserRoles.PARTNER_ADMIN,
        UserRoles.COMPANY_ADMIN
      ],
      values: [
        {
          name: prefixedT("VIEW_USERS"),
          url: "view-users",
          image: ViewUserIcon as string
        },
        {
          name: prefixedT("ADD_USER"),
          url: "add-user",
          image: AddUserIcon as string
        }
      ]
    }
  ];

  const filteredRoutes = routes.filter((route) => route.role.includes(role));

  return filteredRoutes;
};
