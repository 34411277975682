import { useGetProfileQuery } from "@/redux/user/user.api";

// Types
import type { UserRoles } from "@/types/user.type";

export const useUserRole = () => {
  const { data: profile } = useGetProfileQuery();
  const role = profile?.role?.role_code || ("" as UserRoles);

  return role;
};
