import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { Map } from "mapbox-gl";
import { MapElement } from "../../utils/types";
import { clickEvents, lineLayers } from "./utils";

const drawLines = (
  map: Map,
  items: MapElement[],
  doNotAllowClickThrough: boolean
): void => {
  const combinedFeatures = items.map((item) => ({
    type: "Feature",
    properties: {
      tooltip: item.tooltip,
      id: item.id
    },
    geometry: {
      type: "LineString",
      coordinates: item.geometry
    }
  })) as Feature<Geometry, GeoJsonProperties>[];

  lineLayers(map, combinedFeatures);
  clickEvents(map, doNotAllowClickThrough);
};

export default drawLines;
