import React, { FC, useEffect, useState } from "react";
import * as Styled from "./style";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { Button, DropDown, TextField } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import ImageUploadBox from "@/components/ImageUploadBox/ImageUploadBox";
import { usePatchUpdatePartnerMutation } from "@/redux/portalPartners/portalPartners.api";
import theme from "@/theme";
import i18n from "@/localization/i18n";
import { dropDownCountryData } from "@/utils/countryData";
import { PatchUpdatePartnerBody } from "@/types/portalPartner.type";

interface EditPartnerProps {
  partner_id: number;
  partner_name: string;
  partner_code: string;
  country_code: string;
  logo: string;
  closeOverlay: (refetchValues: boolean) => Promise<void>;
}

const EditPartner: FC<EditPartnerProps> = ({
  partner_id,
  partner_name,
  partner_code,
  country_code,
  logo,
  closeOverlay
}) => {
  const [mutate, { isSuccess, isError, reset }] =
    usePatchUpdatePartnerMutation();
  const { t, prefixedT } = useCustomTranslation("EDIT_PARTNER");
  const initialValues: PatchUpdatePartnerBody = {
    partner_name,
    partner_code,
    country_code,
    logo
  };

  const [countryCodes, setCountryCodes] = useState<DropDownOption[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const countryData = await dropDownCountryData();

      setCountryCodes(countryData);
    };

    void fetchData();
  }, [i18n.language]);

  const [isNoChange, setIsNoChange] = useState(false);

  const onSubmit = async (values: PatchUpdatePartnerBody) => {
    // check what has changed and mutate those values only
    const changedValues: PatchUpdatePartnerBody = {};

    Object.entries(initialValues).forEach(([key, value]) => {
      const typedKey = key as keyof PatchUpdatePartnerBody;
      if (values[typedKey] !== value) {
        (changedValues[typedKey] as string | number | undefined) =
          values[typedKey];
      }
    });

    if (Object.entries(changedValues).length === 0) {
      setIsNoChange(true);
    } else {
      await mutate({ id: partner_id, body: changedValues });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await closeOverlay(true);
        reset();
      }
    };

    void fetchData();
  }, [isSuccess]);

  const ValidationSchema = Yup.object().shape({
    partner_name: Yup.string().required(t("VALIDATION.REQUIRED")),
    partner_code: Yup.string().required(t("VALIDATION.REQUIRED")),
    country_code: Yup.string().required(t("VALIDATION.REQUIRED")),
    logo: Yup.string()
  });

  return (
    <>
      <Styled.Underlay
        data-testid={"overlay"}
        onClick={() => {
          void (async () => {
            await closeOverlay(false);
          })();
        }}
      />
      <Styled.DialogBox open={true}>
        <Styled.Wrapper>
          <Styled.TitleContainer>
            <h3>{prefixedT("TITLE")}</h3>
          </Styled.TitleContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, errors, submitForm, setFieldValue }) => {
              return (
                <div>
                  <div>
                    <ImageUploadBox
                      label={t("ADD_COMPANY.LOGO.LABEL")}
                      onImageUpload={(imageString: string[]) =>
                        void setFieldValue("logo", imageString[0])
                      }
                    />
                  </div>
                  <Styled.FormRow>
                    <Field
                      as={TextField}
                      required
                      name="partner_name"
                      testId="partner-name"
                      error={errors.partner_name}
                      label={prefixedT("PARTNER_NAME")}
                    />
                    <Field
                      as={TextField}
                      required
                      name="partner_code"
                      testId="partner-code"
                      error={errors.partner_code}
                      label={prefixedT("PARTNER_CODE")}
                    />
                    <Styled.MarginBottom>
                      <Field
                        as={DropDown}
                        required
                        styles={{ border: true }}
                        options={
                          countryCodes as DropDownOption | DropDownOption[]
                        }
                        label={prefixedT("COUNTRY")}
                        placeholder={t("ADD_COMPANY.COUNTRY.PLACEHOLDER")}
                        onChange={(option: DropDownOption) =>
                          setFieldValue("country_code", option.value)
                        }
                        countOptions={4}
                        error={errors.country_code?.toString()}
                        value={values.country_code?.toString()}
                        testId="country"
                      />
                    </Styled.MarginBottom>
                  </Styled.FormRow>
                  <Styled.ButtonContainer>
                    <Button
                      type="submit"
                      color={theme.colors.coral}
                      onClick={() => {
                        void (async () => {
                          await closeOverlay(false);
                        })();
                      }}
                    >
                      {t("EDIT_COMPANY_PAGE.FORM.DISCARD")}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                    >
                      {t("EDIT_COMPANY_PAGE.FORM.SUBMIT")}
                    </Button>
                  </Styled.ButtonContainer>
                  {isError && (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>{t("ERROR.REQUEST")}</Styled.ErrorText>
                    </Styled.ErrorContainer>
                  )}
                  {isNoChange && (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>
                        {t("EDIT_COMPANY_PAGE.FORM.NO_CHANGES")}
                      </Styled.ErrorText>
                    </Styled.ErrorContainer>
                  )}
                </div>
              );
            }}
          </Formik>
        </Styled.Wrapper>
      </Styled.DialogBox>
    </>
  );
};

export default EditPartner;
