import React, { FC } from "react";
import * as Styled from "./style";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { ErrorModalText, Column } from "@/types/gisUpload.type";
import { useGISUploadValues } from "@/Providers/GISUploadProvider";
import { Badge, cn } from "@fido/ui-lib";

export type FileInformationProps = {
  insufficientSampleData: boolean;
  unselectedColumns: {
    required: Column[];
    optional: Column[];
  };
  hasNonRequiredField: boolean;
};

export const FileInformation: FC<FileInformationProps> = ({
  insufficientSampleData,
  unselectedColumns,
  hasNonRequiredField
}) => {
  const { prefixedT } = useCustomTranslation("PROCESS_FILES");
  const { errors } = useGISUploadValues();

  return (
    <div>
      {insufficientSampleData ? (
        <p className="text-destructive">
          {prefixedT("ERROR.INSUFFICIENT_COLUMNS")}
        </p>
      ) : (
        <div className="space-y-4">
          <h3 className="font-medium">{prefixedT("INSTRUCTIONS")}</h3>
          <div className="space-y-2">
            {unselectedColumns.required.length > 0 ? (
              <div className="flex flex-wrap items-center gap-1">
                <span
                  className={cn("text-nowrap", {
                    "text-destructive": errors?.includes(
                      ErrorModalText.COLUMNS_NOT_SELECTED
                    )
                  })}
                  data-testid="file-column-text"
                >
                  {prefixedT("COLUMNS_SELECT")}:{" "}
                </span>
                {unselectedColumns.required.map((column) => (
                  <Badge key={column.column_name}>{column.column_name}</Badge>
                ))}
              </div>
            ) : (
              <p>{prefixedT("COLUMNS_SELECTED")} ✅</p>
            )}
            {hasNonRequiredField && (
              <>
                {unselectedColumns.optional.length > 0 ? (
                  <div
                    className="flex flex-wrap items-center gap-1"
                    data-testid="file-column-text"
                  >
                    <span className="text-nowrap">
                      {prefixedT("OPTION_COLUMNS_SELECT")}:
                    </span>
                    {unselectedColumns.optional.map((column) => (
                      <Badge
                        key={column.column_name}
                        className="text-white bg-border"
                      >
                        {column.column_name}
                      </Badge>
                    ))}
                  </div>
                ) : (
                  <p>{prefixedT("OPTIONAL_COLUMNS_SELECTED")} ✅</p>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
