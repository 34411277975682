import { useFormContext } from "react-hook-form";

// Components
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@fido/ui-lib";

// Types
import type { ProjectFormValues } from "../schema";
import type { DisabledFields } from "@/components/Form/types";

// Hooks
import { usePartnersAndCompanies } from "@/hooks/query/usePartnersAndCompanies";

type ProjectBaseProps = {
  disableFields?: DisabledFields<Pick<ProjectFormValues, "companyId" | "name">>;
};

export const ProjectBase = (props: ProjectBaseProps) => {
  // const { prefixedT } = useCustomTranslation("ADD_PROJECT");

  const form = useFormContext<ProjectFormValues>();

  const partnersAndCompanies = usePartnersAndCompanies();

  return (
    <div className="grid grid-cols-2 gap-4">
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Project name</FormLabel>
            <FormControl>
              <Input
                placeholder="FIDO Tech"
                {...field}
                disabled={field.disabled || props.disableFields?.name}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="companyId"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Company</FormLabel>
            <Select
              onValueChange={(value) => {
                field.onChange(Number(value));
                form.setValue("dmaIds", []);
              }}
              defaultValue={field.value?.toString()}
              disabled={field.disabled || props.disableFields?.companyId}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select company" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {!!partnersAndCompanies.companies &&
                  partnersAndCompanies.companies.length > 0 &&
                  partnersAndCompanies.companies.map((c) => (
                    <SelectItem key={c.value} value={c.value}>
                      {c.label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
