import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import * as Styled from "./checkboxStyle";
import { FilterAttributes } from "@/Providers/FilterProvider";

type Props = {
  label: string;
  formName: string;
  id?: number;
};

const Checkbox: FC<Props> = ({ formName, label, id }) => {
  const [field, , helpers] = useField(formName);
  const { setFieldValue, values } = useFormikContext<FilterAttributes>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (id !== undefined) {
      const statusIndex = values.status.findIndex(
        (item: { id: number }) => item.id === id
      );

      if (statusIndex !== -1) {
        const updatedStatus = [...values.status];
        updatedStatus[statusIndex] = {
          ...updatedStatus[statusIndex],
          selected: checked
        };

        void setFieldValue("status", updatedStatus);
      }
    } else {
      helpers.setValue(e.target.checked);
    }
  };

  if (id !== undefined) {
    values.status.map((status: { id: number; selected: boolean }) => {
      if (status.id === id) {
        field.value = status.selected;
      }
    });
  }

  return (
    <>
      <Styled.CheckboxInput
        id={field.name}
        name={field.name}
        checked={field.value as boolean}
        onChange={handleChange}
      />
      <label htmlFor={field.name}>{label}</label>
    </>
  );
};

export default Checkbox;
