import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  border-radius: 20px;
  gap: 20px;
`;

export const Panel = styled.div`
  border: 1px solid var(--border);
  border-radius: 10px;
  flex: 1;
  text-align: center;
  padding: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Unit = styled.p`
  font-size: 20px;
  color: #009fe3;
  font-weight: 600;
`;

export const DetailTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
`;

export const TimeUnit = styled.p`
  font-size: 12px;
  color: #77c2e5;
  font-weight: 600;
`;
