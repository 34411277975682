import { getGridItemWidth } from "@/utils/style";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: ${getGridItemWidth(3, 20)};
  column-gap: 20px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    grid-template-columns: ${getGridItemWidth(2, 20)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    grid-template-columns: ${getGridItemWidth(1, 20)};
  }
`;

export const Card = styled.div`
  padding: 20px;
  padding-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  width: calc(100% - 40px);
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
export const Label = css`
  color: ${({ theme }) => theme.colors.blue};
`;
