import Button from "@/components/Button/Button";
import Dialog from "@/components/Dialog/Dialog";
import DropDown, { DropDownOption } from "@/components/DropDown/DropDown";
import { Modal } from "@/components/Modal";
import useCustomTranslation from "@/localization/useCustomTranslation";
import theme from "@/theme";
import {
  ReportDataSource,
  ReportMapSettings,
  ReportWidget
} from "@/types/report.type";
import { Field, Form, Formik } from "formik";
import React, { FC, useRef, useState } from "react";
import * as Styled from "../../style";
import { getSubTitle, getTitle } from "../../utils/getTitles";
import prepareInitialValues from "../../utils/prepareInitialValues";
import validateTableOfResultsConfig from "../../utils/validateTableOfResultsConfig";

type Props = {
  open: boolean;
  sources: ReportDataSource[] | undefined;
  onClose: () => void;
  onUpdateWidget: (
    widget: ReportWidget<ReportMapSettings>,
    layoutId: string | number
  ) => void;
  widget: ReportWidget<ReportMapSettings>;
  layoutId: string | number;
};

type InitialValues = {
  source: number | null;
};

const initialMapValues: InitialValues = {
  source: null
};

const style = {
  color: {
    confirm: theme.colors.coral,
    cancel: theme.colors.blue
  }
};

const MapViewSettings: FC<Props> = ({
  open,
  sources = [],
  onClose,
  widget,
  onUpdateWidget,
  layoutId
}) => {
  const [dialogState, setDialogState] = useState<null | string>(null);
  const tempSource = useRef("");
  const blockRef = useRef(null);
  const { t } = useCustomTranslation("");

  return (
    <Formik
      onSubmit={(values) => {
        const _widget = {
          ...widget,
          settings: { datasource: Number(values.source) }
        };
        onUpdateWidget(_widget, layoutId);
        onClose();
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={prepareInitialValues(widget.settings) || initialMapValues}
      validationSchema={validateTableOfResultsConfig}
    >
      {({ setFieldValue, values, dirty, resetForm, errors, submitForm }) => {
        const applySourceChange = (option: string) => {
          setFieldValue("source", option);
          if (dialogState) {
            setDialogState(null);
            setFieldValue("columns", []);
          }
        };
        const onChangeSource = (option: DropDownOption) => {
          if (!values.source) {
            applySourceChange(option.value);
          } else {
            tempSource.current = option.value;
            setDialogState("change");
          }
        };
        const onModalClose = () => {
          if (dirty) {
            setDialogState("close");
          } else {
            onClose();
          }
        };
        const onConfirm = () => {
          if (dialogState === "change") {
            applySourceChange(tempSource.current);
          } else {
            setDialogState(null);
            resetForm();
            onClose();
          }
        };
        return (
          <Form>
            <Modal
              modalRef={blockRef}
              isOpen={open}
              onClose={onModalClose}
              title={t("MAP_VIEW.MODAL_TITLE")}
            >
              <Dialog
                open={!!dialogState}
                title={getTitle(String(dialogState))}
                subtitle={getSubTitle(String(dialogState))}
                onConfirm={onConfirm}
                onCancel={() => setDialogState(null)}
                style={style}
              />
              <Field
                as={DropDown}
                options={sources.map((item) => ({
                  value: item.id,
                  label: item.name
                }))}
                label={t("MAP_VIEW.FORM.SOURCE.LABEL")}
                placeholder={t("MAP_VIEW.FORM.SOURCE.PLACEHOLDER")}
                name="source"
                onChange={onChangeSource}
                error={errors.source && t(errors.source)}
                blockRef={blockRef}
              />
              <Styled.ButtonContainer>
                <Button
                  disabled={!dirty}
                  width="120px"
                  height="34px"
                  type="submit"
                  onClick={submitForm}
                >
                  {t("REPORT_BUILDER.APPLY")}
                </Button>
              </Styled.ButtonContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MapViewSettings;
