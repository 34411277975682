// Types
import {
  InsertDateParams,
  UserRole,
  GetEngineersResponse,
  GetDashboardResponse,
  GetPartnerDashboardParams,
  GetCompanyDashboardParams,
  UserListData,
  PostSaveFlowDataParams,
  PostSaveFlowDataValues,
  PostViewSessionsResponse,
  PostViewSessionsBody,
  GetViewSessionsDistinctResponse,
  PostViewWaypointsResponse,
  PostViewWaypointsBody,
  GetViewWaypointsDistinctResponse,
  GetViewJobsDistinctResponse,
  PostViewJobsBody,
  PostViewJobsResponse,
  PostViewWaypointLogsBody,
  PostViewWaypointLogsResponse,
  GetViewWaypointLogsDistinctResponse,
  PostViewRelaysBody,
  PostViewRelaysResponse,
  GetViewRelaysDistinctResponse,
  GetUserUnitsResponse,
  PostViewRelaysRequest
} from "@/types/portalUsers.type";
import {
  GetDeviceDistributionResponse,
  EditSensorValues,
  PostSensorsResponse,
  PostRelaysResponse,
  EditRelayValues,
  SensorFilterOptions,
  RelayFilterOptions
} from "@/types/stock.type";
import {
  IDateSearchProps,
  IPaginationProps,
  IPaginationResponse,
  iSearchProps,
  ISortBy,
  IStockFilterRTK,
  RealISort
} from "@/types/rtk.type";
import type { MeasurementSystem } from "@/types/measurement.type";
import type { UnitObject, UnitSymbol } from "@/types/unit.type";

// Utils
import { buildQueryParams } from "@/utils/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "../api";
import { Currency } from "@/types/currency.type";
import { Country } from "@/types/country.type";

/*
  {
    // "first_name": "Name",
    // "last_name": "Surname",
    // "email": "example@example.com",
    // "phone": "077632246637",
    // "role_id": 1,
    // "partner_id": "null",
    // "company_id": "null",
    // "status": "active",
    // "active": "true",
    // "redirectUrl": "https://example.com",
    // "metric_system": "Metric",
    // "currency": "GBP",
    // "country_code": "GBR",
    "units": [
      {
        "unit_category": "FlowRateUnit",
        "unit": "CUBIC_METERS_PER_HOUR",
        "unit_symbol": "m3/h"
      },
      {
        "unit_category": "LongDistanceUnit",
        "unit": "KILOMETERS",
        "unit_symbol": "km"
      },
      {
        "unit_category": "ShortDistanceUnit",
        "unit": "METERS",
        "unit_symbol": "m"
      },
      {
        "unit_category": "VolumeUnit",
        "unit": "CUBIC_METERS",
        "unit_symbol": "m3"
      },
      {
        "unit_category": "DiameterUnit",
        "unit": "MILLIMETER",
        "unit_symbol": "mm"
      },
      {
        "unit_category": "WeightUnit",
        "unit": "KILOGRAM",
        "unit_symbol": "kg"
      }
    ]
  }
 */

// TODO: Refactor - fix duplicate types, create proper reusable types
export type FormUserValues = {
  first_name?: string;
  last_name?: string;
  email?: string;
  partner_id: number | undefined;
  company_id: number | undefined;
  role_id: number | undefined;

  phone?: string;
  metric_system?: MeasurementSystem;
  currency?: Currency["code"];
  country_code?: Country;
  units?: UnitObject[];
};

// TODO: Refactor - mb change to FormValuesWaypoint (same for user)
export type FormWaypointValues = {
  actual_leak_type?: string;
  actual_leak_type_id?: string;
  address: string;
  AI_size?: string;
  comment: string;
  current_status: string;
  status_id: string;
  diameter_unit?: UnitSymbol;
  diameter?: number;
  environmental_notes: string;
  estimated_size_client_unit?: UnitSymbol;
  estimated_size_client?: number;
  estimated_size_engineer_unit?: UnitSymbol;
  estimated_size_engineer?: number;
  estimated_size_unit?: UnitSymbol;
  estimated_size?: number;
  last_scheduled_engineer?: string;
  last_scheduled_engineer_id?: string;
  pipe_material: string;
  predicted_leak_type?: string;
  predicted_leak_type_id?: string;
  repair_time?: Date | string;
  surface_type: string;
};

export type AddUserBody = FormUserValues & {
  status?: string;
  active?: string;
  // TODO: replace with values from Alex (different for environments)
  redirectUrl?: string;
};

export const portalUsersApi = createApi({
  reducerPath: "portalUsersQuery",
  baseQuery: apiBaseQuery,
  tagTypes: [
    "InsertDates",
    "AddUser",
    "GetRoles",
    "DashboardInfo",
    "getEngineers",
    "getUserList",
    "getPortalUsers",
    "postSensors",
    "getSensorDistribution",
    "patchSensors",
    "postRelays",
    "getRelayDistribution",
    "patchRelays"
  ],
  endpoints: (builder) => ({
    insertDates: builder.mutation({
      query: (params: InsertDateParams) => ({
        url: `/portal_users/insert_dates?${buildQueryParams(params)}`,
        method: "POST"
      }),
      invalidatesTags: ["InsertDates"]
    }),
    addUser: builder.mutation<undefined, { body: AddUserBody }>({
      query: ({ body }) => ({
        url: "/portal_users/",
        method: "POST",
        body
      }),
      invalidatesTags: ["AddUser"]
    }),
    getRoles: builder.query<UserRole[], void>({
      query: () => "/portal_users/roles/list",
      providesTags: ["GetRoles"]
    }),
    getPartnerDashboard: builder.mutation<
      GetDashboardResponse[],
      GetPartnerDashboardParams
    >({
      query: (params: GetPartnerDashboardParams) =>
        `/portal_users/dashboard?${buildQueryParams(params || {})}`,
      invalidatesTags: ["DashboardInfo"]
    }),

    getCompanyDashboard: builder.mutation<
      GetDashboardResponse[],
      { id: number; params: GetCompanyDashboardParams }
    >({
      query: ({ id, params }) =>
        `/portal_users/dashboard/${id}/branches?${buildQueryParams(params || {})}`,
      invalidatesTags: ["DashboardInfo"]
    }),
    getEngineers: builder.query<GetEngineersResponse[], void>({
      query: () => "portal_users/engineers",
      providesTags: ["getEngineers"]
    }),
    getUserList: builder.query<
      IPaginationResponse<UserListData>,
      IPaginationProps & iSearchProps
    >({
      query: (params) =>
        `portal_users/user_dashboard?${buildQueryParams(params || {})}`,
      providesTags: ["getUserList"]
    }),
    getPortalUsers: builder.query<UserListData[], void>({
      query: () => "portal_users/list",
      providesTags: ["getPortalUsers"]
    }),
    postSensors: builder.query<
      IPaginationResponse<PostSensorsResponse>,
      IPaginationProps &
        RealISort &
        iSearchProps &
        IStockFilterRTK &
        IDateSearchProps
    >({
      query: ({ filter, ...params }) => ({
        url: `portal_users/stock/sensors?${buildQueryParams(params)}`,
        method: "POST",
        body: { filter }
      }),
      providesTags: ["postSensors"]
    }),
    getSensorDistribution: builder.query<
      GetDeviceDistributionResponse,
      IDateSearchProps
    >({
      query: (params) => ({
        url: `portal_users/stock/sensors/distribution?${buildQueryParams(params)}`,
        method: "GET"
      }),
      providesTags: ["getSensorDistribution"]
    }),
    patchSensors: builder.mutation<undefined, { body: EditSensorValues }>({
      query: ({ body }) => ({
        url: "portal_users/stock/sensors",
        method: "PATCH",
        body
      }),
      invalidatesTags: ["patchSensors"]
    }),
    postRelayTracking: builder.query<
      IPaginationResponse<PostRelaysResponse>,
      IPaginationProps &
        RealISort &
        iSearchProps &
        IStockFilterRTK &
        IDateSearchProps
    >({
      query: ({ filter, ...params }) => ({
        url: `portal_users/stock/relay/tracking?${buildQueryParams(params)}`,
        method: "POST",
        body: { filter }
      }),
      providesTags: ["postRelays"]
    }),
    getRelayDistribution: builder.query<
      GetDeviceDistributionResponse,
      IDateSearchProps
    >({
      query: (params) => ({
        url: `portal_users/stock/relay/distribution?${buildQueryParams(params)}`,
        method: "GET"
      }),
      providesTags: ["getRelayDistribution"]
    }),
    patchRelays: builder.mutation<undefined, { body: EditRelayValues }>({
      query: ({ body }) => ({
        url: "portal_users/stock/relay",
        method: "PATCH",
        body
      }),
      invalidatesTags: ["patchRelays"]
    }),
    getDistinctSensorFilters: builder.query<SensorFilterOptions, void>({
      query: () => "portal_users/stock/sensors/distinct"
    }),
    getDistinctRelayFilters: builder.query<RelayFilterOptions, void>({
      query: () => "portal_users/stock/relay/distinct"
    }),
    postSaveFlowData: builder.mutation<
      void,
      { body: PostSaveFlowDataValues; params: PostSaveFlowDataParams }
    >({
      query: ({ body, params }) => ({
        url: `/portal_users/save_flow_data?${buildQueryParams(params)}`,
        method: "POST",
        body
      })
    }),
    postViewSessions: builder.query<
      PostViewSessionsResponse,
      {
        body: PostViewSessionsBody;
        query: IPaginationProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/sessions?${buildQueryParams(query)}`,
        method: "POST",
        body
      })
    }),
    getViewSessionsDistinct: builder.query<
      GetViewSessionsDistinctResponse,
      void
    >({
      query: () => ({
        url: "/portal_users/view/sessions/distinct"
      })
    }),
    postViewWaypoints: builder.query<
      PostViewWaypointsResponse,
      {
        body: PostViewWaypointsBody;
        query: IPaginationProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/waypoints?${buildQueryParams(query)}`,
        method: "POST",
        body
      })
    }),
    getViewWaypointsDistinct: builder.query<
      GetViewWaypointsDistinctResponse,
      void
    >({
      query: () => ({
        url: "/portal_users/view/waypoints/distinct"
      })
    }),
    postViewJobs: builder.query<
      PostViewJobsResponse,
      {
        body: PostViewJobsBody;
        query: IPaginationProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/waypointjobs?${buildQueryParams(query)}`,
        method: "POST",
        body
      })
    }),
    getViewJobsDistinct: builder.query<GetViewJobsDistinctResponse, void>({
      query: () => ({
        url: "/portal_users/view/waypointjobs/distinct"
      })
    }),
    postViewWaypointLogs: builder.query<
      PostViewWaypointLogsResponse,
      {
        body: PostViewWaypointLogsBody;
        query: IPaginationProps & ISortBy & iSearchProps & IDateSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/waypointlogs?${buildQueryParams(query)}`,
        method: "POST",
        body
      })
    }),
    getViewWaypointLogsDistinct: builder.query<
      GetViewWaypointLogsDistinctResponse,
      void
    >({
      query: () => ({
        url: "/portal_users/view/waypointlogs/distinct"
      })
    }),
    postViewRelays: builder.query<
      PostViewRelaysResponse,
      PostViewRelaysRequest
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/relay?${buildQueryParams(query)}`,
        method: "POST",
        body
      })
    }),
    getViewRelaysDistinct: builder.query<GetViewRelaysDistinctResponse, void>({
      query: () => ({
        url: "/portal_users/view/relay/distinct"
      })
    }),
    getUserUnits: builder.query<GetUserUnitsResponse, void>({
      query: () => ({
        url: "/portal_users/units"
      })
    })
  })
});

export const {
  useInsertDatesMutation,
  useGetPartnerDashboardMutation,
  useGetCompanyDashboardMutation,
  useAddUserMutation,
  useGetRolesQuery,
  useGetEngineersQuery,
  useLazyGetUserListQuery,
  useGetPortalUsersQuery,
  useLazyGetSensorDistributionQuery,
  useLazyPostSensorsQuery,
  usePatchSensorsMutation,
  useLazyGetRelayDistributionQuery,
  useLazyPostRelayTrackingQuery,
  usePatchRelaysMutation,
  useLazyGetDistinctSensorFiltersQuery,
  useLazyGetDistinctRelayFiltersQuery,
  usePostSaveFlowDataMutation,
  useLazyPostViewSessionsQuery,
  useGetViewSessionsDistinctQuery,
  useLazyPostViewWaypointsQuery,
  useGetViewWaypointsDistinctQuery,
  useLazyPostViewJobsQuery,
  useGetViewJobsDistinctQuery,
  useLazyPostViewWaypointLogsQuery,
  useGetViewWaypointLogsDistinctQuery,
  useLazyPostViewRelaysQuery,
  useGetViewRelaysDistinctQuery,
  useGetUserUnitsQuery
} = portalUsersApi;
