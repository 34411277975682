import { Map } from "mapbox-gl";
import { MapElement } from "../../utils/types";
import { createClusterlayer, createOrUpdateSource } from "./utils/utils";
import { paintMapIcons } from "./utils/paintMapIcons";
import { createCustomIconLayer } from "./utils/createCustomIconLayer";
import { getUniqueIcons } from "./utils/getUniqueIcons";
import { createCombinedFeatures } from "./utils/createCombinedFeatures";
import { clickEvents } from "./utils/clickEvents";

export const INITIAL_LONGITUDE = -2.244644;
export const INITIAL_LATITUDE = 53.483959;
export const INITIAL_ZOOM = 5;
export const MAP_STYLE = "mapbox://styles/mapbox/streets-v9";

const drawClusters = async (
  map: Map,
  items: MapElement[],
  doNotAllowClickThrough: boolean
) => {
  const combinedFeatures = createCombinedFeatures(items);

  const uniqueIcons = getUniqueIcons(combinedFeatures);

  createOrUpdateSource(map, combinedFeatures);

  await paintMapIcons(map, uniqueIcons);

  createCustomIconLayer(map, uniqueIcons);

  createClusterlayer(map);

  clickEvents(map, doNotAllowClickThrough);
};

export default drawClusters;
