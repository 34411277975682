import React, { FC } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { ITablePaginate } from "../types";
import * as Styled from "./style";

export interface IPaginationProps {
  onChange: (page: ITablePaginate) => void;
  pages: number;
  initialPage?: number;
}

const Pagination: FC<
  Omit<ReactPaginateProps, "onPageChange" | "pageCount"> & IPaginationProps
> = ({ onChange, pages, initialPage = 1, forcePage }) => {
  // TODO: Refactor - look into indexes (currently some warning occurs): mb use 0 as starting index and not 1
  const initialValue = initialPage > pages ? 1 : initialPage;
  const forcedPage =
    typeof forcePage === "number" ? Math.max(0, forcePage - 1) : undefined;

  return (
    <Styled.Container>
      <Styled.Text data-testid="pagination-text">{`${initialPage} of ${pages}`}</Styled.Text>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={onChange}
        pageRangeDisplayed={3}
        initialPage={
          typeof forcedPage === "number" ? undefined : initialValue - 1
        }
        forcePage={forcedPage}
        pageCount={pages}
        previousLabel="<"
        marginPagesDisplayed={2}
        containerClassName="pagination"
        pageClassName="pagination__page"
        activeClassName="pagination__page-active"
        pageLinkClassName="pagination__link"
        previousClassName="pagination__prev"
        nextClassName="pagination__next"
        previousLinkClassName="pagination__prev-link"
        nextLinkClassName="pagination__next-link"
        disabledClassName="pagination__disabled"
        breakClassName="pagination__break"
      />
    </Styled.Container>
  );
};

export default Pagination;
