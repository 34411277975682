import ArrowIcon from "@/assets/icons/arrow_down.svg?react";
import styled from "styled-components";

export const CompanyTitle = styled.h3`
  margin-top: 20px;
`;

export const ChooseCompanyTitle = styled.h2`
  padding: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  font-size: 12px;
`;

export const ErrorMsg = styled.div`
  color: #000;
`;

export const CompanyWrapper = styled.tbody<{
  heightProp: string;
}>`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  overflow-y: scroll;
  height: ${({ heightProp }) => (heightProp ? heightProp : "auto")};

  tr:nth-child(odd) {
    background-color: #108dcc;
  }

  tr:nth-child(even) {
    background-color: #0779b1;
  }

  tr:hover {
    background-color: #a8e2ff;
    div {
      color: black;
    }
  }
`;

export const ListItemContainer = styled.th`
  margin-bottom: 5px;
  padding: 15px;
  color: #fff;
`;

export const TableTitleContainer = styled.tr`
  padding: 5px 5px 10px 5px;
  flex: 1;
  cursor: pointer;
  position: relative;
`;

export const TableTDContainer = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTitle = styled.p`
  font-weight: bold;
  color: #fff;
  font-size: 14px;
`;

export const LoadingContainer = styled.div``;

export const ChooseCompanyContainer = styled.div`
  background: #00699d;
  border: 1px solid #f9f9f9;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
`;

export const PairContainer = styled.tr`
  display: grid;
  background: #108dcc;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  font-size: 12px;

  :hover {
    background: ${({ theme }) => theme.colors.lochmara};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const TableTitleWrapper = styled.thead`
  display: flex;
`;

export const TableWrapper = styled.table`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  padding: 5%;
`;

export const ArrowDownIcon = styled(ArrowIcon)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  fill: #fff;
`;

export const Input = styled.input`
  margin: 0 auto;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 14px;
  border: 1px solid #D0CFCE;
  border-radius: 10px;
  outline: none;
  &:focus{
    border: 1px solid #008ABF;
    transition: 0.35s ease;
    color: ${({ theme }) => theme.colors.blue}
    &::-webkit-input-placeholder{
      transition: opacity 0.45s ease; 
  	  opacity: 0;
     }
    &::-moz-placeholder {
      transition: opacity 0.45s ease; 
  	  opacity: 0;
     }
    &:-ms-placeholder {
     transition: opacity 0.45s ease; 
  	 opacity: 0;
     }    
   }
 }
 `;

export const Container = styled.form`
  position: relative;
`;

export const SearchBarContainer = styled.div`
  width: 400px;
  padding: 20px 60px 20px 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
