const theme = {
  colors: {
    black: "#000000",
    white: "#FFFFFF",
    body: "#F9F9F9",
    deepDark: "#192A3E",
    mediumDark: "#2E3A59",
    gray: "#9FA2B4",
    lightGray: "#E4E6EE",
    deepBlue: "#00699D",
    frenchBlue: "#007CBB",
    celestialBlue: "#469DDD",
    mediumPersianBlue: "#00699D",
    seashell: "#F1F1F1",
    blue: "#007DBB",
    mediumBlue: "#009FE3",
    bitterPill: "#dfdfdf",
    silverMaiden: "#eff4fa",
    lightBlue: "#4298D7",
    malibu: "#5DC6FB",
    sky: "#82D0F5",
    lighterBlue: "#21CCFF",
    softBlue: "#BCD0F8",
    lochmara: "#007CBB",
    coral: "#E95F70",
    disabled: "#F3F3F3",
    green: "#1A947E",
    lightGreen: "#86DF6C",
    purple: "#846BCE",
    mediumGreen: "#34B639",
    artyClickRed: "#FF0101",
    mascaraTears: "#19191b",
    midnight: "#26252a",
    historianGrey: "#b3b3b3",
    darkPurple: "#302e36"
  },
  breakpoints: {
    xs: "480px",
    s: "600px",
    m: "768px",
    l: "960px",
    xl: "1200px"
  },
  boxShadow: {
    md: "0px 4px 4px 0px #00000040"
  }
} as const;

export type Theme = typeof theme;

export default theme;
