import { IErrorData } from "@/types/rtk.type";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export const getRTKError = (error?: FetchBaseQueryError | SerializedError) => {
  const errorObject = (error as FetchBaseQueryError)?.data || null;
  return errorObject as IErrorData | null;
};

export const buildQueryParams = (params: { [key: string]: any } | void = {}) =>
  !params
    ? ""
    : Object.keys(params)
        .map((el) =>
          params[el] ? `${el}=${encodeURIComponent(params[el])}` : null
        )
        .filter((el) => Boolean(el))
        .join("&");
