import useCustomTranslation from "@/localization/useCustomTranslation";

// Constants
import {
  UnitCategory,
  UnitCategoryData,
  UnitSymbol
} from "@/constants/Unit.constant";
import { MeasurementSystem } from "@/constants/Measurement.constant";

// Types
import { MeasurementSystem as MeasurementSystemType } from "@/types/measurement.type";
import { GetUserUnitsResponse } from "@/types/portalUsers.type";
import { UnitObject } from "@/types/unit.type";

export const unitTranslation = (value: string) => {
  const { prefixedT } = useCustomTranslation("UNIT");

  if (value === "ML/day") {
    return prefixedT("ML_DAY");
  }

  if (value === "Gallons/day") {
    return prefixedT("GALLONS_DAY");
  }
};

// * NB: will be changed once the backend is adjusted to send only the unit types
export const mapDefaultUnits = (
  unitsData?: GetUserUnitsResponse,
  measurement: MeasurementSystemType = MeasurementSystem.METRIC
) => {
  if (!unitsData) {
    return getDefaultUnits(MeasurementSystem.METRIC);
  }

  return Object.values(unitsData).map(({ defaults }) => {
    const unit =
      defaults[
        measurement === MeasurementSystem.METRIC ? "metric" : "imperial"
      ];

    return {
      unit: unit.unit,
      unit_symbol: unit.unit_symbol,
      unit_category: unit.unit_type
    };
  });
};

export const getDefaultUnits = (measurement: MeasurementSystemType) => {
  return Object.values(UnitCategory).map<UnitObject>((unitCategory) => {
    const unit = UnitCategoryData[unitCategory].default[measurement];

    return {
      unit_category: unitCategory,
      unit,
      unit_symbol: UnitSymbol[unit]
    };
  });
};
