import React, { FC, useRef, DragEvent, useState } from "react";
import * as Styled from "./style";
import { headerNames } from "../ListViewUtils";
import { Button } from "@/components";
import useOutsideClick from "@/hooks/useOutsideClick";
import useCustomTranslation from "@/localization/useCustomTranslation";

interface EditViewProps {
  columnOrder: { name: string; show: boolean }[];
  setColumnOrder: React.Dispatch<
    React.SetStateAction<{ name: string; show: boolean }[]>
  >;
  handleColumnOrderChange: (newColumn: string, draggedColumn: string) => void;
}

const EditView: FC<EditViewProps> = ({
  columnOrder,
  setColumnOrder,
  handleColumnOrderChange
}) => {
  const { prefixedT, t } = useCustomTranslation("LIST_VIEW.RELAYS");

  const [showEditView, setShowEditView] = useState(false);
  const [draggedColumn, setDraggedColumn] = useState<{
    name: string;
    idx: number;
  } | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<{
    name: string;
    idx: number;
  } | null>(null);

  const filterRef = useRef<HTMLDivElement>(null);
  useOutsideClick(filterRef, setShowEditView, showEditView);

  const defaultColumnOrder = headerNames.map((id) => ({
    name: id,
    show: true
  }));

  const handleDrag = (
    e: DragEvent<HTMLDivElement>,
    currentColumn: { name: string; idx: number }
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedColumn(currentColumn);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, colName: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggedColumn !== null) {
      handleColumnOrderChange(colName, draggedColumn.name);
      setDraggedColumn(null);
      setHoveredColumn(null);
    }
  };

  return (
    <Styled.Container ref={filterRef}>
      <Button onClick={() => setShowEditView(!showEditView)}>
        {t("LIST_VIEW.SESSIONS.EDIT_VIEW.BUTTON_LABEL")}{" "}
        <Styled.ClickableAreaIndicator open={showEditView} />
      </Button>

      <Styled.ExpandableContainer showEditView={showEditView}>
        <p>{t("LIST_VIEW.SESSIONS.EDIT_VIEW.DESCRIPTION")}</p>
        <hr />
        <Styled.List>
          {columnOrder.map(({ name, show }, idx) => (
            <Styled.Item key={idx}>
              <Styled.DraggableContainer
                onDrag={(e) => handleDrag(e, { name, idx })}
                onDrop={(e) => handleDrop(e, name)}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDragOver={(e) => {
                  e.preventDefault();
                  setHoveredColumn({ name, idx });
                }}
                draggable={true}
                showBorder={
                  hoveredColumn?.name === name &&
                  draggedColumn?.idx !== undefined &&
                  draggedColumn?.name !== name
                    ? draggedColumn.idx < idx
                      ? "bottom"
                      : "top"
                    : undefined
                }
              >
                ⋮ &nbsp;
                <Styled.Input
                  data-testid={name}
                  aria-label={name}
                  type="checkbox"
                  checked={show}
                  onChange={(e) => {
                    const thisCol = columnOrder.findIndex(
                      (c) => c.name === name
                    );
                    if (thisCol !== undefined) {
                      const newColumnOrder = [...columnOrder];
                      newColumnOrder[thisCol].show = e.target.checked;
                      setColumnOrder(newColumnOrder);
                    }
                  }}
                />
                {prefixedT("HEADERS." + name)}
              </Styled.DraggableContainer>
            </Styled.Item>
          ))}
        </Styled.List>

        <hr />

        <Button
          onClick={() => {
            setColumnOrder(defaultColumnOrder);
          }}
          disabled={
            JSON.stringify(columnOrder) === JSON.stringify(defaultColumnOrder)
          }
        >
          {t("LIST_VIEW.SESSIONS.EDIT_VIEW.RESET_BUTTON_LABEL")}
        </Button>
      </Styled.ExpandableContainer>
    </Styled.Container>
  );
};

export default EditView;
