import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  width: 200px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  z-index: 9991;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 12%);
  border-radius: 8px;
  top: 0;
  right: 0;
`;

export const Menu = styled.ul``;
export const MenuItem = styled.li`
  list-style: none;
  height: 30px;
  transition: 0.3s ease background-color;
  cursor: pointer;
  display: flex;
  padding: 5px;
  align-items: center;

  & > * {
    margin-right: 7px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.softBlue}40;
  }
`;
export const MenuItemText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
`;
export const Wrapper = styled.div`
  position: relative;
  width: max-content;
`;

export const Control = styled.div``;
