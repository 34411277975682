import CheckIconSVG from "@/assets/icons/check.svg?react";
import RunIconSVG from "@/assets/icons/play.svg?react";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100% - 70px);
`;

export const Wrapper = styled.div`
  padding: 40px;
`;

export const WidgetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 54px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 40px;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 94px);
  min-height: 100%;
  overflow-y: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  height: 35px;
`;
export const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray};
`;
export const TitleWrapper = styled.div`
  min-width: 150px;
  height: 35px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const ControlsWrapper = styled.div`
  height: 35px;
  min-width: 300px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RunIcon = styled(RunIconSVG)`
  margin-right: 3px;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const CheckIcon = styled(CheckIconSVG)`
  width: 25px;
  height: 30px;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
