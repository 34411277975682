import { useDecodeTokenMutation } from "@/redux/authorization/authorization.api";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IDecodedData } from "./Recovery";

export const useDecode = (): IDecodedData => {
  const { token = "" } = useParams();
  const navigate = useNavigate();
  const [onDecode, { status, data }] = useDecodeTokenMutation();

  useEffect(() => {
    if (token) {
      onDecode({ access_token: token });
    }
  }, [token]);

  useEffect(() => {
    if (status === QueryStatus.rejected) {
      navigate("/auth/recovery/expired");
    }
  }, [status]);

  return data;
};
