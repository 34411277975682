import styled from "styled-components";

export const ReportLinks = styled.p`
  color: ${({ theme }) => theme.colors.white};
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    max-width: 350px;

    path {
      /* Adjust the width of the SVG itself */
      width: 100%; /* This will scale the SVG to fit the container */
      fill: white;
      width: 100%;
    }
  }
`;

export const OptionTitle = styled.h3`
  margin-bottom: 5px;
`;

export const OptionDesc = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
