import { Dialog, PopupMenu } from "@/components";
import { PopupMenuItem } from "@/components/PopupMenu/PopupMenu";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { ContainerVariant } from "@/types/widget.type";
import React, { FC, PropsWithChildren, useMemo, useState } from "react";
import { ConnectDragSource } from "react-dnd";
import * as Styled from "./style";

type Props = {
  dropRef: ConnectDragSource | null;
  name: string;
  readonly: boolean;
  items?: PopupMenuItem[];
  onDelete: () => void;
  variant?: string;
};

const WidgetLayout: FC<PropsWithChildren<Props>> = ({
  dropRef = null,
  children,
  name,
  readonly,
  items = [],
  onDelete,
  variant = ContainerVariant.default
}) => {
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { prefixedT } = useCustomTranslation("WIDGET_LAYOUT");

  const onOpen = () => {
    if (!openSettings) {
      setOpenSettings(true);
    }
  };
  const onClose = () => setOpenSettings(false);
  const onOpenConfirmDialog = () => {
    onClose();
    setIsDeleteModalOpen(true);
  };

  const menuItems = useMemo(
    () => [
      ...items.map((item) => ({
        ...item,
        handler: () => {
          item.handler();
          onClose();
        }
      })),
      {
        title: prefixedT("REMOVE_WIDGET"),
        handler: onOpenConfirmDialog,
        icon: Styled.DeleteIcon
      }
    ],
    [items, onDelete, onOpenConfirmDialog]
  );

  return (
    <Styled.Container ref={dropRef} readonly={readonly}>
      <Dialog
        open={isDeleteModalOpen}
        confirmText={prefixedT("CONFIRM_TEXT")}
        cancelText={prefixedT("CANCEL_TEXT")}
        onConfirm={onDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        title={prefixedT("TITLE")}
        subtitle={prefixedT("SUBTITLE")}
      />
      <Styled.Header readonly={readonly}>
        <Styled.Title>{name}</Styled.Title>
        <PopupMenu
          open={openSettings}
          onClose={onClose}
          control={<Styled.Settings onClick={onOpen} />}
          items={menuItems}
        />
      </Styled.Header>
      <Styled.Content readonly={readonly}>
        <Styled.ContentWrapper variant={variant} readonly={readonly}>
          {children}
        </Styled.ContentWrapper>
        <Styled.DragIndication readonly={readonly}>
          <Styled.Drag />
        </Styled.DragIndication>
      </Styled.Content>
    </Styled.Container>
  );
};

export default WidgetLayout;
