import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Styled from "./style";
import { Button, Loader } from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  useGetDropdownQuery,
  useLazyPostWaypointJobsQuery,
  usePatchWaypointJobMutation
} from "@/redux/reports/reports.api";
import * as Yup from "yup";
import WaypointHeadings from "../Waypoint/Components/WaypointHeadings/WaypointHeadings";
import { Formik } from "formik";
import ImageContainer from "../Waypoint/Components/ImageContainer/ImageContainer";
import theme from "@/theme";
import {
  patchWaypointJobBody,
  patchWaypointJobForm
} from "@/types/report.type";
import ImageUploadBox from "@/components/ImageUploadBox/ImageUploadBox";
import { urlToBase64 } from "./utils";
import {
  DateInfoSection,
  JobInfoSection,
  LocationInfoSection,
  WaypointInfoSection
} from "./FormSections";
import JobLogTable from "./JobLogsTable/JobLogsTable";

const JobContainer: FC = () => {
  const { prefixedT } = useCustomTranslation("JOB_DETAILS");
  const location = useLocation();

  const [waypointID, setWaypointID] = useState<string>("");
  const [jobID, setJobID] = useState<number>();
  const [edit, setEdit] = useState(false);

  const { data: dropDownData } = useGetDropdownQuery(waypointID, {
    refetchOnMountOrArgChange: true,
    skip: !waypointID
  });

  const [trigger, { data: jobData, isLoading: jobIsLoading }] =
    useLazyPostWaypointJobsQuery();

  const [patchUpdateWaypointJob, { isError }] = usePatchWaypointJobMutation();
  const [initialValues, setInitialValues] = useState<patchWaypointJobForm>();

  useEffect(() => {
    if (waypointID !== "") {
      const body = {
        id: waypointID,
        page: null,
        per_page: null,
        sorted_by: "",
        sorted_order: "",
        search: "",
        filter: {
          job_status: [],
          leak_type: []
        },
        date_type: "",
        date_to: "",
        date_from: ""
      };

      void trigger(body);
    }
  }, [waypointID, edit]);

  const waypointJob = useMemo(() => {
    setEdit(false);
    return jobData?.data?.find((job) => job.id === jobID);
  }, [jobData, jobID]);

  useEffect(() => {
    void (async () => {
      if (waypointJob) {
        const imageArray = await urlToBase64(waypointJob.urls);

        setInitialValues({
          waypoint_job_info: {
            ...waypointJob,
            repair_date:
              waypointJob.repair_date !== null
                ? new Date(waypointJob.repair_date + "z")
                : null,
            date_raised_internally:
              waypointJob.date_raised_internally !== null
                ? new Date(waypointJob.date_raised_internally + "z")
                : null,
            date_raised_externally:
              waypointJob.date_raised_externally !== null
                ? new Date(waypointJob.date_raised_externally + "z")
                : null
          },
          images_current: imageArray,
          images_remove: []
        });
      }
    })();
  }, [waypointJob]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedjobID = queryParams.get("job") as string;
    const updatedWaypointID = queryParams.get("waypoint") as string;
    const parsedUpdatedjobID = parseInt(updatedjobID);
    setJobID(parsedUpdatedjobID);
    setWaypointID(updatedWaypointID);
  }, [location.search]);

  const ValidationSchema = Yup.object().shape({
    waypoint_job_info: Yup.object().shape({
      job_status: Yup.string().required(prefixedT("JOB_STATUS.VALIDATION")),
      comment: Yup.string().nullable(true),
      work_order: Yup.string().nullable(true),
      repair_date: Yup.date().nullable(true),
      leak_type_id: Yup.number()
        .nullable(true)
        .required(prefixedT("LEAK_TYPE.VALIDATION")),
      agreed_flow_reduction: Yup.number().nullable(true),
      road_speed: Yup.number().nullable(true),
      date_raised_internally: Yup.date().nullable(true),
      date_raised_externally: Yup.date().nullable(true),
      address: Yup.string().nullable(true),
      w3w: Yup.string().nullable(true),
      surface_type: Yup.string().nullable(true),
      estimate_leak_size: Yup.number().nullable(true),
      material: Yup.string().nullable(true),
      pipe_diameter: Yup.number().nullable(true),
      environmental_notes: Yup.string().nullable(true)
    })
  });

  const onSubmit = async (values: patchWaypointJobForm) => {
    if (jobID) {
      // Format the form object to match the API
      const body: patchWaypointJobBody = {
        waypoint_job_info: values.waypoint_job_info,
        base64_images: values.images_current
          .filter((image) => image.url === "")
          .map((image) => image.base64),
        url_images_remove: values.images_remove
      };

      await patchUpdateWaypointJob({
        body,
        query: { job_id: jobID }
      });

      setEdit(false);
    }
  };

  return (
    <Styled.Container key={`${edit}`}>
      {dropDownData && (
        <WaypointHeadings
          correlations={dropDownData.correlations}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          jobs={dropDownData.jobs}
          sessions={dropDownData.sessions}
          active="Job"
        />
      )}

      <Styled.FormContainer>
        {jobIsLoading ? (
          <Loader />
        ) : waypointJob && jobID && initialValues !== undefined ? (
          <>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ values, setFieldValue, submitForm }) => {
                return (
                  <>
                    <h1>{prefixedT("TITLE")}</h1>
                    {!edit && (
                      <Styled.ButtonContainer>
                        <Button
                          color={theme.colors.blue}
                          onClick={() => setEdit(true)}
                        >
                          {prefixedT("EDIT")}
                        </Button>
                      </Styled.ButtonContainer>
                    )}
                    <Styled.JobDetailsContainer>
                      <Styled.ListContainer>
                        <WaypointInfoSection
                          waypointID={waypointID}
                          waypointJob={waypointJob}
                          edit={edit}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                        <div>
                          <JobInfoSection
                            jobID={jobID}
                            edit={edit}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                          <LocationInfoSection
                            edit={edit}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                          <DateInfoSection
                            edit={edit}
                            waypointJob={waypointJob}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        </div>
                      </Styled.ListContainer>
                      <Styled.Figure>
                        <Styled.ImageCaption>
                          {prefixedT("JOB_IMAGES_TITLE")}
                        </Styled.ImageCaption>
                        {edit ? (
                          <>
                            <ImageUploadBox
                              onImageUpload={(imageString: string[]) => {
                                void setFieldValue("images_current", [
                                  ...(values.images_current || []),
                                  ...imageString.map((base64) => ({
                                    url: "",
                                    base64
                                  }))
                                ]);
                              }}
                              maxFiles={5}
                              showPreview={false}
                            ></ImageUploadBox>
                            <Styled.ImagesContainer>
                              {values.images_current
                                ? values.images_current.map((image, idx) => (
                                    <Styled.ImageContainer key={idx}>
                                      <Styled.CrossImg
                                        onClick={() => {
                                          if (image.url !== "") {
                                            void setFieldValue(
                                              "images_remove",
                                              [
                                                ...(values.images_remove || []),
                                                image.url
                                              ]
                                            );
                                          }
                                          void setFieldValue("images_current", [
                                            ...(values.images_current?.filter(
                                              (_, i) => i !== idx
                                            ) || [])
                                          ]);
                                        }}
                                      ></Styled.CrossImg>
                                      <Styled.Image
                                        key={idx}
                                        src={image.base64}
                                      />
                                    </Styled.ImageContainer>
                                  ))
                                : null}
                            </Styled.ImagesContainer>
                          </>
                        ) : (
                          <>
                            {waypointJob.urls.length > 0 ? (
                              <ImageContainer linkToAssets={waypointJob.urls} />
                            ) : (
                              <p>{prefixedT("NO_IMAGES")}</p>
                            )}
                          </>
                        )}
                      </Styled.Figure>
                      {edit && (
                        <Styled.ButtonWrapper>
                          <Styled.ButtonContainer>
                            <Button
                              color={theme.colors.coral}
                              onClick={() => setEdit(false)}
                            >
                              {prefixedT("CANCEL")}
                            </Button>
                          </Styled.ButtonContainer>
                          <Styled.ButtonContainer>
                            <Button
                              color={theme.colors.blue}
                              type="submit"
                              onClick={() => void submitForm()}
                            >
                              {prefixedT("SAVE")}
                            </Button>
                          </Styled.ButtonContainer>
                        </Styled.ButtonWrapper>
                      )}
                    </Styled.JobDetailsContainer>
                  </>
                );
              }}
            </Formik>
            {isError && <Styled.Error>{prefixedT("ERROR")}</Styled.Error>}
          </>
        ) : (
          <>
            <h1>
              {prefixedT("NO_JOB")} {jobID}
            </h1>
          </>
        )}
      </Styled.FormContainer>

      {waypointJob !== undefined && <JobLogTable job_id={waypointJob.id} />}
    </Styled.Container>
  );
};

export default JobContainer;
