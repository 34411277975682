import { useEffect, useState } from "react";
import { useGetGeneratedODLsTaskQuery } from "@/redux/portalProjects/portalProjectsGIS.api";
import { WebsocketMessageTypeBase } from "@/types/websocket.type";

type UseUploadODLsProps = {
  taskId: string;
};
export const useUploadODLs = (props: UseUploadODLsProps) => {
  const [shouldPoll, setShouldPoll] = useState(true);
  const query = useGetGeneratedODLsTaskQuery(
    {
      taskId: props.taskId
    },
    {
      skip: !shouldPoll || !props.taskId,
      skipPollingIfUnfocused: true,
      pollingInterval: 4000
    }
  );

  useEffect(() => {
    if (query.isSuccess && query.data) {
      void (async () => {
        switch (query.data.type) {
          case WebsocketMessageTypeBase.PENDING:
          case WebsocketMessageTypeBase.STARTED:
            break;

          case WebsocketMessageTypeBase.SUCCESS:
            setShouldPoll(false);
            break;

          case WebsocketMessageTypeBase.FAILURE:
            setShouldPoll(false);
            break;
          default:
            break;
        }
      })();
    }
  }, [query.isSuccess, query.data, query.isFetching]);

  return query;
};
