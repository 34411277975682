import { MapContext } from "@/Providers/MapProvider";
import React, { useContext, FC, useEffect, useRef } from "react";
import mapbox from "mapbox-gl";
import { PointTooltipType } from "../utils/types";
import { StandardTooltip } from "./tooltips/standardTooltip/StandardTooltip";
import { AssetTooltip } from "./tooltips/assetTooltip/AssetTooltip";
import { setupPopup } from "./utils/utils";

export const Popup: FC = () => {
  const { tooltip, map, lngLat } = useContext(MapContext);
  const popupRef = useRef<HTMLDivElement>(null);

  const popup = new mapbox.Popup({
    closeButton: false,
    closeOnClick: false,
    closeOnMove: true
  });

  useEffect(() => {
    if (map && lngLat.lat !== null && lngLat.lng !== null && tooltip) {
      setupPopup({
        map: map,
        lngLat,
        popup,
        popupRef
      });
    }
    return () => {
      popup.remove();
    };
  }, [lngLat, map]);

  let values: PointTooltipType | null = null;

  if (typeof tooltip === "string" && tooltip.trim() !== "") {
    values = JSON.parse(tooltip) as PointTooltipType;
  }

  if (values?.Asset_id && values?.Asset_key) {
    return (
      <div data-testid="asset-tooltip">
        <div ref={popupRef}>
          <AssetTooltip {...values} />
        </div>
      </div>
    );
  } else if (values) {
    return (
      <div data-testid="regular-tooltip">
        <div ref={popupRef}>
          <StandardTooltip {...values} />
        </div>
      </div>
    );
  }
  return null;
};

export default Popup;
