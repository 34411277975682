import styled from "styled-components";
import FilterIconSVG from "@/assets/icons/filter-icon.svg?react";

export const Wrapper = styled.div`
  padding: 20px;
  position: relative;
  background-color: #fff;
  margin: 20px 0;
`;

export const LegendContainer = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  flex-direction: column;
  align-items: end;
`;

export const FilterButton = styled.button`
  width: 100px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #e9eef2;
  border-radius: 5px;
  border: none;
`;

export const UnorderedList = styled.ul`
  height: 300px;
  width: 400px;
  overflow-y: scroll;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #e9e9e9;
`;

export const ListItem = styled.li`
  display: flex;
  margin: 10px 0;
`;

export const Checkbox = styled.input`
  margin: 0 10px;
`;

export const ListItemText = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const FilterIcon = styled(FilterIconSVG)`
  margin-right: 10px;
  transform: scale(1.7);
`;
