import { FC, PropsWithChildren } from "react";
import Action, { IActionProps } from "./Action";
import Body, { IBodyProps } from "./Body";
import Cell, { ICellProps } from "./Cell";
import { TableProvider } from "./context";
import Head, { IHeadProps } from "./Head";
import Header, { IHeaderProps } from "./Header";
import Menu from "./Menu";
import Pagination, { IPaginationProps } from "./Pagination";
import Row from "./Row";
import * as Styled from "./style";
import { ITableHeader, TableSortOrders } from "./types";

interface ContentComposition<T> {
  Row?: FC<PropsWithChildren>;
  Cell?: FC<PropsWithChildren<ICellProps<T>>>;
  Body?: FC<IBodyProps<T>>;
  Head?: FC<IHeadProps>;
  Header?: FC<IHeaderProps>;
  Action?: FC<IActionProps>;
  Pagination?: FC<IPaginationProps>;
  Menu?: FC;
}

export interface ITableProps<ObjectType> {
  data?: ObjectType[];
  order?: TableSortOrders;
  orderBy?: keyof ObjectType;
  headers: ITableHeader[];
  onSort?: (orderBy: string, order: string) => void;
  minHeight?: string;
}

function Table<T>({
  children,
  data,
  order,
  orderBy,
  headers,
  onSort,
  minHeight
}: PropsWithChildren<ITableProps<T>> & ContentComposition<T>) {
  return (
    <TableProvider
      data={data}
      order={order}
      orderBy={orderBy}
      headers={headers}
      onSort={(orderBy, order) => {
        onSort?.(orderBy, order);
      }}
    >
      <Styled.Container minHeight={minHeight} data-testid="table">
        <Styled.TableComponent cellSpacing="0">
          {children}
        </Styled.TableComponent>
      </Styled.Container>
    </TableProvider>
  );
}
Table.Row = Row;
Table.Cell = Cell;
Table.Body = Body;
Table.Head = Head;
Table.Header = Header;
Table.Action = Action;
Table.Pagination = Pagination;
Table.Menu = Menu;

export default Table;
