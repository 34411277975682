import { SessionData, SessionDataWithArray } from "@/types/report.type";
import moment from "moment";

export const formatDate = (date: string | null) => {
  if (!date) {
    return null;
  }
  return moment(date).format("MMMM D, YYYY [at] h:mm:ss A");
};

export const formatSessionData = <T extends SessionDataWithArray | SessionData>(
  type: string,
  sessionData?: (SessionDataWithArray | SessionData)[] | null
): T => {
  const sessionObjectType = sessionData?.find((item) => item.type === type);

  if (type === "overnight") {
    return {
      urls: sessionObjectType?.urls || "",
      timeline: sessionObjectType?.timeline || ""
    } as T;
  }
  return {
    urls: sessionObjectType?.urls || [],
    timeline: sessionObjectType?.timeline || []
  } as T;
};
