import React, { FC, useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import * as Styled from "./style";
import theme from "@/theme";
import { Button } from "@/components";
import { csvFormat } from "d3-dsv";
import useCustomTranslation from "@/localization/useCustomTranslation";

export interface Data {
  level: number;
  timestamp: Date;
}

interface PlotlyConsumptionChartProps {
  initialData: Data[];
  setShowNoDataMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const LineGraph: FC<PlotlyConsumptionChartProps> = ({
  initialData,
  setShowNoDataMessage
}) => {
  const { prefixedT } = useCustomTranslation(
    "CONSUMPTION_PROFILE_PAGE.LINE_GRAPH"
  );
  const ref = useRef<HTMLDivElement>(null);
  const displayedXRange = useRef<[string, string]>();

  const [width, setWidth] = useState<number>(800);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setWidth(event[0].contentBoxSize[0].inlineSize);
    });

    resizeObserver.observe(ref.current as Element);
  }, []);

  const handleExportData = () => {
    const slicedData = initialData.filter((datum) => {
      const timestamp = datum.timestamp;
      if (displayedXRange.current) {
        const [start, end] = displayedXRange.current;
        return timestamp >= new Date(start) && timestamp <= new Date(end);
      }
      return true;
    });

    if (slicedData.length > 0) {
      const csv = csvFormat(slicedData);
      const downloadFile = (content: string, fileName: string) => {
        const element = document.createElement("a");
        const file = new Blob([content], { type: "text/csv" });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      };

      downloadFile(csv, "consumption_data.csv");
    } else {
      setShowNoDataMessage(true);
    }
  };

  return (
    <>
      <Styled.Container data-testid="consumption-chart-container" ref={ref}>
        <Plot
          data={[
            {
              x: initialData.map((datum) => datum.timestamp),
              y: initialData.map((datum) => datum.level),
              type: "scatter",
              mode: "lines",
              line: { color: [theme.colors.mediumBlue] }
            }
          ]}
          layout={{ width, title: prefixedT("TITLE") }}
          onUpdate={(figure) => {
            const range = figure.layout.xaxis?.range;
            if (range !== undefined) {
              displayedXRange.current = range as [string, string];
            }
            setShowNoDataMessage(false);
          }}
        />
      </Styled.Container>
      <Styled.ButtonContainer>
        <Button width="fit-content" onClick={handleExportData}>
          {prefixedT("EXPORT")}
        </Button>
      </Styled.ButtonContainer>
    </>
  );
};

export default LineGraph;
