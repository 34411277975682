import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { LanguageSelector, Profile } from "../index";
import * as Styled from "./style";

interface HeaderProps {
  sidebarControl: () => void;
}

const Header: FC<PropsWithChildren<HeaderProps>> = () => {
  const location = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { prefixedT } = useCustomTranslation("HEADER");
  let title;
  const path = location.pathname;
  const query = useQuery();
  const projectName = query.get("project-name");

  switch (true) {
    case path.includes("delivery-performance-le"):
      title = prefixedT("DPLE");
      break;
    case path.includes("delivery-performance-sensor-technician"):
      title = prefixedT("DPST");
      break;
    case path.includes("dashboard"):
      title = prefixedT("DASHBOARD");
      break;
    case path.includes("session"):
      title = prefixedT("SESSION");
      break;
    case path.includes("project-status"):
      title =
        prefixedT("PROJECT_STATUS") + (projectName ? " - " + projectName : "");
      break;
    case path.includes("consumption-profile"):
      title = prefixedT("CONSUMPTION_PROFILE");
      break;
    case path.includes("job"):
      title = prefixedT("JOB_DETAILS");
      break;
    case path.includes("top-sound"):
      title = prefixedT("TOP_SOUND");
      break;
    case path.includes("correlation"):
      title = prefixedT("CORRELATION");
      break;
    case path.includes("reports"):
      title = prefixedT("REPORTS");
      break;
    case path.includes("logs"):
      title = prefixedT("LOGS");
      break;
    case path.includes("user-management"):
      title = prefixedT("USERS");
      break;
    case path.includes("sensor-management"):
      title = prefixedT("SENSOR_MANAGEMENT");
      break;
    case path.includes("relay-management"):
      title = prefixedT("RELAY_MANAGEMENT");
      break;
    case path.includes("gis-upload"):
      title = prefixedT("GIS_UPLOAD");
      break;
    case path.includes("gis-admin"):
      title = prefixedT("GIS_ADMIN");
      break;
    default:
      title = "";
      break;
  }

  return (
    <Styled.Container data-testid="header-container">
      <Styled.MenuSection>
        {/* <Styled.Icon data-testid="header-icon" onClick={sidebarControl} /> */}
        <div>
          <Styled.headerHeading>{title}</Styled.headerHeading>
        </div>
        <Styled.MobileSeparator />
      </Styled.MenuSection>
      <Styled.MainSection>
        <Styled.Separator />
        <LanguageSelector />
        <Styled.Separator />
        <Styled.ProfileContainer>
          <Profile />
        </Styled.ProfileContainer>
      </Styled.MainSection>
    </Styled.Container>
  );
};

export default Header;
