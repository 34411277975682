import React, { FC } from "react";
import * as Styled from "./style";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { Button } from "@/components";
import { useDeleteUserMutation } from "@/redux/user/user.api";
import { ButtonVariant } from "@/components/Button/Button";

interface DeleteUserProps {
  id: number | undefined;
  closeOverlay: (refetchValues: boolean) => Promise<void>;
}

const DeleteUser: FC<DeleteUserProps> = ({ id, closeOverlay }) => {
  const [mutate, { isSuccess, isError, reset }] = useDeleteUserMutation();
  const { prefixedT } = useCustomTranslation("DELETE_USER");

  const onSubmit = async () => {
    if (id) {
      await mutate(id);
    }
  };

  return (
    <>
      <Styled.Underlay
        data-testid={"overlay"}
        onClick={() => {
          void (async () => {
            await closeOverlay(false);
          })();
        }}
      />
      <Styled.DialogBox open={true}>
        <Styled.Wrapper>
          {!isSuccess ? (
            <>
              <Styled.TitleContainer>
                <Styled.Title>{prefixedT("TITLE")}</Styled.Title>
                <Styled.Paragraph>{prefixedT("DESC")}</Styled.Paragraph>
              </Styled.TitleContainer>
              <Styled.ButtonContainer>
                <Button
                  onClick={() => {
                    void onSubmit();
                  }}
                >
                  {prefixedT("BTN.SUBMIT")}
                </Button>
                <Button
                  variant={ButtonVariant.outline}
                  onClick={() => {
                    void (async () => {
                      await closeOverlay(false);
                    })();
                  }}
                >
                  {prefixedT("BTN.CANCEL")}
                </Button>
              </Styled.ButtonContainer>
              {isError && (
                <Styled.ErrorText>
                  {prefixedT("VALIDATION.ERROR")}
                </Styled.ErrorText>
              )}
            </>
          ) : (
            <div>
              <Styled.SvgContainer>
                <Styled.CheckPostiveIcon />
              </Styled.SvgContainer>
              <Styled.AcknowledgementTitle>
                {prefixedT("SUCCESS.MSG")}
              </Styled.AcknowledgementTitle>
              <Button
                onClick={() => {
                  void (async () => {
                    reset();
                    await closeOverlay(true);
                  })();
                }}
              >
                {prefixedT("BTN.CLOSE")}
              </Button>
            </div>
          )}
        </Styled.Wrapper>
      </Styled.DialogBox>
    </>
  );
};

export default DeleteUser;
