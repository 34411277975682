import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
`;

export const SubmitBtn = styled.button`
  display: flex;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  color: #01699d;
  transition: font-size 0.3s ease;

  &:hover {
    font-size: 1.02em;
  }
`;
