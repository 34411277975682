import LogoIcon from "@/assets/icons/logo_blue.svg?react";
import bg from "@/assets/images/fido_bg.jpeg";
import { Form as FormComponent } from "formik";
import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  max-width: 800px;
  width: 100%;
  height: 550px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 100%;
    flex-direction: column;
    border: none;
  }
  @media (max-height: 600px) {
    flex-direction: row;
    max-width: 100%;
    height: 100vh;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.m}) and (max-height: 600px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const ImageBlock = styled.div`
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 100%;
    min-height: 150px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.m}) and (max-height: 600px) {
    min-height: 150px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.m}) and (max-height: 350px) {
    min-height: 100px;
  }
`;

export const InfoBlock = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 100%;
    flex-grow: 1;
    margin-top: -50px;
    border-radius: 16px 16px;
  }
  @media (max-width: 375px) {
    border-radius: 25px 25px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.m}) and (max-height: 600px) {
    height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-grow: 0;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.xs}) and (max-height: 300px) {
    width: 100%;
  }
  @media (max-height: 450px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const FormContainer = styled.div`
  margin: 20px 32px;
  height: calc(100% - 40px);
  @media (max-width: 375px) {
    margin: 15px 32px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.m}) and (max-height: 600px) {
    height: calc(100% - 20px);
    width: 100%;
    margin: 10px 32px;
    align-self: flex-start;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.m}) and (max-height: 540px) {
    height: auto;
  }
  @media (max-height: 550px) {
    height: auto;
    align-self: flex-start;
    margin: 20px;
  }
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    order: 1;
  }
`;
export const AuthLogo = styled(LogoIcon)`
  width: 100px;
  height: 50px;
  margin-bottom: 30px;
  @media (max-height: 400px) {
    margin-bottom: 10px;
  }
  @media (max-width: 320px) {
    margin-bottom: 10px;
  }
`;
export const AuthFormTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.deepDark};
  margin-bottom: 8px;
  line-height: 25px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.s}) and (max-height: 350px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media (max-width: 320px) {
    font-size: 17px;
    margin-bottom: 10px;
  }
  @media (max-height: 450px) {
    margin-bottom: 10px;
  }
`;
export const AuthSubtitleWrapper = styled.div`
  width: 100%;
  height: 18px;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-bottom: 20px;
  }
`;
export const AuthFormSubtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.mediumDark};
  margin-bottom: 30px;
  line-height: 18px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.s}) and (max-height: 350px) {
    font-size: 12px;
    margin-bottom: 20px;
  }
  @media (max-height: 450px) {
    margin-bottom: 10px;
  }
  @media (max-width: 320px) {
    font-size: 11px;
    margin-bottom: 10px;
  }
`;
export const AuthButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const AuthForm = styled(FormComponent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-height: 600px) {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 100%;
  }
`;
export const AuthFields = styled.div`
  margin-bottom: 32px;
  flex-grow: 1;
  & > * :last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0;
  }
`;
