import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface DropdownProps {
  name: string;
  values: { name: string; url: string; image: string }[];
  selected: string;
  keyValue: number;
}

// TODO: Refactor - as tailwindcss

const Title = styled.div`
  line-height: 30px;
  font-size: 12px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;

const DropdownContainer = styled.div`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
`;

const SelectedBorder = styled.div`
  width: 5px;
  content: "";
  border-radius: 10px;
  background: #00699d;
  position: absolute;
  left: -10px;
  top: 3px;
  bottom: 3px;
`;

const LayerTitle = styled.div<{
  selected: boolean;
}>`
  position: relative;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background: ${({ selected }) => (selected ? "#F8F9FA" : "#FFF")};
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  display: flex;
  align-items: center;

  :hover {
    background: #f8f9fa;
  }
`;

const Image = styled.img`
  margin-right: 10px;
  height: 25px;
  width: 25px;
  margin-left: 10px;
`;

const Wrapper = styled.div<{
  firstChild: boolean;
}>`
  border-top: ${({ firstChild }) =>
    firstChild ? "none" : "1px solid #e7e7e7"};
  margin-bottom: 5px;
`;

const UserManagementDropdown: React.FC<DropdownProps> = ({
  keyValue,
  selected,
  name,
  values
}) => {
  const navigate = useNavigate();

  const handleOptionClick = (selectedOption: string) => {
    navigate(`/app/user-management/${selectedOption}`);
  };

  return (
    <Wrapper firstChild={keyValue === 0}>
      <Title>{name}</Title>
      <DropdownContainer>
        {values.map((value, index) => (
          <React.Fragment key={index}>
            <LayerTitle
              key={index}
              selected={!!selected && value.url.includes(selected)}
              className="dropdown-item"
              onClick={() => handleOptionClick(value.url)}
            >
              {!!selected && value.url.includes(selected) && <SelectedBorder />}
              {value.image && <Image alt={value.name} src={value.image} />}
              {value.name}
            </LayerTitle>
          </React.Fragment>
        ))}
      </DropdownContainer>
    </Wrapper>
  );
};

export default UserManagementDropdown;
