import {
  DropWidget,
  ReportLayout as ReportLayoutType,
  ReportLayoutItem,
  ReportWidget
} from "@/types/report.type";
import React, { FC, useMemo } from "react";
import { TableSortOrders } from "../Table/types";
import * as Styled from "./style";
import Target from "./Target/Target";

type Props = {
  structure: ReportLayoutType;
  reportName: string | undefined;
  reportId: number | undefined;
  tableResultsCount: number | undefined;
  pageNumber: number | undefined;
  order: TableSortOrders | undefined;
  orderBy: string | undefined;
  onAddWidget?: (widget: DropWidget, layoutId: string | number) => void;
  onMoveWidget?: (widget: DropWidget, layoutId: string | number) => void;
  onSortWidgets?: (
    layoutId: string | number,
    newWidgets: ReportWidget<unknown>[]
  ) => void;
  onRemoveWidget?: (
    widget: ReportWidget<unknown>,
    layoutId: string | number
  ) => void;
  onUpdateWidget?: (
    widget: ReportWidget<unknown>,
    layoutId: string | number
  ) => void;
  readonly?: boolean;
  sortable?: boolean;
  handleUpdateRunningQuery?: (query: {
    page: number;
    perPage: number;
    orderBy: string | undefined;
    order: string | undefined;
    type: string;
  }) => void;
};

const ReportLayout: FC<Props> = ({
  structure,
  reportName,
  reportId,
  tableResultsCount,
  readonly = false,
  sortable = false,
  pageNumber,
  order,
  orderBy,
  onAddWidget,
  onMoveWidget,
  onSortWidgets,
  onRemoveWidget,
  onUpdateWidget,
  handleUpdateRunningQuery
}) => {
  const isSortable = useMemo(() => sortable && !readonly, [readonly, sortable]);

  const renderTargets = (items: ReportLayoutType) =>
    items.map((item: ReportLayoutItem) => {
      if (item.widgets) {
        return (
          <Styled.ColumnWrapper key={item.layoutId} items={items.length}>
            <Styled.Column>
              <Target
                onAddWidget={onAddWidget}
                onMoveWidget={onMoveWidget}
                widgets={item.widgets}
                structure={structure}
                reportName={reportName}
                reportId={reportId}
                tableResultsCount={tableResultsCount}
                id={item.layoutId}
                sortable={isSortable}
                pageNumber={pageNumber}
                order={order ?? undefined}
                orderBy={orderBy ?? undefined}
                readonly={Boolean(readonly)}
                onSortWidgets={onSortWidgets}
                onRemoveWidget={onRemoveWidget}
                onUpdateWidget={onUpdateWidget}
                handleUpdateRunningQuery={handleUpdateRunningQuery}
              />
            </Styled.Column>
          </Styled.ColumnWrapper>
        );
      }
      if (item.type === "row") {
        return (
          <Styled.Row count={item.children?.length || 0} key={item.layoutId}>
            {renderTargets(item.children || [])}
          </Styled.Row>
        );
      }
      return (
        <Styled.ColumnWrapper key={item.layoutId} items={items.length}>
          <Styled.Column>{renderTargets(item.children || [])}</Styled.Column>
        </Styled.ColumnWrapper>
      );
    });

  return (
    <Styled.Container readonly={readonly}>
      {renderTargets(structure)}
    </Styled.Container>
  );
};

export default React.memo(ReportLayout);
