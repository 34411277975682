import { GetStatusResponse } from "@/types/mobileApp.type";
import {
  GetDMAsResponse,
  GetProjectResponse
} from "@/types/portalProject.type";
import { WaypointInfoResponse } from "@/types/report.type";
import React, { FC, useState } from "react";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { Filter } from "./Filter/Filter";
import * as Styled from "./style";
import WaypointTable from "./WaypointTable/WaypointTable";
// import { WaypointTableShad } from "./WaypointTable/WaypointTableShad";

interface WaypointFilterProps {
  statuses: GetStatusResponse[] | undefined;
  waypointData: WaypointInfoResponse | undefined;
  projects?: GetProjectResponse | undefined;
  dmas?: GetDMAsResponse | undefined;
}

export const WaypointFilter: FC<WaypointFilterProps> = ({
  dmas,
  waypointData,
  statuses,
  projects
}) => {
  const [isFilterTableOpen, setisFilterTableOpen] = useState(true);

  const handleClick = () => {
    setisFilterTableOpen(!isFilterTableOpen);
  };
  // console.log("[wp] WaypointFilter render: ", {
  //   dmas,
  //   waypointData,
  //   statuses,
  //   projects,
  //   isFilterTableOpen
  // });

  return (
    <Styled.Wrapper>
      <Styled.FilterBar>
        <Filter dmas={dmas} projects={projects} statuses={statuses} />
        <Styled.CloseBtnContainer>
          <Styled.CloseBtn data-testid={"closebtn"} onClick={handleClick}>
            <Styled.ArrowDownIcon open={!isFilterTableOpen} />
          </Styled.CloseBtn>
        </Styled.CloseBtnContainer>
      </Styled.FilterBar>
      <Breadcrumbs />
      {isFilterTableOpen && <WaypointTable waypointData={waypointData} />}

      {/* <WaypointTableShad waypointData={waypointData} /> */}
    </Styled.Wrapper>
  );
};
