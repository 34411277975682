import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC, useState } from "react";
import { Funnel, FunnelChart, Cell, LabelList } from "recharts";
import type { Props } from "recharts/types/component/Label";
import * as Styled from "./style";

const COLORS = ["#009FE3", "#59C4E9", "#F09425", "#F3F3F3"];

interface FunnelChartComponentProps {
  dimensions: number;
  data: Data[];
  onClick?: () => void;
}

interface Data {
  value: number;
  fill?: string;
  name: string;
}

interface ExtendedProps extends Props {
  index: number;
}

const FunnelChartComponent: FC<FunnelChartComponentProps> = ({
  onClick,
  dimensions,
  data
}) => {
  const noData = data.every((e) => e.value === 0);
  const { t } = useCustomTranslation("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const renderCustomizedLabel = (props: ExtendedProps): React.ReactNode => {
    const { x, y, width, height, index, value } = props;
    const isLast = index === data.length - 1;
    const fillColor = isLast ? "#007CBB" : "#fff";

    const widthValue = Number(width) || 0;
    const heightValue = Number(height) || 0;

    return (
      <text
        x={Number(x) + widthValue / 2}
        y={Number(y) + heightValue / 2}
        fill={fillColor}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  const handleMouseEnter = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  const CustomTooltip = () => (
    <Styled.ToolTipContainer
      data-testid="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {data.map((entry, index) => (
        <Styled.ToolTipItem key={index}>
          <p>{entry.name}</p>
          <Styled.block color={entry.fill || ""} />
        </Styled.ToolTipItem>
      ))}
    </Styled.ToolTipContainer>
  );

  return (
    <Styled.Container onClick={onClick} data-testid="funnel-chart-container">
      {noData ? (
        <Styled.errorMsg>
          {t("COMPONENTS.FUNNEL_CHART.ERROR_MSG")}
        </Styled.errorMsg>
      ) : (
        <div>
          {tooltipOpen && <CustomTooltip />}
          <FunnelChart
            width={dimensions + 100}
            height={dimensions}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Funnel dataKey="value" data={data} isAnimationActive={false}>
              <LabelList
                position="inside"
                content={
                  renderCustomizedLabel as (props: Props) => React.ReactNode
                }
              />
              {data.map((_entry, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Funnel>
          </FunnelChart>
        </div>
      )}
    </Styled.Container>
  );
};

export default FunnelChartComponent;
