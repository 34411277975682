import { useCallback, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useTranslation } from "react-i18next";

// Types
import {
  WebsocketMessageTypeBase,
  WebsocketMessageTypeGISUpload,
  WebsocketStatus,
  WebsocketType
} from "@/types/websocket.type";

// Redux
import { useAppDispatch, useAppSelector } from "@/redux";
import { singleWebsocketSelector } from "@/redux/websocket/websocket.selector";
import {
  closeWSConnection,
  removeWSConnection
} from "@/redux/websocket/websocket.slice";
import { setUploadValues } from "@/redux/gisUpload/gisUpload.slice";

// Hooks
import useNotification from "@/hooks/useNotification";

// Utils
import { parseWebsocketMessage } from "@/utils/websocket.util";

type WebsocketProps = {
  websocketId: string;
};

export const WebsocketHandler = ({ websocketId }: WebsocketProps) => {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const { i18n, t } = useTranslation();

  // TODO: Test for memoization issue (mb use useMemo)
  const websocket = useAppSelector((state) =>
    singleWebsocketSelector(state, websocketId)
  );
  const { lastMessage, readyState } = useWebSocket(
    websocket?.url ?? null,
    {},
    !!websocket?.url
  );

  const handleIncomingMessage = useCallback(
    (message: string) => {
      const { type, data } = parseWebsocketMessage(message as string);

      let errorMessage = "Could not upload shapefile";

      switch (websocket.type) {
        case WebsocketType.GIS_UPLOAD:
          switch (type) {
            case WebsocketMessageTypeGISUpload.UPLOAD_SUCCESS:
              dispatch(
                setUploadValues({
                  uploadId: Number(data.upload_id),
                  uploadStatus: "success"
                })
              );
              dispatch(closeWSConnection(websocket.id));

              break;
            case WebsocketMessageTypeGISUpload.UPLOAD_FAILURE:
              dispatch(
                setUploadValues({
                  uploadId: Number(data.upload_id),
                  uploadStatus: "error"
                })
              );
              dispatch(closeWSConnection(websocket.id));

              if (data.error_code) {
                const errorKey = `GIS_UPLOAD.ERROR.${(data.error_code as string).toUpperCase()}`;
                if (i18n.exists(errorKey)) {
                  errorMessage = t(errorKey);
                }
              } else if (data.error) {
                const errorKey = `SERVER_ERRORS.${(data.error as string).toUpperCase()}`;
                if (i18n.exists(errorKey)) {
                  errorMessage = t(errorKey);
                } else {
                  errorMessage = data.error as string;
                }
              }

              showNotification({
                type: "error",
                message: errorMessage
              });
              break;

            default:
              break;
          }
          break;
        case WebsocketType.ODL_GENERATION:
          switch (type) {
            case WebsocketMessageTypeBase.PENDING:
            case WebsocketMessageTypeBase.STARTED:
              break;
            case WebsocketMessageTypeBase.FAILURE:
            case WebsocketMessageTypeBase.SUCCESS:
              dispatch(closeWSConnection(websocket.id));
              break;

            default:
              break;
          }

          break;
        default:
          break;
      }
    },
    [t, websocket, dispatch, showNotification]
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (websocket && websocket.status === WebsocketStatus.CLOSING) {
      dispatch(removeWSConnection(websocketId));
    }
  }, [websocket, websocketId, dispatch]);

  useEffect(() => {
    if (lastMessage !== null && websocket.status !== WebsocketStatus.CLOSING) {
      handleIncomingMessage(lastMessage.data as string);
    }
  }, [websocket, lastMessage, readyState, handleIncomingMessage]);

  return null;
};
