import styled from "styled-components";
import tickIcon from "@/assets/icons/tick/tick.svg?react";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TickIcon = styled(tickIcon)`
  position: absolute;
  left: 17px;
  z-index: 3;
  width: 22px;
  fill: #fff;
`;

export const ListWrap = styled.div``;

export const List = styled.div`
  counter-reset: list-number;
  position: relative;
  font-size: 14px;
  display: inline-flex;
  flex-direction: column;
`;

export const ListItem = styled.div<{
  selected: boolean;
  completed: boolean;
}>`
  position: relative;
  padding: 20px 20px 20px 50px;
  margin-bottom: 20px;
  display: flex;
  border-radius: 5px;
  align-items: center;

  background: ${({ selected }) => selected && "#ebebeb"};
  color: ${({ selected }) => selected && "#007cbb"};

  :before {
    counter-increment: list-number;
    content: "";
    content: ${({ completed }) => (completed ? "" : "counter(list-number)")};

    z-index: 2;
    margin-right: 10px;
    margin-left: 10px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ completed }) => (completed ? "#1F7BF4" : "#fff")};
    border: 1px solid
      ${({ selected, completed }) =>
        selected ? "#1F7BF4" : completed ? "none" : "#d9d9d9"};
    border-radius: 50%;
    color: ${({ selected }) => (selected ? "#1F7BF4" : "#000")};

    position: absolute;
    left: 0;
  }

  :after {
    content: "";
    position: absolute;
    margin-left: 10px;
    left: 17px;
    top: 26px;
    bottom: -45px;
    border: 1px dashed #bbbbbb;
    z-index: 1;
  }

  :last-child:after {
    display: none;
  }
`;

export const Paragraph = styled.p`
  margin-left: 20px;
  font-weight: 500;
`;

export const Loader = styled.p`
  margin-left: 20px;
  font-weight: 500;
  animation: fadeOut 1s linear infinite alternate;

  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
`;
