import LoaderIcon from "@/assets/icons/loader_light.svg?react";
import theme from "@/theme";
import { IReactChildren } from "@/types/common.type";
import React, { FC } from "react";
import * as Styled from "./style";

export enum ButtonVariant {
  default = "default",
  outline = "outline"
}

export interface IProps {
  width?: string;
  height?: string;
  children: IReactChildren;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  testId?: string;
  loading?: boolean;
  variant?: ButtonVariant;
  icon?: string;
  color?: string;
}

const Button: FC<IProps> = ({
  width = "auto",
  height = "auto",
  icon = "",
  children,
  onClick,
  type = "button",
  disabled = false,
  testId,
  loading = false,
  variant = ButtonVariant.default,
  color = theme.colors.blue
}) => {
  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled && !loading && onClick) {
      onClick(event);
    }
  };

  return (
    <Styled.Container
      disabled={Boolean(disabled || loading)}
      width={width}
      height={height}
      onClick={clickHandler}
      data-testid={testId || "button"}
      variant={variant}
    >
      <Styled.Button
        variant={variant}
        color={color}
        disabled={Boolean(disabled || loading)}
        type={type}
        icon={icon}
      >
        {icon && (
          <img
            height="16px"
            width="16px;"
            style={{ marginRight: "10px" }}
            src={icon}
            alt="icon"
          />
        )}
        {loading ? <LoaderIcon width={25} height={25} /> : children}
      </Styled.Button>
    </Styled.Container>
  );
};

export default Button;
