import React, { FC, PropsWithChildren } from "react";
import * as Styled from "./style";

interface RowProps {
  background?: string;
}

const Row: FC<PropsWithChildren & RowProps> = ({ children, background }) => (
  <Styled.Container
    style={{ backgroundColor: background }}
    data-testid="table-row"
  >
    {children}
  </Styled.Container>
);

export default Row;
