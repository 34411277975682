const config = {
  NODE_ENV: import.meta.env.VITE_NODE_ENV,
  COOKIE_PATH: import.meta.env.VITE_COOKIE_PATH,
  COOKIE_DOMAIN: import.meta.env.VITE_COOKIE_DOMAIN,
  MAPBOX_KEY: import.meta.env.VITE_MAPBOX_KEY,
  API_URL: import.meta.env.VITE_API,
  GIS_API_URL: import.meta.env.VITE_GIS_API,
  ASK_FIDO_URL: import.meta.env.VITE_ASK_FIDO_URL,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT
};

export default config;
