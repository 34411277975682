import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Components
import { Loader } from "@/components";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Slider
} from "@fido/ui-lib";

// Types
import type { ProjectFormValues } from "../schema";
import { useUploadODLs } from "@/hooks/query/useUploadODLs";
import { useGISMapPreview } from "@/hooks/query/useGISMapPreview";
import { useUploadDMAs } from "@/hooks/query/useUploadDMAs";
import { WebsocketMessageTypeBase } from "@/types/websocket.type";
// import type {
//   BaseQueryFn,
//   TypedUseMutationResult
// } from "@reduxjs/toolkit/query/react";
// import type {
//   GeneratedODLsResponse,
//   GeneratedVirtualDMAResponse
// } from "@/types/portalProject.type";
// import { Loader } from "@/components";

// // TODO: TS - fix types, add proper dynamic ones or change approach
// export type ExtraMapDataLayer = {
//   dmaStatus: TypedUseMutationResult<
//     GeneratedVirtualDMAResponse,
//     { uploadId: number },
//     BaseQueryFn
//   >;
//   odlStatus: TypedUseMutationResult<
//     GeneratedODLsResponse,
//     { uploadId: number },
//     BaseQueryFn
//   >;
// };

type ProjectODLProps = object;

// TODO: Finish - add translation
const DescriptionODL = {
  1: "Sparse Deployment: Could be a predominantly metallic area or a low budget where you don't need every inch covered,",
  2: "Standard Deployment: This is our standard recommendation for balancing project cost and covering an area that is a mix of metallic and plastic.",
  3: "Complete Coverage: This could be used if you have a very complex plastic network with lots of junctions to ensure complete guaranteed coverage, at a high sensor consumption."
};

export const ProjectODL = (props: ProjectODLProps) => {
  const { t } = useTranslation();
  const form = useFormContext<ProjectFormValues>();
  const gisUploadCompanyId = form.watch("gisUploadCompanyId");
  const gisUploadId = form.watch("gisUploadId");

  const { data: mapPreviewData } = useGISMapPreview({
    companyId: gisUploadCompanyId,
    uploadId: gisUploadId
  });

  const {
    data: ODLData,
    isError: areODLsError,
    isLoading: areODLsLoading
  } = useUploadODLs({
    taskId: mapPreviewData?.odl_task_id
  });
  const { isLoading: areDMAsLoading } = useUploadDMAs({
    uploadId: gisUploadId
  });

  const isODLError =
    areODLsError || ODLData?.type === WebsocketMessageTypeBase.FAILURE;

  if (areDMAsLoading) {
    return <Loader label="DMAs loading..." />;
  }

  if (
    areODLsLoading ||
    [
      WebsocketMessageTypeBase.PENDING,
      WebsocketMessageTypeBase.STARTED
    ].includes(ODLData?.type)
  ) {
    return <Loader label="ODLs loading..." />;
  }

  if (isODLError) {
    return <p className="text-destructive">Error loading ODLs data...</p>;
  }

  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="levelODL"
        render={({ field }) => (
          <FormItem className="space-y-3">
            <FormLabel>
              Deployment Density:{" "}
              <span className="font-semibold">Level {field.value}</span>
            </FormLabel>
            <FormControl className="w-52">
              <Slider
                max={3}
                min={1}
                step={1}
                defaultValue={[field.value]}
                // onValueChange={(v) => {
                //   console.log("change: ", v);
                //   field. onChange(v[0] as number);
                // }}
                onValueCommit={(v) => {
                  field.onChange(v[0] as number);
                }}
                disabled={field.disabled}
              />
            </FormControl>
            <FormMessage />

            <p className="text-muted-foreground text-sm">
              {DescriptionODL[field.value]}
            </p>
          </FormItem>
        )}
      />
    </div>
  );
};
