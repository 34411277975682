import { useTranslation } from "react-i18next";

// Components
import { ProjectAdminTable } from "../components/tables/ProjectAdmin/ProjectAdmin.table";

export const ProjectAdmin = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-[100vh-var(--header-height)] p-10">
      <div className="mb-2">
        <div className="font-medium text-[var(--mediumBlue)]">
          {t("PROJECT_ADMIN.TITLE")}
        </div>
      </div>

      <ProjectAdminTable />
    </div>
  );
};
