import React, { PropsWithChildren, useContext, useMemo } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { TableContext } from "../context";
import { ITableHeader, TableCellAlignment } from "../types";
import * as Styled from "./style";

export interface ICellProps<T> {
  heading?: boolean;
  sortable?: boolean;
  id?: keyof T;
  align?: TableCellAlignment;
  headerId?: string;
  onClick?: (headerId: string | undefined) => void;
}

function Cell<T>({
  children,
  heading,
  sortable,
  id,
  align = TableCellAlignment.left,
  headerId,
  onClick
}: PropsWithChildren & ICellProps<T>) {
  const { onRequestSort, order, orderBy, headers } = useContext(TableContext);
  const onSort = () => onRequestSort(id);
  const header = useMemo(
    () => headers?.find((item: ITableHeader) => headerId === item.id),
    [headers, headerId]
  );

  const sortableProps = {
    sortable,
    id,
    onClick: onSort,
    isSorted: orderBy === id,
    order
  };

  const componentProps = heading && sortable ? sortableProps : {};
  if (header?.hidden) {
    return null;
  }

  const isFFTGraph = headerId?.includes("graph");
  const hasChildren = children !== null && children !== undefined;

  const content =
    isFFTGraph && hasChildren ? (
      <div>
        <button
          data-tip
          data-for={String(children)}
          onClick={() => {
            navigator.clipboard.writeText(String(children));
          }}
        >
          Hover over
        </button>
        <ReactTooltip
          id={String(children)}
          style={{
            backgroundColor: "transparent"
          }}
          children={() => (
            <img
              src={String(children)}
              style={{ width: 400, height: 400, objectFit: "fill" }}
              alt=""
            />
          )}
        />
      </div>
    ) : (
      children
    );

  return (
    <Styled.Container
      onClick={() => onClick && onClick(headerId)}
      data-testid="table-cell"
    >
      <Styled.Content data-testid="table-cell-content" align={align}>
        <Styled.Wrapper data-testid="table-cell-wrapper" {...componentProps}>
          {content}
        </Styled.Wrapper>
      </Styled.Content>
    </Styled.Container>
  );
}

export default Cell;
