import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

// Components
import {
  Separator,
  Label,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  cn,
  buttonVariants
} from "@fido/ui-lib";
import { FaFileUpload } from "react-icons/fa";
import { GISUploadsTable } from "../../../../../user-management/components/tables/GISUploadsTable";

// Types
import type { ReactPaginateProps } from "react-paginate";
import type { GISAdminPageResponse } from "@/types/GISAdmin.type";
import type { ProjectFormValues } from "../schema";

// Hooks
import { useQueryGISUploadsQuery } from "@/redux/GISAdmin/GISAdmin.api";
import { useGISUploadTableState } from "../../../../../user-management/hooks/table/useGISUploadTableState";
import { useSelectGISUploadMutation } from "@/redux/portalProjects/portalProjectsGIS.api";

// Data
import {
  getRowStructureGISUploads,
  headersGISUploads
} from "@/features/user-management/components/tables/GISUploadsTable/data";
import { getFormattedDate } from "@/utils/getFormattedDate";

type ProjectGISUploadProps = {
  jumpToNextStep: () => void;
};

export const ProjectGISUpload = ({ jumpToNextStep }: ProjectGISUploadProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const autoSelectGISUploadId = searchParams.get("autoSelectGISUploadId");

  const form = useFormContext<ProjectFormValues>();
  const [selectGISUpload] = useSelectGISUploadMutation();

  const projectId = form.watch("projectId");
  const companyId = form.watch("companyId");

  const {
    searchInput,
    setSearchInput,
    searchQuery,
    setSearchQuery,
    orderBy,
    page,
    setPage,
    perPage
  } = useGISUploadTableState({
    defaultPerPage: 5
  });

  const { data, isFetching, isError } = useQueryGISUploadsQuery(
    {
      page,
      per_page: perPage,
      search: searchQuery,
      order_type: orderBy
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const onPageChange: ReactPaginateProps["onPageChange"] = (p) => {
    setPage(p.selected + 1);
  };

  const selectGISData = async (id: number, companyId: number) => {
    

    const values = form.getValues();

    const { data: newUploadId } = await selectGISUpload({
      uploadId: Number(id),
      projectId: Number(values.projectId),
      companyId: Number(companyId)
    });

    

    // TODO: Refactor - come up with a way to control the form values when the project data is invalidated and refetched (so that we don't have to manually update it and keep it in sync)
    // *NB: we want to reset DMAs on gis upload change
    form.resetField("dmaIds");
    form.setValue("dmaIds", []);

    form.resetField("gisUploadId");
    form.setValue("gisUploadId", newUploadId, {
      shouldValidate: true,
      shouldDirty: true
    });

    form.resetField("gisUploadCompanyId");
    form.setValue("gisUploadCompanyId", companyId, {
      shouldValidate: true,
      shouldDirty: true
    });
    jumpToNextStep();
  };

  const handlePreviewClick = async (id: number, companyId: number) => {
    await selectGISData(id, companyId);
  };

  const handleSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  const handleSearchClick = () => {
    setSearchQuery(searchInput);
    setPage(1);
  };

  useEffect(() => {
    void (async () => {
      if (autoSelectGISUploadId > "") {
        await selectGISData(Number(autoSelectGISUploadId), companyId);
        setSearchParams((params) => {
          params.delete("autoSelectGISUploadId");
          return params;
        });
      }
    })();
  }, [autoSelectGISUploadId, companyId]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className='after:ml-0.5 after:text-destructive after:content-["*"]'>
          GIS Data
        </CardTitle>
        <CardDescription>
          Upload new GIS data or pick an existing one
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-8">
        <Link
          to={`/app/gis-upload?project_id=${projectId}&company_id=${companyId}`}
          className={cn(
            buttonVariants({ variant: "secondary", size: "lg" }),
            "w-full h-12"
          )}
        >
          <FaFileUpload /> Upload New GIS
        </Link>
        <div className="flex items-center gap-4">
          <Separator className="flex-1 bg-muted-foreground/20" />
          <span className="text-sm text-muted-foreground">
            or pick an existing one
          </span>
          <Separator className="flex-1 bg-muted-foreground/20" />
        </div>
        <div className="space-y-2">
          <Label>Existing GIS uploads</Label>
          <GISUploadsTable
            onSearchInputChange={handleSearchInputChange}
            onSearchClick={handleSearchClick}
            // onDeleteClick={handleDeleteClick}
            // onPreviewClick={handlePreviewClick}
            headers={headersGISUploads()}
            getRowStructure={(row: GISAdminPageResponse, idx: number) =>
              getRowStructureGISUploads(row, idx, handlePreviewClick)
            }
            onPageChange={onPageChange}
            data={data?.data}
            isLoading={isFetching}
            error={isError ? t("SERVER_ERRORS.-1") : undefined}
            currentPage={page}
            perPage={perPage}
            totalCount={data?.count}
          />
        </div>
      </CardContent>
    </Card>
  );
};
