import React, { FC } from "react";
import * as Styled from "../style";
import { ButtonVariant } from "@/components/Button/Button";
import { Button } from "@/components";
import BackArrow from "@/assets/icons/back-arrow.png";
import { Column, GISRoutes, SampleData } from "@/types/gisUpload.type";
import { GISHook } from "../../Hooks/GISHook";
import { AllowedType } from "../../utils";
import { GISType } from "../../ProcessFiles/ProcessFiles";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useGISUploadValues } from "@/Providers/GISUploadProvider";

export interface FileHeaderProps {
  filename: string;
  numberOfFiles: number;
  sampleData: SampleData[];
  tableColumns: Column[];
  refetch?: () => Promise<void>;
  queryLoading: boolean;
  lengthOfFiles: Record<AllowedType, number> | undefined;
  type: GISType;
}

const FileHeader: FC<FileHeaderProps> = ({
  filename,
  numberOfFiles,
  sampleData,
  tableColumns,
  lengthOfFiles,
  queryLoading,
  type
  // refetch,
}) => {
  const { prefixedT } = useCustomTranslation("GIS_UPLOAD.FILE_HEADER");
  const {
    discardFile,
    decrementFileIndex,
    incrementFileIndex,
    insufficientSampleData,
    discardFileLoading,
    reset,
    mappingLoading
  } = GISHook({
    sampleData,
    tableColumns,
    lengthOfFiles,
    type
  });
  const { isUploading, currentStep, fileIndex } = useGISUploadValues();

  const loading =
    discardFileLoading || queryLoading || isUploading || mappingLoading;

  return (
    <Styled.Wrapper data-testid="file-header">
      <Styled.Button onClick={reset}>
        <Styled.Img src={BackArrow as string} />
        {prefixedT("RESET")}
      </Styled.Button>
      <Styled.Container>
        <Styled.DetailContainer>
          {!queryLoading ? (
            <>
              {numberOfFiles ? (
                <>
                  <Styled.Step>
                    {prefixedT("COUNTER")} {(fileIndex ?? 0) + 1}/
                    {numberOfFiles}
                  </Styled.Step>
                  <p>
                    <Styled.MappingTxt>
                      {prefixedT("TITLE.MAPPING")}
                    </Styled.MappingTxt>{" "}
                    {prefixedT("TITLE.MAPPING_FOR")}{" "}
                    <Styled.MappingTxt>{filename}</Styled.MappingTxt>
                  </p>
                </>
              ) : (
                <Styled.Step>{prefixedT("NO_FILE")}</Styled.Step>
              )}
            </>
          ) : (
            <Styled.LoadingContainer>
              <Styled.LoadingTxt>{prefixedT("LOADING.TEXT")}</Styled.LoadingTxt>
              <Styled.Loading />
            </Styled.LoadingContainer>
          )}
        </Styled.DetailContainer>
        <Styled.ButtonContainer>
          <Button
            disabled={loading}
            onClick={() =>
              void discardFile(sampleData[fileIndex ?? 0]?.file_name)
            }
            variant={ButtonVariant.outline}
          >
            {prefixedT("DISCARD")}
          </Button>
          <Button
            disabled={loading || currentStep <= GISRoutes.PROCESS_DMA}
            onClick={decrementFileIndex}
            variant={ButtonVariant.outline}
          >
            {prefixedT("PREVIOUS")}
          </Button>
          <Button
            disabled={insufficientSampleData || loading}
            onClick={() => void incrementFileIndex()}
          >
            {prefixedT("NEXT")}
          </Button>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default FileHeader;
