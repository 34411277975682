import styled from "styled-components";

export const AssetTitle = styled.h3`
  text-decoration: underline;
  margin: 10px 0;
`;

export const Table = styled.table`
  border: 2px solid #007cbb;
  border-collapse: collapse;
  border-spacing: 10px;

  th {
    border-left: 2px solid #007cbb;
    padding: 10px;
  }
  th:first-child {
    border-left: none;
  }

  td {
    border-left: 2px solid #007cbb;
    padding: 5px;
  }
  td:first-child {
    border-left: none;
  }
  tbody {
    border-top: 2px solid #007cbb !important;
  }
`;

export const SessionTxt = styled.td`
  color: #007cbb;
`;

export const Container = styled.div`
  border: 2px solid #007cbb;
  padding: 10px;
`;

export const AssetList = styled.ul`
  list-style-type: none;
`;

export const AssetInfoTitle = styled.span`
  font-weight: 600;
`;
