import { useEffect, useState } from "react";
import { dropDownCountryData } from "@/utils/countryData";
import { DropDownOption } from "@/components/DropDown/DropDown";

export const useCountryCodesDropdown = () => {
  const [countryCodes, setCountryCodes] = useState<DropDownOption[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const countryData = await dropDownCountryData();

      setCountryCodes(countryData);
    };

    void fetchData();
  }, []);

  return countryCodes;
};
