import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "../api";

// Types
import type {
  IPaginationProps,
  IPaginationResponse,
  iSearchProps
} from "@/types/rtk.type";
import type {
  Company,
  GetListResponse,
  PatchUpdateCompanyBody,
  PostCreateCompanyBody
} from "@/types/portalCompany.type";

// Utils
import { buildQueryParams } from "@/utils/rtk";
import { Tag } from "../tag";

export const portalCompaniesApi = createApi({
  reducerPath: "portalCompaniesQuery",
  baseQuery: apiBaseQuery,
  tagTypes: [Tag.COMPANY_LIST],
  endpoints: (builder) => ({
    getCompanyList: builder.query<GetListResponse[], void>({
      query: () => "/portal_companies/list",
      providesTags: [{ type: Tag.COMPANY_LIST }]
    }),
    getCompanies: builder.query<
      IPaginationResponse<Company>,
      IPaginationProps & iSearchProps
    >({
      query: (params) =>
        `/portal_companies/companies/?${buildQueryParams(params || {})}`,
      providesTags: []
    }),
    postCreateCompany: builder.mutation<
      undefined,
      { body: PostCreateCompanyBody }
    >({
      query: ({ body }) => ({
        url: "/portal_companies/",
        method: "POST",
        body
      }),
      invalidatesTags: [{ type: Tag.COMPANY_LIST }]
    }),
    patchUpdateCompany: builder.mutation<
      undefined,
      { id: number; body: PatchUpdateCompanyBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_companies/${id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: []
    })
  })
});

export const {
  useGetCompanyListQuery,
  useLazyGetCompanyListQuery,
  useLazyGetCompaniesQuery,
  usePostCreateCompanyMutation,
  usePatchUpdateCompanyMutation
} = portalCompaniesApi;
