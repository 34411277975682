import {
  useGISUploadContext,
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { useGetUniqueValuesQuery } from "@/redux/gisUpload/gisUpload.api";
import * as Styled from "./style";
import React, { FC, useEffect, useMemo } from "react";
import { addOrReplaceError } from "@/containers/GISUpload/utils";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  createDropdown,
  standardising,
  // updateMappedFields,
  getUpdatedMappedFieldValue
} from "./Utils/utils";
import { Loader } from "@/components";
import SystemDropDown from "./Components/RenderDropdown/SystemDropDown";
import {
  GIS_COLUMN_MAPPING,
  ErrorModalText,
  FileSelectedValues,
  SelectedValue
} from "@/types/gisUpload.type";
import {
  Badge,
  cn,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@fido/ui-lib";
import { Trans } from "react-i18next";

interface SystemFieldsProps {
  file: FileSelectedValues;
  selectedFileValue: SelectedValue;
}

const SystemFields: FC<SystemFieldsProps> = ({ file, selectedFileValue }) => {
  const { t, prefixedT } = useCustomTranslation("SYSTEM_FIELDS");
  const { uploadId } = useGISUploadContext();
  const { selectedValues, mappedSystemFields, errors, companyId } =
    useGISUploadValues();
  const { setMappedSystemFields, setErrors, setLoading, setSelectedValues } =
    useGISUploadMethods();

  const filename = file.file;

  const {
    data,
    isError: UniqueValuesError,
    isFetching
  } = useGetUniqueValuesQuery(
    {
      companyId,
      uploadId,
      filename,
      columnTitle: selectedFileValue.name,
      standardising: standardising(selectedFileValue)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (UniqueValuesError) {
      setErrors(
        addOrReplaceError(errors, ErrorModalText.DEFAULT_ERROR_MESSAGE)
      );
    }
  }, [UniqueValuesError]);

  const dropdownOptions = createDropdown(selectedFileValue, data);

  const selectedFileValues = useMemo(() => {
    if (file && data && selectedFileValue.type) {
      // updateMappedFields(
      //   selectedFileValue.type,
      //   file,
      //   data,
      //   setMappedSystemFields,
      // );

      const fieldType = selectedFileValue.type;
      const fieldValue = getUpdatedMappedFieldValue({
        fieldType,
        file,
        data
      });

      if (mappedSystemFields[fieldType] !== fieldValue) {
        setMappedSystemFields({
          ...mappedSystemFields,
          [fieldType]: fieldValue
        });
      }
    }

    return selectedValues.find((f) => f.file === filename);
  }, [
    selectedValues,
    mappedSystemFields,
    selectedFileValue,
    file,
    data,
    filename
  ]);

  // useEffect(() => {
  //   if (file && data && selectedFileValue.type) {
  //     setMappedSystemFields();
  //   }
  // }, [mappedSystemFields, selectedFileValue, file, data, filename]);

  const cellValues = useMemo((): number => {
    switch (selectedFileValue.type) {
      case GIS_COLUMN_MAPPING.MATERIAL:
        return (file?.material && file?.material.length) || 0;
      case GIS_COLUMN_MAPPING.ASSET_TYPE:
        return (file?.asset_type && file?.asset_type.length) || 0;
      case GIS_COLUMN_MAPPING.PIPE_TYPE:
        return (file?.pipe_type && file?.pipe_type.length) || 0;
      case GIS_COLUMN_MAPPING.STATUS:
        return (file?.status && file?.status.length) || 0;
      case GIS_COLUMN_MAPPING.UNITS:
        return Array.isArray(file?.units) ? file.units.length : 0;
      default:
        return 0;
    }
  }, [file, selectedFileValue]);

  return (
    <>
      {isFetching ? (
        <Styled.LoadContainer>
          <Loader />
        </Styled.LoadContainer>
      ) : (
        <>
          {data?.unique_values?.length !== 0 ? (
            <div className="space-y-3">
              <div className="flex justify-between">
                <h2 className="font-medium">
                  {/* {t("PROCESS_FILES.MAP_FIELD_TITLE", {
                    title: selectedFileValue.title
                  })} */}

                  <Trans
                    i18nKey={"PROCESS_FILES.MAP_FIELD_TITLE"}
                    components={[
                      <Badge
                        className={cn({
                          "text-white": !selectedFileValue.required,
                          "bg-border": !selectedFileValue.required
                        })}
                      />
                    ]}
                    values={{ title: selectedFileValue.title }}
                  />
                </h2>
                <p
                  className={cn({
                    "text-destructive":
                      cellValues !== data?.unique_values?.length &&
                      errors?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)
                  })}
                >
                  <span className="font-bold">{selectedFileValue.title}</span>{" "}
                  {prefixedT("CELLS_FILLED")} {cellValues}/
                  {data?.unique_values?.length}
                </p>
                {errors?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC) && (
                  <p
                    className={cn({
                      "text-destructive": errors?.includes(
                        ErrorModalText.SYSTEM_FIELD_NUMERIC
                      )
                    })}
                  >
                    {prefixedT("ERROR.NUMERIC_UNIQUE_FIELD")}
                  </p>
                )}
              </div>
              <div className="[&>div]:max-h-[350px]">
                <Table className="border h-full ">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="text-center text-black">
                        {prefixedT("ROW_1")}
                      </TableHead>
                      <TableHead className="text-center text-black">
                        {prefixedT("ROW_2")}
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data?.unique_values?.map((el: string, key: number) => (
                      <TableRow key={key}>
                        <TableCell className="text-center">{el}</TableCell>
                        <TableCell className="text-center">
                          <SystemDropDown
                            keyIndex={key}
                            el={el}
                            selectedFileValues={selectedFileValues}
                            selectedFileValue={selectedFileValue}
                            dropdownOptions={dropdownOptions}
                            filename={filename}
                            data={data}
                            setErrors={setErrors}
                            errors={errors}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          ) : (
            <p
              className={cn({
                "text-destructive": errors?.includes(
                  ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED
                )
              })}
            >
              {prefixedT("UNIQUE_VALUES_NOT_FOUND")}
            </p>
          )}
        </>
      )}
    </>
  );
};

export default SystemFields;
