// Pages
import {
  ChangeLogContainer,
  DashboardContainer,
  ForgotPasswordContainer,
  LoginContainer,
  ProjectStatusContainer,
  RecoveryContainer,
  RecoveryExpiredContainer,
  ReportBuilderContainer,
  ReportsContainer,
  RunningReportContainer
} from "@/containers";
import ConsumptionProfileContainer from "@/containers/ConsumptionProfile/ConsumptionProfileContainer";
import CorrelationContainer from "@/containers/Correlation/CorrelationContainer";
import { ProfileContainer } from "@/containers/Profile";
import { RunningReportOutlet } from "@/containers/RunningReport";
import SessionContainer from "@/containers/Session/SessionContainer";
import StockManagementContainer from "@/containers/StockManagement/StockManagementContainer";
import TopSoundContainer from "@/containers/TopSound/TopSoundContainer";
import WaypointContainer from "@/containers/Waypoint/WaypointContainer";
import JobContainer from "@/containers/JobContainer/JobContainer";
import { AuthGuard } from "@/guards/AuthGuard";
import { PermissionGuard } from "@/guards/PermissionGuard";
import { AppLayout } from "@/layouts";
import { DashboardProvider } from "@/Providers/DashboardProvider";
import { UserRoles } from "@/types/user.type";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { FilterProvider } from "../Providers/FilterProvider";
import BrowseContainer from "@/containers/Browse/BrowseContainer";
import { GISNewUploadNew } from "@/containers/GISUpload/GISUploadNew";
import { GISUploadExisting } from "@/containers/GISUpload/GISUploadExisting";
import { PageTitle } from "@/components/PageTitle";

// Types
import type { AppRoute, AppRoutes } from "@/types/router.type";

// Containers
import { GISUploadContainer } from "@/containers/GISUpload/GISUploadContainer";

// Pages
import { UserManagement } from "@/features/user-management/layouts/UserManagement";
import { GISAdmin } from "@/features/user-management/pages/GISAdmin/GISAdmin";

import ViewPartners from "@/features/user-management/pages/ViewPartners/ViewPartners";
import ViewProjects from "@/features/user-management/pages/ViewProjects/ViewProjects";
import ViewUsers from "@/features/user-management/pages/ViewUsers/ViewUsers";
import ViewCompanies from "@/features/user-management/pages/ViewCompanies/ViewCompanies";
import AddUser from "@/features/user-management/pages/AddUser/AddUser";
import AddCompany from "@/features/user-management/pages/AddCompany/AddCompany";
import AddPartner from "@/features/user-management/pages/AddPartner/AddPartner";
import { ProjectCreate } from "@/features/projects/pages/ProjectCreate.page";
import { ProjectAdmin } from "@/features/projects/pages/ProjectAdmin.page";
import { ProjectUpdate } from "@/features/projects/pages/ProjectUpdate.page";
import { ROUTE_PORTAL_NAME } from "@/constants/Route.constant";

const LOGIN_REDIRECT = "/auth/login";
const DASHBOARD_REDIRECT = "/app/dashboard";


export const routes: AppRoutes = [
  {
    path: "/",
    element: <Navigate to={LOGIN_REDIRECT} />
  },
  {
    path: "auth",
    guards: [{ Guard: AuthGuard, props: { redirect: DASHBOARD_REDIRECT } }],
    children: [
      {
        index: true,
        element: <Navigate to="login" />
      },
      {
        path: "login",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Login`} />
            <LoginContainer />
          </>
        )
      },
      {
        path: "forgot",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Login`} />
            <ForgotPasswordContainer />
          </>
        )
      },
      {
        path: "recovery/expired",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Login`} />
            <RecoveryExpiredContainer />
          </>
        )
      },
      {
        path: "recovery/:token",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Login`} />
            <RecoveryContainer />
          </>
        )
      },
      {
        path: "confirm",
        element: <></>
      }
    ]
  },
  {
    path: "app",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Dashboard`} />
            <DashboardProvider>
              <DashboardContainer />
            </DashboardProvider>
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Dashboard`} />
                <DashboardProvider>
                  <DashboardContainer />
                </DashboardProvider>
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Dashboard`} />
                <DashboardProvider>
                  <DashboardContainer />
                </DashboardProvider>
              </>
            )
          }
        ]
      },
      {
        path: "admin",
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [UserRoles.SUPER_ADMIN]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: DASHBOARD_REDIRECT } }
        ],
        children: [
          {
            path: "project",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Project Admin`} />
                <ProjectAdmin />
              </>
            )
          }
        ]
      },
      {
        path: "user-management",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - User management`} />{" "}
            <UserManagement />
          </>
        ),
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN
              ]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "view-users",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - View users`} />
                {/* // TODO: test guard for children routes and call hooks inside ViewUsers  */}
                <ViewUsers />
              </>
            )
          },
          {
            path: "view-companies",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - View companies`} />
                <ViewCompanies />
              </>
            )
          },
          {
            path: "view-partners",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - View partners`} />
                <ViewPartners />
              </>
            )
          },
          {
            path: "view-projects",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - View projects`} />
                <ViewProjects />
              </>
            )
          },
          {
            path: "add-user",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Add user`} />
                <AddUser />
              </>
            )
          },
          {
            path: "add-company",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Add company`} />
                <AddCompany />
              </>
            )
          },
          {
            path: "add-partner",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Add partner`} />
                <AddPartner />
              </>
            )
          },
          {
            path: "project",
            children: [
              {
                path: "new",
                index: true,
                element: (
                  <>
                    <PageTitle title={`${ROUTE_PORTAL_NAME} - Add project`} />
                    <ProjectCreate />
                  </>
                )
              },
              {
                path: ":companyId/:projectId",
                element: (
                  <>
                    <PageTitle
                      title={`${ROUTE_PORTAL_NAME} - Update project`}
                    />
                    <ProjectUpdate />
                  </>
                )
              }
            ]
          }
        ]
      },
      {
        path: "gis-upload",
        element: <GISUploadContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: {
              roles: [UserRoles.SUPER_ADMIN]
            }
          }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - New GIS Upload`} />
                <GISNewUploadNew />
              </>
            )
          },
          {
            path: ":uploadId",
            element: (
              <>
                {/* // TODO: UI - maybe add upload id to title  */}
                <PageTitle
                  title={`${ROUTE_PORTAL_NAME} - Existing GIS Upload`}
                />
                <GISUploadExisting />
              </>
            )
          }
        ]
      },
      {
        path: "gis-admin",
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: {
              roles: [UserRoles.SUPER_ADMIN]
            }
          }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - GIS Admin`} />
                <GISAdmin />
              </>
            )
          }
        ]
      },
      {
        path: "reports",
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Reports`} />
                <ReportsContainer />
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Build report`} />
                <ReportBuilderContainer />
              </>
            )
          },
          {
            path: ":id/running",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - View report`} />
                <RunningReportOutlet />
              </>
            )
          }
        ]
      },
      {
        path: "project-status",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Project status`} />
            <FilterProvider>
              <ProjectStatusContainer />
            </FilterProvider>
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Project status`} />
                <FilterProvider>
                  <ProjectStatusContainer />
                </FilterProvider>
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Project status`} />
                <FilterProvider>
                  <ProjectStatusContainer />
                </FilterProvider>
              </>
            )
          }
        ]
      },
      // {
      //   path: 'delivery-performance-sensor-technician',
      //   element: (
      //     <DeliveryPerformanceProvider>
      //       <DeliveryPerformanceSTContainer />
      //     </DeliveryPerformanceProvider>
      //   ),
      //   guards: [
      //     { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
      //   ],
      //   children: [
      //     {
      //       path: '',
      //       index: true,
      //       element: (
      //         <DeliveryPerformanceProvider>
      //           <DeliveryPerformanceSTContainer />
      //         </DeliveryPerformanceProvider>
      //       ),
      //     },
      //     {
      //       path: ':id',
      //       element: (
      //         <DeliveryPerformanceProvider>
      //           <DeliveryPerformanceSTContainer />
      //         </DeliveryPerformanceProvider>
      //       ),
      //     },
      //   ],
      // },
      // {
      //   path: 'delivery-performance-le',
      //   element: (
      //     <DeliveryPerformanceProvider>
      //       <DeliveryPerformanceLEContainer />
      //     </DeliveryPerformanceProvider>
      //   ),
      //   guards: [
      //     { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
      //   ],
      //   children: [
      //     {
      //       path: '',
      //       index: true,
      //       element: (
      //         <DeliveryPerformanceProvider>
      //           <DeliveryPerformanceLEContainer />
      //         </DeliveryPerformanceProvider>
      //       ),
      //     },
      //     {
      //       path: ':id',
      //       element: (
      //         <DeliveryPerformanceProvider>
      //           <DeliveryPerformanceLEContainer />
      //         </DeliveryPerformanceProvider>
      //       ),
      //     },
      //   ],
      // },
      {
        path: "logs",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Change logs`} />
            <ChangeLogContainer />
          </>
        ),
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN
              ]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ]
      },
      {
        path: "waypoint",
        element: (
          <>
            <FilterProvider>
              <WaypointContainer />
            </FilterProvider>
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Waypoint`} />
                <FilterProvider>
                  <WaypointContainer />
                </FilterProvider>
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Waypoint`} />
                <FilterProvider>
                  <WaypointContainer />
                </FilterProvider>
              </>
            )
          }
        ]
      },
      {
        path: "job",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Job`} />
            <JobContainer />
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ]
      },
      {
        path: "session",
        element: (
          <>
            <SessionContainer />
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Session`} />
                <SessionContainer />
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Session`} />
                <SessionContainer />
              </>
            )
          }
        ]
      },
      {
        path: "top-sound",
        element: (
          <>
            <TopSoundContainer />
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Top sound`} />
                <TopSoundContainer />
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Top sound`} />
                <TopSoundContainer />
              </>
            )
          }
        ]
      },
      {
        path: "correlation",
        element: (
          <>
            <CorrelationContainer />
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Correlation`} />
                <CorrelationContainer />
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Correlation`} />
                <CorrelationContainer />
              </>
            )
          }
        ]
      },
      {
        path: "consumption-profile",
        element: (
          <>
            <ConsumptionProfileContainer />
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            index: true,
            element: (
              <>
                <PageTitle
                  title={`${ROUTE_PORTAL_NAME} - Consumption profile`}
                />
                <ConsumptionProfileContainer />
              </>
            )
          },
          {
            path: ":id",
            element: (
              <>
                <PageTitle
                  title={`${ROUTE_PORTAL_NAME} - Consumption profile`}
                />
                <ConsumptionProfileContainer />
              </>
            )
          }
        ]
      },
      {
        path: "logs",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Change logs`} />
            <ChangeLogContainer />
          </>
        ),
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN
              ]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ]
      },
      {
        path: "profile",
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Profile`} />
            <ProfileContainer />
          </>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ]
      },
      {
        path: "sensor-management",
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN
              ]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Sensor management`} />
            <StockManagementContainer isSensorManagement={true} />
          </>
        )
      },
      {
        path: "relay-management",
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN
              ]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        element: (
          <>
            <PageTitle title={`${ROUTE_PORTAL_NAME} - Relay management`} />
            <StockManagementContainer isSensorManagement={false} />
          </>
        )
      },
      {
        path: "browse",
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN
              ]
            }
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }
        ],
        children: [
          {
            path: "",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Browse`} />
                <BrowseContainer dataType="" />
              </>
            )
          },
          {
            path: "waypoints",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Waypoints`} />{" "}
                <BrowseContainer dataType="waypoints" />
              </>
            )
          },
          {
            path: "sessions",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Sessions`} />{" "}
                <BrowseContainer dataType="sessions" />
              </>
            )
          },
          {
            path: "jobs",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Jobs`} />{" "}
                <BrowseContainer dataType="jobs" />
              </>
            )
          },
          {
            path: "waypoint-logs",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Waypoint logs`} />{" "}
                <BrowseContainer dataType="waypoint-logs" />
              </>
            )
          },
          {
            path: "relays",
            element: (
              <>
                <PageTitle title={`${ROUTE_PORTAL_NAME} - Relays`} />{" "}
                <BrowseContainer dataType="relays" />
              </>
            )
          }
        ]
      }
    ]
  },
  {
    path: "reports/:id/running",
    guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
    element: (
      <>
        <PageTitle title={`${ROUTE_PORTAL_NAME} - Running report`} />{" "}
        <RunningReportContainer />
      </>
    )
  }
];

export const generateRouterObject = (router: AppRoutes): RouteObject[] =>
  // @ts-expect-error fix at a later date
  router.map(({ children, element, guards = [], ...rest }: AppRoute) => {
    const outlet = element || <Outlet />;
    const component = guards.reduce(
      (acc, { Guard, props }) => <Guard {...props}>{acc}</Guard>,
      outlet
    );

    return {
      ...rest,
      element: component,
      children: children && generateRouterObject(children)
    };
  });
