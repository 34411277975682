import { HistoryLog } from "@/types/report.type";
import moment from "moment";
import React, { FC } from "react";
import * as Styled from "./style";

interface HistoryItemProps {
  history: HistoryLog;
  firstChild: boolean;
}

const HistoryItem: FC<HistoryItemProps> = ({ history }) => (
  <Styled.TR>
    <Styled.TD>
      {moment(history.datetime).format("YYYY-MM-DD h:mm:ss A")}
    </Styled.TD>
    <Styled.TD>{history.user_name}</Styled.TD>
    <Styled.TD>
      {history.status_changed_from_id && history.status_changed_from_id + " >"}
      {history.changed_to_status_name}
    </Styled.TD>
    <Styled.TD>{history.notes}</Styled.TD>
    <Styled.TD>{history.time_elapsed}</Styled.TD>
  </Styled.TR>
);

export default HistoryItem;
