import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import * as Styled from "./style";
import React, { FC, useMemo } from "react";
import { updateSelectedValues } from "./utils";
import { SelectedValue } from "@/types/gisUpload.type";
import { Badge, Button, Card, CardContent, CardTitle, cn } from "@fido/ui-lib";
import { LuX } from "react-icons/lu";

interface SelectedItemsProps {
  fileName: string;
}

export const SelectedItems: FC<SelectedItemsProps> = ({ fileName }) => {
  const { prefixedT } = useCustomTranslation("GIS_UPLOAD.TABLE");
  const { selectedValues, currentStep } = useGISUploadValues();
  const { setSelectedValues } = useGISUploadMethods();

  const selectedFileValues = useMemo(
    () => selectedValues.find((f) => f.file === fileName),
    [selectedValues, fileName]
  );

  const removeItem = (column: SelectedValue) => {
    // setSelectedValues((prevValues) =>
    //   updateSelectedValues(prevValues, fileName, column, currentStep),
    // );
    setSelectedValues(
      updateSelectedValues(selectedValues, fileName, column, currentStep)
    );
  };

  // console.log("selected values: ", {
  //   selectedFileValues,
  //   fileName
  // });

  return (
    <Card className="p-4">
      <CardTitle className="text-base font-medium mb-2">
        {prefixedT("MAPPING_TITLE")}
      </CardTitle>
      {(selectedFileValues?.data.length === 0 || !selectedFileValues?.data) && (
        <p className="text-muted-foreground">{prefixedT("SELECT_COLUMN")}</p>
      )}
      <CardContent className="flex flex-wrap gap-2 p-0 gap-y-0">
        {selectedFileValues?.data.map((value, index) => {
          const duplicateColumn =
            selectedFileValues.data.filter((file) => file.title === value.title)
              .length > 1;
          return (
            <div
              key={`selected-value-${value.name}`}
              className={cn("flex gap-2 items-center", {
                "text-destructive": duplicateColumn
              })}
            >
              <div className="flex gap-2 leading-normal">
                <Badge
                  variant={duplicateColumn ? "destructive" : "default"}
                  className={cn({
                    "text-white": !value.required && !duplicateColumn,
                    "bg-border": !value.required && !duplicateColumn
                  })}
                >
                  {value.title}
                </Badge>
                <span>→</span>
                <span>{value.name}</span>
              </div>
              <Button
                type="button"
                onClick={() => removeItem(value)}
                variant={duplicateColumn ? "destructive" : "ghost"}
                size="icon"
                data-testid="remove-button"
              >
                <LuX className="h-4 w-4" />
              </Button>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};
