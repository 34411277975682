import { useEffect, useState } from "react";

// Components
import SearchBar from "@/components/SearchBar/SearchBar";
import { DropDown, Loader, Table } from "@/components";
import { ConfirmDialog, ConfirmDialogProps } from "../../dialogs/ConfirmDialog";

// Styles
import * as Styled from "./style";

// Hooks
import {
  useGetProjectsListQuery,
  useLazyGetProjectsListQuery
} from "@/redux/portalProjects/portalProjects.api";
import { useUpdateProjectStatusMutation } from "@/redux/portalProjects/portalProjectsGIS.api";
import useCustomTranslation from "@/localization/useCustomTranslation";

// Data
import { getRowStructure, headers } from "./structure";

// Types
import type { DropDownOption } from "@/components/DropDown/DropDown";
import { ProjectStatus, type Project } from "@/types/portalProject.type";
import { type ITablePaginate } from "@/components/Table/types";

const defaultPageCount = 1;
const defaultPerPage = 10;
const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];
// TODO: Refactor - as shadcnui table
export const ProjectAdminTable = () => {
  const { t, prefixedT } = useCustomTranslation("VIEW_PROJECTS");

  const initialConfirmDialog = {
    open: false,
    onConfirm: () => {},
    title: ""
  };
  const [confirmDialog, setConfirmDialog] =
    useState<Omit<ConfirmDialogProps, "setOpen">>(initialConfirmDialog);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(defaultPerPage);

  const [updateProjectStatus, { isLoading: isUpdateStatusLoading }] =
    useUpdateProjectStatusMutation();

  // TODO: Refactor - redo as not "lazy" query (fix "Rendered more hooks than during the previous render" error)
  const [fetchProjectList, { data: projectList, isFetching, isError }] =
    useLazyGetProjectsListQuery();

  const currentPageCount = Math.ceil(
    (projectList?.count || defaultPageCount) / perPage
  );

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setPage(1);
    // reset page to 1 when the search query changes to ensure we always show results
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleRowAmountChange = (value: DropDownOption) => {
    setPerPage(Number(value.value));
    setPage(1);
    // reset page to 1 when the number per page changes to ensure we always show results
  };

  const onAccept = (projectId: number) => {
    setConfirmDialog({
      open: true,
      title: "Approve project?",
      onConfirm: async () => {
        await updateProjectStatus({
          projectId,
          status: ProjectStatus.APPROVED
        });
        handleToggleDialog(false);
      }
    });
  };

  const onReject = (projectId: number) => {
    setConfirmDialog({
      open: true,
      title: "Reject project?",
      onConfirm: async () => {
        await updateProjectStatus({
          projectId,
          status: ProjectStatus.REJECTED
        });
        handleToggleDialog(false);
      }
    });
  };

  const handleToggleDialog = (open: boolean) => {
    setConfirmDialog((prev) => ({
      ...prev,
      open
    }));
  };

  useEffect(() => {
    if (fetchProjectList) {
      void fetchProjectList({
        page,
        per_page: perPage,
        search: searchQuery
        // sort_order: TableSortOrders.desc,
        // sort_by: "created_at"
      });
    }
  }, [page, perPage, searchQuery, fetchProjectList]);

  return (
    <div>
      <ConfirmDialog
        {...confirmDialog}
        setOpen={handleToggleDialog}
        isLoading={isUpdateStatusLoading}
      />
      <div className="mb-2 w-1/2">
        <SearchBar
          placeholder={t("COMPONENTS.SEARCH_BAR.GENERIC")}
          onSearch={handleSearch}
        />
      </div>
      <Styled.Container>
        <Styled.TitleContainer>
          <Styled.Title>{prefixedT("TITLE.LIST")}</Styled.Title>
        </Styled.TitleContainer>
        <Table data={projectList?.data || []} headers={headers()}>
          <Table.Head getHeaderStructure={(header) => header.title} />
          {isFetching && (
            <div className="w-full relative h-72 ">
              <div className="w-full flex justify-center items-center">
                <Loader />
              </div>
            </div>
          )}
          {isError && <p>{t("ERROR.REQUEST")}</p>}
          {!isFetching && !isError && (
            <Table.Body
              getRowStructure={(row: Project, idx: number) =>
                getRowStructure({
                  row,
                  idx,
                  onAccept,
                  onReject,
                  isLoading: isUpdateStatusLoading
                })
              }
              striped
            />
          )}
        </Table>
        <Styled.PaginationWrapper>
          <Styled.PagniationContainer>
            <Styled.DropDownContainer>
              <p>{t("VIEW_USERS.PAGINATION.TITLE")}</p>
              <DropDown
                value={perPage.toString()}
                options={pageValues}
                onChange={(value) =>
                  handleRowAmountChange(value as DropDownOption)
                }
                placeholder={"Number of rows"}
              />
            </Styled.DropDownContainer>
            <Table.Pagination
              pages={currentPageCount}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PagniationContainer>
        </Styled.PaginationWrapper>
      </Styled.Container>
    </div>
  );

  // return (
  //   <SearchableTable
  //     queryResponse={projectList}
  //     isFetching={isFetching}
  //     isError={isError}
  //     trigger={trigger}
  //     getRowStructure={getRowStructure}
  //     headers={headers}
  //     translationPrefix="VIEW_PROJECTS"
  //   />
  // );
};
