import { Styles } from "@/types/styles.type";
import React, { FC } from "react";
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Styled from "./style";

interface IProps {
  label?: string;
  placeholder?: string;
  value?: Date | null;
  onChange: (value: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  disableFuture?: boolean;
  required?: boolean;
  disablePast?: boolean;
  error?: string;
  styles?: Styles;
  testId?: string;
  showTimeSelect?: boolean;
}

const DatePicker: FC<IProps> = ({
  onChange,
  value,
  label,
  disableFuture,
  disablePast,
  placeholder,
  required,
  error,
  styles,
  testId,
  maxDate,
  minDate,
  showTimeSelect = false
}) => (
  <Styled.Container data-testid={testId || "datepicker"} error={error}>
    {label && (
      <Styled.Label
        labelStyle={styles?.label}
        data-testid="datepicker-label"
        error={!!error}
      >
        {label} {required && <Styled.Required>*</Styled.Required>}
      </Styled.Label>
    )}
    <Picker
      placeholderText={placeholder}
      selected={value}
      onChange={onChange}
      maxDate={disableFuture ? maxDate || new Date() : null}
      minDate={disablePast ? minDate || new Date() : null}
      popperClassName="datepicker-dialog"
      calendarClassName="picker"
      showTimeSelect={showTimeSelect}
      dateFormat={showTimeSelect ? "yyyy/MM/dd hh:mm:ss" : "dd/MM/yyyy"}
    />
    {error && (
      <Styled.Error data-testid="datepicker-error">{error}</Styled.Error>
    )}
  </Styled.Container>
);

export default DatePicker;
