import InsertDate from "@/components/ProgressBar/insertDate/InsertDate";
import React, { FC } from "react";
import * as Styled from "./style";

interface DateChoiceWidgetProps {
  startDate: string | undefined | null;
  endDate: string | undefined | null;
  id: number;
}

const DateChoiceWidget: FC<DateChoiceWidgetProps> = ({
  startDate,
  endDate,
  id
}) => (
  <Styled.Wrapper>
    <div>
      <InsertDate
        dateType="start"
        startDate={startDate}
        endDate={endDate}
        id={id}
        type={"project"}
      />
    </div>
    <Styled.ArrowImg />
    <div>
      <InsertDate
        dateType="end"
        startDate={startDate}
        endDate={endDate}
        id={id}
        type={"project"}
      />
    </div>
  </Styled.Wrapper>
);

export default DateChoiceWidget;
