import { FC, Fragment } from "react";

// Components
import { ProjectCreateForm } from "../components/forms/Project/ProjectCreate.form";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";

export const ProjectCreate: FC = () => {
  const { prefixedT } = useCustomTranslation("ADD_PROJECT");

  return (
    <Fragment>
      <div className="mb-8">
        <h2 className="text-lg">{prefixedT("TITLE")}</h2>
      </div>

      <ProjectCreateForm />
    </Fragment>
  );
};
