import { useCallback, useMemo } from "react";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDebounce from "./useDebounce";

type ToastNotificationProps = {
  type: TypeOptions;
  message: string;
} & Partial<ToastOptions>;

export const useNotification = (delay = 3000) => {
  const options: ToastOptions = useMemo(
    () => ({
      position: "top-right",
      autoClose: delay,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined
    }),
    [delay]
  );

  const createNotification = useCallback(
    ({ type, message, ...restOptions }: ToastNotificationProps) => {
      switch (type) {
        case "info":
          return toast.info(message, { ...options, ...restOptions });
        case "success":
          return toast.success(message, { ...options, ...restOptions });
        case "warning":
          return toast.warning(message, { ...options, ...restOptions });
        case "error":
          return toast.error(message, { ...options, ...restOptions });
        default:
          return toast.info(message, { ...options, ...restOptions });
      }
    },
    [options]
  );

  // const showNotification = useDebounce(createNotification, 300);
  return { showNotification: createNotification };
};

export default useNotification;
