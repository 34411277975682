import React, { FC } from "react";
import IconInsight from "../IconInsight/IconInsight";
import TargetEdit from "../TargetEdit/TargetEdit";
import CurrentProgressKey from "./CurrentProgressKey/CurrentProgressKey";
import KeyContainer from "./KeyContainer/KeyContainer";
import * as Styled from "./style";
import Tunnel from "./Tunnel/Tunnel";

export interface KeyValues {
  name: string;
  color: string;
}

export interface ProgressValues {
  value: number | null;
  color: string;
  hover: number | null;
  type: string | null;
}

export interface TargetProgress {
  value: number | null;
  type: string | null;
  target: "target_pipeline" | "target_leakage_reduction";
  errorMsg: string;
}

interface ProgressBarProps {
  title: string;
  keyValues: KeyValues[];
  insightLabel: {
    location: string;
    title: string;
  };
  progressValues: ProgressValues[];
  targetProgress?: TargetProgress;
  id?: number;
  isCompany?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  insightLabel,
  title,
  targetProgress,
  progressValues,
  id,
  keyValues,
  isCompany
}) => {
  if (targetProgress) {
    return (
      <>
        <Styled.InfoIconWrapper>
          <Styled.PipelineTitle>{title}</Styled.PipelineTitle>
          <IconInsight
            label={insightLabel.title}
            location={insightLabel.location}
          />
        </Styled.InfoIconWrapper>
        <TargetEdit
          value={targetProgress?.value}
          target={targetProgress.target}
          type={targetProgress?.type}
          id={id}
          isCompany={isCompany}
        />
        <Styled.Container>
          {targetProgress?.value ? (
            <Tunnel progressValues={progressValues} />
          ) : (
            <p>{targetProgress?.errorMsg}</p>
          )}
          {!!targetProgress?.value && (
            <div data-testid="progress-key">
              {progressValues.map((progress, key) => (
                <CurrentProgressKey key={key} progress={progress} />
              ))}
            </div>
          )}
        </Styled.Container>
        {!!targetProgress.value && <KeyContainer keyValues={keyValues} />}
      </>
    );
  } else {
    return (
      <>
        <Styled.InfoIconWrapper>
          <Styled.PipelineTitle>{title}</Styled.PipelineTitle>
          <IconInsight
            label={insightLabel.title}
            location={insightLabel.location}
          />
        </Styled.InfoIconWrapper>
        <Styled.Container>
          <Tunnel progressValues={progressValues} />
          <div data-testid="progress-key">
            {progressValues.map((progress, key) => (
              <CurrentProgressKey key={key} progress={progress} />
            ))}
          </div>
        </Styled.Container>
        <KeyContainer keyValues={keyValues} />
      </>
    );
  }
};

export default ProgressBar;
