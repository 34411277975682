import useCustomTranslation from "@/localization/useCustomTranslation";
import { useGetCompanyListQuery } from "@/redux/portalCompanies/portalCompanies.api";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import SearchBar from "../SearchBar/SearchBar";
import * as Styled from "./style";

type Props = {
  reportName: string;
};

interface Pair {
  partner: string;
  partnerId: number;
  project: string;
  projectId: number;
}

const CompanySelect: FC<Props> = ({ reportName }) => {
  const { prefixedT, t } = useCustomTranslation("COMPONENTS.COMPANY_SELECT");
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetCompanyListQuery();
  const [projectOpen, setProjectOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [partnerCompanyPairs, setPartnerCompanyPairs] = useState<Pair[]>([]);
  const [originalData, setOriginalData] = useState<Pair[]>([]);
  const [search, setSearch] = useState<string>("");
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleSearch = (searchString: string) => {
    setSearch(searchString);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const calculatedPartnerCompanyPairs = data.reduce(
        (acc, partner) => {
          if (partner?.branches?.length) {
            partner.branches.forEach((branch) => {
              if (branch.name && branch.id) {
                acc.push({
                  partner: partner.name || "",
                  partnerId: partner.id,
                  project: branch.name,
                  projectId: branch.id
                });
              }
            });
          }
          return acc;
        },
        [] as {
          partner: string;
          partnerId: number;
          project: string;
          projectId: number;
        }[]
      );

      const sortedData = calculatedPartnerCompanyPairs.sort((a, b) =>
        a.partner.localeCompare(b.partner)
      );

      setOriginalData(sortedData);
      setPartnerCompanyPairs(sortedData);
    }
  }, [data]);

  const changeBranch = (pair: Pair) => {
    const dple = "delivery-performance-le";
    const dpst = "delivery-performance-sensor-technician";
    const extension = `?company=${pair.partnerId}&company-name=${pair.partner}&project=${pair?.projectId}&project-name=${pair.project}`;
    if (reportName === "project-status") {
      navigate(
        `/app/project-status?project=${pair.projectId}&project-name=${pair.project}`
      );
    }
    if (reportName === dple) {
      navigate(`/app/${dple + extension}`);
    }

    if (reportName === dpst) {
      navigate(`/app/${dpst + extension}`);
    }
  };

  const toggleSortOrder = (type: string) => {
    let sortedPairs: Pair[] = [];
    if (type === "company") {
      sortedPairs = [...partnerCompanyPairs].sort((a, b) =>
        companyOpen
          ? (a.partner || "").localeCompare(b.partner || "")
          : (b.partner || "").localeCompare(a.partner || "")
      );
      setCompanyOpen(!companyOpen);
    }

    if (type === "project") {
      sortedPairs = [...partnerCompanyPairs].sort((a, b) =>
        projectOpen
          ? (a.project || "").localeCompare(b.project || "")
          : (b.project || "").localeCompare(a.project || "")
      );
      setProjectOpen(!projectOpen);
    }
    setPartnerCompanyPairs(sortedPairs);
  };

  const filterBySearch = (searchTerm: string, data: Pair[]) => {
    const searchLower = searchTerm.toLowerCase();
    return data.filter(
      (pair) =>
        pair.partner.toLowerCase().includes(searchLower) ||
        pair.project.toLowerCase().includes(searchLower)
    );
  };

  useEffect(() => {
    if (search) {
      setPartnerCompanyPairs(filterBySearch(search, originalData));
    } else {
      setPartnerCompanyPairs(originalData);
    }
  }, [search, originalData]);

  return (
    <div ref={wrapperRef}>
      {isLoading && (
        <Styled.LoadingContainer>
          <Loader />
        </Styled.LoadingContainer>
      )}
      {!isLoading && !isError && (
        <Styled.ChooseCompanyContainer>
          <Styled.HeaderContainer>
            <Styled.ChooseCompanyTitle>
              {prefixedT("CHOOSE_COMPANY")}
            </Styled.ChooseCompanyTitle>
            <Styled.SearchBarContainer>
              <SearchBar
                placeholder={"Search for Company, Partner or Project"}
                onSearch={handleSearch}
              />
            </Styled.SearchBarContainer>
          </Styled.HeaderContainer>
          <Styled.TableWrapper>
            <Styled.TableTitleWrapper>
              <Styled.TableTitleContainer
                onClick={() => toggleSortOrder("company")}
              >
                <Styled.TableTDContainer>
                  <Styled.TableTitle>{prefixedT("PARTNER")}</Styled.TableTitle>
                  <Styled.ArrowDownIcon open={companyOpen} />
                </Styled.TableTDContainer>
              </Styled.TableTitleContainer>
              <Styled.TableTitleContainer
                onClick={() => toggleSortOrder("project")}
              >
                <Styled.TableTDContainer>
                  <Styled.TableTitle>{prefixedT("COMPANY")}</Styled.TableTitle>
                  <Styled.ArrowDownIcon open={projectOpen} />
                </Styled.TableTDContainer>
              </Styled.TableTitleContainer>
            </Styled.TableTitleWrapper>
            <Styled.CompanyWrapper heightProp={"500px"}>
              {partnerCompanyPairs?.map((pair, key) => (
                <Styled.PairContainer
                  key={key}
                  onClick={() => changeBranch(pair)}
                >
                  <Styled.ListItemContainer data-testid={`partner ${key}`}>
                    {pair.partner}
                  </Styled.ListItemContainer>
                  <Styled.ListItemContainer onClick={() => changeBranch(pair)}>
                    {pair.project}
                  </Styled.ListItemContainer>
                </Styled.PairContainer>
              ))}
            </Styled.CompanyWrapper>
          </Styled.TableWrapper>
        </Styled.ChooseCompanyContainer>
      )}
      {isError && <Styled.ErrorMsg>{t("ERROR.REQUEST")}</Styled.ErrorMsg>}
    </div>
  );
};

export default CompanySelect;
