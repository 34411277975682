import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CompanySelect from "../../components/CompanySelect/CompanySelect";
import ProjectStatus from "./ProjectStatus";

const ProjectStatusContainer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [projectID, setProjectID] = useState(queryParams.get("project"));

  useEffect(() => {
    const newProjectID = queryParams.get("project");
    if (newProjectID !== projectID) {
      setProjectID(newProjectID);
    }
  }, [location.search, projectID, queryParams]);

  return (
    <>
      {projectID ? (
        <ProjectStatus projectID={projectID} />
      ) : (
        <div style={{ padding: "40px 40px 0 40px" }}>
          <CompanySelect reportName="project-status" />
        </div>
      )}
    </>
  );
};

export default ProjectStatusContainer;
