// import { useEffect } from "react";

// Types
import type { GISMapPreviewProps } from "@/types/gisUpload.type";
import type { SubscriptionOptions } from "@reduxjs/toolkit/query";
// import { WebsocketType } from "@/types/websocket.type";

// Hooks
import { useGetPreviewGISQuery } from "@/redux/gisUpload/gisUpload.api";

// Redux
// import { useAppDispatch, useAppSelector } from "@/redux";
// import { createWSConnection } from "@/redux/websocket/websocket.slice";

type UseGISMapPreviewProps = GISMapPreviewProps;

export const useGISMapPreview = (
  props: UseGISMapPreviewProps,
  options: SubscriptionOptions & {
    refetchOnMountOrArgChange?: number | boolean;
  } = {}
) => {
  // const dispatch = useAppDispatch();
  // const token = useAppSelector((state) => state.auth.token);

  const query = useGetPreviewGISQuery(
    {
      companyId: props.companyId,
      uploadId: props.uploadId,
      withCache: props.withCache
    },
    {
      skip: !props.uploadId,
      ...options
    }
  );

  // *NB: websocket approach for ODL data loading (return to this if needed)
  // useEffect(() => {
  //   if (query.isSuccess && query.data.odl_task_id) {
  //     dispatch(
  //       createWSConnection({
  //         token,
  //         type: WebsocketType.ODL_GENERATION,
  //         id: query.data.odl_task_id
  //       })
  //     );
  //   }
  // }, [query.data, query.isSuccess, token, dispatch]);

  return query;
};
