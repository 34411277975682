import Gear from "@/assets/icons/gear.svg?react";

import styled from "styled-components";

export const Image = styled.div<{
  source: string;
}>`
  width: 300px;
  height: 300px;
  background-image: url(${(props) => props.source});
  background-size: contain;
`;

export const Title = styled.p`
  span {
    font-weight: 600;
  }
  max-width: 300px;
`;

export const FilterBtn = styled.div`
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 1;
`;

export const LngLat = styled.div`
  position: absolute;
  bottom: 20px;
  right: 0;
  z-index: 1;
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
  text-align: right;
`;

// TODO: Add to global file if needed (.mapbox-container is parent element)
// export const Container = styled.div`
//   #marker {
//     width: 10px;
//     height: 10px;
//     border-radius: 50%;
//     cursor: pointer;
//   }
//   .mapboxgl-popup-close-button {
//     padding: 0px 5px;
//     outline: none;
//     font-size: 30px;
//     color: #cdcdcd;
//     border-radius: 2px;
//   } 
// `;

export const DataSourceContainer = styled.div`
  display: flex;
  width: 100%;

  button {
    font-size: 30px;
    margin-top: 24px;
  }
  & > * {
    margin-right: 20px;
  }
  & > *:last-child {
    margin-right: 0px;
  }
`;

export const OptionsContainer = styled.div<{
  isOverflow: boolean;
}>`
  display: grid;
  row-gap: 15px;
  max-height: 330px;
  overflow-y: ${({ isOverflow }) => (isOverflow ? "auto" : "initial")};
  position: relative;
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const GearIcon = styled(Gear)`
  width: 23px;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export const ImageContainer = styled.div<{
  position: string;
}>`
  position: absolute;
  top: -100px;
  left: ${({ position }) => (position === "left" ? "380px" : "-360px")};
  background: #fff;
  width: 310px;
  height: 310px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
`;

export const ToolTipContainer = styled.div`
  margin: 10px;
  width: 100%;
`;

export const Button = styled.button`
  margin-top: 10px;
  padding: 10px;
  border-style: none;
  border-radius: 8px;
  color: #fff;
  background: #4298d7;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition:
    0.3s ease background,
    0.3s ease box-shadow,
    0.3s ease color,
    0.3s ease fill;
  &:hover {
    box-shadow: 0px 6px 12px #4298d757;
  }
`;

export const AudioContainer = styled.div`
  overflow: auto;
  max-height: 100px;
  margin-top: 10px;

  audio {
    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 100px;
`;
