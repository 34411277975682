import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  text-align: center;
  min-width: 300px;
  min-height: 100px;
`;
