import { EditUserBody, IRole, IUser } from "@/types/user.type";
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "../api";
import { Tag } from "../tag";

// Utils
import { buildQueryParams } from "@/utils/rtk";

export const userApi = createApi({
  reducerPath: "userQuery",
  baseQuery: apiBaseQuery,
  tagTypes: [Tag.PROFILE],
  endpoints: (builder) => ({
    getProfile: builder.query<IUser, { user_id?: number } | void>({
      query: (params = {}) => ({
        url: `/portal_users/profile_from_id?${buildQueryParams(params)}`,
        method: "GET"
      }),
      providesTags: (res) =>
        res
          ? [{ type: Tag.PROFILE }, { type: Tag.PROFILE, id: res?.id }]
          : [{ type: Tag.PROFILE }]
    }),
    editUser: builder.mutation<
      void,
      { id: string | number; body: EditUserBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_users/${id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: Tag.PROFILE, id: args.id }
      ]
    }),
    getRoles: builder.query<IRole[], void>({
      query: () => "/portal_users/roles",
      providesTags: []
    }),
    deleteUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `/portal_users/${id}`,
        method: "DELETE"
      })
    })
  })
});

export const {
  useDeleteUserMutation,
  useGetProfileQuery,
  useEditUserMutation,
  useGetRolesQuery
} = userApi;
