import React, { FC, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import moment from "moment";

import { Button, DatePicker, Loader } from "@/components";
import StockBarCharts from "@/components/Stock/StockBarCharts/StockBarCharts";
import CardsRow from "@/components/CardsRow/CardsRow";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  useLazyGetRelayDistributionQuery,
  useLazyGetSensorDistributionQuery
} from "@/redux/portalUsers/portalUsers.api";
import { CardValues, FormattedStockDataBarChart } from "@/types/stock.type";

import * as Styled from "./style";
import { formatStockData } from "./utils";

interface StockDistributionProps {
  isSensorManagement: boolean;
}

const StockDistribution: FC<StockDistributionProps> = ({
  isSensorManagement
}) => {
  const { t, prefixedT } = useCustomTranslation(
    `STOCK_MANAGEMENT.${isSensorManagement ? "SENSOR" : "RELAY"}`
  );

  const [dateFilters, setDateFilters] = useState<[string, string]>([
    moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD")
  ]);

  const [
    fetchSensors,
    {
      data: sensorData,
      isFetching: sensorIsFetching,
      isSuccess: sensorIsSuccess
    }
  ] = useLazyGetSensorDistributionQuery();

  const [
    fetchRelays,
    { data: relayData, isFetching: relayIsFetching, isSuccess: relayIsSuccess }
  ] = useLazyGetRelayDistributionQuery();

  const data = isSensorManagement ? sensorData : relayData;
  const isFetching = isSensorManagement ? sensorIsFetching : relayIsFetching;
  const isSuccess = isSensorManagement ? sensorIsSuccess : relayIsSuccess;

  const [formattedDataBarChart, setFormattedDataBarChart] = useState<
    FormattedStockDataBarChart[]
  >([]);

  const [cardValues, setCardValues] = useState<CardValues>({
    stock: 0,
    faulty: 0,
    returned: 0,
    refurb: 0
  });

  useEffect(() => {
    if (isSensorManagement) {
      void fetchSensors(
        { date_from: dateFilters[0], date_to: dateFilters[1] },
        true
      );
    } else {
      void fetchRelays(
        { date_from: dateFilters[0], date_to: dateFilters[1] },
        true
      );
    }
  }, [isSensorManagement, dateFilters]);

  useEffect(() => {
    // Format the data into formattedDataBarChart
    if (data?.data) {
      // All data for the entire response
      const formattedData = formatStockData(data);

      // Filter out the null values for the bar chart and only use total
      setFormattedDataBarChart(
        formattedData
          .map((item) => ({
            name: item.name,
            total: item.total
          }))
          .filter((item) => item.name !== "null")
      );

      // if a sensor has any status as shown on the cards i.e. faulty, returned, refurb or stock
      // then there is no company associated with it and is shown as null. We need to filter it out
      // for the bar chart's data and company fitler
      setCardValues(
        formattedData.reduce(
          (acc, curr) => {
            return {
              stock: acc.stock + curr.stock,
              faulty: acc.faulty + curr.faulty,
              returned: acc.returned + curr.returned,
              refurb: acc.refurb + curr.refurb
            };
          },
          { stock: 0, faulty: 0, returned: 0, refurb: 0 }
        )
      );
    }
  }, [data]);

  return (
    <Styled.Wrapper>
      <h1>{prefixedT("DISTRIBUTION.TITLE")}</h1>
      <Formik
        initialValues={{
          mindate: new Date(dateFilters[0]),
          maxdate: new Date(dateFilters[1])
        }}
        onSubmit={(values: { mindate: Date; maxdate: Date }) => {
          if (values.mindate > values.maxdate) {
            alert(prefixedT("DISTRIBUTION.DATE_ERROR"));
          } else {
            setDateFilters([
              moment(values.mindate).format("YYYY-MM-DD"),
              moment(values.maxdate).format("YYYY-MM-DD")
            ]);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <Styled.Row>
              {t("STOCK_MANAGEMENT.DATES_BETWEEN")}{" "}
              <Styled.DateWrapper>
                <Field
                  as={DatePicker}
                  name="mindate"
                  onChange={(date: Date) => setFieldValue("mindate", date)}
                />
              </Styled.DateWrapper>{" "}
              {t("STOCK_MANAGEMENT.DATES_BETWEEN.AND")}{" "}
              <Styled.DateWrapper>
                <Field
                  as={DatePicker}
                  name="maxdate"
                  onChange={(date: Date) => setFieldValue("maxdate", date)}
                />
              </Styled.DateWrapper>
              <Button type="submit" width="200px" height="50px">
                {t("SESSION_PAGE.ENGINEERING_DETAILS.APPLY_DATES")}
              </Button>{" "}
            </Styled.Row>
          </Form>
        )}
      </Formik>

      {isFetching ? (
        <Loader />
      ) : isSuccess && !isFetching && formattedDataBarChart.length > 0 ? (
        <Styled.ChartWrapper>
          <StockBarCharts data={formattedDataBarChart} />
          <CardsRow
            cards={[
              {
                title: prefixedT("DISTRIBUTION.STOCKED"),
                value: cardValues.stock
              },
              {
                title: prefixedT("DISTRIBUTION.FAULTY"),
                value: cardValues.faulty
              },
              {
                title: prefixedT("DISTRIBUTION.RETURNED"),
                value: cardValues.returned
              },
              {
                title: prefixedT("DISTRIBUTION.REFURBED"),
                value: cardValues.refurb
              }
            ]}
          />
        </Styled.ChartWrapper>
      ) : (
        <h3>{t("STOCK_MANAGEMENT.DISTRIBUTION.NO_DATA")}</h3>
      )}
    </Styled.Wrapper>
  );
};

export default StockDistribution;
