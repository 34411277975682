import { t } from "i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import type { AnyObject, Maybe, Message } from "yup/lib/types";

/*
 * Custom validators
 */

Yup.addMethod(Yup.string, "phone", function (errorMessage: Message) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this;

    const phoneNumber = value || "";

    if (phoneNumber === "") return true;

    return (
      isPossiblePhoneNumber(phoneNumber) ||
      createError({ path, message: errorMessage })
    );
  });
});

/*
 * Types declaration for validators
 */

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    phone(errorMessage: Message): StringSchema<TType, TContext>;
  }
}

/*
 * Validators
 */

export const EmailValidator = Yup.string().email(t("VALIDATION.EMAIL"));
export const PhoneValidator = Yup.string().phone(t("VALIDATION.PHONE"));
export const LoginPasswordValidator = Yup.string()
  .min(8, "VALIDATION.PASSWORD.SHORT")
  .max(128, "VALIDATION.PASSWORD.LONG");

export const PasswordValidator = Yup.string()
  .min(8, "VALIDATION.PASSWORD.SHORT")
  .max(128, "VALIDATION.PASSWORD.LONG")
  .matches(/^(?=.*[!@#$%^&*])/, "VALIDATION.PASSWORD.SPECIAL");
