import {
  useGISUploadContext,
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import {
  usePostColumnMappingMutation,
  usePostDiscardFileMutation
} from "@/redux/gisUpload/gisUpload.api";
import {
  Column,
  GIS_COLUMN_MAPPING,
  SampleData,
  ErrorModalText,
  GISRoutes,
  SelectedValue
} from "@/types/gisUpload.type";
import { useCallback, useEffect, useMemo } from "react";
import {
  goForwardStep,
  goBackStep,
  addOrReplaceError,
  AllowedType
} from "../utils";
import {
  getSelectedColumnIds,
  getUnselectedColumns,
  mappedColumns
} from "./utils";
import { deepCopy } from "@/utils/common";
import { defaultSystemFields } from "@/redux/gisUpload/gisUpload.slice";

interface GISHookProps {
  sampleData: SampleData[];
  tableColumns: Column[];
  lengthOfFiles?: Record<AllowedType, number> | undefined;
  type?: string;
}

export const GISHook = ({
  sampleData,
  tableColumns,
  lengthOfFiles,
  type
}: GISHookProps) => {
  const { uploadId } = useGISUploadContext();
  const {
    errors,
    selectedValues,
    companyId,
    currentStep,
    fileIndex,
    mappedSystemFields
  } = useGISUploadValues();

  const { setErrors, setUploadValues, setCurrentStep, resetUpload } =
    useGISUploadMethods();

  const [
    discardFileMutate,
    {
      isSuccess: discardFileSuccess,
      isError: discardError,
      isLoading: discardFileLoading,
      reset: resetDiscardFile
    }
  ] = usePostDiscardFileMutation();

  const [
    columnMappingMutate,
    {
      isSuccess: mappingSuccess,
      isError: mappingError,
      isLoading: mappingLoading,
      reset: resetColumnMapping
    }
  ] = usePostColumnMappingMutation();

  useEffect(() => {
    if (discardError || mappingError) {
      // setErrors(
      //   addOrReplaceError(errors, ErrorModalText.DEFAULT_ERROR_MESSAGE)
      // );
      resetColumnMapping();
      resetDiscardFile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discardError, mappingError]);

  useEffect(() => {
    if (discardFileSuccess) {
      resetDiscardFile();

      if (fileIndex === sampleData.length - 1) {
        setUploadValues({
          errors: [],
          data: {
            currentStep: goForwardStep(currentStep),
            fileIndex: 0
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, discardFileSuccess, sampleData, fileIndex]);

  const discardFile = async (filename: string) => {
    await discardFileMutate({
      body: { company_id: companyId, upload_id: uploadId, filename }
    });
  };

  const incrementFileIndex = async () => {
    const body = mappedColumns(selectedValues, uploadId, sampleData, fileIndex);

    let updatedErrors = deepCopy(errors);

    const hasStatusColumn: boolean = selectedValues.some((values) => {
      return values.data.some((selectedValue: SelectedValue) => {
        return selectedValue.title === "status";
      });
    });

    if (sampleData[fileIndex]?.data && unselectedColumns.required.length > 0) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.COLUMNS_NOT_SELECTED
      );
    }

    if (
      currentStep === GISRoutes.PROCESS_ASSETS &&
      !mappedSystemFields.asset_type &&
      sampleData[fileIndex]
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED
      );
    }

    if (
      currentStep === GISRoutes.PROCESS_PIPES &&
      sampleData[fileIndex] &&
      (!mappedSystemFields.pipe_type || !mappedSystemFields.material)
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED
      );
    }

    // if (currentStep === GISRoutes.PROCESS_PIPES && sampleData[fileIndex]) {
    //   updatedErrors = addOrReplaceError(
    //     updatedErrors,
    //     ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED
    //   );
    // }

    if (
      currentStep === GISRoutes.PROCESS_PIPES &&
      sampleData[fileIndex] &&
      hasStatusColumn &&
      !mappedSystemFields.status
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED
      );
    }

    if (updatedErrors.length > 0) {
      // setIsError(updatedErrors);
      // setIsErrorModalVisible(true);

      setUploadValues({
        errors: updatedErrors,
        isErrorModalOpen: true
      });
    }

    if (sampleData.length === 0) {
      setCurrentStep(goForwardStep(currentStep));
      return;
    }

    if (updatedErrors.length > 0) {
      return;
    }

    await columnMappingMutate({ body });
  };

  useEffect(() => {
    if (mappingSuccess) {
      resetColumnMapping();

      if (sampleData.length === 0 || fileIndex === sampleData.length - 1) {
        setUploadValues({
          data: {
            currentStep: goForwardStep(currentStep),
            fileIndex: 0,
            mappedSystemFields: defaultSystemFields
          }
        });
      } else {
        setUploadValues({
          data: {
            fileIndex: fileIndex + 1,
            mappedSystemFields: defaultSystemFields
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingSuccess]);

  const decrementFileIndex = () => {
    if (sampleData.length === 0) {
      // setCurrentStep((prevStep: number) => goBackStep(prevStep));
      let newFileIndex = fileIndex;
      if (type === "Pipes") {
        newFileIndex = (lengthOfFiles?.dma && lengthOfFiles?.dma - 1) || 0;
        // setFileIndex((lengthOfFiles?.dma && lengthOfFiles?.dma - 1) || 0);
      } else if (type === "Assets") {
        newFileIndex = (lengthOfFiles?.pipe && lengthOfFiles?.pipe - 1) || 0;
        // setFileIndex((lengthOfFiles?.pipe && lengthOfFiles?.pipe - 1) || 0);
      }
      // setIsError(null);

      setUploadValues({
        errors: [],
        data: {
          currentStep: goBackStep(currentStep),
          fileIndex: newFileIndex,
          mappedSystemFields: defaultSystemFields
        }
      });
      return;
    }

    if (fileIndex === 0) {
      let newFileIndex = fileIndex;
      // setIsError(null);
      // setCurrentStep((prevStep: number) => goBackStep(prevStep));
      if (type === "Pipes") {
        newFileIndex = (lengthOfFiles?.dma && lengthOfFiles?.dma - 1) || 0;
        // setFileIndex((lengthOfFiles?.dma && lengthOfFiles?.dma - 1) || 0);
      } else if (type === "Assets") {
        newFileIndex = (lengthOfFiles?.pipe && lengthOfFiles?.pipe - 1) || 0;
        // setFileIndex((lengthOfFiles?.pipe && lengthOfFiles?.pipe - 1) || 0);
      }

      setUploadValues({
        errors: [],
        data: {
          currentStep: goBackStep(currentStep),
          fileIndex: newFileIndex,
          mappedSystemFields: defaultSystemFields
        }
      });
      return;
    }

    // setIsError(null);
    // setFileIndex(fileIndex - 1);

    setUploadValues({
      errors: [],
      data: {
        fileIndex: fileIndex - 1
      }
    });
  };

  const selectedColumnIds = useMemo(() => {
    return getSelectedColumnIds(sampleData, selectedValues, fileIndex);
  }, [sampleData, selectedValues, fileIndex]);

  const unselectedColumns = useMemo(() => {
    const columns = getUnselectedColumns(tableColumns, selectedColumnIds);

    return columns;
  }, [tableColumns, selectedColumnIds]);

  const requiredFieldsCount = tableColumns.filter(
    (column) => column.required
  ).length;

  const insufficientSampleData =
    sampleData.length > 0 &&
    sampleData[fileIndex] !== undefined &&
    sampleData[fileIndex].data.length > 0 &&
    sampleData[fileIndex].data[0] !== undefined &&
    Object.keys(sampleData[fileIndex].data[0] as object).length <
      requiredFieldsCount;

  const reset = () => {
    resetUpload();
  };

  const hasNonRequiredField = tableColumns.some((column) => !column.required);

  // const selectedFile = (filename: string): FileSelectedValues | undefined =>
  //   selectedValues?.find((el) => el.file === filename);

  const currentFile = useMemo(
    () =>
      selectedValues?.find(
        (el) => el.file === sampleData[fileIndex]?.file_name
      ),
    [sampleData, selectedValues, fileIndex]
  ); // selectedFile(sampleData[fileIndex]?.file_name);

  const getSelectedItems = useCallback(() => {
    const material = currentFile?.data.find((ele) => ele.title === "material");
    const asset_type =
      currentStep === GISRoutes.PROCESS_ASSETS
        ? currentFile?.data.find((ele) => ele.title === "type")
        : undefined;

    const pipe_type =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === "type")
        : undefined;

    const status =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === "status")
        : undefined;

    const units =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === "units")
        : undefined;

    const diameter =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === "diameter")
        : undefined;

    const diameter_units =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === "diameter_units")
        : undefined;

    const selectedValues: SelectedValue[] = [];

    if (material) {
      selectedValues.push({ ...material, type: GIS_COLUMN_MAPPING.MATERIAL });
    }

    if (diameter_units) {
      selectedValues.push({
        ...diameter_units,
        type: GIS_COLUMN_MAPPING.UNITS
      });
    }

    if (diameter && !diameter_units) {
      selectedValues.push({ ...diameter, type: GIS_COLUMN_MAPPING.UNITS });
    }

    if (units) {
      selectedValues.push({ ...units, type: GIS_COLUMN_MAPPING.UNITS });
    }

    if (status) {
      selectedValues.push({ ...status, type: GIS_COLUMN_MAPPING.STATUS });
    }

    if (pipe_type) {
      selectedValues.push({
        ...pipe_type,
        type: GIS_COLUMN_MAPPING.PIPE_TYPE
      });
    }

    if (asset_type) {
      selectedValues.push({
        ...asset_type,
        type: GIS_COLUMN_MAPPING.ASSET_TYPE
      });
    }

    return selectedValues;
  }, [currentFile, currentStep]);

  return {
    discardFile,
    incrementFileIndex,
    decrementFileIndex,
    selectedColumnIds,
    unselectedColumns,
    hasNonRequiredField,
    insufficientSampleData,
    reset,
    discardFileLoading,
    mappingLoading,
    currentFile,
    getSelectedItems
  };
};
