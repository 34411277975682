import styled from "styled-components";

export const Container = styled.div`
  padding: 40px;
`;

export const PollenGraphContainer = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background: white;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  text-align: center;
`;

export const PollenGraphTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 20px;
`;

export const PollenGraphs = styled.div`
  display: flex;
  justify-content: center;
`;

export const PollenGraph = styled.img`
  width: 400px;
  height: fit-content;
`;

export const List = styled.ul`
  list-style-type: none;
  font-weight: bold;
  line-height: 35px;
  color: #fff;
  gap: 20px;
  flex-wrap: wrap;
  font-size: 12px;
`;

export const ListContainerItem = styled.div`
  background-color: ${({ theme }) => theme.colors.deepBlue};
  color: white;
  margin: 0 1rem 1rem 0;
  display: inline-block;
  padding: 10px 0;
  width: 100%;
  text-align: center;
`;

export const ListWrapper = styled.div`
  columns: 3 auto;
  column-gap: 1rem;
  width: 100%;
`;

export const AssetContainer = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background: white;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const Asset = styled.img``;

export const ListTitleContainer = styled.div`
  margin-top: 20px;
`;

export const RelayGraphContainer = styled.div``;

export const GraphContainer = styled.div`
  background: white;
  padding: 20px;
  text-align: center;
`;

export const GraphTitle = styled.h2`
  font-size: 20px;
  font-weight: normal;
`;

export const ListObject = styled.span`
  font-weight: normal;
`;

export const ListContainer = styled.div`
  margin-bottom: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
`;

export const MapContainer = styled.div`
  background: white;
  padding: 20px;
`;

export const GetAudioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const GetAudioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const BetaWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 20px;

  ::after {
    content: "beta";
    position: absolute;
    width: 80px;
    height: 25px;
    background: #e59234;
    top: 7px;
    left: -20px;
    text-align: center;
    font-size: 13px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    line-height: 27px;
    transform: rotate(-45deg);
  }
`;

export const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 0;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const Graph = styled.img`
  width: 100%;
  max-width: 400px;
`;

export const ListTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
