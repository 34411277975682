import Delete from "@/assets/icons/delete.svg?react";
import Gear from "@/assets/icons/gear.svg?react";

import styled from "styled-components";

export const DeleteIcon = styled(Delete)`
  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export const DeleteLight = styled(Delete)`
  width: 30px;
  height: 30px;
  path {
    fill: ${({ theme }) => theme.colors.coral};
  }
`;

export const GearIcon = styled(Gear)`
  width: 23px;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export const Container = styled.div`
  display: flex;
  user-select: none;
  flex-wrap: wrap;
`;

export const Info = styled.div<{
  readonly: boolean;
}>`
  ${({ readonly, theme }) =>
    readonly
      ? `
  display: flex;
  flex-direction: column;
  max-width: 180px;
  width: 100%;
`
      : `
  width: max-content;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 8px;
  max-height: 40px;
`}
  padding: 12px;
  margin: 5px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Placeholder = styled.p<{
  readonly: boolean;
}>`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  font-size: 12px;
  ${({ readonly }) => (readonly ? "margin-bottom: 17px;" : null)}
`;

export const DataSourceContainer = styled.div`
  display: flex;
  width: 100%;

  button {
    font-size: 30px;
    margin-top: 24px;
  }
  & > * {
    margin-right: 20px;
  }
  & > *:last-child {
    margin-right: 0px;
  }
`;

export const OptionsContainer = styled.div<{
  isOverflow: boolean;
}>`
  display: grid;
  row-gap: 15px;
  max-height: 330px;
  overflow-y: ${({ isOverflow }) => (isOverflow ? "auto" : "initial")};
  position: relative;
`;

export const ButtonContainer = styled.div`
  align-self: end;
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const FiltersContainer = styled.div<{
  isOverflow: boolean;
}>`
  display: grid;
  row-gap: 15px;
  max-height: 330px;
  overflow-y: ${({ isOverflow }) => (isOverflow ? "auto" : "initial")};
  position: relative;
`;

export const FilterItemContainer = styled.div<{
  error: boolean;
}>`
  display: flex;
  height: 100px;
  position: relative;
  ${({ error, theme }) =>
    error ? `border: 1px solid ${theme.colors.coral}` : null};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.body};
`;

export const FilterItem = styled.div`
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px 0 5px 10px;
  border-radius: 4px 0 0 4px;
  margin-right: 10px;
  width: 100%;
`;

export const FilterDeleteContainer = styled.div`
  margin-right: 8px;
  border-radius: 0 4px 4px 0;
  transition: 0.3s ease width;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const FilterDelete = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
`;

export const SummaryContent = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.deepBlue};
  word-wrap: break-word;
`;
