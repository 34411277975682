import { Button, Table } from "@/components";
import {
  ITableHeader,
  ITablePaginate,
  TableActionTypes,
  TableCellAlignment,
  TableSortOrders
} from "@/components/Table/types";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { Report } from "@/types/report.type";
import { getRowIndex } from "@/utils/table";
import { format } from "date-fns";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "../style";

type Props = {
  reports: Report[];
  totalCount: number;
  page: number;
  pages: number;
  onPageChange: (p: ITablePaginate) => void;
  onOpenModal: () => void;
  setSorting: (orderBy: string, order: string) => void;
  order: TableSortOrders;
  orderBy: string;
};

const headers: ITableHeader[] = [
  { id: "id", title: "NUMBER", sortable: true, align: TableCellAlignment.left },
  {
    id: "name",
    title: "REPORT_NAME",
    sortable: true,
    align: TableCellAlignment.center
  },
  {
    id: "create_at",
    title: "CREATED",
    sortable: true,
    align: TableCellAlignment.center
  },
  {
    id: "actions",
    title: "ACTIONS",
    sortable: false,
    align: TableCellAlignment.right
  }
];

const ReportsTable: FC<Props> = ({
  reports,
  totalCount,
  page,
  pages,
  onPageChange,
  onOpenModal,
  setSorting,
  order,
  orderBy
}) => {
  const { t, prefixedT } = useCustomTranslation("REPORTS_PAGE.TABLE");
  const navigate = useNavigate();

  const getRowStructure = (row: Report, index: number) => (
    <Table.Row key={row.id}>
      <Table.Cell align={TableCellAlignment.left} headerId="id">
        {getRowIndex(page, index)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="name">
        {row.name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="create_at">
        {format(new Date(row.update_at), "P")}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.right} headerId="actions">
        <Table.Action
          onClick={() => navigate(`/app/reports/${row.id}/running`)}
          type={TableActionTypes.run}
        />
        <Table.Action
          onClick={() => navigate(`/app/reports/${row.id}`)}
          type={TableActionTypes.edit}
        />
      </Table.Cell>
    </Table.Row>
  );

  const getHeaderStructure = (header: ITableHeader) => prefixedT(header.title);

  const headerControl = (
    <Button
      width="160px"
      height="34px"
      testId="create-report-button"
      onClick={onOpenModal}
    >
      <Styled.TableHeaderButton>
        <Styled.PlusIcon />
        {t("REPORTS_PAGE.CREATE_REPORT.BUTTON")}
      </Styled.TableHeaderButton>
    </Button>
  );

  return (
    <>
      <Table.Header
        title={
          !totalCount
            ? t("REPORTS_PAGE.TITLE.NO_REPORTS")
            : t("REPORTS_PAGE.TITLE.TOTAL_COUNT") + " " + totalCount
        }
        subtitle={t("REPORTS_PAGE.SUBTITLE")}
        controls={headerControl}
      />
      {Boolean(totalCount) && (
        <>
          <Table
            data={reports}
            order={order}
            orderBy={orderBy as keyof Report}
            onSort={setSorting}
            headers={headers}
          >
            <Table.Head getHeaderStructure={getHeaderStructure} />
            <Table.Body getRowStructure={getRowStructure} striped />
          </Table>
          <Table.Pagination
            pages={pages}
            onChange={onPageChange}
            initialPage={page}
          />
        </>
      )}
    </>
  );
};

export default ReportsTable;
