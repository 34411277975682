import { sessionData } from "@/types/report.type";
import * as Styled from "./style";
import React from "react";
import { FC } from "react";
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import { mergeSessionFiles } from "./utils/MergeSessionFiles";
import useCustomTranslation from "@/localization/useCustomTranslation";

interface CorrelationGraphsProps {
  sessionData: sessionData[];
  correlationUrl: string[];
}

export const CorrelationGraphs: FC<CorrelationGraphsProps> = ({
  sessionData,
  correlationUrl
}) => {
  const { prefixedT } = useCustomTranslation("CORRELATION_GRAPHS");
  const maxLengths = sessionData.map((session) => {
    const psdFiles = session.files.filter((file) => file.type === "psd");
    return Math.max(...psdFiles.map((file) => file.urls.length));
  });
  const maxLength = Math.max(...maxLengths);
  const numbersArray = Array.from({ length: maxLength }, (_, index) => index);

  return (
    <Styled.Wrapper>
      <Styled.SpectralTitle>{prefixedT("PAGE_TITLE")}</Styled.SpectralTitle>
      <Styled.MainWrapper>
        {sessionData.map((session, index) => {
          const sessionFiles = mergeSessionFiles(session.files);
          return (
            <React.Fragment key={index}>
              {sessionFiles.length > 0 ? (
                <React.Fragment key={session.session_id}>
                  <div>
                    <Styled.SessionTitle>
                      <Styled.SessionText>
                        {prefixedT("SESSION_ID")}: {session.session_id}
                      </Styled.SessionText>
                    </Styled.SessionTitle>
                    <Styled.Container>
                      {sessionFiles.map((item, index) => (
                        <Styled.AudioAndImage key={index}>
                          <Styled.Timeline noImg={!item.psd}>
                            {item.timeline}
                          </Styled.Timeline>
                          {item.psd && (
                            <Styled.PSDImg
                              src={item.psd}
                              alt={`${prefixedT("PSD_IMG_ALT")} ${session.session_id}`}
                            />
                          )}
                          {item.audio && (
                            <AudioPlayer
                              audioKey={index}
                              audioUrl={item.audio}
                            />
                          )}
                        </Styled.AudioAndImage>
                      ))}
                    </Styled.Container>
                  </div>
                  {index === 0 && (
                    <Styled.CorrelationContainer>
                      {correlationUrl.map((url, key) => (
                        <div key={key}>
                          {numbersArray.map((number, key) => (
                            <Styled.CorrelationImgContainer key={key}>
                              <Styled.CorrelationImg
                                key={key}
                                src={url}
                                itemNumber={number}
                                itemNumberLength={maxLength - 1}
                                alt={prefixedT("CORRELATION_IMG_ALT")}
                              />
                            </Styled.CorrelationImgContainer>
                          ))}
                        </div>
                      ))}
                    </Styled.CorrelationContainer>
                  )}
                </React.Fragment>
              ) : (
                <div>
                  {prefixedT("NO_RESULTS")}
                  <Styled.Session> {session.session_id}</Styled.Session>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </Styled.MainWrapper>
    </Styled.Wrapper>
  );
};
