import { Map, MapMouseEvent } from "mapbox-gl";
import { LatLngType } from "@/Providers/MapProvider";
import { linesLayerID } from "../lines/utils";
import { layerID } from "../clusters/utils/utils";

export const hoverEvents = (
  map: Map,
  setLngLat: React.Dispatch<React.SetStateAction<LatLngType>>,
  setTooltip: React.Dispatch<React.SetStateAction<string>>
) => {
  let isMouseDown = false;
  let timeout: NodeJS.Timeout;

  const handleResetTooltip = () => {
    clearTimeout(timeout);
    setLngLat({
      lng: null,
      lat: null
    });
    setTooltip("");
  };

  const handleMapAreaEnter = (e: MapMouseEvent, _area: any) => {
    // console.log('[map] entering area: ', { e, isMouseDown, area });
    handleResetTooltip();
    if (isMouseDown) return;
    map.getCanvas().style.cursor = "pointer";

    const clickedFeature = map.queryRenderedFeatures(e.point)[0];

    if (clickedFeature) {
      const tooltip = clickedFeature.properties?.tooltip as string;
      const containsDMAKey = tooltip && tooltip.includes("Dma_key");
      if (tooltip && !containsDMAKey) {
        timeout = setTimeout(() => {
          setLngLat(e.lngLat);
          setTooltip(tooltip);
        }, 300);
      }
    }
  };

  // const handleMapAreaLeave = (e: MapMouseEvent, area: any) => {
  //   console.log('[map] leaving area: ', { e, area }), handleResetTooltip();
  // };

  map.on("mousedown", () => {
    isMouseDown = true;
  });

  map.on("mouseup", () => {
    isMouseDown = false;
  });

  const mapLayers = map.getStyle().layers;

  mapLayers?.forEach((layer) => {
    if (layer.type === "fill") {
      map.on("mouseenter", layer.id, (e) => handleMapAreaEnter(e, layer.id));
    }
  });

  map.on("mouseenter", layerID, (e) => handleMapAreaEnter(e, layerID));
  map.on("mouseenter", linesLayerID, (e) =>
    handleMapAreaEnter(e, linesLayerID)
  );
};
