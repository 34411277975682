import { RootState } from "@/redux";
import { useGetStatusesQuery } from "@/redux/mobileApp/mobileApp.api";
import {
  useGetPartnerDashboardMutation,
  useGetEngineersQuery,
  useGetCompanyDashboardMutation
} from "@/redux/portalUsers/portalUsers.api";
import { useGetProfileQuery } from "@/redux/user/user.api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";

export interface ActiveDashboard {
  partner: string;
  company: string;
}

const DashboardContainer = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [companyOrPartner, setCompanyOrPartner] = useState<ActiveDashboard>({
    partner: query.get("partner") || "",
    company: query.get("company") || ""
  });

  const location = useLocation();

  useEffect(() => {
    if (query.get("partner")) {
      setCompanyOrPartner({
        partner: query.get("partner") || "",
        company: ""
      });
    } else if (query.get("company")) {
      setCompanyOrPartner({
        partner: "",
        company: query.get("company") || ""
      });
    }
  }, [location]);

  const [
    PartnerDashboardMutate,
    { data: partnerData, isLoading: PartnersLoading, isError: partnerError }
  ] = useGetPartnerDashboardMutation();
  const [
    CompanyDashboardMutate,
    { data: companyData, isLoading: CompaniesLoading, isError: companyError }
  ] = useGetCompanyDashboardMutation();

  const {
    data: profile,
    isLoading: ProfileLoading,
    isSuccess: profileLoaded,
    isError: profileError
  } = useGetProfileQuery();
  const { data: Engineers, isLoading: engineersLoading } =
    useGetEngineersQuery();
  const { data: statuses, isLoading: statusLoading } = useGetStatusesQuery();

  const activeAccountState = useSelector(
    (state: RootState) => state.activeAccount.currentAccount
  );

  useEffect(() => {
    if (!activeAccountState && profile) {
      if (!companyOrPartner.partner || !companyOrPartner.company) {
        setCompanyOrPartner({
          partner:
            (profile.partner?.id && profile.partner?.id.toString()) || "",
          company: (profile.company?.id && profile.company?.id.toString()) || ""
        });
      }
    }
  }, [profileLoaded]);

  useEffect(() => {
    if (activeAccountState) {
      if (activeAccountState?.type === "partner") {
        setCompanyOrPartner({
          partner: activeAccountState.id.toString(),
          company: ""
        });
      }
      if (activeAccountState?.type === "company") {
        setCompanyOrPartner({
          partner: "",
          company: activeAccountState.id.toString()
        });
      }
    }
  }, [activeAccountState]);

  useEffect(() => {
    if (companyOrPartner.partner && !PartnersLoading) {
      PartnerDashboardMutate({
        partner_id: companyOrPartner.partner
      });
    }
    if (companyOrPartner.company && !CompaniesLoading) {
      CompanyDashboardMutate({
        id: Number(companyOrPartner.company),
        params: {}
      });
    }
  }, [companyOrPartner.partner, companyOrPartner.company]);

  const data = companyOrPartner.partner
    ? partnerData
    : companyOrPartner.company
      ? companyData
      : undefined;

  return (
    <Dashboard
      loading={
        engineersLoading ||
        statusLoading ||
        PartnersLoading ||
        CompaniesLoading ||
        ProfileLoading
      }
      error={partnerError || companyError || profileError}
      data={data}
      activeDashboard={companyOrPartner}
      statuses={statuses}
      engineers={Engineers}
    />
  );
};

export default DashboardContainer;
