import styled from "styled-components";
import { Label as CommonLabel } from "@/styles/Label";
import { Error as CommonError } from "@/styles/Error";

// TODO: Refactor padding-bottom to be removed from here, should be controlled by form group component
export const Container = styled.label<{ noPadding?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ noPadding }) => (noPadding ? "" : "16px")};
`;

export const Label = styled(CommonLabel)`
  margin-bottom: 8px;
`;

const HORIZONTAL_PADDING = "12px";
const BORDER_WIDTH = "1px";

export const Field = styled.input<{
  error?: string;
  disabled?: boolean;
}>`
  width: calc(100% - ${HORIZONTAL_PADDING} * 2 - ${BORDER_WIDTH} * 2);
  font-weight: 400;
  font-size: 14px;
  padding: 12px;
  outline: none;
  border: 1px solid
    ${({ theme, error }) => theme.colors[error ? "coral" : "lightGray"]};
  background-color: ${({ theme, disabled }) =>
    theme.colors[disabled ? "disabled" : "white"]};
  border-radius: 8px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Error = styled(CommonError)`
  margin-top: 6px;
`;

export const Required = styled.span`
  color: #e11d48;
`;
