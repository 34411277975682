import FiltersPreview from "@/components/ReportLayout/widgets/Filters/FiltersPreview";
import MapPreview from "@/components/ReportLayout/widgets/MapView/components/MapPreview/MapPreview";
import SummaryPreview from "@/components/ReportLayout/widgets/Summary/SummaryPreview";
import TableOfResultsPreview from "@/components/ReportLayout/widgets/TableOfResults/TableOfResultsPreview";
import { WidgetAction, WidgetType } from "@/types/report.type";
import React from "react";
import { CSSProperties, useCallback } from "react";
import { useDragLayer } from "react-dnd";
import type { XYCoord } from "react-dnd";

const layerStyles: CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

export function snapToGrid(x: number, y: number): [number, number] {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  isSnapToGrid: boolean
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none"
    };
  }

  let { x, y } = currentOffset;

  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

const CustomDragLayer = () => {
  const { isDragging, initialOffset, currentOffset, item } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  const renderPreview = useCallback(() => {
    switch (item.widget.key) {
      case WidgetType.FILTERS:
        return <FiltersPreview />;
      case WidgetType.RESULTS:
        return <TableOfResultsPreview />;
      case WidgetType.MAP:
        return <MapPreview />;
      case WidgetType.SUMMARY:
        return <SummaryPreview />;
      default:
        return <></>;
    }
  }, [item]);

  if (!isDragging || item.type !== WidgetAction.ADD) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, false)}>
        {renderPreview()}
      </div>
    </div>
  );
};

export default CustomDragLayer;
