import {
  useGISUploadContext,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { countTypes } from "../utils";
import { useColumnAndSampleData } from "./useColumnAndSampleData";

export const useLengthOfFiles = () => {
  const { uploadId } = useGISUploadContext();
  const { companyId } = useGISUploadValues();

  const { data } = useColumnAndSampleData({ uploadId, companyId });

  return countTypes(data?.sample_data);
};
