import { useGetUploadDMAsQuery } from "@/redux/portalProjects/portalProjectsGIS.api";
import { SubscriptionOptions } from "@reduxjs/toolkit/query";

type UseUploadDMAsProps = {
  uploadId: number;
};
export const useUploadDMAs = (
  props: UseUploadDMAsProps,
  options: SubscriptionOptions & {
    skip?: boolean;
    refetchOnMountOrArgChange?: number | boolean;
  } = {}
) => {
  // const toastLoadingId = useRef<Id | null>(null);
  // const { showNotification } = useNotification();

  const query = useGetUploadDMAsQuery(
    {
      uploadId: props.uploadId
    },
    {
      skip: !props.uploadId,
      ...options
    }
  );

  return query;
};
