import CheckIcon from "@/assets/icons/check.svg?react";
import CrossIcon from "@/assets/icons/cross.svg?react";
import PencilIcon from "@/assets/images/pencil.svg?react";

import styled, { css } from "styled-components";

export const PencilImg = styled(PencilIcon)`
  width: 16px;
  height: 16px;
`;

export const ShowDateContainer = styled.div`
  display: flex;
  gap: 5px;
  background-color: #f3f3f3;
  border-radius: 20px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;

  :hover {
    background-color: #e8e8e8;
  }
`;

export const ShowDateText = styled.p`
  color: #f18400;
  font-size: 14px;
  font-weight: 700;
`;

export const ExceptionTab = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.artyClickRed};
  text-transform: uppercase;
  font-weight: 600;
`;

export const ProgressTab = styled.div`
  flex: 1;
  margin: 20px;
  min-width: 380px;
  border: 1px solid var(--border);
  padding: 20px;
  border-radius: 5px;
`;

export const RowContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  padding: 20px;

  @media (min-width: 2120px) {
    max-width: fit-content;
  }
`;

export const Subheading = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.frenchBlue};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ProjectTitleContainer = styled.div`
  margin: 20px 0 0 20px;
  color: ${({ theme }) => theme.colors.celestialBlue};
`;

export const ProjectName = styled.h3`
  color: #e59234;
  font-weight: normal;
  margin-bottom: 10px;
`;

export const ProjectTimeline = styled.h5`
  color: ${({ theme }) => theme.colors.frenchBlue};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
`;

export const DashboardContainer = styled.div``;

export const ReportContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const WaterCostContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.frenchBlue};
  border-radius: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.frenchBlue};
  width: 100px;
  padding: 25px 10px;
`;

export const WaterCostSessionsContainer = styled.div`
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.frenchBlue};
  padding: 5px 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  text-align: center;
`;

export const WaterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
`;

export const WaterCostTitle1 = styled.p`
  font-size: 10px;
`;

export const WaterCostTitle2 = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const WaterCostTitle3 = styled.p`
  font-size: 10px;
  font-weight: 600;
`;

export const RowOne = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const RowTwo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeakageValue = styled.p`
  margin-top: 5px;
  text-align: center;
  color: ${({ theme }) => theme.colors.lochmara};
  font-weight: bold;
`;

export const LeakageUnit = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.lochmara};
  font-weight: bold;
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    max-width: 350px;

    path {
      /* Adjust the width of the SVG itself */
      width: 100%; /* This will scale the SVG to fit the container */
      fill: white;
      width: 100%;
    }
  }
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const CheckPostiveIcon = styled(CheckIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
`;

export const CrossNegativeIcon = styled(CrossIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
`;

export const SvgContainer = styled.div<{
  positiveIcon?: boolean;
}>`
  position: absolute;
  top: -85px;
  left: 0;
  background-color: ${({ theme, positiveIcon }) =>
    theme.colors[positiveIcon ? "lightGreen" : "artyClickRed"]};
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  right: 0;
`;

export const AcknowledgementTitle = styled.h3`
  padding: 40px 0 20px 0;
  font-size: 36px;
  color: #676767;
`;

export const AcknowledgementDesc = styled.p`
  padding: 20px 0 40px 0;
  font-size: 18px;
  color: #959595;
  line-height: 30px;
`;

export const SubmitBtn = styled.button<{
  isSuccess?: boolean;
}>`
  padding: 20px;
  background-color: ${({ isSuccess }) => (isSuccess ? "#38e030" : "#FF0101")};
  width: 100%;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  color: white;
  cursor: pointer;

  :hover {
    background-color: ${({ isSuccess }) => (isSuccess ? "#2eb026" : "#d80000")};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const OptionTitle = styled.h3`
  margin-bottom: 5px;
`;

export const OptionDesc = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const Label = css`
  color: ${({ theme }) => theme.colors.blue};
`;
