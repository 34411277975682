import { createApi } from "@reduxjs/toolkit/query/react";
import { gisApiBaseQuery } from "../api";
import {
  ApproveGISUploadBody,
  DeleteGisUploadBody,
  GISAdminPageResponse
} from "@/types/GISAdmin.type";
import {
  IPaginationResponse,
  IPaginationProps,
  iSearchProps,
  iSortBy
} from "@/types/rtk.type";
import { buildQueryParams } from "@/utils/rtk";
import { Tag } from "../tag";

export const GISAdminApi = createApi({
  reducerPath: "GISAdminApi",
  baseQuery: gisApiBaseQuery,
  tagTypes: ["PostDeleteGISUpload", "PostApproveGISUpload", Tag.GIS_UPLOAD],
  endpoints: (builder) => ({
    PostDeleteGISUpload: builder.mutation<
      undefined,
      { body: DeleteGisUploadBody }
    >({
      query: ({ body }) => ({
        url: `/gis_admin/delete_gis_upload`,
        method: "POST",
        body: body
      }),
      invalidatesTags: ["PostDeleteGISUpload"]
    }),
    PostApproveGISUpload: builder.mutation<
      undefined,
      { body: ApproveGISUploadBody }
    >({
      query: ({ body }) => ({
        url: `/gis_admin/approve_gis_upload`,
        method: "POST",
        body: body
      }),
      invalidatesTags: ["PostApproveGISUpload"]
    }),
    queryGISUploads: builder.query<
      IPaginationResponse<GISAdminPageResponse>,
      IPaginationProps & iSearchProps & iSortBy
    >({
      query: (params) =>
        `/gis_admin/gis_admin_page?${buildQueryParams(params || {})}`,
      providesTags: [{ type: Tag.GIS_UPLOAD }]
    })
  })
});

export const {
  usePostDeleteGISUploadMutation,
  usePostApproveGISUploadMutation,
  useQueryGISUploadsQuery
} = GISAdminApi;
