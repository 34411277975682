import styled from "styled-components";
import FilterIconSVG from "@/assets/icons/filter-icon.svg?react";
import ArrowDown from "@/assets/icons/arrow_down.svg?react";
import theme from "@/theme";

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExpandableContainer = styled.div<{ showEditView: boolean }>`
  position: absolute;
  width: 400px;
  top: 80px;
  right: 0;
  background: ${theme.colors.lightGray};
  z-index: 10;
  display: flex;
  gap: 10px;
  flex-direction: column;
  max-height: ${({ showEditView }) => (showEditView ? "800px" : "0")};
  transition: all 0.3s;
  overflow-y: ${({ showEditView }) => (showEditView ? "hidden" : "hidden")};
  border-radius: 5px;
  padding: ${({ showEditView }) => (showEditView ? "10px 10px" : "0 10px")};
  margin: ${({ showEditView }) => (showEditView ? "10px 0" : "0")};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const List = styled.ul`
  list-style: none;
`;

export const Item = styled.li``;

export const ClickableAreaIndicator = styled(ArrowDown)<{
  open: boolean;
}>`
  fill: #fff;
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
`;

export const DraggableContainer = styled.div<{
  showBorder: "top" | "bottom" | undefined;
}>`
  display: flex;
  gap: 5px;
  cursor: grab;
  border-top: ${({ showBorder }) =>
    showBorder === "top" ? `2px solid ${theme.colors.celestialBlue}` : "none"};
  border-bottom: ${({ showBorder }) =>
    showBorder === "bottom"
      ? `2px solid ${theme.colors.celestialBlue}`
      : "none"};
  padding: 5px;

  :hover {
    background: ${theme.colors.softBlue};
  }
`;

export const CountContainer = styled.div`
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.blue};
  border-radius: 5px;
`;

export const FilterButton = styled.button`
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9eef2;
  border-radius: 5px;
  border: none;
  transition: all 0.3s;

  :hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
`;

export const Input = styled.input`
  width: 20px;
  height: 20px;
`;

export const FilterIcon = styled(FilterIconSVG)`
  transform: scale(1.3);
`;

export const ColumnHeader = styled.div`
  display: flex;
  position: relative;
  gap: 5px;
  justify-content: space-between;
`;

export const FilterContainer = styled.div<{
  align?: string;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: ${({ align }) => (align === "right" ? "0" : "unset")};
  color: black;
  z-index: 10;
  top: 100%;
  max-height: 270px;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
`;

export const SortOption = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 400;
`;

export const HR = styled.hr`
  margin: 10px 0;
`;
