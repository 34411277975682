import CrossIcon from "@/assets/icons/cross.svg?react";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: right;
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const WaypointName = styled.p``;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    min-width: 500px;
    max-width: 600px;
    width: 50%;

    path {
      width: 100%;
      fill: white;
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  width: 200px;
`;

export const StatusTitle = styled.h2`
  background: rgb(66, 152, 215);
  color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: normal;
`;

export const StatusContainer = styled.div``;

export const StatusItem = styled.div`
  padding: 10px;
  font-size: 12px;

  :hover {
    background: #f5f5f5;
    cursor: pointer;
  }
`;

export const StatusWrapper = styled.div`
  text-align: left;
`;

export const Cross = styled(CrossIcon)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;

  path {
    fill: #000 !important;
  }
`;

export const EngineerDetails = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectedWaypoints = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  max-height: 190px;
  overflow: auto;
  margin-bottom: 10px;
`;

export const EngineerListContainer = styled.div`
  background: #fff;
  max-height: 300px;
  overflow: auto;
  margin-bottom: 10px;
`;

export const WaypointDetails = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Status = styled.div<{
  locationTop?: boolean;
  success?: boolean;
}>`
  background: ${({ success }) => (success ? "#38e030" : "#FF0101")};
  padding: 10px;
  color: white;
  margin-bottom: ${({ locationTop }) => (locationTop ? "10px" : "0")};
  margin-top: ${({ locationTop }) => (locationTop ? "0" : "10px")};
`;

export const WaypointBtnContainer = styled.div`
  padding-left: 20px;
`;

export const SearchContainer = styled.div`
  margin: 0px auto;
  border-bottom: 1px solid #d5d5d5;
  padding: 20px;
  background: white;
`;
