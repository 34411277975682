import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./locales";

const lang_code = localStorage.getItem("lang_code") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: lang_code,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
