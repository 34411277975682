import styled, { FlattenSimpleInterpolation } from "styled-components";

export const Container = styled.label<{ noPadding?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ noPadding }) => (noPadding ? "" : "16px")};
`;

export const Label = styled.span<{
  labelStyle?: FlattenSimpleInterpolation;
  isError?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ isError, theme }) =>
    theme.colors[isError ? "coral" : "deepDark"]};
  margin-bottom: 8px;
  ${({ labelStyle }) => labelStyle || null};
`;

const HORIZONTAL_PADDING = "12px";
const BORDER_WIDTH = "1px";

export const Field = styled.textarea<{
  error?: string;
  disabled?: boolean;
}>`
  width: calc(100% - ${HORIZONTAL_PADDING} * 2 - ${BORDER_WIDTH} * 2);
  font-weight: 400;
  min-height: 150px;
  font-size: 14px;
  resize: vertical;
  padding: 12px;
  outline: none;
  border: 1px solid
    ${({ theme, error }) => theme.colors[error ? "coral" : "lightGray"]};
  background-color: ${({ theme, disabled }) =>
    theme.colors[disabled ? "disabled" : "white"]};
  border-radius: 8px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  bottom: 3px;
  left: 0;
`;

export const Required = styled.span`
  color: #e11d48;
`;
