import SatelliteIcon from "@/assets/icons/satellite-icon.png";
import StreetIcon from "@/assets/icons/street-icon.png";
import React, { FC } from "react";
import mapboxgl from "mapbox-gl";
import { MAP_STYLE, MAP_STYLE_SATELLITE } from "../../utils/config";
import * as Styled from "./style";

interface FilterMapViewProps {
  map: React.MutableRefObject<mapboxgl.Map | null>;
}

const FilterMapView: FC<FilterMapViewProps> = ({ map }) => {
  const [mapStyle, setMapStyle] = React.useState("streets-v11");

  const toggleMapStyle = (mapView: string) => {
    if (map && map.current) {
      if (mapView === "satellite") {
        setMapStyle("satellite-v9");
        map.current.setStyle(MAP_STYLE_SATELLITE);
      } else {
        setMapStyle("streets-v11");
        map.current.setStyle(MAP_STYLE);
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.Button
        data-testid="mapStyleSatellite"
        disabled={mapStyle === "satellite-v9"}
        onClick={() => toggleMapStyle("satellite")}
      >
        <img
          width={20}
          height={20}
          alt="satellite icon"
          src={SatelliteIcon as string}
        />
      </Styled.Button>
      <Styled.Button
        data-testid="mapStyleStreet"
        disabled={mapStyle === "streets-v11"}
        onClick={() => toggleMapStyle("street")}
      >
        <img
          width={20}
          height={20}
          alt="street icon"
          src={StreetIcon as string}
        />
      </Styled.Button>
    </Styled.Container>
  );
};

export default FilterMapView;
