import { LeakMapper } from "@/components";
import GenerateMap from "@/components/ReportLayout/widgets/MapView/GenerateMap";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  useGetDropdownQuery,
  useGetSessionInformationMutation
} from "@/redux/reports/reports.api";
import React, { FC, useEffect, useMemo, useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";

import { useLocation } from "react-router-dom";
import GraphToggle from "../../components/GraphToggle/GraphToggle";
import WaypointHeadings from "../Waypoint/Components/WaypointHeadings/WaypointHeadings";
import * as Styled from "./style";
import { formatDate, formatSessionData } from "./utils";
import { MapProvider } from "@/Providers/MapProvider";
import HistoryAndOvernightGraphs from "./components/HistoryAndOvernightGraphs";
import { SessionDataWithArray, SessionData } from "@/types/report.type";
import AudioRetrieval from "./components/AudioRetrieval/AudioRetrieval";

interface ConditionalListItemProps {
  item: string | number | null;
  text: string;
}

const SessionContainer: FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const updatedSessionID = queryParams.get("session") as string;
  const waypoint = queryParams.get("waypoint") as string;
  const [waypointID, setWaypointID] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");

  const { t, prefixedT } = useCustomTranslation(
    "SESSION_PAGE.ENGINEERING_DETAILS"
  );
  const [mutateSession, { data: sessionData }] =
    useGetSessionInformationMutation();
  const { data: dropDownData } = useGetDropdownQuery(waypointID, {
    refetchOnMountOrArgChange: true,
    skip: !waypointID
  });
  useEffect(() => {
    setSessionID(updatedSessionID);
    setWaypointID(waypoint);
  }, [location.search]);

  useEffect(() => {
    void (async () => {
      if (sessionID) {
        await mutateSession(sessionID);
      }
    })();
  }, [sessionID]);

  const sessionDataFormatted = useMemo(() => {
    const data = sessionData?.session_data;
    const audio = formatSessionData<SessionDataWithArray>("audio", data);
    const psd = formatSessionData<SessionDataWithArray>("psd", data);
    const stft = formatSessionData<SessionDataWithArray>("stft", data);
    const history = formatSessionData<SessionDataWithArray>("history", data);
    const overnight = formatSessionData<SessionData>("overnight", data);
    const pollen = formatSessionData<SessionDataWithArray>("pollen", data);

    return {
      audio,
      psd,
      stft,
      history,
      overnight,
      pollen
    };
  }, [sessionData]);

  const ConditionalListItem: React.FC<ConditionalListItemProps> = ({
    item,
    text
  }) => (
    <li>
      {text}: {item && <Styled.ListObject>{item}</Styled.ListObject>}
    </li>
  );

  const imagesExist =
    sessionData &&
    sessionData.session_data &&
    ((sessionDataFormatted.audio.urls &&
      sessionDataFormatted.audio.urls.length > 0) ||
      (sessionDataFormatted.stft.urls &&
        sessionDataFormatted.stft.urls.length > 0));

  const showPollenGraph =
    sessionDataFormatted.pollen.urls &&
    sessionDataFormatted.pollen.urls.length > 0 &&
    !sessionDataFormatted.overnight.urls &&
    sessionDataFormatted.history.urls?.length === 0;

  const images: ReactImageGalleryItem[] = [];

  if (sessionData && sessionData.assets && sessionData?.assets.length > 0) {
    sessionData?.assets.map((asset: string) => {
      images.push({
        original: asset,
        thumbnail: asset
      });
    });
  }

  return (
    <Styled.Container>
      {
        <>
          {waypoint && dropDownData && (
            <WaypointHeadings
              correlations={dropDownData.correlations}
              consumptionProfileIds={dropDownData.consumption_profile_ids}
              topSoundIds={dropDownData.topsound_ids}
              waypointID={waypointID}
              sessions={dropDownData.sessions}
              jobs={dropDownData.jobs}
              active="Session"
            />
          )}

          <Styled.MapContainer>
            <div className="h-[650px] max-h-[650px]">
              {sessionData && (
                <MapProvider>
                  <GenerateMap
                    fullMapData={sessionData.map_info.data}
                    viewportMapData={sessionData.map_info.data}
                    readonly={true}
                  />
                </MapProvider>
              )}
            </div>
          </Styled.MapContainer>

          <Styled.GetAudioWrapper>
            <Styled.BetaWrapper>
              <AudioRetrieval
                sessionId={sessionID}
                relayInfo={sessionData?.relay_info}
              />
            </Styled.BetaWrapper>
          </Styled.GetAudioWrapper>

          <LeakMapper sessionData={sessionData} sessionID={sessionID} />

          {showPollenGraph && sessionDataFormatted.pollen.urls && (
            <Styled.PollenGraphContainer data-testid="pollen-graph">
              <Styled.PollenGraphTitle>
                {prefixedT("GRAPH_TITLE")}
              </Styled.PollenGraphTitle>
              <Styled.PollenGraphs>
                {sessionDataFormatted.pollen.urls.map((url) => (
                  <Styled.PollenGraph
                    alt={prefixedT("GRAPH_TITLE")}
                    src={url}
                  />
                ))}
              </Styled.PollenGraphs>
            </Styled.PollenGraphContainer>
          )}

          {sessionData && (
            <HistoryAndOvernightGraphs
              history={sessionDataFormatted.history}
              overnight={sessionDataFormatted.overnight}
            />
          )}

          {sessionData && (
            <>
              <Styled.ListContainer>
                <Styled.ListWrapper>
                  <Styled.ListContainerItem>
                    <Styled.ListTitle>
                      {prefixedT("SESSION_TITLE")}
                    </Styled.ListTitle>
                    <Styled.List>
                      <ConditionalListItem
                        item={sessionData?.company}
                        text={prefixedT("DESC.COMPANY")}
                      />
                      <ConditionalListItem
                        item={sessionData?.fido_result}
                        text={prefixedT("DESC.FIDO_RESULT")}
                      />
                      <ConditionalListItem
                        item={sessionData?.leak_size}
                        text={prefixedT("DESC.LEAK_SIZE")}
                      />
                      <ConditionalListItem
                        item={sessionData?.type}
                        text={prefixedT("DESC.TYPE")}
                      />
                      <ConditionalListItem
                        item={sessionData?.days_from_last_update}
                        text={prefixedT("LAST_UPDATED")}
                      />
                    </Styled.List>
                  </Styled.ListContainerItem>
                  <Styled.ListContainerItem>
                    <Styled.ListTitle>{prefixedT("ID_TITLE")}</Styled.ListTitle>
                    <Styled.List>
                      <ConditionalListItem
                        item={sessionData?.bug_id}
                        text={prefixedT("DESC.SENSOR_ID")}
                      />
                      <ConditionalListItem
                        item={sessionData?.dma_key}
                        text={prefixedT("DESC.DMA_KEY")}
                      />
                      <ConditionalListItem
                        item={sessionData?.session_id}
                        text={prefixedT("DESC.SESSION_ID")}
                      />
                      <ConditionalListItem
                        item={sessionData?.waypoint_name}
                        text={prefixedT("DESC.WAYPOINT_NAME")}
                      />
                    </Styled.List>
                  </Styled.ListContainerItem>
                  <Styled.ListContainerItem>
                    <Styled.ListTitle>
                      {prefixedT("ENGINEER_TITLE")}
                    </Styled.ListTitle>
                    <Styled.List>
                      <ConditionalListItem
                        item={sessionData?.collected_engineer}
                        text={prefixedT("COLLECTED_ENGINEER")}
                      />
                      <ConditionalListItem
                        item={sessionData?.deployed_engineer}
                        text={prefixedT("DEPLOYED_ENGINEER")}
                      />
                    </Styled.List>
                  </Styled.ListContainerItem>
                  <Styled.ListContainerItem>
                    <Styled.ListTitle>
                      {prefixedT("ADDRESS_TITLE")}
                    </Styled.ListTitle>
                    <Styled.List>
                      <ConditionalListItem
                        item={sessionData?.address}
                        text={prefixedT("DESC.ADDRESS")}
                      />
                      <ConditionalListItem
                        item={sessionData?.location}
                        text={prefixedT("DESC.LOCATION")}
                      />
                      <ConditionalListItem
                        item={sessionData?.postcode}
                        text={prefixedT("POSTCODE")}
                      />
                      <ConditionalListItem
                        item={sessionData?.what3words}
                        text={prefixedT("WHAT3WORDS")}
                      />
                    </Styled.List>
                  </Styled.ListContainerItem>
                  <Styled.ListContainerItem>
                    <Styled.ListTitle>
                      {prefixedT("DATE_TITLE")}
                    </Styled.ListTitle>
                    <Styled.List>
                      <ConditionalListItem
                        item={formatDate(sessionData?.created)}
                        text={prefixedT("DESC.CREATED")}
                      />
                      <ConditionalListItem
                        item={formatDate(sessionData?.end_time)}
                        text={prefixedT("DESC.END_TIME")}
                      />
                      <ConditionalListItem
                        item={formatDate(sessionData?.expected_finish)}
                        text={"Expected finish"}
                      />
                      <ConditionalListItem
                        item={formatDate(sessionData?.last_update)}
                        text={prefixedT("DESC.LAST_UPDATE")}
                      />
                      <ConditionalListItem
                        item={formatDate(sessionData?.start_time)}
                        text={prefixedT("DESC.START_TIME")}
                      />
                    </Styled.List>
                  </Styled.ListContainerItem>
                  <Styled.ListContainerItem>
                    <Styled.ListTitleContainer>
                      <Styled.ListTitle>
                        {t("SESSION_PAGE.SESSION_INFORMATION.TITLE")}
                      </Styled.ListTitle>
                      <Styled.List>
                        <ConditionalListItem
                          item={sessionData?.fido_result}
                          text={t(
                            "SESSION_PAGE.SESSION_INFORMATION.FIDO_RESULT"
                          )}
                        />
                        <ConditionalListItem
                          item={sessionData?.leak_size}
                          text={t("SESSION_PAGE.SESSION_INFORMATION.LEAK_SIZE")}
                        />
                      </Styled.List>
                    </Styled.ListTitleContainer>
                  </Styled.ListContainerItem>
                </Styled.ListWrapper>
              </Styled.ListContainer>

              {images.length > 0 && (
                <Styled.AssetContainer>
                  <Styled.ListTitle>
                    {prefixedT("ASSET_TITLE")}
                  </Styled.ListTitle>
                  <ImageGallery showPlayButton={false} items={images} />
                </Styled.AssetContainer>
              )}

              {imagesExist && (
                <GraphToggle
                  audioUrls={sessionDataFormatted.audio.urls}
                  psdUrls={sessionDataFormatted.psd.urls}
                  psdTimestamps={sessionDataFormatted.psd.timeline}
                  stftUrls={sessionDataFormatted.stft.urls}
                />
              )}
            </>
          )}
        </>
      }
    </Styled.Container>
  );
};

export default SessionContainer;
