import { resetState } from "@/redux";
import { logout, setToken } from "@/redux/authorization/authorization.slice";
import { setError, setSuccess } from "@/redux/request/request.slice";
import type { ApiEntities, ApiEntity } from "@/types/redux.type";
import {
  Action,
  isFulfilled,
  isRejectedWithValue,
  Middleware
} from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const isFulfilledWithValue = isFulfilled();
const GENERAL_ERROR_CODE = -1;

// TODO: Refactor - come up with a better middleware setup (more TS friendly)
// TODO: Refactor - come up with a better error handling of queries and mutations (should be here, but more customizable and efficient)
const endpointsToSkipError = [
  "getGISUploadData",
  "getGeneratedODLs",
  "getGeneratedVirtualDMAs",
  "getGeneratedODLsTask",
  "getUploadDMAs",
  "createProject"
];

const rtkQueryErrorHandler: Middleware =
  (store) => (next) => (action: Action) => {
    if (isRejectedWithValue(action)) {
      const endpointName = (action?.meta?.arg as { endpointName: string })
        ?.endpointName;

      const payload = action.payload as FetchBaseQueryError;
      const statusCode = payload?.status || GENERAL_ERROR_CODE;

      if (!endpointsToSkipError.includes(endpointName)) {
        store.dispatch(setError(statusCode));
      }

      const isLoginEndpoint = endpointName === "login";

      if (!isLoginEndpoint && statusCode === 401) {
        store.dispatch(setToken(""));
        store.dispatch(resetState());
        store.dispatch(logout());
      }
    } else if (isFulfilledWithValue(action)) {
      const type = (action.meta.arg as { endpointName: string })?.endpointName;
      if (type) {
        store.dispatch(setSuccess(type));
      }
    }

    return next(action);
  };

export const initialEntities: ApiEntities = {
  reducers: {},
  middlewares: [rtkQueryErrorHandler]
};

const getApiEntitiesCallback = <T extends ApiEntity>(
  data: ApiEntities,
  item: T
) => ({
  ...data,
  reducers: {
    ...data.reducers,
    [item.reducerPath]: item.reducer
  },
  middlewares: [...data.middlewares, item.middleware]
});

export const getApiEntities = <T extends ApiEntity>(list: T[]) =>
  list.reduce<ApiEntities>(getApiEntitiesCallback, initialEntities);

export const getTranslationFormatNameFromAction = (name: string) =>
  name
    .split(/(?=[A-Z])/)
    .map((str: string) => str.toUpperCase())
    .join("_");
