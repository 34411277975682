import Delete from "@/assets/icons/delete.svg?react";

import styled, { FlattenSimpleInterpolation } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 2px);
`;

export const Label = styled.span<{
  labelStyle?: FlattenSimpleInterpolation;
  isError?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ isError, theme }) =>
    theme.colors[isError ? "coral" : "deepDark"]};
  margin-bottom: 8px;
  ${({ labelStyle }) => labelStyle || null};
`;

export const Container = styled.div<{
  disabled: boolean;
  error: boolean;
}>`
  height: 40px;
  width: 100%;
  border: 1px solid
    ${({ theme, error }) => theme.colors[error ? "coral" : "lightGray"]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ disabled, theme }) =>
    theme.colors[disabled ? "disabled" : "white"]};
  margin-bottom: 16px;
  position: relative;
`;

export const Clear = styled(Delete)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.mediumDark};
  }
`;

export const Input = styled.input`
  display: none;
`;

export const InputLabel = styled.label<{
  disabled: boolean;
}>`
  border-radius: 4px;
  width: 100px;
  min-width: 100px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.lightGray}90;
  margin-left: 4px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputLabelText = styled.p`
  color: ${({ theme }) => theme.colors.mediumDark}90;
  font-weight: 500;
  font-size: 14px;
  user-select: none;
`;

export const Placeholder = styled.p`
  color: ${({ theme }) => theme.colors.mediumDark}90;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  bottom: 3px;
  left: 0;
`;
