import { MapElement } from "../../../utils/types";
import { Feature, GeoJsonProperties, Point } from "geojson";

export const createCombinedFeatures = (items: MapElement[]) => {
  return items.map((item) => {
    let icon = "empty";

    if (item.tooltip?.Relay_session === "True") {
      switch (item.icon_type.toLowerCase()) {
        case "small_leak_session":
          icon = `small_relay_session`;
          break;
        case "medium_leak_session":
          icon = `medium_relay_session`;
          break;
        case "large_leak_session":
          icon = `large_relay_session`;
          break;
        case "non_leak_session":
          icon = `non_leak_relay_session`;
          break;
        case "in_progress_session":
          icon = `in_progress_relay_session`;
          break;
        case "awaiting_upload_session":
          icon = `awaiting_upload_relay_session`;
          break;
        default:
          break;
      }
    } else if (item.icon_type) {
      icon = item.icon_type.toLowerCase();
    }

    return {
      type: "Feature",
      properties: {
        tooltip: item.tooltip,
        id: item.id,
        color: item.color,
        icon: `${icon}-${item.color}`,
        lastAction: item.tooltip?.days_from_last_update ?? null,
        mapType: item.map_type
      },
      geometry: {
        type: "Point",
        coordinates: item.geometry[0]
      }
    };
  }) as Feature<Point, GeoJsonProperties>[];
};
