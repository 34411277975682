import React from "react";
import * as Styled from "./style";
import { Button } from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";

const Description: React.FC = () => {
  const [show, setShow] = React.useState(false);
  const { prefixedT } = useCustomTranslation("SESSION_PAGE.GET_AUDIO.DESCRIP");

  return (
    <>
      <div>
        <Styled.DescriptionContainer>
          <h2>{prefixedT("TITLE")}:</h2>
          <Styled.ButtonContainer>
            <Button onClick={() => setShow(!show)}>
              <Styled.ClickableAreaIndicator open={show} />
            </Button>
          </Styled.ButtonContainer>
        </Styled.DescriptionContainer>

        {show && (
          <Styled.DescriptionText>
            <p>
              <b>{prefixedT("AVAILABLE.HEADING")}:</b>{" "}
              {prefixedT("AVAILABLE.CONTENT")}
            </p>
            <br />
            <p>
              <b>{prefixedT("PROCESS.HEADING")}:</b>{" "}
              {prefixedT("PROCESS.CONTENT1")}
            </p>
            <Styled.UnorderedList>
              <li>{prefixedT("PROCESS.CONTENT2")}</li>
              <li>{prefixedT("PROCESS.CONTENT3")}</li>
              <li>{prefixedT("PROCESS.CONTENT4")}</li>
            </Styled.UnorderedList>
            <br />
            <p>
              <b>{prefixedT("FUNCTION.TITLE")}:</b>{" "}
              {prefixedT("FUNCTION.CONTENT1")}:
              <Styled.UnorderedList>
                <li>{prefixedT("FUNCTION.CONTENT2")}</li>
                <li>{prefixedT("FUNCTION.CONTENT3")}</li>
                <li>{prefixedT("FUNCTION.CONTENT4")}</li>
                <li>{prefixedT("FUNCTION.CONTENT5")}</li>
                <li>{prefixedT("FUNCTION.CONTENT6")}</li>
              </Styled.UnorderedList>
            </p>
          </Styled.DescriptionText>
        )}
      </div>
    </>
  );
};

export default Description;
