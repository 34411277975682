import { MapElement } from "@/components/ReportLayout/widgets/MapView/utils/types";
import { WaypointMapInfo } from "@/types/report.type";

interface MapInfo {
  area_of_interest?: { data?: MapElement[] };
  leak_location?: { data?: MapElement[] };
  Assets?: { data?: MapElement[] };
  Pipes?: { data?: MapElement[] };
  sessions?: { data?: MapElement[] };
}

export const formatMapData = (data: WaypointMapInfo | undefined) => {
  const mapData: MapElement[] = [];

  if (data) {
    for (const key of Object.keys(data) as (keyof MapInfo)[]) {
      const propertyData = data[key]?.data;
      if (propertyData) {
        mapData.push(...propertyData);
      }
    }
  }

  return mapData;
};

export const jobStatuses = [
  {
    value: "Raised internally",
    label: "JOB_STATUS.RAISED_INTERNALLY"
  },
  { value: "Raised externally", label: "JOB_STATUS.RAISED_EXTERNALLY" },
  { value: "Repaired", label: "JOB_STATUS.REPAIRED" },
  { value: "RFA (Request further action)", label: "JOB_STATUS.RFA" },
  { value: "Cancelled", label: "JOB_STATUS.CANCELLED" },
  { value: "NFA (No further action)", label: "JOB_STATUS.NFA" },
  { value: "Dry Dig", label: "JOB_STATUS.DRY_DIG" },
  { value: "Dry Dig Appealed", label: "JOB_STATUS.DRY_DIG_APPEALED" }
];

export const leakTypes: { [key: number]: string } = {
  1: "LEAK_TYPE.MAINS_LEAK",
  2: "LEAK_TYPE.CUSTOMER_SIDE_LEAK",
  3: "LEAK_TYPE.SERVICE_LEAK",
  4: "LEAK_TYPE.ASSET_LEAK",
  5: "LEAK_TYPE.LEAK_FIXED_ONSITE",
  6: "LEAK_TYPE.FIRE_HYDRANT",
  8: "LEAK_TYPE.PASSING_HYDRANT",
  9: "LEAK_TYPE.FIRE_MAIN",
  10: "LEAK_TYPE.STOP_TAP_EXCHANGE",
  11: "LEAK_TYPE.SLUICE_VALVE_VL",
  12: "LEAK_TYPE.LEAK_ON_SPINDLE_OF_SV",
  13: "LEAK_TYPE.NO_LEAK",
  14: "LEAK_TYPE.NA_WO_STOPPED_ON_SITE",
  15: "LEAK_TYPE.LEAK_ON_SPINDLE_OF_SV_FLANGE",
  16: "LEAK_TYPE.FH_STOPPED_ON_SITE",
  17: "LEAK_TYPE.MAINS_BURST",
  18: "LEAK_TYPE.LEAK_ON_FH",
  19: "LEAK_TYPE.COMMUNICATION_PIPE",
  20: "LEAK_TYPE.WO_FH",
  21: "LEAK_TYPE.FERRULE",
  22: "LEAK_TYPE.PASSING_FH",
  23: "LEAK_TYPE.SLUICE_VALVE",
  24: "LEAK_TYPE.OSV_EXCHANGE",
  25: "LEAK_TYPE.STOP_TAP_LEAK",
  26: "LEAK_TYPE.STOP_TAP_LEAK_NO_7_FERRULE_EXCHANGE_NO_5",
  27: "LEAK_TYPE.RENEW_COVER_FRAME_STANDARD",
  28: "LEAK_TYPE.RESET_SURFACE_BOX_STANDARD",
  29: "LEAK_TYPE.INSTALL_FERRULE_ONLY",
  30: "LEAK_TYPE.RENEW_FERRULE_ONLY",
  31: "LEAK_TYPE.DECOMMISSION_FERRULE",
  32: "LEAK_TYPE.RENEW_FIRE_HYDRANT",
  33: "LEAK_TYPE.RENEW_FIRE_HYDRANT_CHAMBER",
  34: "LEAK_TYPE.RENEW_FIRE_HYDRANT_FRAME_COVER",
  35: "LEAK_TYPE.INSTALL_WASHOUT_HYDRANT",
  36: "LEAK_TYPE.RENEW_WASHOUT_HYDRANT",
  37: "LEAK_TYPE.EXTERNAL_LEAKING_METER_GT_40MM_NO_EXC",
  38: "LEAK_TYPE.EXTERNAL_LEAKING_METER_GT_40MM_INC_EXC",
  39: "LEAK_TYPE.EXTERNAL_LEAKING_METER_LT_40MM_NO_EXC",
  40: "LEAK_TYPE.EXTERNAL_LEAKING_METER_LT_40MM_INC_EXC",
  41: "LEAK_TYPE.REPAIR_MAIN_DOWEL_PIECE_LT_200MM",
  42: "LEAK_TYPE.REPAIR_MAIN_DOWEL_PIECE_GTE_200MM",
  43: "LEAK_TYPE.REPAIR_MAIN_NOT_DOWEL_LT_200MM",
  44: "LEAK_TYPE.REPAIR_MAIN_NOT_DOWEL_GTE_200MM",
  45: "LEAK_TYPE.COMMERCIAL_REPAIR",
  46: "LEAK_TYPE.REPAIR_MAJOR_BURST",
  47: "LEAK_TYPE.REPAIR_EXTERNAL_LSP_NON_ELIGIBLE",
  48: "LEAK_TYPE.REPAIR_RUNNING_OVERFLOW_RECH",
  49: "LEAK_TYPE.REPAIR_INTERNAL_LEAK_RECH",
  50: "LEAK_TYPE.RENEW_LEAKING_SUPPLY_PIPE_LT_10M",
  51: "LEAK_TYPE.RENEW_LEAKING_SUPPLY_PIPE_GT_10M",
  52: "LEAK_TYPE.REPAIR_LSP_ELIGIBLE",
  53: "LEAK_TYPE.REPAIR_LEAKING_SUPPLY_PIPE_RECH",
  54: "LEAK_TYPE.REPAIR_LOP",
  55: "LEAK_TYPE.REMEDIAL_WORK_EXTERNAL",
  56: "LEAK_TYPE.RENEW_COMMUNICATION_PIPE_SHORTSIDE",
  57: "LEAK_TYPE.REPAIR_COMMUNICATION_PIPE",
  58: "LEAK_TYPE.FLOW_TEST_AT_BOUNDARY_NO_EXC",
  59: "LEAK_TYPE.FLOW_TEST_AT_BOUNDARY_WITH_EXC",
  60: "LEAK_TYPE.RENEW_COMMUNICATION_PIPE_LONGSIDE",
  61: "LEAK_TYPE.INST_MSM_TO_EXIST_COMM_PIPE_UNMTRD",
  62: "LEAK_TYPE.INSTALL_MSM_INC_METER",
  63: "LEAK_TYPE.INSTALL_SAMPLE_TAP",
  64: "LEAK_TYPE.RENEW_MSM_STOP_TAP_ASSEMBLY",
  65: "LEAK_TYPE.REPOSITION_MSM_STOP_TAP_ASSEMBLY",
  66: "LEAK_TYPE.EXCAVATE_TRIAL_HOLE_PERMIT",
  67: "LEAK_TYPE.REPAIR_NON_RETURN_VALVE",
  68: "LEAK_TYPE.INSTALL_SLUICE_VALVE",
  69: "LEAK_TYPE.RENEW_SLUICE_VAVLE",
  70: "LEAK_TYPE.REPAIR_SLUICE_VALVE",
  71: "LEAK_TYPE.REMOVE_SLUICE_VAVLE",
  72: "LEAK_TYPE.REPACK_SLUICE_VALVE",
  73: "LEAK_TYPE.CSL",
  74: "LEAK_TYPE.MAINS",
  75: "LEAK_TYPE.OTHER",
  76: "LEAK_TYPE.CUSTOMER_METER_LEAK",
  77: "LEAK_TYPE.NETWORK_MAINTENANCE",
  78: "LEAK_TYPE.CLIENT_METER_PRV_LEAK"
};
