import { IRole, UserRoles } from "@/types/user.type";
export const UserRole = {
  SAD: "USER_ROLE.SUPER_ADMIN",
  PAD: "USER_ROLE.PARTNER_ADMIN",
  PUS: "USER_ROLE.PARTNER_USER",
  PLE: "USER_ROLE.PARTNER_LEAK_ENGINEER",
  PBDE: "USER_ROLE.PARTNER_BUG_DEPLOYMENT_ENGINEER",
  CAD: "USER_ROLE.COMPANY_ADMIN",
  CUS: "USER_ROLE.COMPANY_USER",
  CLE: "USER_ROLE.COMPANY_LEAK_ENGINEER",
  CBDE: "USER_ROLE.COMPANY_BUG_DEPLOYMENT_ENGINEER"
};

export const checkRestictedPermissions = (permissions: string[], role = "") =>
  permissions.includes(role);

export const filterRolesAccordingToUserRole = (
  roles: IRole[],
  code: string
) => {
  const adminRoles = [UserRoles.COMPANY_ADMIN, UserRoles.SUPER_ADMIN];

  switch (code) {
    case UserRoles.SUPER_ADMIN:
      return roles;
    case UserRoles.COMPANY_ADMIN:
      return roles.filter((role) => role.role_code !== UserRoles.SUPER_ADMIN);
    default:
      return roles.filter((role) => !adminRoles.includes(role.role_code));
  }
};

export const isSuperUser = (role_id?: number | null): boolean => {
  return role_id === 1;
};

export const isPartnerUser = (role_id?: number | null): boolean => {
  return role_id === 2 || role_id === 3 || role_id === 4 || role_id === 5;
};

export const isCompanyUser = (role_id?: number | null): boolean => {
  return role_id === 10 || role_id === 11 || role_id === 12 || role_id === 13;
};

export const partnerRoleIds = [2, 3, 4, 5];
export const companyRoleIds = [10, 11, 12, 13, 14];

export const partnerAdminId = 2;
export const companyAdminId = 10;
