import styled from "styled-components";
import ArrowDown from "@/assets/icons/arrow_down.svg?react";
import theme from "@/theme";

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const ClickableAreaIndicator = styled(ArrowDown)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  fill: #fff;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 50px;
`;

export const DescriptionText = styled.div`
  margin-top: 20px;
`;

export const UnorderedList = styled.ul`
  padding-left: 20px;
`;

export const Error = styled.p`
  color: ${theme.colors.coral};
`;
