import DataGrid from "@/assets/icons/data-grid.svg?react";
import Filters from "@/assets/icons/filter.svg?react";
import Map from "@/assets/icons/map.svg?react";
import Summary from "@/assets/icons/summary.svg?react";

const widgetIcons = {
  summary: Summary,
  map: Map,
  results: DataGrid,
  filters: Filters
};

export default widgetIcons;
