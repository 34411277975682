import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import "./index.css";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";
import config from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// ENVIRONMENT should be localhost in your local machine
Sentry.init({
  environment: config.ENVIRONMENT || "localhost",
  dsn: config.SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
