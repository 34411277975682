import styled from "styled-components";
import FilterIconSVG from "@/assets/icons/filter-icon.svg?react";
import theme from "@/theme";

export const CountContainer = styled.div`
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.blue};
  border-radius: 5px;
`;

export const FilterButton = styled.button`
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9eef2;
  border-radius: 5px;
  border: none;
  transition: all 0.3s;

  :hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
`;

export const FilterIcon = styled(FilterIconSVG)`
  transform: scale(1.3);
`;

export const ColumnHeader = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
`;

export const FilterContainer = styled.div<{
  align?: string;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: ${({ align }) => (align === "right" ? "0" : "unset")};
  color: black;
  z-index: 10;
  top: 100%;
  max-height: 270px;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
`;

export const SortOption = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 400;
`;

export const HR = styled.hr`
  margin: 10px 0;
`;
