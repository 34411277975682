export const Tag = {
  COMPANY: "Company",
  COMPANY_LIST: "Company-LIST",

  PARTNER: "Partner",
  PARTNER_LIST: "Partner-LIST",

  PROFILE: "Profile",

  WAYPOINT: "Waypoint",

  // GIS
  GIS_COLUMN_AND_SAMPLE_DATA: "GISColumnAndSampleData",

  GIS_UPLOAD: "GISUpload",

  GIS_UPLOAD_DATA: "GISUploadData",

  // Project
  PROJECT: "Project",
  PROJECT_LIST: "Project-LIST"
} as const;
