import React from "react";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { PointTooltipType } from "../../../utils/types";
import * as Styled from "./style";
import { useGetAssetSessionsInfoQuery } from "@/redux/reports/reports.api";
import Loader from "@/components/Loader/Loader";
import moment from "moment";
import { SessionInfo } from "@/types/report.type";

export const AssetTooltip = (values: PointTooltipType) => {
  const { prefixedT } = useCustomTranslation("ASSET_TOOLTIP");

  const {
    data: assetSessionsInfo,
    isFetching,
    isSuccess,
    isError
  } = useGetAssetSessionsInfoQuery(
    {
      query: { asset_id: values?.Asset_id || null }
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !values?.Asset_id
    }
  );

  return (
    <>
      {isFetching && <Loader />}
      {isError && <p>{prefixedT("ERROR")}</p>}
      {isSuccess && (
        <>
          <Styled.AssetTitle>{prefixedT("TITLE.SESSION")}</Styled.AssetTitle>
          {assetSessionsInfo?.session_info &&
          assetSessionsInfo?.session_info.length > 0 ? (
            <Styled.Table data-testid="asset-tooltip-table">
              <thead>
                <tr>
                  <th>{prefixedT("TABLE.HEADER.1")}</th>
                  <th>{prefixedT("TABLE.HEADER.2")}</th>
                  <th>{prefixedT("TABLE.HEADER.3")}</th>
                  <th>{prefixedT("TABLE.HEADER.4")}</th>
                </tr>
              </thead>
              <tbody>
                {assetSessionsInfo?.session_info.map((session: SessionInfo) => {
                  const formattedTimestamp =
                    session.timestamp && moment(session.timestamp).isValid()
                      ? moment(session.timestamp).format("DD-MM-YY HH:mm")
                      : "N/A";
                  return (
                    <tr key={session.session_id}>
                      <Styled.SessionTxt>
                        <a
                          href={`/app/session?session=${session.session_id}`}
                          target="_blank"
                        >
                          {session.session_id}
                        </a>
                      </Styled.SessionTxt>
                      <td>{session.bug_id || "N/A"}</td>
                      <td>{formattedTimestamp || "N/A"}</td>
                      <td>{session.outcome || "N/A"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Styled.Table>
          ) : (
            <p>{prefixedT("NO_DATA")}</p>
          )}
          <Styled.AssetTitle>{prefixedT("TITLE.ASSET")}</Styled.AssetTitle>
          <Styled.Container>
            <Styled.AssetList>
              <li>
                <Styled.AssetInfoTitle>
                  {prefixedT("DESC_TITLE.ASSET_TYPE")}
                </Styled.AssetInfoTitle>{" "}
                - {assetSessionsInfo?.standardised_asset_type}
              </li>
              <li>
                <Styled.AssetInfoTitle>
                  {prefixedT("DESC_TITLE.ASSET_ID")}
                </Styled.AssetInfoTitle>{" "}
                - {values?.Asset_key}
              </li>
            </Styled.AssetList>
          </Styled.Container>
        </>
      )}
    </>
  );
};
