import useCustomTranslation from "@/localization/useCustomTranslation";
import { useGetProfileQuery } from "@/redux/user/user.api";
import { UserRoles } from "@/types/user.type";
import { UserRole } from "@/utils/user";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import FacebookLoader from "../FacebookLoader/FacebookLoader";
import * as Styled from "./style";

interface ProfileProps {
  mobile?: boolean;
}

const Profile: FC<ProfileProps> = ({ mobile }) => {
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();
  const { t } = useCustomTranslation("");

  const role = profile?.role?.role_code || "";

  return (
    <Styled.Container onClick={() => navigate("/app/profile")}>
      <Styled.AvatarSection>
        <Styled.AvatarIconWrapper mobile={mobile} data-testid="icon-wrapper">
          <Styled.AvatarIcon />
          {profile?.role?.role_code === UserRoles.SUPER_ADMIN ? (
            <Styled.SuperIcon data-testid="super-icon" />
          ) : null}
        </Styled.AvatarIconWrapper>
      </Styled.AvatarSection>
      <Styled.InfoSection mobile={mobile}>
        <Styled.ProfileWrapper isLoader={!!profile}>
          <FacebookLoader active={!profile}>
            <Styled.Name
              mobile={mobile}
              data-testid="profile-userFullName"
            >{`${profile?.first_name} ${profile?.last_name}`}</Styled.Name>
          </FacebookLoader>
        </Styled.ProfileWrapper>
        <Styled.ProfileWrapper isLoader={!!profile}>
          <FacebookLoader active={!profile}>
            <Styled.Role mobile={mobile} data-testid="profile-userRole">
              {t(UserRole[role as keyof typeof UserRole])}
            </Styled.Role>
          </FacebookLoader>
        </Styled.ProfileWrapper>
      </Styled.InfoSection>
    </Styled.Container>
  );
};

export default Profile;
