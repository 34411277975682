import { useState } from "react";

const defaultPerPage = 10;

type HookProps = {
  defaultPerPage?: number;
};

export const useGISUploadTableState = (props: HookProps = {}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [orderBy, _setOrderBy] = useState<string>("desc");
  const [uploadId, setUploadId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    props.defaultPerPage || defaultPerPage
  );

  return {
    searchInput,
    setSearchInput,
    searchQuery,
    setSearchQuery,
    companyId,
    setCompanyId,
    orderBy,
    uploadId,
    setUploadId,
    page,
    setPage,
    perPage,
    setPerPage
  };
};
