import { Styles } from "@/types/styles.type";
import React, { forwardRef } from "react";
import * as Styled from "./style";

export type TextFieldProps = {
  onChange: (e: React.ChangeEvent<HTMLElement>) => void;
  value: string | number;
  label?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  error?: string;
  testId?: string;
  styles?: Styles;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const TextFieldPlain = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      value = "",
      placeholder,
      name,
      type = "text",
      disabled,
      error,
      testId,
      ...rest
    },
    ref
  ) => (
    <Styled.Field
      ref={ref}
      value={value}
      placeholder={placeholder}
      name={name}
      type={type}
      disabled={disabled}
      error={error}
      data-testid={testId || "text-field"}
      {...rest}
    />
  )
);

// TODO: Refactor label & error - imo should be controlled by parent form group
const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      value = "",
      label,
      placeholder,
      name,
      type = "text",
      disabled,
      required,
      error,
      testId,
      styles,
      ...rest
    },
    ref
  ) => (
    <Styled.Container noPadding={styles?.noPadding}>
      {label && (
        <Styled.Label labelStyle={styles?.label} isError={!!error}>
          {label} {required && <Styled.Required>*</Styled.Required>}
        </Styled.Label>
      )}
      <TextFieldPlain
        ref={ref}
        value={value}
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        error={error}
        data-testid={testId || "text-field"}
        {...rest}
      />
      {Boolean(error) && <Styled.Error>{error}</Styled.Error>}
    </Styled.Container>
  )
);
export default React.memo(TextField);
