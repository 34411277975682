import styled from "styled-components";

export const ProgressTab = styled.div`
  flex: 1;
  margin: 0 20px;
  min-width: 380px;
  border: 1px solid var(--border);
  padding: 20px;
  border-radius: 10px;
`;

export const RowBox = styled.div`
  flex: 1;
  margin: 10px 20px;
  display: grid;
`;

export const CompanyImage = styled.img`
  max-height: 100px;
  max-width: 200px;
`;

export const RowContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin: 20px;
  padding: 20px;

  @media (min-width: 2120px) {
    max-width: fit-content;
  }
`;

export const ProjectTitleContainer = styled.div`
  margin: 20px 0 0 20px;
  color: ${({ theme }) => theme.colors.celestialBlue};
`;

export const ProjectContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

export const ProjectName = styled.h3`
  color: #e59234;
  font-weight: normal;
  margin-bottom: 10px;
`;

export const ProjectTimeline = styled.h5`
  color: ${({ theme }) => theme.colors.mediumBlue};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
`;

export const DashboardContainer = styled.div``;

export const RowOne = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const RowTwo = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const RowThree = styled.div`
  display: flex;
  justify-content: center;
`;

export const RowFour = styled.div`
  display: flex;
  justify-content: center;
`;
