import { FileUploadStatus } from "@/components/FileUpload/FileUpload";
import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  // add translations
  company_id: Yup.number().required("Company is required"),
  file: Yup.array()
    .min(1, `${FileUploadStatus.NoValue}`)
    .required("File is required")
});
