import { useTranslation } from "react-i18next";

// Types
import type { TOptionsBase } from "i18next";

const useCustomTranslation = (customPrefix: string) => {
  const { t } = useTranslation();

  const prefixedT = (
    path: string,
    options?: TOptionsBase & Record<string, unknown>
  ) => {
    return t(`${customPrefix}.${path}`, options);
  };

  return { t, prefixedT };
};

export default useCustomTranslation;
