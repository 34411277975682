import {
  assets,
  clusterLayerID,
  layerID,
  relaySessions,
  sessions
} from "./utils";
import { Map } from "mapbox-gl";

export const defaultSize = 1;

export const getSizeFromIcon = (icon: string): number => {
  const [name, _color] = icon.split("-");
  const sizeLookup: { [key: string]: number } = {
    valve: 0.8,
    small_relay_session: 1,
    medium_relay_session: 1,
    large_relay_session: 1,
    non_leak_relay_session: 1,
    in_progress_relay_session: 1,
    awaiting_upload_relay_session: 1,
    logger: 1,
    small_leak_session: 1,
    medium_leak_session: 1,
    large_leak_session: 1,
    non_leak_session: 1,
    in_progress_session: 1,
    awaiting_upload_session: 1,
    bug_deployments: 1,
    marker: 0.6,
    pump: 0.6,
    unknown: 0.6,
    tapping: 0.6,
    pmv: 0.6,
    meter: 0.6,
    connection: 0.6,
    fitting: 0.6,
    hydrant: 0.6
  };
  const size = sizeLookup[name] || 1;
  return size;
};

export const getIconsWithLastActionText = (icons: string[]): string[] => {
  const allowedIcons = [...sessions, ...relaySessions, ...assets];

  return icons.filter((icon) => {
    const iconName = icon.split("-")[0];
    return allowedIcons.includes(iconName);
  });
};

export const createCustomIconLayer = (map: Map, icons: string[]) => {
  if (!map.getLayer(clusterLayerID)) {
    map.addLayer({
      id: layerID,
      type: "symbol",
      source: clusterLayerID,
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 30
      },
      layout: {
        "icon-image": [
          "match",
          ["get", "icon"],
          ...icons.flatMap((icon) => [icon, icon]),
          "default",
          "marker",
          "marker"
        ],
        "icon-size": [
          "match",
          ["get", "icon"],
          ...icons.flatMap((icon) => [icon, getSizeFromIcon(icon)]),
          defaultSize
        ],
        "icon-allow-overlap": true,
        "text-field": [
          "case",
          [
            "in",
            ["get", "icon"],
            ["literal", getIconsWithLastActionText(icons)]
          ],
          ["get", "lastAction"],
          ""
        ],
        "text-size": 12,
        "text-allow-overlap": true,
        "text-offset": [0, -0.3]
      }
    });
  }
};
