import useOutsideClick from "@/hooks/useOutsideClick";
import useOutsideScroll from "@/hooks/useOutsideScroll";
import useRect from "@/hooks/useRect";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { format } from "date-fns";
import { AnimatePresence } from "framer-motion";
import React, { FC, RefObject, useMemo, useRef, useState } from "react";
import { animated } from "react-spring";
import styled from "styled-components";
import DateRangePicker from "./DateRangePicker";
import * as Styled from "./style";

type Props = {
  value: [Date, Date];
  onChange: (data: (Date | undefined)[]) => void;
  disabled?: boolean;
  error: string;
  placeholder: string;
  dateRangeBtn?: boolean;
  minMax?: boolean;
  label: string;
  blockRef?: RefObject<HTMLDivElement>;
};

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 }
};

const ArrowIcon = styled(({ isOpen, ...props }) => (
  <animated.div
    {...props}
    style={{
      transform: `rotateX(${isOpen ? "180deg" : "0deg"})`
    }}
  />
))<{ isOpen: boolean }>`
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #555 transparent transparent transparent;
  transition: transform 0.3s;
`;

const DateRange: FC<Props> = ({
  value,
  onChange,
  disabled = false,
  error,
  placeholder,
  dateRangeBtn = false,
  minMax = false,
  label,
  blockRef
}) => {
  const { t } = useCustomTranslation("");
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dateRangeRef = useRef(null);
  const elementRef = useRef(null);
  const rectConfig = useRect(open, dateRangeRef, elementRef, blockRef);

  const activeLabel = useMemo(() => {
    if (!value) {
      return placeholder;
    }
    return `${format(value[0], "dd/MM/yyyy")} - ${format(value[1], "dd/MM/yyyy")}`;
  }, [value, placeholder]);

  useOutsideClick(dateRangeRef, setOpen, open, elementRef);
  useOutsideScroll(blockRef, setOpen, open);

  return (
    <Styled.Container>
      {label && <Styled.Label error={!!error}>{label}</Styled.Label>}
      <Styled.Wrapper ref={ref}>
        <Styled.ClickableArea
          ref={elementRef}
          button={dateRangeBtn}
          error={!!error}
          disabled={disabled}
          data-testid="date-range-clickable-area"
          onClick={() => setOpen(!open)}
        >
          <Styled.ClickableAreaText
            disabled={disabled}
            isPlaceholder={activeLabel === placeholder}
          >
            {dateRangeBtn ? t("FILTERS.DATE_RANGE.TITLE") : activeLabel}
          </Styled.ClickableAreaText>
          {dateRangeBtn && <ArrowIcon isOpen={open ? true : false} />}
        </Styled.ClickableArea>
        {Boolean(error) && <Styled.Error>{error}</Styled.Error>}
        <AnimatePresence>
          {open && (
            <DateRangePicker
              minMax={minMax}
              rectConfig={rectConfig}
              dateRangeRef={dateRangeRef}
              animationProps={animationProps}
              onChange={onChange}
              value={value}
            />
          )}
        </AnimatePresence>
      </Styled.Wrapper>
    </Styled.Container>
  );
};
export default DateRange;
