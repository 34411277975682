import { createApi } from "@reduxjs/toolkit/query/react";

// Utils
import { gisApiBaseQuery } from "../api";

// Types
import {
  GeneratedODLsResponse,
  GeneratedVirtualDMAResponse,
  ProjectStatusType,
  ProjectUpdateData,
  UploadDMAResponse
} from "@/types/portalProject.type";
import { WebsocketMessageTypeBase } from "@/types/websocket.type";
import { Tag } from "../tag";
import { portalProjectsApi } from "./portalProjects.api";

export const portalProjectsGISApi = createApi({
  reducerPath: "portalProjectsGISQuery",
  baseQuery: gisApiBaseQuery,
  tagTypes: [Tag.GIS_UPLOAD_DATA, Tag.PROJECT, Tag.PROJECT_LIST],
  endpoints: (builder) => ({
    getGISUploadData: builder.query<
      { upload_id: number; company_id: number; odl_level?: number },
      { projectId: number }
    >({
      query: ({ projectId }) => ({
        url: `/gis_admin/project_id/${projectId}/upload-id`,
        method: "GET"
      }),
      providesTags: (res) =>
        res
          ? [
              { type: Tag.GIS_UPLOAD_DATA },
              { type: Tag.GIS_UPLOAD_DATA, id: res.upload_id }
            ]
          : [{ type: Tag.GIS_UPLOAD_DATA }]
    }),
    selectGISUpload: builder.mutation<
      number | undefined,
      { uploadId: number; projectId: number; companyId: number }
    >({
      query: ({ uploadId, projectId, companyId }) => ({
        url: `/gis_admin/existing_upload/upload_id/${uploadId}/project_id/${projectId}/company_id/${companyId}`,
        method: "GET"
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: Tag.GIS_UPLOAD_DATA, id: arg.uploadId }
      ]
    }),
    updateProject: builder.mutation<
      number | undefined,
      { uploadId: number; projectId: number; body: ProjectUpdateData }
    >({
      query: ({ uploadId, projectId, body }) => ({
        url: `/gis_admin/project_id/${projectId}/upload_id/${uploadId}`,
        method: "PATCH",
        body
      })
      // onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
      //   await queryFulfilled;
      //   dispatch(
      //     portalProjectsApi.util.invalidateTags([
      //       { type: Tag.PROJECT, id: args.projectId }
      //     ])
      //   );

      //   console.log("[API] invalidated!", { args });
      // }
      // invalidatesTags: (_result, _error, arg) => [
      //   { type: Tag.PROJECT, id: arg.projectId }
      // ]
    }),
    updateProjectStatus: builder.mutation<
      boolean,
      { projectId: number; status: ProjectStatusType }
    >({
      query: ({ projectId, status }) => ({
        // url: `/update_status/upload_id/${uploadId}/status/${status}`,
        url: `/gis_admin/update_status/project_id/${projectId}/status/${status}`,
        method: "PATCH"
      }),

      // TODO: Refactor redux - use injectEndpoints as opposed to having a bunch of createApi instances (for invalidation purposes between the same baseQuery)
      // *NB: workaround as GIS server and fido_api monolith are different baseQueries
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          portalProjectsApi.util.invalidateTags([
            { type: Tag.PROJECT_LIST },
            { type: Tag.PROJECT, id: args.projectId }
          ])
        );
      }
      // invalidatesTags: (_result, _error, arg) => [
      //   { type: Tag.PROJECT_LIST },
      //   { type: Tag.PROJECT, id: arg.projectId }
      // ]
    }),
    updateProjectGISData: builder.mutation<
      undefined,
      { uploadId: number; projectId: number }
    >({
      query: ({ uploadId, projectId }) => ({
        url: `/gis_admin/${uploadId}`,
        method: "PATCH",
        body: {
          project_id: projectId
        }
      })
    }),
    getGeneratedODLs: builder.query<
      GeneratedODLsResponse,
      { uploadId: number }
    >({
      query: ({ uploadId }) => ({
        url: `/project_creation/generate_odls/${uploadId}`,
        method: "GET"
      })
    }),
    // * Deprecated
    getGeneratedVirtualDMAs: builder.query<
      GeneratedVirtualDMAResponse,
      { uploadId: number }
    >({
      query: ({ uploadId }) => ({
        url: `/dma/virtual_dma/upload_id/${uploadId}`,
        method: "POST"
      })
    }),
    getUploadDMAs: builder.query<UploadDMAResponse, { uploadId: number }>({
      query: ({ uploadId }) => ({
        url: `/dma/upload/${uploadId}`,
        method: "POST"
      })
    }),
    getGeneratedODLsTask: builder.query<
      {
        type: WebsocketMessageTypeBase;
        data: Record<string, any>;
        error?: string;
      },
      { taskId: string }
    >({
      query: ({ taskId }) => ({
        url: `/project_creation/odl_task/${taskId}`,
        method: "GET"
      })
    })
  })
});

export const {
  useUpdateProjectStatusMutation,
  useGetGISUploadDataQuery,
  useSelectGISUploadMutation,
  useUpdateProjectGISDataMutation,

  useUpdateProjectMutation,
  useGetGeneratedODLsQuery,
  useGetGeneratedODLsTaskQuery,
  useGetGeneratedVirtualDMAsQuery,
  useGetUploadDMAsQuery
} = portalProjectsGISApi;
