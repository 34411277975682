import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import { Form } from "@fido/ui-lib";

// Steps
import { ProjectBase } from "./blocks/ProjectBase.block";

// Hooks
import useNotification from "@/hooks/useNotification";
import { useCreateProjectMutation } from "@/redux/portalProjects/portalProjects.api";

// Utils
import { ProjectFormValues } from "./schema";
import { useProjectForm } from "./hooks/useProjectForm";
import { ProjectFormFooter } from "./blocks/ProjectFormFooter.block";

export const ProjectCreateForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showNotification } = useNotification();
  const form = useProjectForm();

  const [createProject] = useCreateProjectMutation();

  const handleSubmit = async (values: ProjectFormValues) => {
    try {
      const response = await createProject({
        companyId: values.companyId,
        projectName: values.name
      }).unwrap();

      if (typeof response.id === "number") {
        navigate(
          `/app/user-management/project/${values.companyId}/${response.id}`
        );
      } else {
        throw new Error("No project id returned on creation");
      }
    } catch (error) {
      // TODO: Refactor - improve on error handling (1. Standardize server responses 2. Create custom hook or handle with RTK + create helpers and constanst for all error messages in a separate package)
      if (error?.data?.detail?.errorCode) {
        showNotification({
          type: "error",
          message: t(
            `ERROR.${(error.data.detail.errorCode as string).toUpperCase()}`
          )
        });
      } else {
        console.error(error);
        showNotification({
          type: "error",
          message: "Something went wrong: could not create project"
        });
      }
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        <ProjectBase />

        <ProjectFormFooter showNext />
      </form>
    </Form>
  );
};
