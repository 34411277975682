import { GetLeakTypeResponse, GetStatusResponse } from "@/types/mobileApp.type";
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "../api";

export const mobileAppApi = createApi({
  reducerPath: "mobileAppQueries",
  baseQuery: apiBaseQuery,
  tagTypes: ["GetStatuses", "GetLeakTypes"],
  endpoints: (builder) => ({
    getStatuses: builder.query<GetStatusResponse[], void>({
      query: () => "/mobile_app/get_wp_statuses",
      providesTags: ["GetStatuses"]
    }),
    getLeakTypes: builder.query<GetLeakTypeResponse[], void>({
      query: () => "/mobile_app/get_leak_types",
      providesTags: ["GetLeakTypes"]
    })
  })
});

export const { useGetStatusesQuery, useGetLeakTypesQuery } = mobileAppApi;
