import { ReactSVGIcon } from "@/types/common.type";
import { WidgetAction, WidgetType } from "@/types/report.type";
import React, { FC, useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import * as Styled from "./style";
export interface Props {
  name: string;
  id: WidgetType;
  disabled?: boolean;
  icon: ReactSVGIcon;
}

export const Widget: FC<Props> = ({ name, id, disabled, icon: Icon }) => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "widget",
    item: {
      widget: { key: id, data: null, settings: null },
      type: WidgetAction.ADD
    },
    end: () => {},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <Styled.Container
      id={id}
      tooltip={!disabled ? name : ""}
      isDragging={isDragging}
      ref={!disabled ? drag : null}
      disabled={Boolean(disabled)}
    >
      <Icon />
      <Styled.Name>{name}</Styled.Name>
    </Styled.Container>
  );
};
