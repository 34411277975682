import { GISUploadValues } from "@/types/gisUpload.type";
import { DeepPartial } from "@/types/util.type";
import { DEFAULT_UPLOAD_VALUES } from "./gisUpload.slice";

// TODO: Refactor - remove "flattening" of "uploadValues.data" for ease of development (all the places where "useGISUploadValues" is used needs to be refactored for this)
export const makeWatchedUploadValues = (uploadValues: GISUploadValues) => ({
  ...uploadValues,
  ...uploadValues.data,
  isUploading: uploadValues.uploadStatus === "uploading"
});

// TODO: Write a test for this deep merge
// TODO: Come up with a more robust approach - currently not fully deep nesting supported
export const getDefaultUploadValues = (
  uploadValues: DeepPartial<GISUploadValues> = {}
) => {
  return {
    ...DEFAULT_UPLOAD_VALUES,
    ...(uploadValues || {}),
    data: {
      ...DEFAULT_UPLOAD_VALUES.data,
      ...(((uploadValues as GISUploadValues).data as GISUploadValues["data"]) ||
        {})
    }
  } as GISUploadValues;
};
