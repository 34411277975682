import { DropWidget } from "@/types/report.type";
import { useDrop } from "react-dnd";

const useDropLayout = (onUpdate: (widget: DropWidget) => void) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "widget",
    drop: (item: DropWidget) => {
      onUpdate(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  return { isActive: canDrop && isOver, ref: drop };
};

export default useDropLayout;
