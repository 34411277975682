export function arrayMoveMutable(
  array: unknown[],
  fromIndex: number,
  toIndex: number
) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(
  array: unknown[],
  fromIndex: number,
  toIndex: number
) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export function arrayEquality(arr1: unknown[], arr2: unknown[]) {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return (
    arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item))
  );
}

/**
 * Checks if all items in arr1 are included in arr2
 */
export function arrayIncludes(arr1: unknown[], arr2: unknown[]) {
  const set2 = new Set(arr2);
  return arr1.every((item) => set2.has(item));
}
