export const getMimeTypesFromExtensions = (
  extensions: string[]
): Record<string, string[]> => {
  const mimeTypesMap: Record<string, string[]> = {
    "application/zip": [".zip"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"],
    "text/plain": [".txt"],
    "text/csv": [".csv"]
  };

  const result: Record<string, string[]> = {};

  extensions.forEach((extension) => {
    for (const [mimeType, extList] of Object.entries(mimeTypesMap)) {
      if (extList.includes(extension)) {
        if (!result[mimeType]) {
          result[mimeType] = [];
        }
        result[mimeType].push(extension);
      }
    }
  });

  return result;
};
