import {
  ErrorModalText,
  Column,
  SampleData,
  TableColumns
} from "@/types/gisUpload.type";

type Route = {
  title: string;
  id: number;
  selected: boolean;
  completed: boolean;
  isLoading: boolean;
};

export const routes: Route[] = [
  {
    title: "ROUTES.STEP_ONE",
    id: 0,
    selected: false,
    completed: false,
    isLoading: false
  },
  {
    title: "ROUTES.STEP_TWO",
    id: 1,
    selected: false,
    completed: false,
    isLoading: false
  },
  {
    title: "ROUTES.STEP_THREE",
    id: 2,
    selected: false,
    completed: false,
    isLoading: false
  },
  {
    title: "ROUTES.STEP_FOUR",
    id: 3,
    selected: false,
    completed: false,
    isLoading: false
  },
  {
    title: "ROUTES.STEP_FIVE",
    id: 4,
    selected: false,
    completed: false,
    isLoading: false
  }
];

export const updateGISRoutes = (
  selectedId: number,
  isLoading: boolean
): Route[] => {
  return routes.map((route) => {
    if (route.id < selectedId) {
      return { ...route, selected: false, completed: true };
    } else if (route.id === selectedId) {
      return { ...route, selected: true, completed: false };
    } else if (isLoading && route.id === selectedId + 1) {
      return { ...route, isLoading: true, selected: false, completed: false };
    } else {
      return { ...route, selected: false, completed: false };
    }
  });
};

export const extractTables = (
  data: TableColumns[] | undefined
): { dmaColumns: Column[]; assetColumns: Column[]; pipeColumns: Column[] } => {
  const tablesMap: { [key: string]: Column[] } = {};

  if (data) {
    data.forEach((table) => {
      tablesMap[table.table_name] = table.columns;
    });
  }

  return {
    dmaColumns: tablesMap["dmas"] || [],
    assetColumns: tablesMap["assets"] || [],
    pipeColumns: tablesMap["pipes"] || []
  };
};

export const groupDataByType = (
  data: SampleData[] | undefined
): {
  dmaSampleData: SampleData[];
  pipeSampleData: SampleData[];
  assetSampleData: SampleData[];
} => {
  const result: { [key: string]: SampleData[] } = {};

  if (data) {
    data.forEach((table) => {
      if (!result[table.type]) {
        result[table.type] = [];
      }
      result[table.type].push(table);
    });
  }

  return {
    dmaSampleData: result["dma"] || [],
    pipeSampleData: result["pipe"] || [],
    assetSampleData: result["asset"] || []
  };
};

const maxSteps = 4;
const firstStep = 0;

export const goForwardStep = (prevStep: number) =>
  Math.min(prevStep + 1, maxSteps);

export const goBackStep = (prevStep: number) =>
  Math.max(prevStep - 1, firstStep);

/**
 * Adds or replaces an error in the list of errors, or removes it if specified.
 *
 * @param {Array<ErrorModalText> | null} errors - The current list of error messages. Can be null.
 * @param {ErrorModalText} error - The error message to add, replace, or remove.
 * @param {boolean} [shouldRemove=false] - Whether the error should be removed from the list. Defaults to false.
 *
 * @returns {Array<ErrorModalText> | null} - The updated list of errors. Returns null if no errors remain after removal or if the list was initially null.
 *
 * @description
 * - If `errors` is null and `shouldRemove` is false, returns a new array containing the provided error.
 * - If `errors` is null and `shouldRemove` is true, returns null (no action needed).
 * - If `shouldRemove` is true, removes the specified error from the list and returns the updated array. If no errors remain, returns null.
 * - If the error already exists in the list, it is replaced with a new reference to ensure proper state update.
 * - If the error doesn't exist, it is appended to the list.
 */

export const addOrReplaceError = (
  errors: Array<ErrorModalText>,
  error: ErrorModalText,
  shouldRemove: boolean = false
) => {
  if (errors.length === 0) {
    return shouldRemove ? [] : [error];
  }

  if (shouldRemove) {
    const updatedErrors = errors.filter((e) => e !== error);

    return updatedErrors.length === 0 ? [] : updatedErrors;
  }

  const index = errors.findIndex((e) => e === error);

  if (index !== -1) {
    // I am creating a new array with the updated value since the state won't update if the reference is the same
    return [...errors.slice(0, index), error, ...errors.slice(index + 1)];
  } else {
    return [...errors, error];
  }
};

export type AllowedType = "dma" | "asset" | "pipe";

export const countTypes = (
  data: SampleData[] | undefined
): Record<AllowedType, number> | undefined => {
  if (!data) {
    return undefined;
  }

  const initialCount: Record<AllowedType, number> = {
    dma: 0,
    asset: 0,
    pipe: 0
  };

  return data.reduce((acc, item) => {
    acc[item.type as AllowedType] += 1;
    return acc;
  }, initialCount);
};
