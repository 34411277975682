import {
  Column,
  ColumnMappingBody,
  MappedColumns,
  SampleData,
  Item,
  FileSelectedValues
} from "@/types/gisUpload.type";

const UNIT_MAP = {
  mm: 1,
  in: 2
} as const;

type UnitType = keyof typeof UNIT_MAP;

// TODO: Refactor - use constant values and Enums on the frontend, get rid of multiple steps of mapping (should be resolved after full GIS page refactoring)
// TODO: Refactor TS - use inferred types, fix the types for items
const mapUnits = (units: FileSelectedValues["units"]) => {
  if (!units || typeof units !== "object") {
    return units || [];
  }

  return units.map((u) => ({
    title: u.title,
    id: UNIT_MAP[u.type as UnitType]
  })) as unknown as Item[];
};

export const mappedColumns = (
  selectedValues: FileSelectedValues[],
  uploadId: number | null,
  sampleData: SampleData[],
  fileIndex: number
): ColumnMappingBody => {
  const columns = selectedValues.reduce<MappedColumns>(
    (acc, column) => {
      if (column.file === sampleData[fileIndex]?.file_name) {
        const newColumns = column.data
          .map((data) => {
            if (data.required) {
              return {
                id: Number(data.value),
                title: data.name
              };
            } else {
              return;
            }
          })
          .filter(
            (column): column is { id: number; title: string } => !!column
          );
        acc.columns.push(...newColumns);
        acc.material = column.material || [];
        acc.asset_type = column.asset_type || [];
        acc.pipe_type = column.pipe_type || [];
        acc.status = column.status || [];
        acc.units = mapUnits(column.units);
      }
      return acc;
    },
    {
      columns: [],
      material: [],
      asset_type: [],
      pipe_type: [],
      status: [],
      units: []
      // diameter_units: [],
    }
  );

  // TODO: GIS - change mm / inch to enums
  // Change to ENUM - ValueError: [{'title': 'Metric', 'type': 'mm'}, {'title': 'Imperial', 'type': 'in'}] is not a valid UnitsValues
  // 1 - mm
  // 2 - inch

  return {
    upload_id: uploadId,
    filename: sampleData[fileIndex]?.file_name,
    mapped_columns: columns
  };
};

interface UnselectedColumns {
  required: Column[];
  optional: Column[];
}

export const getUnselectedColumns = (
  tableColumns: Column[],
  selectedColumnIds: number[]
): UnselectedColumns => {
  const required: Column[] = [];
  const optional: Column[] = [];

  tableColumns.forEach((column) => {
    if (!selectedColumnIds.includes(column.id)) {
      if (column.required) {
        required.push(column);
      } else {
        optional.push(column);
      }
    }
  });

  return {
    required,
    optional
  };
};

export const getSelectedColumnIds = (
  sampleData: { file_name: string }[],
  selectedValues: FileSelectedValues[],
  fileIndex: number
): number[] => {
  if (sampleData.length === 0) {
    return [];
  }

  const selectedFile = selectedValues.find(
    (column) => column?.file === sampleData[fileIndex]?.file_name
  );

  return selectedFile
    ? selectedFile.data
        .map((data) => {
          const numberValue = Number(data.value);
          return isNaN(numberValue) ? null : numberValue;
        })
        .filter((value): value is number => value !== null)
    : [];
};
