import React, { useState } from "react";
import * as Styled from "./style";

import { Button, Table } from "@/components";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";
import useCustomTranslation from "@/localization/useCustomTranslation";

import {
  GetViewJobsDistinctResponse,
  JobsData
} from "@/types/portalUsers.type";
import moment from "moment";
import { DropDownOption } from "@/components/DropDown/DropDown";

export const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];

export const headerNames = [
  "id",
  "work_order",
  "job_status",
  "branch_id",
  "dma_key",
  "last_scheduled_engineer_id",
  "waypoint_id",
  "environmental_notes",
  "estimate_leak_size",
  "client_leak_size_estimate",
  "agreed_flow_reduction",
  "date_raised_externally",
  "date_raised_internally",
  "road_speed",
  "created_at",
  "updated_at",
  "repair_date",
  "comment"
] as const;

export const headers = (
  columnOrder: { name: string; show: boolean }[]
): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("LIST_VIEW.JOBS.HEADERS");

  const currentColumns = headerNames.map((header) => ({
    id: header,
    title: prefixedT(header),
    sortable: false,
    align: TableCellAlignment.center
  }));

  try {
    const result = columnOrder
      .filter((column) => column.show)
      .map((column) => {
        const matchedColumn = currentColumns.find(
          (col) => col.id === column.name
        );
        if (!matchedColumn) {
          throw new Error(`Column not found`);
        }
        return matchedColumn;
      });

    return result;
  } catch (error) {
    console.error("headers error: ", error);
    // Something went wrong, reset the columns
    localStorage.removeItem("listView.Waypoints");
    return currentColumns;
  }
};

const ButtonCell = ({ children }: { children: string }) => {
  const [showChildren, setShowChildren] = useState(false);
  return (
    <Styled.ExpandableCell>
      {children && (
        <Styled.ButtonContainer>
          <Button width={"40px"} onClick={() => setShowChildren(!showChildren)}>
            <Styled.ClickableAreaIndicator open={showChildren} />
          </Button>
        </Styled.ButtonContainer>
      )}
      <Styled.ChildWrapper show={showChildren}>{children}</Styled.ChildWrapper>
    </Styled.ExpandableCell>
  );
};

export const getRowStructure = (
  data: JobsData,
  index: number,
  columnOrder: { name: string; show: boolean }[]
) => {
  const currentRowOrder = [
    {
      id: "id",
      child: (
        <a
          href={`/app/job?waypoint=${data.waypoint_id}&job=${data.id}`}
          target="_blank"
        >
          {data.id}
        </a>
      )
    },
    {
      id: "work_order",
      child: data.work_order
    },
    {
      id: "job_status",
      child: data.job_status
    },
    {
      id: "branch_id",
      child: data.branch_name
    },
    {
      id: "dma_key",
      child: data.dma_key
    },
    {
      id: "last_scheduled_engineer_id",
      child: data.last_scheduled_engineer
    },
    {
      id: "waypoint_id",
      child: (
        <a href={`/app/waypoint?waypoint=${data.waypoint_id}`} target="_blank">
          {data.waypoint_name}
        </a>
      )
    },
    {
      id: "comment",
      child: <ButtonCell children={data.comment} />
    },
    {
      id: "environmental_notes",
      child: data.environmental_notes
    },
    {
      id: "estimate_leak_size",
      child: data.estimate_leak_size
    },
    {
      id: "client_leak_size_estimate",
      child: data.client_leak_size_estimate
    },
    {
      id: "agreed_flow_reduction",
      child: data.agreed_flow_reduction
    },
    {
      id: "road_speed",
      child: data.road_speed
    },
    {
      id: "created_at",
      child:
        data.created_at !== null &&
        moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      id: "updated_at",
      child:
        data.updated_at !== null &&
        moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      id: "repair_date",
      child:
        data.repair_date !== null &&
        moment(data.repair_date).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      id: "date_raised_externally",
      child:
        data.date_raised_externally !== null &&
        moment(data.date_raised_externally).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      id: "date_raised_internally",
      child:
        data.date_raised_internally !== null &&
        moment(data.date_raised_internally).format("YYYY-MM-DD HH:mm:ss")
    }
  ];

  const result = columnOrder
    .filter((column) => column.show)
    .map((column) => {
      const matchedColumn = currentRowOrder.find(
        (col) => col.id === column.name
      );
      if (!matchedColumn) {
        throw new Error(`Column not found ${column.name}`);
      }
      return matchedColumn;
    });

  return (
    <Table.Row key={index}>
      {result.map((row, idx) => (
        <Table.Cell
          key={idx}
          align={TableCellAlignment.center}
          headerId={row.id}
        >
          {row.child}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const filterColumns = [
  "work_order",
  "job_status",
  "branch_id",
  "last_scheduled_engineer_id"
];

export const sortColumns = [
  "id",
  "work_order",
  "job_status",
  "branch_id",
  "dma_key",
  "last_scheduled_engineer_id",
  "waypoint_id",
  "environmental_notes",
  "estimate_leak_size",
  "client_leak_size_estimate",
  "agreed_flow_reduction",
  "date_raised_externally",
  "date_raised_internally",
  "road_speed",
  "created_at",
  "updated_at",
  "repair_date",
  "comment"
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

/**
 * Formats the response from the API to be used in the filter dropdowns
 */
export const processDistinctValues = (
  distinct: GetViewJobsDistinctResponse | undefined
) => {
  return {
    work_order:
      distinct?.work_order
        .map((currentWorkOrder) => {
          if (currentWorkOrder === null) {
            return {
              label: "No Work Order",
              value: "null"
            };
          } else {
            return {
              label: currentWorkOrder,
              value: currentWorkOrder
            };
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    job_status:
      distinct?.job_status
        .map((currentStatus) => {
          if (currentStatus === null) {
            return {
              label: "No Job Status",
              value: "null"
            };
          } else {
            return {
              label: currentStatus,
              value: currentStatus
            };
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    branch_id:
      distinct?.project
        .map((currentProject) => {
          if (currentProject.id === null || currentProject.name === null) {
            return { label: "No Project", value: "null" };
          }
          return {
            label: currentProject.name,
            value: `${currentProject.id}`
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    last_scheduled_engineer_id:
      distinct?.engineer
        .map((currentEngineer) => {
          if (currentEngineer.id === null || currentEngineer.name === null) {
            return { label: "No Engineer", value: "null" };
          }
          return {
            label: currentEngineer.name,
            value: `${currentEngineer.id}`
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || []
  };
};
