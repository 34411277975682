import { useFormContext } from "react-hook-form";
import { useEffect, useRef } from "react";
import { WebsocketMessageTypeBase } from "@/types/websocket.type";
import { Id, toast, ToastOptions } from "react-toastify";

// Types
import type { ProjectFormValues } from "../schema";

// Hooks
import { useUploadODLs } from "@/hooks/query/useUploadODLs";
import { useGISMapPreview } from "@/hooks/query/useGISMapPreview";
import { useUploadDMAs } from "@/hooks/query/useUploadDMAs";
import { useMounted } from "@/hooks/useMounted";

const toastOptionsTemporary: ToastOptions = {
  autoClose: 4500,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined
};

const toastOptionsPermanent: ToastOptions = {
  autoClose: false,
  hideProgressBar: true,
  closeButton: false,
  draggable: false
};

// TODO: Finish toasts - extract the logic into a reusable hook
// TODO: Refactor - mb rethink toasts approach for queries in general
export const ProjectToasts = () => {
  const form = useFormContext<ProjectFormValues>();
  const mounted = useMounted();

  const gisUploadCompanyId = form.watch("gisUploadCompanyId");
  const gisUploadId = form.watch("gisUploadId");

  /*
   * Common
   */

  const handleClearToasts = () => {
    handleClearODLToasts();
    handleClearDMAToasts();
  };

  useEffect(() => {
    return () => {
      handleClearToasts();
    };
  }, []);

  /*
   * ODL toasts
   */

  const toastsODL = useRef<{
    pending: Id | null;
    loading: Id | null;
    error: Id | null;
    success: Id | null;
  }>({
    pending: null,
    loading: null,
    error: null,
    success: null
  });

  const { data: mapPreviewData } = useGISMapPreview(
    {
      companyId: gisUploadCompanyId,
      uploadId: gisUploadId
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const odlQuery = useUploadODLs({
    taskId: mapPreviewData?.odl_task_id
  });

  const handleClearODLToasts = (
    types: string[] = ["pending", "loading", "error", "success"]
  ) => {
    types.forEach((type) => {
      if (toastsODL.current[type] !== null) {
        toast.dismiss(toastsODL.current[type]);
      }
      toastsODL.current[type] = null;
    });
  };

  useEffect(() => {
    if (!mounted) {
      return;
    }

    const createODLToast = (type: string, message?: string) => {
      if (toastsODL.current[type] === null) {
        switch (type) {
          case "pending":
            handleClearODLToasts(["loading", "error", "success"]);
            toastsODL.current[type] = toast.info(
              message || "Pending ODL data...",
              toastOptionsPermanent
            );
            break;

          case "loading":
            handleClearODLToasts(["pending", "error", "success"]);
            toastsODL.current[type] = toast.info(
              message || "Loading ODL data...",
              toastOptionsPermanent
            );
            break;

          case "error":
            handleClearODLToasts(["pending", "loading", "success"]);
            toastsODL.current[type] = toast.error(
              message || "Error loading ODL data.",
              toastOptionsTemporary
            );
            break;

          case "success":
            handleClearODLToasts(["pending", "loading", "error"]);
            toastsODL.current[type] = toast.success(
              message || "Successfully loaded ODL data.",
              toastOptionsTemporary
            );
            break;
          default:
            break;
        }
      }
    };

    if (odlQuery.isSuccess && odlQuery.data) {
      switch (odlQuery.data.type) {
        case WebsocketMessageTypeBase.PENDING:
          createODLToast("loading");
          break;

        case WebsocketMessageTypeBase.STARTED:
          createODLToast("loading");
          break;

        case WebsocketMessageTypeBase.SUCCESS:
          createODLToast("success");
          break;

        case WebsocketMessageTypeBase.FAILURE:
          createODLToast(
            "error",
            odlQuery.data.error
              ? `ODL Error: ${odlQuery.data.error}`
              : undefined
          );

          break;
        default:
          break;
      }
    }

    if (odlQuery.isError) {
      createODLToast("error");
    }

    if (odlQuery.isLoading || odlQuery.isFetching) {
      createODLToast("loading");
    }
  }, [
    odlQuery.isSuccess,
    odlQuery.data,
    odlQuery.isLoading,
    odlQuery.isError,
    odlQuery.isFetching,
    mounted
  ]);

  /*
   * DMA toast
   */

  const toastsDMA = useRef<{
    loading: Id | null;
    error: Id | null;
    success: Id | null;
  }>({
    loading: null,
    error: null,
    success: null
  });

  const dmaQuery = useUploadDMAs({
    uploadId: gisUploadId
  });

  const handleClearDMAToasts = (
    types: string[] = ["loading", "error", "success"]
  ) => {
    types.forEach((type) => {
      if (toastsDMA.current[type] !== null) {
        toast.dismiss(toastsDMA.current[type]);
      }
      toastsDMA.current[type] = null;
    });
  };

  useEffect(() => {
    if (!mounted) {
      return;
    }

    const createDMAToast = (type: string) => {
      if (toastsDMA.current[type] === null) {
        switch (type) {
          case "loading":
            handleClearDMAToasts(["error", "success"]);
            toastsDMA.current[type] = toast.info(
              "Loading DMA data...",
              toastOptionsPermanent
            );
            break;

          case "error":
            handleClearDMAToasts(["loading", "success"]);
            toastsDMA.current[type] = toast.error(
              "Error loading DMA data.",
              toastOptionsTemporary
            );
            break;

          case "success":
            handleClearDMAToasts(["loading", "error"]);
            toastsDMA.current[type] = toast.success(
              "Successfully loaded DMA data.",
              toastOptionsTemporary
            );
            break;
          default:
            break;
        }
      }
    };

    if (dmaQuery.isSuccess && dmaQuery.data) {
      createDMAToast("success");
    }

    if (dmaQuery.isError) {
      createDMAToast("error");
    }

    if (dmaQuery.isLoading || dmaQuery.isFetching) {
      createDMAToast("loading");
    }
  }, [
    dmaQuery.isSuccess,
    dmaQuery.data,
    dmaQuery.isFetching,
    dmaQuery.isLoading,
    dmaQuery.isError,
    mounted
  ]);

  return null;
};
