import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import { Form, Button, Label } from "@fido/ui-lib";
import { ProjectGISPreviewMap } from "./blocks/ProjectGISPreviewMap.block";
import { LuX } from "react-icons/lu";

// Steps
import { ProjectBase } from "./blocks/ProjectBase.block";
import { ProjectGISUpload } from "./blocks/ProjectGISUpload.block";
import { ProjectData } from "./blocks/ProjectData.block";
import { ProjectODL } from "./blocks/ProjectODL.block";
import { ProjectToasts } from "./blocks/ProjectToasts.block";

// Hooks
// import { usePartnersAndCompanies } from "@/hooks/query/usePartnersAndCompanies";
import { useSteps } from "@/hooks/useSteps";
import useNotification from "@/hooks/useNotification";
import { useUpdateProjectMutation } from "@/redux/portalProjects/portalProjectsGIS.api";

// Utils
import { getFormattedDate } from "@/utils/getFormattedDate";
import { cleanObject } from "@/utils/common";
import { ProjectUpdateData } from "@/types/portalProject.type";
import { ProjectFormStep, ProjectFormValues, ProjectSteps } from "./schema";
import { useProjectForm } from "./hooks/useProjectForm";
import { ProjectFormFooter } from "./blocks/ProjectFormFooter.block";

export type ProjectUpdateFormProps = {
  defaultValues?: Partial<ProjectFormValues>;
  isReadonly?: boolean;
};

export const ProjectUpdateForm = (props: ProjectUpdateFormProps) => {
  const { defaultValues, isReadonly } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { showNotification } = useNotification();
  const form = useProjectForm({
    disabled: isReadonly,
    defaultValues
  });

  const gisUploadId = form.watch("gisUploadId");

  const hasGisUploadId = typeof gisUploadId === "number";
  const shouldAutoSelectNewGIS = searchParams.get("autoSelectGISUploadId") > "";

  const { prev, next, jump, isLast, hasPrev, current } = useSteps(
    ProjectSteps,
    {
      initialStep:
        (hasGisUploadId && !shouldAutoSelectNewGIS) || isReadonly
          ? ProjectFormStep.PROJECT_DATA
          : ProjectFormStep.UPLOAD_GIS
    }
  );

  const shouldShowNavigation = current !== ProjectFormStep.UPLOAD_GIS;

  // const partnersAndCompanies = usePartnersAndCompanies();

  const [updateProject] = useUpdateProjectMutation();

  const handleSaveProjectData = async (values: ProjectFormValues) => {
    if (isReadonly) return;

    const initialValues = form.formState.defaultValues;

    await updateProject({
      projectId: Number(values.projectId),
      uploadId: Number(values.gisUploadId),
      body: cleanObject<ProjectUpdateData>({
        dma_keys:
          values.dmaIds.length > 0 ? values.dmaIds.map((dma) => dma.value) : [],
        project_id: Number(values.projectId),
        branch_name:
          initialValues.name === values.name ? undefined : values.name,
        // company_id:
        //   initialValues.companyId === values.companyId
        //     ? undefined
        //     : values.companyId,
        odl_level: values.levelODL,
        start_date: values.startDate
          ? getFormattedDate(values.startDate.toISOString())
          : undefined,
        end_date: values.endDate
          ? getFormattedDate(values.endDate.toISOString())
          : undefined,
        cost_per_distance: Number(values.costPerDistance),
        target_pipeline: Number(values.targetDistance),
        target_leakage_reduction: Number(values.targetLeakage)
      })
    });
  };
  const handleSubmit = async (values: ProjectFormValues) => {
    if (ProjectFormStep.PROJECT_DATA === current) {
      try {
        await handleSaveProjectData(values);
        next();
      } catch (error) {
        showNotification({
          type: "error",
          message: "Something went wrong: could not update project"
        });
      }
    }

    if (isLast) {
      try {
        await handleSaveProjectData(values);
        if (!isReadonly) {
          showNotification({
            type: "success",
            message: "Project saved"
          });
        }
        navigate("/app/user-management/view-projects");
      } catch (error) {
        showNotification({
          type: "error",
          message: "Something went wrong: could not update project"
        });
      }
    }
  };

  // TODO: Finish Project - add status of project somewhere in the form
  // TODO: Finish Project - test overlap when it's fixed

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        {hasGisUploadId && <ProjectToasts />}
        <ProjectBase disableFields={{ companyId: true }} />

        {/*
         * Step blocks
         */}

        {ProjectFormStep.UPLOAD_GIS === current && (
          <ProjectGISUpload jumpToNextStep={next} />
        )}

        {[ProjectFormStep.PROJECT_DATA, ProjectFormStep.PROJECT_ODL].includes(
          current
        ) && (
          <div className="space-y-2">
            <div className="flex gap-2">
              <Label>GIS preview</Label>
              <Button
                variant="destructive"
                className="h-4 p-1 [&_svg]:size-3 rounded-sm gap-0.5 text-xs"
                onClick={
                  form.formState.disabled || isReadonly
                    ? undefined
                    : () => {
                        form.setValue("gisUploadId", undefined);
                        form.setValue("gisUploadCompanyId", undefined);
                        jump(ProjectFormStep.UPLOAD_GIS);
                      }
                }
                disabled={form.formState.disabled || isReadonly}
              >
                <LuX className="mr-0.5" /> Clear
              </Button>
            </div>
            {hasGisUploadId ? (
              <div className="h-[400px]">
                <ProjectGISPreviewMap
                  shouldShowGeoJSON={current === ProjectFormStep.PROJECT_ODL}
                />
              </div>
            ) : (
              <div>
                <span className="text-muted-foreground text-sm">
                  No GIS uploaded
                </span>
              </div>
            )}
          </div>
        )}

        {ProjectFormStep.PROJECT_DATA === current && <ProjectData />}

        {ProjectFormStep.PROJECT_ODL === current && <ProjectODL />}

        {/*
         * Footer
         */}

        <ProjectFormFooter
          showPrevious={
            hasPrev &&
            current !== ProjectFormStep.PROJECT_DATA &&
            shouldShowNavigation
          }
          isReadonly={isReadonly}
          isLast={isLast}
          prev={prev}
          showNext={shouldShowNavigation}
        />
      </form>
    </Form>
  );
};
