import theme from "@/theme";
import React, { FC } from "react";
import Button from "../Button/Button";
import { Modal } from "../Modal";
import * as Styled from "./style";

export type DialogProps = {
  open: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  title: string;
  subtitle?: string;
  style?: {
    color?: {
      confirm?: string;
      cancel?: string;
    };
  };
};

const Dialog: FC<DialogProps> = ({
  open,
  onCancel,
  onConfirm,
  confirmText = "Confirm",
  cancelText = "Cancel",
  title,
  subtitle,
  style
}) => {
  if (!onCancel && !onConfirm) {
    return null;
  }
  return (
    <Modal isOpen={open}>
      <Styled.Container data-testid="dialog">
        <Styled.Title data-testid="dialog-title">{title}</Styled.Title>
        {subtitle && (
          <Styled.SubTitle data-testid="dialog-subtitle">
            {subtitle}
          </Styled.SubTitle>
        )}
        <Styled.Actions>
          {onConfirm && (
            <Button
              onClick={onConfirm}
              width="130px"
              height="40px"
              testId="dialog-confirm"
              color={style?.color?.confirm}
            >
              {confirmText}
            </Button>
          )}
          {onCancel && (
            <Button
              onClick={onCancel}
              width="130px"
              height="40px"
              color={style?.color?.cancel || theme.colors.coral}
              testId="dialog-cancel"
            >
              {cancelText}
            </Button>
          )}
        </Styled.Actions>
      </Styled.Container>
    </Modal>
  );
};

export default Dialog;
