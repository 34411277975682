import mapbox, { Map } from "mapbox-gl";
import { updateDraggedFeature } from "./updateDraggedFeature";
import { getSourceData } from "../utils";

export const handleDragStart = (
  event: mapbox.MapTouchEvent & {
    features?: mapbox.MapboxGeoJSONFeature[];
  } & mapbox.EventData,
  map: Map,
  draggedFeatureIndexRef: React.MutableRefObject<number>
) => {
  const data = getSourceData(map);

  if (event.points.length !== 1) {
    return;
  }

  event.preventDefault();

  const { lngLat, features } = event;

  if (!features) {
    return;
  }

  draggedFeatureIndexRef.current = data.findIndex(
    (feat) =>
      feat.properties && feat.properties.id === features[0].properties?.id
  );

  if (draggedFeatureIndexRef.current !== -1) {
    const coordinates = [lngLat.lng, lngLat.lat];
    updateDraggedFeature(map, draggedFeatureIndexRef, coordinates);
  }
};
