import Delete from "@/assets/icons/delete.svg?react";
import DragIcon from "@/assets/icons/drag.svg?react";
import MoreIcon from "@/assets/icons/more.svg?react";

import styled from "styled-components";

export const DeleteIcon = styled(Delete)`
  width: 23px;
  height: 23px;
  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;
export const Container = styled.div<{ readonly: boolean }>`
  border: ${({ readonly, theme }) =>
    readonly
      ? `1px solid ${theme.colors.lightGray}`
      : ` 1px dashed ${theme.colors.gray}`};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ readonly }) => (readonly ? "16px" : "0")};
`;

export const Header = styled.div<{ readonly: boolean }>`
  display: ${({ readonly }) => (readonly ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 10px 10px 10px 15px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mediumDark};
`;

export const Settings = styled(MoreIcon)`
  cursor: pointer;
`;
export const Drag = styled(DragIcon)``;

export const Content = styled.div<{ readonly: boolean }>`
  display: flex;
  position: relative;

  ${({ readonly }) =>
    !readonly
      ? ` &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
  }`
      : null}
`;

export const ContentWrapper = styled.div<{
  readonly: boolean;
  variant: string;
}>`
  flex-grow: 1;
  padding: ${({ readonly }) => (readonly ? "0px" : "15px")};
  overflow: ${({ variant }) => variant};
`;

export const DragIndication = styled.div<{ readonly: boolean }>`
  width: 28px;
  border-radius: 0px 0px 4px 0px;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: ${({ readonly }) => (readonly ? "none" : "flex")};
  align-items: center;
  justify-content: center;
`;
