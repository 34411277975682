import {
  GetOkta,
  GetOktaCallback,
  IChangePasswordValues,
  IForgotPasswordValues,
  IRecoveryValues
} from "@/types/auth.type";
import { IDecodeTokenValues } from "@/types/auth.type";
import { Login } from "@/types/user.type";
import { createApi } from "@reduxjs/toolkit/query/react";
import { authBaseQuery } from "../api";

export const authorizationApi = createApi({
  reducerPath: "authQuery",
  baseQuery: authBaseQuery,
  tagTypes: ["ChangePassword"],
  endpoints: (builder) => ({
    login: builder.mutation<Login, string>({
      query: (body: string) => ({
        url: "/authorization/login",
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body
      })
    }),
    getOkta: builder.mutation<GetOkta, void>({
      query: () => "/authorization/login/okta"
    }),
    getOktaCallback: builder.mutation<GetOktaCallback, { code: string }>({
      query: ({ code }) => ({
        url: `/authorization/login/okta/callback?code=${code}`,
        method: "GET"
      })
    }),
    forgotPassword: builder.mutation({
      query: (body: IForgotPasswordValues) => ({
        url: "/authorization/forgot",
        method: "POST",
        body
      })
    }),
    recovery: builder.mutation({
      query: (body: IRecoveryValues) => ({
        url: "/authorization/recovery",
        method: "POST",
        body
      })
    }),
    changePassword: builder.mutation({
      query: (body: IChangePasswordValues) => ({
        url: "/authorization/change-password",
        method: "POST",
        body
      }),
      invalidatesTags: ["ChangePassword"]
    }),
    decodeToken: builder.mutation({
      query: (body: IDecodeTokenValues) => ({
        url: "/authorization/decode",
        method: "POST",
        body
      })
    })
  })
});

export const {
  useLoginMutation,
  useRecoveryMutation,
  useGetOktaMutation,
  useGetOktaCallbackMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useDecodeTokenMutation
} = authorizationApi;
