import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC } from "react";
import * as Styled from "./style";

interface DetailWidgetProps {
  totalSessions: number;
  runtime: {
    value: number | null;
    type: string;
  };
  volumetricIdentified: {
    value: number | null;
    type: string;
  };
}

const DetailWidget: FC<DetailWidgetProps> = ({
  volumetricIdentified,
  totalSessions,
  runtime
}) => {
  const { prefixedT } = useCustomTranslation("DETAIL_WIDGET");
  return (
    <Styled.Wrapper>
      <Styled.Panel>
        <Styled.Unit>{totalSessions}</Styled.Unit>
        <Styled.DetailTitle>{prefixedT("TITLE_A")}</Styled.DetailTitle>
      </Styled.Panel>
      <Styled.Panel>
        <Styled.DetailTitle>{prefixedT("TITLE_B")}</Styled.DetailTitle>
        <Styled.Unit>{volumetricIdentified.value}</Styled.Unit>
        <Styled.TimeUnit>{volumetricIdentified.type}</Styled.TimeUnit>
      </Styled.Panel>
      <Styled.Panel>
        <Styled.DetailTitle>{prefixedT("TITLE_C")}</Styled.DetailTitle>
        <Styled.Unit>
          {runtime.type}
          {runtime.value}
        </Styled.Unit>
      </Styled.Panel>
    </Styled.Wrapper>
  );
};

export default DetailWidget;
