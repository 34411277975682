import { FeatureCollection, Point, Feature, GeoJsonProperties } from "geojson";
import * as turf from "@turf/turf";
import { MyFeature } from "./type";
import { PointTooltipType } from "../../../../utils/types";

export const findNearestSession = (
  data: Feature<Point, GeoJsonProperties>[],
  pt: Feature<Point, GeoJsonProperties>,
  draggedFeatureIndexRef: React.MutableRefObject<number>
) => {
  const filteredFeatures: MyFeature[] = data.filter(
    (feature): feature is MyFeature => !!feature.properties
  );

  const listOfAssets = createAssetFeatureCollection(filteredFeatures);

  if (listOfAssets.features.length === 0) {
    return;
  }

  const points = turf.featureCollection(listOfAssets.features);

  const nearest = turf.nearestPoint(pt, points);

  const draggedFeature = data[draggedFeatureIndexRef.current];
  const sessionTooltip = draggedFeature.properties?.tooltip as PointTooltipType;

  return {
    assetId: nearest.properties.id as string,
    sessionId: sessionTooltip.Session_id as string,
    assetKey: nearest.properties.key as string
  };
};

const createAssetFeatureCollection = (
  data: MyFeature[]
): FeatureCollection<Point, Record<string, any>> => {
  const listOfAssets: FeatureCollection<Point, Record<string, any>> = {
    type: "FeatureCollection",
    features: []
  };

  data.forEach((feature: MyFeature) => {
    const assetId = feature.properties.tooltip?.Asset_id;
    const assetKey = feature.properties.tooltip?.Asset_key;
    if (assetId !== null && assetId !== undefined) {
      listOfAssets.features.push(
        turf.point(feature.geometry.coordinates, {
          id: assetId,
          key: assetKey
        })
      );
    }
  });

  return listOfAssets;
};
