import { Feature, Geometry, GeoJsonProperties } from "geojson";
import { Map, MapMouseEvent } from "mapbox-gl";
import { PointTooltipType } from "../../utils/types";

export const linesLayerID = "lines-layer";

export const lineLayers = (
  map: Map,
  combinedFeatures: Feature<Geometry, GeoJsonProperties>[]
) => {
  const linesSourceID = "lines-source";

  if (!map.getSource(linesSourceID)) {
    map.addSource(linesSourceID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: combinedFeatures
      }
    });
  }

  if (!map.getLayer(linesLayerID)) {
    map.addLayer({
      id: linesLayerID,
      type: "line",
      source: "lines-source",
      layout: {
        "line-join": "round",
        "line-cap": "round"
      },
      paint: {
        "line-color": "#0000FF",
        "line-width": 3
      },
      minzoom: 14
    });
  }
};

export const clickEvents = (map: Map, doNotAllowClickThrough: boolean) => {
  const clickHandler = (e: MapMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [linesLayerID]
    });
    const clickedFeature = features[0];

    if (clickedFeature) {
      const tooltipString = clickedFeature?.properties?.tooltip as string;
      const tooltip = JSON.parse(tooltipString) as PointTooltipType;
      if (tooltip?.Waypoint_id) {
        window.open(`/app/waypoint?waypoint=${tooltip?.Waypoint_id}`, "_blank");
      }
    }
  };

  if (!doNotAllowClickThrough) {
    map.on("click", linesLayerID, clickHandler);
  }
};
