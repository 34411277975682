import { useGetProfileQuery } from "@/redux/user/user.api";
import { GuardComponent, IReactChildren } from "@/types/common.type";
import { Navigate } from "react-router-dom";

interface PermissionGuardProps {
  roles?: string[];
  children: IReactChildren;
}

const PermissionGuard: GuardComponent<PermissionGuardProps> = ({
  children,
  roles = []
}) => {
  const { data: profile, isLoading } = useGetProfileQuery();

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (!roles.includes(`${profile?.role?.role_code}`)) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default PermissionGuard;
