import styled from "styled-components";

export const Container = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;
export const Input = styled.input`
  display: none;
`;
export const Label = styled.div<{ checked: boolean }>`
  width: 50px;
  height: 26px;
  background: ${({ checked, theme }) =>
    theme.colors[checked ? "blue" : "gray"]};
  display: block;
  border-radius: 100px;
  position: relative;
  transition: 0.3s ease background;

  &:after {
    content: "";
    position: absolute;
    left: ${({ checked }) => (!checked ? "3px" : "calc(100% - 23px)")};
    top: 3px;
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 90px;
    transition: 0.3s ease left;
  }
`;

export const LabelText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  padding-right: 10px;
  user-select: none;
  font-weight: 500;
  width: max-content;
`;
