import EsFlag from "@/assets/icons/languages/es";
import GBFlag from "@/assets/icons/languages/gb";
import ItFlag from "@/assets/icons/languages/it";
import useOutsideClick from "@/hooks/useOutsideClick";
import i18n from "@/localization/i18n";
import { ReactSVGIcon } from "@/types/common.type";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Styled from "./style";

export interface ILanguageConfig {
  id: number | string;
  name: string;
  code: string;
  icon: ReactSVGIcon | null;
  abbr: string;
}

const LanguageSelector = () => {
  const { t } = useTranslation();

  const languageConfig = useMemo(
    () => [
      {
        id: 1,
        name: t("LANGUAGE.ENGLISH"),
        code: "en",
        icon: GBFlag,
        abbr: "EN"
      },
      {
        id: 2,
        name: t("LANGUAGE.ITALIAN"),
        code: "it",
        icon: ItFlag,
        abbr: "IT"
      },
      {
        id: 3,
        name: t("LANGUAGE.SPANISH"),
        code: "es",
        icon: EsFlag,
        abbr: "ES"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initialLanguage = localStorage.getItem("lang_code") || "en";
  const [active, setActive] = useState<ILanguageConfig | undefined>(
    languageConfig.find((lang) => lang.code === initialLanguage)
  );
  const [open, setOpen] = useState(false);

  const languageSelectorRef = useRef(null);

  const changeHandler = async (code: string) => {
    await i18n.changeLanguage(code);
    localStorage.setItem("lang_code", code);
    setOpen(false);
    // TODO: Test - come back to this (try to await for "i18n.changeLanguage" in .test.tsx file without calling setActive)
    setActive(languageConfig.find((lang) => lang.code === code));
  };

  useEffect(() => {
    // Update the active language whenever the language changes
    setActive(languageConfig.find((lang) => lang.code === i18n.language));
  }, [languageConfig]);

  const onClickHandler = () => setOpen(!open);

  const outsideContainerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(outsideContainerRef, () => setOpen(false));

  return (
    <Styled.Container ref={outsideContainerRef}>
      <Styled.ActiveLanguageWrapper
        onClick={onClickHandler}
        ref={languageSelectorRef}
      >
        {active?.icon && <active.icon />}
        <Styled.ActiveLanguage data-testid="active_lang">
          {active?.name}
        </Styled.ActiveLanguage>
        <Styled.ArrowDownIcon open={open} />
      </Styled.ActiveLanguageWrapper>
      <Styled.LanguageList open={open}>
        {languageConfig.map(({ id, code, name, icon: FlagIcon }) => (
          <Styled.LanguageItem
            key={id}
            onClick={() => changeHandler(code)}
            data-testid="lang_item"
          >
            <Styled.ItemWrapper bold={active?.id === id}>
              <Styled.IconWrapper>
                {FlagIcon && <FlagIcon />}
              </Styled.IconWrapper>
              {name}
            </Styled.ItemWrapper>
            {active?.id === id && <Styled.CheckLanguageIcon />}
          </Styled.LanguageItem>
        ))}
      </Styled.LanguageList>
    </Styled.Container>
  );
};

export default LanguageSelector;
