import styled from "styled-components";

export const ImageContainer = styled.div``;

export const Container = styled.div`
  margin-bottom: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
`;

export const Image = styled.img`
  width: 400px;
  max-height: 300px;
`;

export const FigCaption = styled.figcaption`
  text-align: center;
`;

export const btnContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
`;

export const btnContainerTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  max-height: 375px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  overflow-y: scroll;
`;

export const Button = styled.button`
  cursor: pointer;
  border: 1px solid rgb(0, 124, 187);
  background: ${({ theme }) => theme.colors.white};
  padding: 10px;
  min-width: 150px;

  :hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export const AudioAndImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
