import React from "react";

import { Table } from "@/components";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";

import * as Styled from "./style";
import {
  WaypointJobLog,
  WaypointJobLogChanges,
  WaypointJobLogsQuery
} from "@/types/report.type";
import moment from "moment";
import useCustomTranslation from "@/localization/useCustomTranslation";

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("WAYPOINT_PAGE.JOB_LOG_TABLE");
  return [
    {
      id: "created_at",
      title: prefixedT("DATE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "status",
      title: prefixedT("STATUS"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "action",
      title: prefixedT("ACTION"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "full_name",
      title: prefixedT("USER"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "old",
      title: prefixedT("OLD"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "new",
      title: prefixedT("NEW"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "notes",
      title: prefixedT("NOTES"),
      sortable: false,
      align: TableCellAlignment.center
    }
  ];
};

const ValuesCell = ({
  values,
  t
}: {
  values: WaypointJobLogChanges | null;
  t: (path: string) => string;
}) => {
  return (
    <Styled.ValuesContainer>
      {values !== null ? (
        Object.entries(values).map(([key, value], idx) => {
          const isDate =
            key === "repair_date" ||
            key === "date_raised_externally" ||
            key === "date_raised_internally";

          const renderedValue = (
            value !== null && isDate
              ? moment(value as string).format("YYYY-MM-DD HH:mm:ss")
              : value !== null
                ? value
                : "-"
          ) as string;

          return (
            <Styled.ValueEntry key={idx}>
              <Styled.Bold>
                {t("WAYPOINT_PAGE.JOB_LOG_TABLE.KEYS." + key)}
              </Styled.Bold>
              : {renderedValue}
            </Styled.ValueEntry>
          );
        })
      ) : (
        <p>-</p>
      )}
    </Styled.ValuesContainer>
  );
};

export const getRowStructure = (
  job: WaypointJobLog,
  index: number,
  t: (path: string) => string
) => {
  return (
    <Table.Row key={index}>
      <Table.Cell align={TableCellAlignment.center} headerId="created_at">
        <Styled.WrapCell>
          {job.created_at &&
            moment(job.created_at).format("YYYY-MM-DD HH:mm:ss")}
        </Styled.WrapCell>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="status">
        {job.status}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <Styled.ActionContainer>
          <Styled.ActionEntry>{job.action}</Styled.ActionEntry>
        </Styled.ActionContainer>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="full_name">
        {job.full_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.left} headerId="old">
        {job.changes_info !== null && (
          <ValuesCell values={job.changes_info.old} t={t} />
        )}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.left} headerId="new">
        {job.changes_info !== null && (
          <ValuesCell values={job.changes_info.new} t={t} />
        )}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="notes">
        <Styled.Overflow>{job.notes}</Styled.Overflow>
      </Table.Cell>
    </Table.Row>
  );
};

export const isQueryEqual = (
  query1: WaypointJobLogsQuery,
  query2: WaypointJobLogsQuery
): boolean => {
  const keys1 = Object.keys(query1);
  const keys2 = Object.keys(query2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (query1[key] !== query2[key]) {
      return false;
    }
  }

  return true;
};
