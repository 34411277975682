import { Modal } from "@/components";
import { ITablePaginate, TableSortOrders } from "@/components/Table/types";
import withPageLoader from "@/HOCs/withPageLoader";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { CreateReportValues, Layouts, Report } from "@/types/report.type";
import React, { FC } from "react";
import { CreateReport } from "./CreateReport";
import { ReportsTable } from "./ReportsTable";
import * as Styled from "./style";

interface ReportsProps {
  reports: Report[];
  totalCount: number;
  page: number;
  pages: number;
  onPageChange: (p: ITablePaginate) => void;
  onOpenModal: () => void;
  isCreateModalOpen: boolean;
  onClose: () => void;
  initialValues: CreateReportValues;
  onCreateReport: (values: CreateReportValues) => void;
  layouts: Layouts;
  setSorting: (orderBy: string, order: string) => void;
  order: TableSortOrders;
  orderBy: string;
}
const Reports: FC<ReportsProps> = ({
  reports,
  totalCount,
  onPageChange,
  page,
  pages,
  onOpenModal,
  isCreateModalOpen,
  onClose,
  initialValues,
  onCreateReport,
  layouts,
  setSorting,
  order,
  orderBy
}) => {
  const { prefixedT } = useCustomTranslation("REPORTS_PAGE");

  return (
    <Styled.Container data-testid="reportsPage">
      <Modal
        isOpen={isCreateModalOpen}
        title={prefixedT("CREATE_REPORT.TITLE")}
        onClose={onClose}
      >
        <CreateReport
          onSubmit={onCreateReport}
          initialValues={initialValues}
          layouts={layouts}
        />
      </Modal>
      <ReportsTable
        reports={reports}
        totalCount={totalCount}
        onPageChange={onPageChange}
        page={page}
        pages={pages}
        onOpenModal={onOpenModal}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </Styled.Container>
  );
};

export default withPageLoader(Reports);
