import AskFidoIcon from "@/assets/icons/chatbot/askFido.svg?react";
import CrossIcon from "@/assets/icons/chatbot/cross.svg?react";
import MessageIcon from "@/assets/icons/chatbot/message.svg?react";
import SubmitIcon from "@/assets/icons/chatbot/submitIcon.svg?react";
import styled from "styled-components";

export const ChatBotIcon = styled(MessageIcon)`
  width: 40px;
  height: 40px;
`;

export const SubmitImg = styled(SubmitIcon)`
  width: 40px;
  height: 40px;

  :hover {
    path {
      fill: #007cbb;
    }
  }
`;

export const CrossImg = styled(CrossIcon)`
  height: 25px;
  width: 25px;

  :hover {
    path {
      fill: #999a9b;
    }
  }
`;

export const AskFido = styled(AskFidoIcon)``;

export const HeaderTextContainer = styled.div`
  margin-left: 20px;
`;

export const CloseContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const Circle = styled.div`
  background-color: #5151f9;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  cursor: pointer;

  :hover {
    background-color: #6f6fff;
  }
`;

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
`;

export const Subheading = styled.h5`
  font-size: 15px;
  color: rgb(155, 166, 179);
  font-weight: normal;
`;

export const Wrapper = styled.div`
  bottom: 20px;
  right: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 660px;
  width: 370px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #eaeef2;
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 9.5px 12.7px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 9.5px 12.7px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  border-top: 1px solid #e3e3e3;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const MsgInput = styled.input`
  border: none;
  padding: 20px;
  width: calc(100% - 50px);
  border-radius: 10px;

  :focus {
    outline: none;
  }
`;

export const InputContainer = styled.div`
  width: calc(100% - 50px);
  background: #fff;
  border-radius: 10px;
`;

export const SubmitButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding-right: 10px;
  border-radius: 10px;
`;
