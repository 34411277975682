import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Types
import {
  WebsocketStatus,
  type WebsocketItem,
  type WebsocketState
} from "@/types/websocket.type";
import { buildWebsocketURL } from "@/utils/websocket.util";

const initialState: WebsocketState = {
  websockets: []
};

const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    // TODO: Refactor - maybe get token from global redux store as opposed to passing as prop
    createWSConnection: (
      state,
      action: PayloadAction<
        Omit<WebsocketItem, "status" | "url"> & { token: string }
      >
    ) => {
      const { type, id, token } = action.payload;
      const newWebsocket = {
        type,
        id,
        url: buildWebsocketURL({ type, id, token }),
        status: WebsocketStatus.UNINITIALIZED
      };
      const newWebsockets = [...state.websockets, newWebsocket];

      return {
        ...state,
        websockets: newWebsockets
      };
    },

    closeWSConnection: (state, action: PayloadAction<WebsocketItem["id"]>) => {
      const websocketIdToClose = action.payload;
      const websockets = state.websockets;

      for (let i = 0; i < websockets.length; i++) {
        const ws = websockets[i];
        if (ws.id === websocketIdToClose) {
          ws.status = WebsocketStatus.CLOSING;
          break;
        }
      }

      // return {
      //   ...state,
      //   websockets: newWebsockets,
      // };
    },

    removeWSConnection: (state, action: PayloadAction<WebsocketItem["id"]>) => {
      const websocketIdToRemove = action.payload;
      const newWebsockets = state.websockets.filter(
        (c) => c.id !== websocketIdToRemove
      );

      return {
        ...state,
        websockets: newWebsockets
      };
    }
  }
});

export const { createWSConnection, closeWSConnection, removeWSConnection } =
  websocketSlice.actions;

export default websocketSlice.reducer;
