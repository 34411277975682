import CrossIcon from "@/assets/icons/cross.svg?react";
import FilterIconSVG from "@/assets/icons/filter-icon.svg?react";
import styled from "styled-components";

export const StatusTitle = styled.h2`
  background: rgb(66, 152, 215);
  color: white;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: normal;
`;

export const StatusContainer = styled.div`
  border: 1px solid #e0e0e0;
  max-height: 300px;
  overflow: auto;
  background: white;
`;

export const StatusItem = styled.div`
  padding: 10px;
  font-size: 12px;

  :hover {
    background: #f5f5f5;
    cursor: pointer;
  }
`;

export const StatusWrapper = styled.div`
  text-align: left;
`;

export const Cross = styled(CrossIcon)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;

  path {
    fill: #000 !important;
  }
`;

export const FilterBarContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 5px;
  align-items: center;
  display: flex;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 5px 10px;
`;

export const Input = styled.input`
  outline: none;
  border: 0;
  background: #fff;
  font-size: 14px;
  margin-right: 5px;
  width: 130px;
`;

export const Icon = styled.div``;

export const FilterIcon = styled(FilterIconSVG)`
  margin-right: 10px;
  transform: scale(1.7);
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    max-width: 350px;

    path {
      /* Adjust the width of the SVG itself */
      width: 100%; /* This will scale the SVG to fit the container */
      fill: white;
      width: 100%;
    }
  }
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const SizeContainer = styled.div`
  display: flex;
`;
