import React, { createContext, useState, useContext } from "react";

export enum TimeRangeOptions {
  Today = "Today",
  LastWeek = "Last 7 Days",
  LastMonth = "Last 30 Days",
  LastYear = "Last Year"
}

export interface TimeRange {
  startDate: string;
  endDate: string;
  defaultTimeRange: TimeRangeOptions;
}

interface DashboardContextType {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export const DashboardContext = createContext<DashboardContextType>({
  search: "",
  setSearch: () => ""
});

interface DashboardProviderProps {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children
}) => {
  const [search, setSearch] = useState<string>("");

  return (
    <DashboardContext.Provider value={{ search, setSearch }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
};
