import infoIcon from "@/assets/icons/info-icon.png";
import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC, useState } from "react";
import * as Styled from "./style";

type Props = {
  title?: string;
  label: string;
  location: string;
};

const IconInsight: FC<Props> = ({ title, label, location }) => {
  const { t } = useCustomTranslation("");
  const [hovered, setHovered] = useState(false);

  return (
    <Styled.Container
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-testid="icon-insight-container"
    >
      <Styled.Image
        alt={t("COMPONENTS.ICON_INSIGHT.ALT")}
        src={infoIcon as string}
      />
      {hovered && (
        <Styled.IconInsightWrapper location={location}>
          {title && <Styled.Title>{title}</Styled.Title>}
          <Styled.Paragraph>{label}</Styled.Paragraph>
          <Styled.Arrow location={location}>
            {location.includes("bottom") ? <>&#9650;</> : <>&#9660;</>}
          </Styled.Arrow>
        </Styled.IconInsightWrapper>
      )}
    </Styled.Container>
  );
};

export default IconInsight;
