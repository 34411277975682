import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 20px;
  min-height: 0;
`;

export const Title = styled.h3`
  text-align: center;
`;

export const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const Error = styled.p`
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.artyClickRed};
`;

export const NoData = styled.p`
  text-align: center;
  margin-top: 10px;
`;
