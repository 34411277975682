import { MapElement } from "@/components/ReportLayout/widgets/MapView/utils/types";
import {
  DateFilters,
  FilterAttributes,
  MapFilters,
  Status
} from "@/Providers/FilterProvider";
import {
  DmaItem,
  ProjectStatusReportType,
  ProjectStatusRequestBody
} from "@/types/report.type";
import moment from "moment";

export const filterEnumMapping: { [key: string]: number } = {
  WAYPOINTS: 1,
  DMAS: 2,
  PIPES: 3,
  ASSETS: 4,
  IN_PROGRESS_SESSION: 5,
  AWAITING_UPLOAD_SESSION: 6,
  LARGE_LEAK_SESSION: 7,
  MEDIUM_LEAK_SESSION: 8,
  SMALL_LEAK_SESSION: 9,
  NON_LEAK_SESSION: 10,
  ODL: 12
};

export const getStatusNames = (status: Status[]): string[] => {
  return status.filter((item) => item.selected).map((item) => item.name);
};

export interface SizeSelection {
  [key: string]: boolean;
}

export const getSelectedSizes = (sizes: SizeSelection): string[] => {
  return Object.entries(sizes)
    .filter(([_key, value]) => value)
    .map(([key]) => key);
};

export const requestBody = (
  mapFilters: MapFilters,
  dateFilters: DateFilters,
  filterAttributes: FilterAttributes
): ProjectStatusRequestBody => {
  const mappedFilters = mapFilters?.filters?.map(
    (filter: string) => filterEnumMapping[filter]
  );

  const options =
    mappedFilters?.length > 0 ? mappedFilters : [filterEnumMapping.DMAS];

  const dateFrom = moment(dateFilters.dateFrom).format("YYYY-MM-DD");
  const dateTo = moment(dateFilters.dateTo).format("YYYY-MM-DD");

  const status = getStatusNames(filterAttributes.status);

  const predicted_size = getSelectedSizes(filterAttributes.predicted_size);

  return {
    options: options.filter((option) => typeof option === "number"),
    filters: {
      status,
      predicted_size,
      dates: [dateFrom, dateTo]
    }
  };
};

export const concatenateMapData = (
  data: ProjectStatusReportType
): MapElement[] => [
  ...(data.map.waypointsinfo?.data || []),
  ...(data.map.dmas?.data || []),
  ...(data.map.pipes?.data || []),
  ...(data.map.assets?.data || []),
  ...(data.map.sweep_in_progress_session?.data || []),
  ...(data.map.sweep_awaiting_upload_session?.data || []),
  ...(data.map.sweep_large_leak_session?.data || []),
  ...(data.map.sweep_medium_leak_session?.data || []),
  ...(data.map.sweep_small_leak_session?.data || []),
  ...(data.map.sweep_non_leak_session?.data || []),
  ...(data.map.bugdeploymentview?.data || [])
];

/**
 * Sorts the DMAs in the project status report alphabetically by their name.
 *
 * @param {ProjectStatusReportType | undefined} data - The project status report data containing the DMAs to be sorted.
 * @returns {DmaItem[] | undefined} A sorted array of DMAs, or undefined if the input data is undefined.
 */
export const alphabeticallySortDmas = (
  data: ProjectStatusReportType | undefined
): DmaItem[] | undefined =>
  data?.dmas?.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
