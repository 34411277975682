import React, { FC, useEffect } from "react";
import * as Styled from "./style";
import { Button, Modal } from "@/components";
import { usePostDeleteGISUploadMutation } from "@/redux/GISAdmin/GISAdmin.api";
import useCustomTranslation from "@/localization/useCustomTranslation";

export type DeleteModalProps = {
  open: boolean;
  uploadId: number;
  closeModal: (refetch: boolean) => void | Promise<void>;
};

const DeleteModal: FC<DeleteModalProps> = ({ open, uploadId, closeModal }) => {
  const { prefixedT } = useCustomTranslation("GIS_ADMIN.DELETE_MODAL");
  const [mutate, { isSuccess, isError }] = usePostDeleteGISUploadMutation();
  const onConfirm = async () => {
    await mutate({ body: { upload_id: uploadId } });
  };

  useEffect(() => {
    if (isSuccess) {
      void closeModal(true);
    }
  }, [isSuccess]);

  const onCancel = async () => {
    await closeModal(false);
  };

  return (
    <Modal isOpen={open}>
      <Styled.Container data-testid="delete-modal">
        <Styled.Title>{prefixedT("TITLE")}</Styled.Title>
        {isError && <Styled.Error>{prefixedT("ERROR_MSG")}</Styled.Error>}
        <Styled.Actions>
          <Button
            onClick={() => {
              void onConfirm();
            }}
            width="130px"
            height="40px"
          >
            {prefixedT("BTN.REMOVE")}
          </Button>
          <Button
            onClick={() => {
              void onCancel();
            }}
            width="130px"
            height="40px"
          >
            {prefixedT("BTN.CANCEL")}
          </Button>
        </Styled.Actions>
      </Styled.Container>
    </Modal>
  );
};

export default DeleteModal;
