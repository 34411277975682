import React, { FC } from "react";

import * as Sentry from "@sentry/react";
import { Table } from "@/components";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";
import useCustomTranslation from "@/localization/useCustomTranslation";

import {
  GetViewRelaysDistinctResponse,
  RelaysData
} from "@/types/portalUsers.type";
import moment from "moment";
import { DropDownOption } from "@/components/DropDown/DropDown";

export const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];

export type RelayDataKey = keyof RelaysData;

export type HeaderShowItem = {
  name: string;
  show: boolean;
};

export const headerNames: RelayDataKey[] = [
  "relay_status",
  "relay_uptime",
  "relay_id",
  "bug_id",
  "session_id",
  "last_contact",
  "last_update",
  "dma_name",
  "company_name",
  "partner_name",
  "project_name"
];

export const containsInvalidHeader = (items: HeaderShowItem[]) => {
  const validNames = new Set(headerNames);

  return items.some((item) => !validNames.has(item.name as RelayDataKey));
};

export const headers = (
  columnOrder: { name: string; show: boolean }[]
): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("LIST_VIEW.RELAYS.HEADERS");

  const currentColumns = headerNames.map((header) => ({
    id: header,
    title: prefixedT(header),
    sortable: false,
    align: TableCellAlignment.center
  }));

  try {
    const result = columnOrder
      .filter((column) => column.show)
      .map((column) => {
        const matchedColumn = currentColumns.find(
          (col) => col.id === column.name
        );
        if (!matchedColumn) {
          throw new Error(`Column not found`);
        }
        return matchedColumn;
      });

    return result;
  } catch (error) {
    // Something went wrong, reset the columns
    localStorage.removeItem("listView.Waypoints");
    return currentColumns;
  }
};

export const RowStructure: FC<{
  data: RelaysData;
  index: number;
  columnOrder: { name: string; show: boolean }[];
}> = ({ data, index, columnOrder }) => {
  const currentRowOrder: { id: keyof RelaysData; child: React.ReactNode }[] = [
    {
      id: "relay_status",
      child: data.relay_status
    },
    {
      id: "relay_uptime",
      child: data.relay_uptime
    },
    {
      id: "relay_id",
      child: data.relay_id
    },
    { id: "bug_id", child: data.bug_id },
    {
      id: "session_id",
      child: (
        <a href={`/app/session?session=${data.session_id}`} target="_blank">
          {data.session_id}
        </a>
      )
    },
    {
      id: "last_contact",
      child:
        data.last_contact !== null &&
        moment(data.last_contact).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      id: "last_update",
      child:
        data.last_update !== null &&
        moment(data.last_update).format("YYYY-MM-DD HH:mm:ss")
    },
    { id: "dma_name", child: data.dma_name },
    { id: "company_name", child: data.company_name },
    { id: "partner_name", child: data.partner_name },
    { id: "project_name", child: data.project_name }
  ];

  const result = columnOrder
    .filter((column) => column.show)
    .map((column) => {
      const matchedColumn = currentRowOrder.find(
        (col) => col.id === column.name
      );
      if (!matchedColumn) {
        const err = `Column not found ${column.name}`;
        // console.log("[table] relay: ", { err });
        Sentry.captureException(err);
        throw new Error(err);
      }
      return matchedColumn;
    });

  return (
    <Table.Row key={index}>
      {result.map((row, idx) => (
        <Table.Cell
          key={idx}
          align={TableCellAlignment.center}
          headerId={row.id}
        >
          {row.child}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const filterColumns: RelayDataKey[] = [
  "company_name",
  "partner_name",
  "project_name",
  "relay_status",
  "relay_uptime"
];

export const sortColumns: RelayDataKey[] = [
  "relay_status",
  "relay_uptime",
  "relay_id",
  "bug_id",
  "session_id",
  "last_contact",
  "last_update",
  "dma_name",
  "company_name",
  "partner_name",
  "project_name"
];

export const isFilterColumn = (column: RelayDataKey): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: RelayDataKey): boolean => {
  return sortColumns.includes(column);
};

const ColumnFilterMap = {
  company_name: "company",
  partner_name: "partner",
  project_name: "project",
  relay_status: "relay_status",
  relay_uptime: "relay_uptime"
};

export const mapFilterName = (column: RelayDataKey) => {
  return ColumnFilterMap[column];
};

/**
 * Formats the response from the API to be used in the filter dropdowns
 */
export const processDistinctValues = (
  distinct: GetViewRelaysDistinctResponse | undefined
) => {
  return {
    company:
      distinct?.company
        .map((currentCompany) => {
          if (currentCompany.id === null || currentCompany.name === null) {
            return { label: "No Company", value: "null" };
          }
          return {
            label: currentCompany.name,
            value: `${currentCompany.id}`
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    partner:
      distinct?.partner
        .map((currentPartner) => {
          if (currentPartner.id === null || currentPartner.name === null) {
            return { label: "No Partner", value: "null" };
          }
          return {
            label: currentPartner.name,
            value: `${currentPartner.id}`
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    project:
      distinct?.project
        .map((currentProject) => {
          if (currentProject.id === null || currentProject.name === null) {
            return { label: "No Project", value: "null" };
          }
          return {
            label: currentProject.name,
            value: `${currentProject.id}`
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    relay_status: distinct?.relay_status.map((status) => ({
      label: status.name,
      value: status.name
    })),
    relay_uptime: distinct?.relay_uptime.map((status) => ({
      label: status.name,
      value: status.name
    }))
  };
};
