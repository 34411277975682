import Button from "@/components/Button/Button";
import Dialog from "@/components/Dialog/Dialog";
import DropDown, { DropDownOption } from "@/components/DropDown/DropDown";
import { Modal } from "@/components/Modal";
import useCustomTranslation from "@/localization/useCustomTranslation";
import theme from "@/theme";
import {
  ReportDataSource,
  ReportSummarySettings,
  ReportWidget
} from "@/types/report.type";
import { Field, Form, useFormikContext } from "formik";
import React, { FC, useRef, useState } from "react";
import { v4 as guid } from "uuid";
import { getSubTitle, getTitle, InitialSummaryValues } from "./service";
import * as Styled from "./style";
import SummarySettingsItem from "./SummarySettingsItem";

type Props = {
  open: boolean;
  sources: ReportDataSource[] | undefined;
  onClose: () => void;
  onUpdateWidget: (
    widget: ReportWidget<ReportSummarySettings>,
    layoutId: string | number
  ) => void;
  widget: ReportWidget<ReportSummarySettings>;
  layoutId: string | number;
};

const emptyFilter = {
  columnId: null,
  typeId: null,
  title: ""
};

const style = {
  color: {
    confirm: theme.colors.coral,
    cancel: theme.colors.blue
  }
};

const SummarySettings: FC<Props> = ({ open, sources = [], onClose }) => {
  const [dialogState, setDialogState] = useState<null | string>(null);
  const tempSource = useRef("");
  const contentRef = useRef(null);
  const { t } = useCustomTranslation("");

  const { setFieldValue, values, dirty, resetForm, errors, submitForm } =
    useFormikContext<InitialSummaryValues>();

  const onAddFilter = () => {
    setFieldValue("options", [
      ...values.options,
      { ...emptyFilter, id: guid() }
    ]);
  };
  const applySourceChange = (option: string) => {
    setFieldValue("source", option);
    if (dialogState) {
      setFieldValue("options", []);
      setDialogState(null);
    }
  };
  const onChangeSource = (option: DropDownOption) => {
    if (!values.options.length) {
      applySourceChange(option.value);
    } else {
      tempSource.current = option.value;
      setDialogState("change");
    }
  };
  const onModalClose = () => {
    if (dirty) {
      setDialogState("close");
    } else {
      onClose();
    }
  };
  const onConfirm = () => {
    if (dialogState === "change") {
      applySourceChange(tempSource.current);
    } else {
      setDialogState(null);
      resetForm();
      onClose();
    }
  };
  const isOverflow = values.options.length > 1;

  return (
    <Form>
      <Modal
        styles={{ maxWidth: "600px" }}
        title={t("SUMMARY.MODAL_TITLE")}
        isOpen={open}
        onClose={onModalClose}
      >
        <Styled.Container>
          <Dialog
            open={!!dialogState}
            title={getTitle(String(dialogState))}
            subtitle={getSubTitle(String(dialogState))}
            onConfirm={onConfirm}
            onCancel={() => setDialogState(null)}
            style={style}
          />
          <Styled.DataSourceContainer>
            <Field
              as={DropDown}
              options={sources.map((item) => ({
                value: item.id,
                label: item.name
              }))}
              label={t("SUMMARY.FORM.SOURCE.LABEL")}
              placeholder={t("SUMMARY.FORM.SOURCE.PLACEHOLDER")}
              name="source"
              onChange={onChangeSource}
              error={errors.source && t(errors.source)}
              value={values.source}
            />
            <Button
              onClick={onAddFilter}
              width="48px"
              height="44px"
              disabled={!values.source}
            >
              +
            </Button>
          </Styled.DataSourceContainer>
          <Styled.OptionsContainer ref={contentRef} isOverflow={isOverflow}>
            {values.options.map((filter, index) => (
              <SummarySettingsItem
                key={filter.id}
                index={index}
                filter={filter}
                source={sources.find((el) => el.id === values.source)}
                blockRef={contentRef}
              />
            ))}
          </Styled.OptionsContainer>
          <Styled.ButtonContainer>
            <Button
              disabled={!dirty}
              width="120px"
              height="34px"
              type="submit"
              onClick={submitForm}
            >
              {t("REPORT_BUILDER.APPLY")}
            </Button>
          </Styled.ButtonContainer>
        </Styled.Container>
      </Modal>
    </Form>
  );
};

export default SummarySettings;
