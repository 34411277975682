import { z } from "zod";

// Schema
export const formSchemaProject = z.object({
  projectId: z.number().optional(),
  gisUploadId: z.number().optional(),
  gisUploadCompanyId: z.number().optional(),
  name: z
    .string()
    .min(2, {
      message: "Project name must be at least 2 characters long."
    })
    .max(250, {
      message: "Project name must not be longer than 250 characters."
    }),
  companyId: z.number().optional(),
  costPerDistance: z.string().optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  dmaIds: z.array(
    z.object({
      label: z.string(),
      value: z.string()
    })
  ),
  targetDistance: z.number().or(z.string()).optional(),
  targetLeakage: z.number().or(z.string()).optional(),
  levelODL: z.number().min(1).max(3)
});

export const ProjectFormStep = {
  UPLOAD_GIS: 0,
  PROJECT_DATA: 1,
  PROJECT_ODL: 2
};

export const ProjectSteps = Object.values(ProjectFormStep);

export type ProjectFormValues = z.infer<typeof formSchemaProject>;
