import { ReportResultsSettings } from "@/types/report.type";
import { t } from "i18next";
import * as Yup from "yup";

export const getTitle = (dialogState: string) => {
  if (dialogState === "change") {
    return t("TABLE_OF_RESULTS.DIALOG.TITLE.CHANGE");
  }
  return t("TABLE_OF_RESULTS.DIALOG.TITLE.CLOSE");
};

export const getSubTitle = (dialogState: string) => {
  if (dialogState === "change") {
    return t("TABLE_OF_RESULTS.DIALOG.SUBTITLE.CHANGE");
  }
  return t("TABLE_OF_RESULTS.DIALOG.SUBTITLE.CLOSE");
};

export const validateTableOfResultsConfig = Yup.object({
  columns: Yup.array().of(Yup.number()).min(1, "VALIDATION.REQUIRED"),
  source: Yup.number().required("VALIDATION.REQUIRED")
});

export const prepareInitialValues = (
  values: ReportResultsSettings | undefined
) => ({
  source: values?.datasource || null,
  columns: values?.columns || []
});
