import moment from "moment";

export const getFormattedDate = (
  dateUploaded: string,
  format: string = "YYYY-MM-DD"
) => {
  return moment(dateUploaded, moment.ISO_8601, true).isValid()
    ? moment(dateUploaded).format(format)
    : "";
};
