import { ContentLoader } from "@/components";
import { ITablePaginate, TableSortOrders } from "@/components/Table/types";
import useModalStatus from "@/hooks/useModalStatus";
import {
  useCreateReportMutation,
  useGetReportLayoutMutation,
  useLazyGetReportLayoutsQuery,
  useGetReportsQuery
} from "@/redux/reports/reports.api";
import { CreateReportValues, Report } from "@/types/report.type";
import { IPaginationResponse } from "@/types/rtk.type";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Reports from "./Reports";

const initialValues: CreateReportValues = {
  name: "",
  layoutId: null,
  redirectToEdit: true
};

const ReportsContainer = () => {
  const navigate = useNavigate();
  const createModal = useModalStatus();

  const [searchParams, setSearchParams] = useSearchParams({});
  const pageParam = searchParams.get("page");
  const perPageParam = searchParams.get("perPage");

  const [page, setPage] = useState<number>(Number(pageParam) || 1);
  const [perPage] = useState<number>(Number(perPageParam) || 10);
  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.asc);
  const [orderBy, setOrderBy] = useState<string>("id");

  const reportsQuery = useGetReportsQuery(
    { page, perPage, sortedBy: orderBy, sortedOrder: order },
    { refetchOnMountOrArgChange: true }
  );
  const reports = reportsQuery.data as IPaginationResponse<Report>;
  const [createReport, { isLoading: isCreateReportLoading }] =
    useCreateReportMutation();
  const [reportLayoutMutate] = useGetReportLayoutMutation();
  const [fetchLayouts, { data: layouts, isLoading: isLayoutsLoading }] =
    useLazyGetReportLayoutsQuery();

  useEffect(() => {
    setSearchParams({ page: String(page) });
  }, [page, setSearchParams]);

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const onCreateReport = async ({
    redirectToEdit,
    ...data
  }: CreateReportValues) => {
    let layout;
    if (data.layoutId) {
      layout = await reportLayoutMutate({ id: data.layoutId }).unwrap();
    }
    const report = await createReport({
      layout_id: data.layoutId,
      structure: layout ? layout.structure : {},
      name: data.name
    }).unwrap();
    createModal.hide();
    if (redirectToEdit && report) {
      navigate(`/app/reports/${report.id}`);
    }
  };

  const onOpenModal = async () => {
    if (!layouts) {
      await fetchLayouts().unwrap();
    }
    createModal.show();
  };

  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  return (
    <>
      <ContentLoader
        active={
          (reportsQuery.data && reportsQuery.isFetching) ||
          isLayoutsLoading ||
          isCreateReportLoading
        }
      />
      <Reports
        reports={reports?.data || []}
        totalCount={reports?.count}
        page={page}
        pages={Math.ceil((reports?.count || 1) / perPage)}
        onPageChange={onPageChange}
        onOpenModal={onOpenModal}
        onCreateReport={onCreateReport}
        isCreateModalOpen={createModal.isOpen}
        onClose={createModal.hide}
        initialValues={initialValues}
        layouts={layouts || []}
        loading={reportsQuery.isLoading}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </>
  );
};

export default ReportsContainer;
