import { Tabs } from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { UserRole } from "@/utils/user";
import { Form, Formik } from "formik";
import React, { FC, useMemo, useState } from "react";
import * as Styled from "./style";
import { ChangePassword, AccountSettings } from "./tabs";
import { FormUserValues } from "@/redux/portalUsers/portalUsers.api";
import { useEditUserMutation } from "@/redux/user/user.api";
import useNotification from "@/hooks/useNotification";
import { useInitialValuesUser } from "@/hooks/form/useInitialValuesUser";
import { QueryStatus } from "@reduxjs/toolkit/query";

// Types
import { Tab } from "@/types/profile.type";
import { IUser, UserRoles } from "@/types/user.type";

// Utils
import { ValidationSchemaFormUser } from "@/components/Form/schema";

type ProfileProps = {
  user: IUser;
};

const Profile: FC<ProfileProps> = ({ user }) => {
  const { t, prefixedT } = useCustomTranslation("PROFILE_PAGE");
  const [selectedTab, setSelectedTab] = useState(1);

  // console.log('[profile][PROFILE] user: ', user);

  const initialValues = useInitialValuesUser({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    metric_system: user?.metric_system,
    company_id: user?.company_id,
    role_id: user?.role_id,

    phone: user?.phone,
    currency: user?.currency,
    country_code: user?.country_code,
    units: user?.units,
    partner_id: user?.partner_id
  });

  const [editUser, { status }] = useEditUserMutation();
  const { showNotification } = useNotification();

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: 1,
        title: prefixedT("TABS.ACCOUNT_SETTINGS"),
        component: AccountSettings,
        disabled: false
      },
      { id: 2, title: prefixedT("TABS.COMPANY_SETTINGS"), disabled: true },
      { id: 3, title: prefixedT("TABS.DOCUMENTS"), disabled: true },
      {
        id: 4,
        title: prefixedT("TABS.CHANGE_PASSWORD"),
        component: ChangePassword,
        disabled: false
      }
    ],
    [prefixedT]
  );

  const handleTabClick = (id: number) => {
    setSelectedTab(id);
  };

  const handleSubmitEditUser = async (values: FormUserValues) => {
    // console.log('[form][FORM] submit start: ', { status, values });
    if (status === QueryStatus.pending) {
      return;
    }

    const data = {
      ...values
    };

    delete data.email;

    if (user?.id) {
      try {
        // console.log('[form][FORM] submit: ', { data });
        await editUser({ id: user?.id, body: data }).unwrap();
        await showNotification({
          type: "success",
          message: "User updated successfully"
        });
      } catch (error) {
        console.error(error);
        await showNotification({
          type: "error",
          message: "Failed to update user"
        });
      }
    }
    // setEditable(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitEditUser}
      validationSchema={ValidationSchemaFormUser}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form style={{ padding: "40px" }}>
        <Styled.Header>
          <Styled.Wrapper data-testid="profilePage">
            <Styled.Title>{prefixedT("TITLE")}</Styled.Title>
          </Styled.Wrapper>
          {/* <>
              {isEditable ? (
                getControls(formProps, onDiscard, prefixedT)
              ) : (
                <Button
                  type="button"
                  variant={ButtonVariant.default}
                  width="122px"
                  height="34px"
                  testId="edit"
                  onClick={onEditActive}
                >
                  {prefixedT('EDIT')}
                </Button>
              )}
            </> */}
        </Styled.Header>
        <Styled.ProfileWrapper>
          <Styled.Info>
            <Styled.AvatarContainer>
              <Styled.AvatarIcon />
              {user?.role?.role_code === UserRoles.SUPER_ADMIN ? (
                <Styled.SuperIcon data-testid="super-icon" />
              ) : null}
              <Styled.CameraIcon />
            </Styled.AvatarContainer>
            <Styled.ProfileNameContainer>
              <Styled.ProfileName>
                {initialValues.first_name} {initialValues.last_name}
              </Styled.ProfileName>
            </Styled.ProfileNameContainer>
            {user && (
              <Styled.ProfileRole>
                {user.role && t(UserRole[user.role.role_code])}
              </Styled.ProfileRole>
            )}
            <Styled.Separator />
          </Styled.Info>
          <Styled.MobileTabsContainer>
            <Styled.MobileTabTitle>
              {prefixedT("TABS.ACCOUNT_SETTINGS")}
            </Styled.MobileTabTitle>
            <Styled.MobileOptionsWrapper>
              <AccountSettings />
            </Styled.MobileOptionsWrapper>
          </Styled.MobileTabsContainer>
          <Styled.Details />
          <Styled.Options>
            <Tabs
              tabs={tabs}
              selected={selectedTab}
              onChange={handleTabClick}
            />
          </Styled.Options>
        </Styled.ProfileWrapper>
      </Form>
    </Formik>
  );
};

export default Profile;
