import { RootState } from "@/redux";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import config from "../config";

import pkg from "../../package.json";

const setBaseHeaders = (headers: Headers) => {
  headers.set("X-SOURCE", "cms");
  headers.set("X-Portal-Version", pkg.version);
  return headers;
};

export const gisApiBaseQuery = fetchBaseQuery({
  baseUrl: config.GIS_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    const azureToken = (getState() as RootState).auth.azureToken;

    setBaseHeaders(headers);
    if (token) {
      headers.set("user-token", `${token}`);
    }
    if (azureToken) {
      headers.set("Authorization", `Bearer ${azureToken}`);
    }

    return headers;
  }
});

export const apiBaseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    setBaseHeaders(headers);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  }
});

export const authBaseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  credentials: "include",
  prepareHeaders(headers) {
    setBaseHeaders(headers);
    return headers;
  }
});

export const aiAssistantBaseQuery = fetchBaseQuery({
  baseUrl: config.ASK_FIDO_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    setBaseHeaders(headers);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  }
});

export const genericFetchQuery = fetchBaseQuery();
