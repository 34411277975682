import styled from "styled-components";

export const List = styled.ul`
  /* list-style-type: none;
  font-weight: bold;
  line-height: 35px; */

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const ListObject = styled.span`
  font-weight: normal;
`;

export const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const OptionList = styled.div<{
  edit: boolean;
}>`
  gap: 10px;
  align-items: ${({ edit }) => (edit ? "center" : "baseline")};
  justify-content: ${({ edit }) => (edit ? "space-between" : "unset")};
`;

export const DropDownContainer = styled.div`
  margin-bottom: 10px;
`;

export const Option = styled.div`
  width: 100%;
`;
