import styled from "styled-components";

export const SessionContainer = styled.div`
  min-height: 150px;
  font-size: 1rem;
  text-align: left;
`;

export const ParagraphContainer = styled.div`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
