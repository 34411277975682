import { useState } from "react";

// // Types
// import { NonEmptyArray } from "@/types/util.type";

const FIRST_STEP = 0;

type OptionProp = {
  initialStep: number;
};

export const useSteps = (steps: number[], options?: OptionProp) => {
  const [current, setCurrent] = useState(options?.initialStep || FIRST_STEP);
  const size = steps.length;

  const next = () => {
    const nextStep = current + 1;

    if (nextStep <= size - 1) {
      setCurrent(nextStep);
    }
  };

  const prev = () => {
    const prevStep = current - 1;
    if (prevStep >= FIRST_STEP) {
      setCurrent(prevStep);
    }
  };

  const jump = (step: number) => {
    if (step >= FIRST_STEP && step <= size) {
      setCurrent(step);
    }
  };

  const isLast = current === size - 1;
  const isFirst = current === FIRST_STEP;
  const hasPrev = current > FIRST_STEP;
  const hasNext = current < size;
  const progress = Number(((current + 1) / size).toFixed(2));

  return {
    current,
    isLast,
    isFirst,
    hasPrev,
    total: size,
    progress,
    next,
    prev,
    jump,
    hasNext
  };
};
