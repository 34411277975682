import React, { FC, forwardRef } from "react";

// Components
import PhoneInputWithoutCountrySelect, {
  Props as ReactPhoneNumberInputProps
} from "react-phone-number-input/input";

import { TextFieldPlain, TextFieldProps } from "../TextField/TextField";

// Types
import type { DefaultInputComponentProps } from "react-phone-number-input";

// Styles
import "react-phone-number-input/style.css";

type InputPhoneProps = {
  onChange: (value?: string | undefined) => void;
  value: string;
} & ReactPhoneNumberInputProps<DefaultInputComponentProps> &
  React.InputHTMLAttributes<HTMLInputElement> &
  TextFieldProps;

const CustomInput = forwardRef<HTMLInputElement, InputPhoneProps>(
  (props, ref) => {
    return <TextFieldPlain ref={ref} styles={{ noPadding: true }} {...props} />;
  }
);

// TODO: add select as custom component as well (try to use DropDown maybe) for matching style
const InputPhone: FC<InputPhoneProps> = ({
  value,
  onChange,
  defaultCountry = "GB",
  ...props
}) => (
  <PhoneInputWithoutCountrySelect
    inputComponent={
      CustomInput as unknown as (
        props: DefaultInputComponentProps
      ) => JSX.Element
    }
    value={value}
    onChange={(value) => {
      // console.log('[input] onchange:', { value });
      onChange(value || "");
    }}
    defaultCountry={defaultCountry}
    {...props}
  />
);
export default React.memo(InputPhone);
