import { DropDown } from "@/components";
import * as Styled from "./style";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { updateSelectedValues } from "@/containers/GISUpload/Table/utils";
import {
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import React, { FC, useEffect } from "react";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { addOrReplaceError } from "@/containers/GISUpload/utils";
import { deepCopy } from "@/utils/common";
import { ErrorModalText, FileSelectedValues } from "@/types/gisUpload.type";
import {
  Badge,
  cn,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@fido/ui-lib";
import { Trans } from "react-i18next";

interface MapUnitsProps {
  file: FileSelectedValues;
  sampleData: unknown[];
}

export const MapUnits: FC<MapUnitsProps> = ({ file, sampleData }) => {
  const { prefixedT } = useCustomTranslation("MAP_UNITS");
  const { currentStep, selectedValues, errors, mappedSystemFields } =
    useGISUploadValues();
  const { setSelectedValues, setErrors, setMappedSystemFields } =
    useGISUploadMethods();

  const [value, setValue] = React.useState("");
  const [columnValue, setColumnValue] = React.useState<DropDownOption>();
  const typedSampleData = sampleData as Record<string, any>[];

  const filename = file.file;

  useEffect(() => {
    if (selectedValues) {
      selectedValues.map((values) => {
        if (
          values.file === filename &&
          !Array.isArray(values.units) &&
          values.units
        ) {
          if (!value) {
            setValue(values.units.toString());
          }
          // setMappedSystemFields((prevValues) => ({
          //   ...prevValues,
          //   units: true,
          // }));
          setMappedSystemFields({
            ...mappedSystemFields,
            units: true
          });
        }

        if (
          values.file === filename &&
          Array.isArray(values.units) &&
          values.units.length > 0
        ) {
          if (!columnValue) {
            const unitValues = values.data?.filter(
              (item) => item.title === "units"
            )[0];
            if (unitValues) {
              setColumnValue({
                label: unitValues.name,
                value: unitValues.value
              });
            }
          }
        }

        if (
          values.file === filename &&
          values.data.filter((item) => item.title === "units").length === 0
        ) {
          setColumnValue(undefined);
        }
      });
    }
  }, [selectedValues]);

  const dropdownOptions: DropDownOption[] = [
    { label: "inch", value: "1" },
    { label: "mm", value: "2" }
  ];

  const handleColumnChange = (option: DropDownOption | DropDownOption[]) => {
    // const option = dropdownOptionsColumn.find((o) => o.value === v);
    if (Array.isArray(option)) return;
    setValue("");
    setColumnValue(option);

    const value: DropDownOption = {
      value: option.value,
      label: "units"
    };

    // setSelectedValues((prevValues) =>
    //   updateSelectedValues(
    //     prevValues,
    //     filename,
    //     option.label,
    //     value,
    //     currentStep,
    //     false,
    //   ),
    // );

    setSelectedValues(
      updateSelectedValues(
        selectedValues,
        filename,
        option.label,
        value,
        currentStep,
        false
      )
    );
  };

  const handleChange = (option: DropDownOption | DropDownOption[]) => {
    // const option = dropdownOptions.find((o) => o.value === v);

    if (Array.isArray(option)) return;
    setColumnValue(undefined);
    setValue(option.value);

    // setSelectedValues((prevValues) =>
    //   prevValues.map((values) =>
    //     values.file === filename
    //       ? {
    //           ...values,
    //           units: Number(option.value),
    //           data: values.data.filter((item) => item.title !== 'units'),
    //         }
    //       : values,
    //   ),
    // );

    setSelectedValues(
      selectedValues.map((values) =>
        values.file === filename
          ? {
              ...values,
              units: Number(option.value),
              data: values.data.filter((item) => item.title !== "units")
            }
          : values
      )
    );

    let newErrors = deepCopy(errors);

    if (errors?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC)) {
      // setErrors(
      //   addOrReplaceError(errors, ErrorModalText.SYSTEM_FIELD_NUMERIC, true),
      // );
      newErrors = addOrReplaceError(
        errors,
        ErrorModalText.SYSTEM_FIELD_NUMERIC,
        true
      );
    }
    if (errors?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)) {
      // setErrors(
      //   addOrReplaceError(
      //     errors,
      //     ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
      //     true,
      //   ),
      // );
      newErrors = addOrReplaceError(
        errors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
        true
      );
    }

    setErrors(newErrors);
  };

  const dropdownOptionsColumn: DropDownOption[] = Object.entries(
    typedSampleData[0] || {}
  ).map(([key, value]) => ({
    label: key,
    value: value
  }));

  const displayError =
    !!errors?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED) &&
    !value &&
    !columnValue;

  return (
    <div className="space-y-2 w-full">
      <div className="flex items-center justify-start">
        <p
          className={cn("text-nowrap", {
            "text-destructive": displayError
          })}
        >
          {prefixedT("TITLE.COLUMN")}
        </p>
        {/* <Select
          value={columnValue?.label || ""}
          onValueChange={(value) => {
            handleColumnChange(value);
          }}
        >
          <SelectTrigger className="border-none h-8 w-auto mx-auto">
            <SelectValue placeholder={"Select Column"} />
          </SelectTrigger>
          <SelectContent align="center">
            {dropdownOptionsColumn.map((o) => (
              <SelectItem key={o.value} value={o.value}>
                {o.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select> */}
        <div>
          <DropDown
            options={dropdownOptionsColumn}
            label={undefined}
            onChange={(option) => handleColumnChange(option as DropDownOption)}
            value={columnValue?.label || ""}
            styles={{ center: true }}
            placeholder={columnValue?.label || "Select Column"}
            testId="column"
          />
        </div>
      </div>
      <div className="flex items-center justify-start">
        <p
          className={cn("text-nowrap", {
            "text-destructive": displayError
          })}
        >
          {prefixedT("TITLE.UNITS")}
        </p>
        {/* <Select
          value={value}
          onValueChange={(value) => {
            handleChange(value);
          }}
        >
          <SelectTrigger className="border-none h-8 w-auto mx-auto">
            <SelectValue placeholder={"Units"} />
          </SelectTrigger>
          <SelectContent align="center">
            {dropdownOptions.map((o) => (
              <SelectItem key={o.value} value={o.value}>
                {o.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select> */}

        <div>
          <DropDown
            options={dropdownOptions}
            label={undefined}
            placeholder={"Units"}
            onChange={(option) => handleChange(option)}
            value={value}
            styles={{ center: true }}
            testId="units"
          />
        </div>
      </div>
    </div>
  );
};
