import { useField, FieldHookConfig } from "formik";
import React, { FC } from "react";
import * as Styled from "./checkboxWithIconStyle";

type Props = {
  label: string;
  image: string;
  formName: string;
  mapContainerWidth?: number | undefined;
  value?: number;
  singleSelection?: boolean;
};

const CheckboxWithIcon: FC<Props> = ({
  formName,
  label,
  image,
  mapContainerWidth,
  singleSelection
}) => {
  const [field] = useField<FieldHookConfig<string[]>>({
    name: formName,
    type: "checkbox"
  });

  const formatString = (str: string) =>
    str.replace(/-/g, "_").replace(/\s+/g, "_").toUpperCase();

  const handleCheckboxChange = () => {
    if (singleSelection) {
      field.onChange({
        target: {
          name: formName,
          value: [formatString(label)] // Set the value to an array with a single item
        }
      });
    } else {
      const isChecked =
        Array.isArray(field.value) &&
        !field.value?.includes(formatString(label));
      field.onChange({
        target: {
          name: formName,
          value: isChecked
            ? Array.isArray(field.value) && [
                ...(field.value || []),
                formatString(label)
              ]
            : Array.isArray(field.value) &&
              (field.value || []).filter(
                (value: string) => formatString(value) !== formatString(label)
              )
        }
      });
    }
  };

  return (
    <Styled.CheckboxGroup>
      <div>
        <label>
          <Styled.CheckboxInput
            type="checkbox"
            checked={
              Array.isArray(field.value) &&
              field?.value?.includes(formatString(label))
            }
            onChange={handleCheckboxChange}
          />
          <Styled.CheckboxTile
            mapContainerWidth={mapContainerWidth ? mapContainerWidth : 800}
          >
            <Styled.CheckboxIcon>
              <Styled.CheckboxImage
                src={image}
                alt={label}
                mapContainerWidth={mapContainerWidth ? mapContainerWidth : 800}
              />
            </Styled.CheckboxIcon>
            <Styled.CheckboxLabel>{label}</Styled.CheckboxLabel>
          </Styled.CheckboxTile>
        </label>
      </div>
    </Styled.CheckboxGroup>
  );
};

export default CheckboxWithIcon;
