import {
  GISRoutes,
  GISUploadData,
  GISUploadState,
  GISUploadValues,
  MappedSystemFields
} from "@/types/gisUpload.type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/*
 * Additional types
 */

export type UploadId = number | null;

export type CommonUploadId = {
  uploadId: UploadId;
};

export type CommonUploadValues = { data?: Partial<GISUploadData> } & Partial<
  Omit<GISUploadValues, "data">
>;

export const defaultSystemFields: MappedSystemFields = {
  material: false,
  asset_type: false,
  pipe_type: false,
  status: false,
  units: false
};

export const DEFAULT_UPLOAD_VALUES: GISUploadValues = {
  isLoading: false,
  uploadStatus: "uninitialized",
  isErrorModalOpen: false,
  errors: [],
  data: {
    selectedValues: [],
    companyId: null,
    fileIndex: 0,
    currentStep: GISRoutes.UPLOAD_SHAPEFILE,
    mappedSystemFields: defaultSystemFields,
    returnToProject: null,
    returnToCompany: null
  }
};

// TODO: GIS - persist data for uploads
const initialState: GISUploadState = {
  gisUpload: {}
};

const gisUploadSlice = createSlice({
  name: "gisUpload",
  initialState,
  reducers: {
    setUploadValues: (
      state,
      action: PayloadAction<CommonUploadId & CommonUploadValues>
    ) => {
      const { uploadId, data, ...gisUploadState } = action.payload;
      if (typeof uploadId !== "number") return state;

      const uploadValues = state.gisUpload[uploadId] ?? {
        ...DEFAULT_UPLOAD_VALUES
      };

      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...uploadValues,
            ...gisUploadState,
            data: {
              ...(uploadValues.data || {}),
              ...(data || {})
            } as GISUploadData
          }
        }
      };
    },
    setErrorModalOpen: (
      state,
      action: PayloadAction<CommonUploadId & { isOpen: boolean }>
    ) => {
      const { uploadId, isOpen } = action.payload;
      if (typeof uploadId !== "number") return state;
      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            isErrorModalOpen: isOpen
          }
        }
      };
    },
    setUploading: (
      state,
      action: PayloadAction<
        CommonUploadId & {
          uploadStatus: GISUploadValues["uploadStatus"];
        }
      >
    ) => {
      const { uploadId, uploadStatus } = action.payload;
      if (typeof uploadId !== "number") return state;
      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            uploadStatus
          }
        }
      };
    },
    setLoading: (
      state,
      action: PayloadAction<CommonUploadId & { isLoading: boolean }>
    ) => {
      const { uploadId, isLoading } = action.payload;
      if (typeof uploadId !== "number") return state;
      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            isLoading
          }
        }
      };
    },
    setErrors: (
      state,
      action: PayloadAction<
        CommonUploadId & {
          errors: GISUploadValues["errors"];
        }
      >
    ) => {
      const { uploadId, errors } = action.payload;
      if (typeof uploadId !== "number") return state;
      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            errors
          }
        }
      };
    },
    setCurrentStep: (
      state,
      action: PayloadAction<
        CommonUploadId & {
          currentStep: GISUploadData["currentStep"];
        }
      >
    ) => {
      const { uploadId, currentStep } = action.payload;
      if (typeof uploadId !== "number") return state;

      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            data: {
              ...state.gisUpload[uploadId].data,
              currentStep
            }
          }
        }
      };
    },

    setMappedSystemFields: (
      state,
      action: PayloadAction<
        CommonUploadId & {
          mappedSystemFields: GISUploadData["mappedSystemFields"];
        }
      >
    ) => {
      const { uploadId, mappedSystemFields } = action.payload;
      if (typeof uploadId !== "number") return state;

      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            data: {
              ...state.gisUpload[uploadId].data,
              mappedSystemFields
            }
          }
        }
      };
    },
    setSelectedValues: (
      state,
      action: PayloadAction<
        CommonUploadId & {
          selectedValues: GISUploadData["selectedValues"];
        }
      >
    ) => {
      const { uploadId, selectedValues } = action.payload;
      if (typeof uploadId !== "number") return state;

      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: {
            ...state.gisUpload[uploadId],
            data: {
              ...state.gisUpload[uploadId].data,
              selectedValues
            }
          }
        }
      };
    },
    resetUpload: (state, action: PayloadAction<CommonUploadId>) => {
      const { uploadId } = action.payload;
      if (typeof uploadId !== "number") return state;
      return {
        ...state,
        gisUpload: {
          ...state.gisUpload,
          [uploadId]: DEFAULT_UPLOAD_VALUES
        }
      };
    }
  }
});

export const {
  setErrorModalOpen,
  setLoading,
  setUploading,
  setUploadValues,
  setErrors,
  setCurrentStep,
  setMappedSystemFields,
  setSelectedValues,
  resetUpload
} = gisUploadSlice.actions;

export default gisUploadSlice.reducer;
