import styled from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #96bfff;
  background: #cbdbf5;
  color: ${({ theme }) => theme.colors.mediumPersianBlue};
  font-size: 12px;
  display: flex;
  align-items: baseline;

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Text = styled.div``;
