import csvFile from "@/assets/icons/csv-logo.png";
import jsonFile from "@/assets/icons/json.png";
import xlsxFile from "@/assets/icons/xlsx-logo.png";
import { Button, CheckboxWithIcon, Loader } from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useGetPostProjectReportMutation } from "@/redux/reports/reports.api";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as Styled from "./style";
import StatusModal from "@/components/StatusModal/StatusModal";

interface CheckboxValue {
  label: string;
  src: string;
}

interface Filters {
  filters: string[];
}

interface PostProjectReportButtonProps {
  projectId: number;
}

const PostProjectReportButton: FC<PostProjectReportButtonProps> = ({
  projectId
}) => {
  const { prefixedT, t } = useCustomTranslation(
    "COMPONENTS.POST_PROJECT_REPORT_BUTTON"
  );
  const [mutate, { isLoading, isSuccess, isError }] =
    useGetPostProjectReportMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setSubmitted(false);
  };

  const checkboxValues: CheckboxValue[] = [
    {
      label: "JSON",
      src: jsonFile as string
    },
    {
      label: "CSV",
      src: csvFile as string
    },
    {
      label: "XLSX",
      src: xlsxFile as string
    }
  ];

  const handleSubmit = (values: Filters) => {
    let file_format = values.filters[0].toLocaleLowerCase();
    if (file_format === "xlsx") {
      file_format = "excel";
    }
    setSubmitted(true);
    mutate({ id: projectId, query: { file_format, include_waypoints: "no" } });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setSuccess(true);
    } else if (isError) {
      setSuccess(false);
    }
  }, [isSuccess, isError]);

  const initValues = { filters: [] };
  return (
    <>
      <Button onClick={handleToggle}>
        <Styled.ReportLinks>
          {t("DASHBOARD_PAGE.SUMMARY.REPORTS.POST_PROJECT")}
        </Styled.ReportLinks>
      </Button>

      {open && (
        <>
          <Styled.Underlay onClick={() => setOpen(false)} />
          <Styled.DialogBox open>
            {!submitted || isLoading ? (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Formik
                    enableReinitialize
                    initialValues={initValues}
                    onSubmit={handleSubmit}
                  >
                    {({ values }) => (
                      <Form>
                        <Styled.OptionTitle>
                          {prefixedT("SELECT_FORMAT")}
                        </Styled.OptionTitle>
                        <Styled.OptionDesc>
                          {prefixedT("POST_PROJECT_DESC")}
                        </Styled.OptionDesc>
                        <Styled.CheckboxContainer>
                          {checkboxValues.map((filter, index: number) => (
                            <CheckboxWithIcon
                              key={index}
                              formName={"filters"}
                              singleSelection={true}
                              label={filter.label}
                              image={filter.src}
                            />
                          ))}
                        </Styled.CheckboxContainer>

                        <Styled.BtnContainer>
                          <Button
                            disabled={values.filters.length < 1}
                            type="submit"
                            width="200px"
                            height="50px"
                          >
                            {t("MAP_VIEW.FILTERS.SUBMIT")}
                          </Button>
                        </Styled.BtnContainer>
                      </Form>
                    )}
                  </Formik>
                )}
              </>
            ) : (
              <>
                <StatusModal
                  isSuccess={success}
                  title={
                    success
                      ? prefixedT("SUCCESS_TITLE")
                      : prefixedT("FAILURE_TITLE")
                  }
                  desc={
                    success
                      ? prefixedT("SUCCESS_DESC")
                      : prefixedT("FAILURE_DESC")
                  }
                  handleClose={handleClose}
                />
              </>
            )}
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default PostProjectReportButton;
