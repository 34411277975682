import filterIcon from "@/assets/images/filter.png";
import useOutsideClick from "@/hooks/useOutsideClick";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { FilterContext } from "@/Providers/FilterProvider";
import { Field, Form, Formik } from "formik";
import React, { FC, useContext, useRef, useState } from "react";
import * as Yup from "yup";
import Button from "../Button/Button";
import DateRange from "../DateRange/DateRange";
import FilterForm from "./components/FilterForm/FilterForm";
import * as Styled from "./style";
import { SelectOption } from "@/types/report.type";
import Loader from "../Loader/Loader";

interface ReportFiltersProps {
  filters: SelectOption | undefined;
  isLoading: boolean;
}

const ReportFilters: FC<ReportFiltersProps> = ({ filters, isLoading }) => {
  const { t } = useCustomTranslation("");
  const { setDateFilters, dateFilters } = useContext(FilterContext);

  const [toggleFilterMenu, setToggleFilterMenu] = useState(false);

  const ValidationSchema = Yup.object().shape({
    dateFrom: Yup.date().required("Date is required"),
    dateTo: Yup.date().required("Date is required")
  });

  const filterMenuRef = useRef(null);

  useOutsideClick(filterMenuRef, () => setToggleFilterMenu(false));

  return (
    <Styled.Container>
      <Styled.Menu>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={ValidationSchema}
          onSubmit={setDateFilters}
          initialValues={dateFilters}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div onClick={() => setToggleFilterMenu(false)}>
                <Field
                  as={DateRange}
                  name="dateRange"
                  dateRangeBtn
                  onClick={() => setToggleFilterMenu(false)}
                  onChange={(dateRange: Date[]) => {
                    const dateFrom =
                      new Date(dateRange[0]) < new Date(dateRange[1])
                        ? dateRange[0]
                        : dateRange[1];
                    const dateTo =
                      new Date(dateRange[0]) > new Date(dateRange[1])
                        ? dateRange[0]
                        : dateRange[1];

                    setFieldValue("dateFrom", dateFrom);
                    setFieldValue("dateTo", dateTo);

                    if (dateFrom !== dateTo) {
                      setDateFilters({ dateFrom, dateTo });
                    }
                  }}
                  value={[values.dateFrom, values.dateTo]}
                  disableFuture
                />
              </div>
            </Form>
          )}
        </Formik>
        <Styled.FilterWrapper>
          <Button
            aria-label="toggle filters"
            onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
            icon={filterIcon}
          >
            {t("REPORT.FILTERS.FILTER.BUTTON")}
          </Button>
          {toggleFilterMenu && (
            <Styled.FilterContainer ref={filterMenuRef}>
              {!isLoading ? (
                <FilterForm
                  filters={filters}
                  onClose={() => setToggleFilterMenu(false)}
                />
              ) : (
                <Loader />
              )}
            </Styled.FilterContainer>
          )}
        </Styled.FilterWrapper>
      </Styled.Menu>
    </Styled.Container>
  );
};

export default ReportFilters;
