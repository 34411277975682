import { animated } from "react-spring";
import styled from "styled-components";

export const MapOptions = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;
