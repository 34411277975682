import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.div``;

export const ColumnWrapper = styled.div`
  max-width: 1200px;
  align-self: center;
  margin: 50px 0 30px 0;
`;

export const RowWrapper = styled.div<{
  mainAxisAlignment?: string;
  crossAxisAlignment?: string;
}>`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  padding: 20px;
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  justify-content: ${({ mainAxisAlignment }) =>
    mainAxisAlignment ? mainAxisAlignment : "normal"};
  align-items: ${({ crossAxisAlignment }) =>
    crossAxisAlignment ? crossAxisAlignment : "normal"};
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 170px;
  padding: 5px;
`;
export const ArrowDown = styled.span`
  position: absolute;
  top: 22px;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
`;
