import styled from "styled-components";

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
`;

export const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 5px;
  max-width: 1400px;
`;

export const DMAContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
`;

export const LeakTitle = styled.h3`
  color: #469ddd;
`;

export const ProgressBarWrapper = styled.div`
  margin-bottom: 20px;
  flex: 1;
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 5px;
`;

export const DMAHeading = styled.h2`
  margin-bottom: 10px;
  font-weight: 500;
`;

export const TextContainer = styled.div`
  flex: 1;
  margin: 0 20px 20px 20px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  padding: 20px;
`;

export const Subheading = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.frenchBlue};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
`;

export const Value = styled.p`
  margin-top: 5px;
  text-align: center;
  color: ${({ theme }) => theme.colors.lochmara};
  font-weight: bold;
`;

export const WaypointFilterContainer = styled.div`
  margin-top: 10px;
`;
