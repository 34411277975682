import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import * as Styled from "./style";

// Providers
import {
  useGISUploadValues,
  withUploadContextProvider
} from "@/Providers/GISUploadProvider";

// Components
import ErrorModal from "./ErrorModal/ErrorModal";
import FileHeaderMapPreview from "./Header/FileHeaderMapPreview/FileHeaderMapPreview";
import FileHeader from "./Header/FileHeader/FileHeader";
import SequentialNav from "@/components/SequentialNav/SequentialNav";

// Utils
import { updateGISRoutes } from "./utils";
import { useCurrentStepData } from "./Hooks/useCurrentStepData";

// Types
import { GISRoutes } from "@/types/gisUpload.type";
import { useLengthOfFiles } from "./Hooks/useLengthOfFiles";

// TODO: Refactor - use URL as navigation as opposed to manual state manipulations

export const GISUploadContainer = withUploadContextProvider(() => {
  // const { uploadId } = useGISUploadContext();
  const { currentStep, isUploading, isLoading, fileIndex } =
    useGISUploadValues();
  const currentStepData = useCurrentStepData();
  const lengthOfFiles = useLengthOfFiles();

  const [routes, setRoutes] = useState(updateGISRoutes(0, false));

  useEffect(() => {
    setRoutes(updateGISRoutes(currentStep, isUploading || isLoading));
  }, [currentStep, isUploading, isLoading]);

  // console.log('[gis][container] render: ', {
  //   uploadId,
  //   currentStepData,
  //   currentStep,
  //   isUploading,
  //   isLoading,
  //   fileIndex,
  //   lengthOfFiles,
  //   routes,
  //   uploadStatus,
  // });

  return (
    <Styled.Wrapper>
      <ErrorModal />
      {currentStepData &&
        currentStepData.sampleData &&
        currentStep !== GISRoutes.MAP_PREVIEW && (
          <FileHeader
            numberOfFiles={currentStepData.sampleData?.length || 0}
            filename={currentStepData.sampleData?.[fileIndex]?.file_name || ""}
            sampleData={currentStepData.sampleData}
            tableColumns={currentStepData.tableColumns}
            // refetch={handleRefresh}
            queryLoading={false}
            lengthOfFiles={lengthOfFiles}
            type={currentStepData.type}
          />
        )}

      {currentStep === GISRoutes.MAP_PREVIEW && (
        <FileHeaderMapPreview lengthOfFiles={lengthOfFiles} />
      )}

      <Styled.Container>
        <Styled.NavWrapper>
          <SequentialNav navigation={routes} />
        </Styled.NavWrapper>
        {<Outlet />}
      </Styled.Container>
    </Styled.Wrapper>
  );
});
