import React from "react";
import DeleteIcon from "@/assets/icons/delete-icon.png";
import EditIcon from "@/assets/icons/edit-icon.png";
import { Table } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { UserListData } from "@/types/portalUsers.type";
import * as Styled from "../style";
import { IRole, UserRoles } from "@/types/user.type";

export const defaultPageCount = 1;
export const defaultPerPage = 10;

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("VIEW_USERS.TITLE");

  return [
    {
      id: "name",
      title: prefixedT("ROW_ONE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "partner",
      title: prefixedT("ROW_TWO"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "company",
      title: prefixedT("ROW_THREE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "role",
      title: prefixedT("ROW_FOUR"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "action",
      title: prefixedT("ROW_FIVE"),
      sortable: false,
      align: TableCellAlignment.center
    }
  ];
};

export const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];

const shouldShowDeleteButton = (
  roleCode: UserRoles,
  userRole: IRole
): boolean => {
  if (roleCode === UserRoles.SUPER_ADMIN) {
    return true;
  }

  if (
    roleCode === UserRoles.PARTNER_ADMIN &&
    userRole.role_code !== UserRoles.SUPER_ADMIN
  ) {
    return true;
  }

  if (
    roleCode === UserRoles.COMPANY_ADMIN &&
    userRole.role_code !== UserRoles.SUPER_ADMIN &&
    userRole.role_code !== UserRoles.PARTNER_ADMIN &&
    userRole.role_code !== UserRoles.PARTNER_USER &&
    userRole.role_code !== UserRoles.PARTNER_LEAK_ENGINEER &&
    userRole.role_code !== UserRoles.PARTNER_BUG_DEPLOYMENT_ENGINEER
  ) {
    return true;
  }

  return false;
};

export const getRowStructure = (
  data: UserListData,
  idx: number,
  handleEditClick?: (idx: number) => void,
  handleDeleteClick?: (idx: number) => void,
  role?: UserRoles
) => {
  const { prefixedT } = useCustomTranslation("VIEW_USERS");
  const showDeleteBtn = role && shouldShowDeleteButton(role, data.role);

  return (
    <Table.Row key={idx}>
      <Table.Cell align={TableCellAlignment.centerLeft} headerId="name">
        <Styled.NameContainer>
          <Styled.Name>{data.first_name + " " + data.last_name}</Styled.Name>
          <Styled.Email>{data.email}</Styled.Email>
        </Styled.NameContainer>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="partner">
        {data.partner?.partner_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="company">
        {data.company?.company_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="role">
        {data.role.role_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <Styled.ImgContainer>
          <Styled.Img
            onClick={() => handleEditClick && handleEditClick(idx)}
            alt={prefixedT("ROW.ICON.EDIT.ALT")}
            src={EditIcon as string}
          />
          {showDeleteBtn && (
            <Styled.Img
              onClick={() => handleDeleteClick && handleDeleteClick(idx)}
              alt={prefixedT("ROW.ICON.DELETE.ALT")}
              src={DeleteIcon as string}
            />
          )}
        </Styled.ImgContainer>
      </Table.Cell>
    </Table.Row>
  );
};
