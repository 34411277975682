import { FC, Fragment, useEffect } from "react";

// Components
import { ProjectUpdateForm } from "../components/forms/Project/ProjectUpdate.form";
import { Loader } from "@/components";

// Constants
import { ROUTE_PORTAL_NAME } from "@/constants/Route.constant";

// Types
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useGetProjectQuery } from "@/redux/portalProjects/portalProjects.api";
import { useGetGISUploadDataQuery } from "@/redux/portalProjects/portalProjectsGIS.api";
import { PageTitle } from "@/components/PageTitle";

export const ProjectUpdate: FC = () => {
  // const [showGISUploads, setShowGISUploads] = useState(false);
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();

  const isNewProject = !companyId || !projectId;
  // TODO: Refactor - use single "PROJECT_CREATION" for translation
  const { prefixedT } = useCustomTranslation("EDIT_PROJECT");

  const {
    data: project,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching
  } = useGetProjectQuery(
    { company_id: +companyId, project_id: +projectId },
    {
      skip: isNewProject,
      refetchOnMountOrArgChange: true
    }
  );
  const {
    data: GISUploadData,
    isLoading: isGISUploadDataLoading,
    isFetching: isGISUploadDataFetching
  } = useGetGISUploadDataQuery(
    {
      projectId: +projectId
    },
    {
      skip: isNewProject
    }
  );

  useEffect(() => {
    if (isNewProject) {
      navigate("/app/user-management/project/new");
    }
  }, [navigate, isNewProject]);

  const isProjectReadonly = project?.status === "APPROVED";

  return (
    <Fragment>
      {isProjectReadonly && (
        <PageTitle title={`${ROUTE_PORTAL_NAME} - View project`} />
      )}
      <div className="mb-8">
        <h2 className="text-lg">
          {isProjectReadonly ? "View Project" : prefixedT("TITLE")}
        </h2>
      </div>
      {isProjectLoading ||
      isProjectFetching ||
      isGISUploadDataLoading ||
      isGISUploadDataFetching ? (
        <Loader label="Loading project info..." />
      ) : (
        <ProjectUpdateForm
          defaultValues={
            !isNewProject &&
            project && {
              projectId: projectId && +projectId,
              gisUploadId: GISUploadData?.upload_id ?? undefined,
              gisUploadCompanyId: GISUploadData?.company_id ?? undefined,
              companyId: project.company_id,
              name: project.branch_name,
              costPerDistance: project.cost_per_distance?.toString(),
              levelODL: GISUploadData?.odl_level ?? undefined,
              dmaIds: project.dmas
                ? project.dmas.map((dma) => ({
                    label: dma.name,
                    value: dma.name
                  }))
                : [],
              startDate: project.start_date
                ? new Date(project.start_date)
                : new Date(),
              endDate: project.end_date
                ? new Date(project.end_date)
                : new Date(),
              targetDistance: project.target_pipeline ?? undefined,
              targetLeakage: project.target_leakage_reduction ?? undefined
            }
          }
          isReadonly={isProjectReadonly}
        />
      )}
    </Fragment>
  );
};
