import { PropsWithChildren } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled, { css } from "styled-components";

const sortableItemStyles = css`
  &::after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.softBlue};
    opacity: 0.7;
    width: 100%;
    height: 100%;
    top: 0;
  }
`;

export const Canvas = styled.div``;
export const CanvasItem = styled.div<{
  index?: number;
  sortable?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
  ${({ sortable }) => (sortable ? sortableItemStyles : null)};
`;

export const SortableCanvas = SortableContainer<PropsWithChildren>(Canvas);
export const SortableCanvasItem =
  SortableElement<PropsWithChildren>(CanvasItem);

export const DropContainer = styled.div<{
  readonly: boolean;
  isActive: boolean;
}>`
  height: 100%;
  border: 1px dashed
    ${({ readonly, theme, isActive }) =>
      readonly
        ? "transparent"
        : isActive
          ? theme.colors.deepBlue
          : theme.colors.lightGray};
  background: ${({ readonly, isActive }) =>
    readonly
      ? "transparent"
      : isActive
        ? "#BCD0F840"
        : "linear-gradient(180deg, rgba(234, 234, 239, 0) 0%, #F3F3F3 100%)"};
  border-radius: 8px;
`;
