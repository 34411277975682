export interface GetProjectResponse {
  id: number;
  data: ProjectResponse[];
}

interface ProjectResponse {
  id: number;
  name: string;
}
export interface GetProjectParams {
  partner_id?: number;
  company_id?: number;
}

export interface GetDMAsParams {
  partner_id?: number;
  company_id?: number;
  project_id?: number;
}

export interface GetDMAsResponse {
  id: number;
  data: DMAsResponse[];
}

export interface DMAsResponse {
  id: number;
  name: string;
  key: string;
}

export interface ProjectFetchParams {
  project_id?: number;
  company_id?: number;
}

export interface Project {
  id: number;
  name: string;
  company: { id: number; name: string };
  dmas: DMAsResponse[];
  cost_per_distance?: number;
  start_date: string | null;
  end_date: string | null;
  status: keyof typeof ProjectStatus;
}

interface UpdateProjectBase {
  branch_name?: string;
  start_date?: string;
  end_date?: string;
  cost_per_distance?: number;
  metric_system?: string;
  currency?: string;
  company_id?: number;
}

export interface UpdateProjectForm extends UpdateProjectBase {
  dmas?: { value: number; label: string }[];
}

export interface PatchUpdateProjectBody {
  project_info: UpdateProjectBase;
  dmas?: DMAsResponse[];
}

export type ProjectCreateData = {
  branch_name: string | null; // "Test P2",
  address: string | null; // null,
  phone: string | null; // null,
  active: string | null; // true,
  start_date: string | null; // null,
  end_date: string | null; // null,
  target_pipeline: string | null; // null,
  target_leakage_reduction: string | null; // null,
  id: number | null; // 1665
};

export type ProjectUpdateData = {
  branch_name: string;
  company_id?: number;
  cost_per_distance: number;
  dma_keys: string[];
  end_date: string;
  project_id: number;
  odl_level: number; // 1 | 2 | 3
  start_date: string;
  target_pipeline: number;
  target_leakage_reduction: number;
};

/*
 *
 * GIS
 *
 */

export type GeneratedODL = {
  pointer: number; // 1,
  url: string; // "https://gisservice.blob.core.windows.net/gisdata/1/585/odls/1.geojson"
};

export type GeneratedODLsResponse = GeneratedODL[];

export type GeneratedVirtualDMAResponse = {
  url: string;
};

export type UploadDMAResponse = {
  url: string | null;
  status: "EXISTING" | "SUCCESS";
  data: {
    [key: string]: string;
  } | null;
};
/*
 *
 * Constants
 *
 */

export const ProjectStatus = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3
} as const;

export type ProjectStatusType =
  (typeof ProjectStatus)[keyof typeof ProjectStatus];