import React, { FC } from "react";
import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import * as Styled from "./style";

export interface MenuItem {
  title: string;
  id?: number;
  type?: string;
  submenu?: MenuItem[];
}

interface MultiLevelDropdownProps {
  items: {
    title: string;
    id?: number;
    type?: string;
    submenu?: MenuItem[];
  };
  depthLevel: number;
  onChange?: (items: MenuItem) => void;
}

const MultiLevelDropdown: FC<MultiLevelDropdownProps> = ({
  items,
  depthLevel,
  onChange
}) => {
  const [dropdown, setDropdown] = useState(false);

  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent | TouchEvent) => {
      if (
        dropdown &&
        ref.current &&
        !ref.current.contains(event.target as Node | null)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    if (typeof window !== "undefined" && window.innerWidth > 960) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (typeof window !== "undefined" && window.innerWidth > 960) {
      setDropdown(false);
    }
  };

  const handleClick = (clickedItem: MenuItem) => {
    if (onChange) {
      onChange(clickedItem);
    }
  };

  return (
    <Styled.MenuItems
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <Styled.DropdownBtn onClick={() => handleClick(items)}>
            {items.title}{" "}
            {depthLevel > 0 ? (
              <Styled.ArrowRight />
            ) : (
              <Styled.Arrow open={dropdown} />
            )}
          </Styled.DropdownBtn>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            onChange={onChange}
          />
        </>
      ) : (
        <Styled.DropdownBtn onClick={() => handleClick(items)}>
          {items.title}
        </Styled.DropdownBtn>
      )}
    </Styled.MenuItems>
  );
};

export default MultiLevelDropdown;
