import React, { FC } from "react";
import * as Styled from "./style";

interface ToggleSwitchProps {
  isChecked: boolean;
  onToggle: () => void;
  idPrefix: string;
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({
  isChecked,
  onToggle,
  idPrefix
}) => {
  const uniqueId = `${idPrefix}-${Math.random().toString(36).substring(7)}`;

  return (
    <Styled.Container>
      <Styled.Input
        type="checkbox"
        id={uniqueId}
        checked={isChecked}
        onChange={onToggle}
      />
      <Styled.Label htmlFor={uniqueId} isChecked={isChecked}>
        Toggle
      </Styled.Label>
    </Styled.Container>
  );
};

export default ToggleSwitch;
