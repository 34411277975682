import { Column, SampleData } from "@/types/gisUpload.type";
import React, { FC, Fragment, useMemo } from "react";
import * as Styled from "./style";
import Table from "../Table/Table";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { FileInformation } from "./Components/FileInformation/FileInformation";
import { GISHook } from "../Hooks/GISHook";
import SystemFields from "./Components/SystemFields/SystemFields";
import { MapUnits } from "./Components/MapUnits/MapUnits";
import { useGISUploadValues } from "@/Providers/GISUploadProvider";
import { Badge, Card, cn, Separator } from "@fido/ui-lib";
import { Trans } from "react-i18next";

export enum GISType {
  DMA = "DMA",
  PIPES = "Pipes",
  ASSETS = "Assets"
}

interface ProcessFilesProps {
  sampleData: SampleData[];
  tableColumns: Column[];
  type: GISType;
}

const ProcessFiles: FC<ProcessFilesProps> = ({
  tableColumns,
  sampleData,
  type
}) => {
  const { prefixedT } = useCustomTranslation("PROCESS_FILES");
  const {
    insufficientSampleData,
    unselectedColumns,
    hasNonRequiredField,
    currentFile,
    getSelectedItems
  } = GISHook({
    sampleData,
    tableColumns
  });
  const { fileIndex } = useGISUploadValues();

  const memoizedGetItems = useMemo(getSelectedItems, [getSelectedItems]);

  const diameterUnits = ["diameter", "units"];

  return (
    <div
      className="w-full min-w-0 px-4 py-2 space-y-6"
      data-testid="process-files"
    >
      <div className="flex justify-between items-start">
        {sampleData.length === 0 ? (
          <p>{prefixedT("ERROR.NO_FILE_TO_MAP", { type })}</p>
        ) : (
          <FileInformation
            insufficientSampleData={insufficientSampleData}
            unselectedColumns={unselectedColumns}
            hasNonRequiredField={hasNonRequiredField}
          />
        )}
      </div>

      {sampleData.length > 0 &&
        sampleData[fileIndex]?.data &&
        sampleData[fileIndex]?.file_name && (
          <Table
            tableColumns={tableColumns}
            sampleData={sampleData[fileIndex]?.data}
            fileName={sampleData[fileIndex]?.file_name}
          />
        )}

      {currentFile && memoizedGetItems.length > 0 && (
        <>
          <div className="space-y-6 w-full">
            {memoizedGetItems.map((selectedValue) => {
              const isDiameter = selectedValue.title === "diameter";
              const isDiameterOrUnit = diameterUnits.includes(
                selectedValue.title
              );
              return (
                <Card key={selectedValue.name} className="p-4 space-y-4">
                  {isDiameterOrUnit && (
                    <>
                      {isDiameter && (
                        <div className="w-full space-y-3">
                          <Trans
                            i18nKey={"PROCESS_FILES.MAP_FIELD_TITLE"}
                            components={[<Badge />]}
                            values={{ title: "diameter" }}
                          />
                          <p>{prefixedT("DESC.DIAMETER")}</p>
                        </div>
                      )}
                      {selectedValue.title === diameterUnits[0] && (
                        <div className="grow-1 max-w-1/4">
                          <MapUnits
                            sampleData={sampleData[fileIndex]?.data}
                            file={currentFile}
                          />
                        </div>
                      )}
                      {selectedValue.title === diameterUnits[1] && (
                        <div className="grow-1 w-full">
                          <SystemFields
                            selectedFileValue={selectedValue}
                            file={currentFile}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {!isDiameterOrUnit && (
                    <>
                      <div className="w-full">
                        <SystemFields
                          selectedFileValue={selectedValue}
                          file={currentFile}
                        />
                      </div>
                    </>
                  )}
                </Card>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ProcessFiles;
