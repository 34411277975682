import * as React from "react";
const SvgPmv = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "229.829 243.9498 188.5895 75.6336", xmlns: "http://www.w3.org/2000/svg", xmlnsbx: "https://boxy-svg.com", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 79.81 171.223 L 117.622 359.804 L 41.998 359.804 L 79.81 171.223 Z", style: {
  stroke: "displayColor",
  fill: "none",
  transformOrigin: "79.81px 265.513px"
}, transform: "matrix(0.000113000016, 1, -1, 0.000051000006, 244.313767066191, 16.253165213051)", bxshape: "triangle 41.998 171.223 75.624 188.581 0.5 0 1@0e23715b" }), /* @__PURE__ */ React.createElement("rect", { x: 69.666, y: 127.964, width: 3.415, height: 76.072, style: {
  stroke: "displayColor",
  transformOrigin: "71.3735px 166px"
}, transform: "matrix(0.958492219448, 0.28512096405, -0.490735054016, 0.897328019142, 246.791306929897, 116.116735004769)" }));
export default SvgPmv;
