import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

export const ImageContainer = styled.div``;

export const Image = styled.img`
  border-radius: 20px;
`;
