import React from "react";
import { Field, useFormikContext } from "formik";

// Components
// import TextField from '../TextField/TextField';
import { DropDown, TextField } from "@/components";

// Styles
import * as Styled from "./style";

// Redux
import {
  useGetLeakTypesQuery,
  useGetStatusesQuery
} from "@/redux/mobileApp/mobileApp.api";
import { useGetEngineersQuery } from "@/redux/portalUsers/portalUsers.api";

// Constants
import {
  UnitCategory,
  UnitCategoryData,
  UnitSymbol
} from "@/constants/Unit.constant";

// Types
import type { FormWaypointValues } from "@/redux/portalUsers/portalUsers.api";
import type { DisabledFields } from "./types";
import type { DropDownOption } from "@/components/DropDown/DropDown";

// Utils
import {
  engineerDropDownValues,
  leakTypeDropDownValues,
  statusDropDownValues
} from "./utils";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";
import TextArea from "../TextArea/TextArea";

type FormWaypointProps = {
  disabledFields?: DisabledFields<FormWaypointValues>;
};

export const FormWaypoint = ({ disabledFields }: FormWaypointProps) => {
  const { prefixedT } = useCustomTranslation("WAYPOINT_PAGE.WAYPOINT_INFO");
  const { values, errors, setFieldValue } =
    useFormikContext<FormWaypointValues>();

  const { data: engineers } = useGetEngineersQuery();
  const { data: statuses } = useGetStatusesQuery();
  const { data: leakTypes } = useGetLeakTypesQuery();

  const engineerDropDown = engineerDropDownValues(engineers);
  const statusDropDown = statusDropDownValues(statuses);
  const leakTypeDropDown = leakTypeDropDownValues(leakTypes);

  // console.log("[form] waypoint values", {
  //   values,
  //   leakTypeDropDown,
  //   engineerDropDown,
  //   statusDropDown
  // });

  return (
    <Styled.FormWrapper>
      <Field
        as={DropDown}
        styles={{ noPadding: true, border: true }}
        disabled={disabledFields?.last_scheduled_engineer_id}
        name="last_scheduled_engineer_id"
        testId="last_scheduled_engineer_id"
        error={errors.last_scheduled_engineer_id}
        value={values.last_scheduled_engineer_id?.toString()}
        label={prefixedT("LAST_SCHEDULED_ENGINEER")}
        placeholder={prefixedT("PLACEHOLDER.ENGINEER")}
        options={engineerDropDown}
        onChange={(option: DropDownOption) => {
          setFieldValue("last_scheduled_engineer", option.label);
          setFieldValue("last_scheduled_engineer_id", option.value);
        }}
      />
      <Field
        as={DropDown}
        styles={{ noPadding: true, border: true }}
        disabled={disabledFields?.status_id}
        name="status_id"
        testId="status_id"
        error={errors.status_id}
        label={prefixedT("CURRENT_STATUS")}
        placeholder={prefixedT("PLACEHOLDER.STATUS")}
        options={statusDropDown}
        onChange={(option: DropDownOption) =>
          setFieldValue("status_id", option.value)
        }
      />
      <Field
        as={TextField}
        disabled={disabledFields?.pipe_material}
        error={errors.pipe_material}
        label={prefixedT("PIPE_MATERIAL")}
        name="pipe_material"
        placeholder={prefixedT("PLACEHOLDER.MATERIAL")}
        styles={{ noPadding: true }}
        testId="pipe_material"
      />
      <Field
        as={TextField}
        disabled={disabledFields?.surface_type}
        error={errors.surface_type}
        label={prefixedT("SURFACE_TYPE")}
        name="surface_type"
        placeholder={prefixedT("SURFACE_TYPE")}
        styles={{ noPadding: true }}
        testId="surface_type"
      />
      <Field
        as={TextField}
        disabled={disabledFields?.address}
        error={errors.address}
        label={prefixedT("ADDRESS")}
        name="address"
        placeholder={prefixedT("ADDRESS")}
        styles={{ noPadding: true }}
        testId="address"
      />
      <Field
        as={TextArea}
        disabled={disabledFields?.comment}
        error={errors.comment}
        label={prefixedT("COMMENT")}
        name="comment"
        placeholder={prefixedT("COMMENT")}
        styles={{ noPadding: true }}
        testId="comment"
      />
      <Field
        as={TextArea}
        disabled={disabledFields?.environmental_notes}
        error={errors.environmental_notes}
        label={prefixedT("ENVIRONMENTAL_NOTES")}
        name="environmental_notes"
        placeholder={prefixedT("ENVIRONMENTAL_NOTES")}
        styles={{ noPadding: true }}
        testId="environmental_notes"
      />
      <Field
        as={DropDown}
        styles={{ noPadding: true, border: true }}
        disabled={disabledFields?.predicted_leak_type_id}
        name="predicted_leak_type_id"
        testId="predicted_leak_type_id"
        error={errors.predicted_leak_type_id}
        label={prefixedT("PREDICTED_LEAK_SIZE")}
        placeholder={prefixedT("PLACEHOLDER.LEAK_TYPE")}
        options={leakTypeDropDown}
        onChange={(option: DropDownOption) => {
          setFieldValue("predicted_leak_type", option.label);
          setFieldValue("predicted_leak_type_id", option.value);
        }}
      />
      <Field
        as={DropDown}
        styles={{ noPadding: true, border: true }}
        disabled={disabledFields?.actual_leak_type_id}
        name="actual_leak_type_id"
        testId="actual_leak_type_id"
        error={errors.actual_leak_type_id}
        label={prefixedT("ACTUAL_LEAK_TYPE")}
        placeholder={prefixedT("PLACEHOLDER.LEAK_TYPE")}
        options={leakTypeDropDown}
        onChange={(option: DropDownOption) => {
          setFieldValue("actual_leak_type", option.label);
          setFieldValue("actual_leak_type_id", option.value);
        }}
      />
      <Styled.FormGroup>
        <Field
          as={TextField}
          disabled={disabledFields?.estimated_size_engineer}
          error={errors.estimated_size_engineer}
          label={prefixedT("ESTIMATED_SIZE_ENGINEER")}
          name="estimated_size_engineer"
          placeholder={prefixedT("ESTIMATED_SIZE_ENGINEER")}
          styles={{ noPadding: true }}
          testId="estimated_size_engineer"
          type="number"
        />

        <Field
          as={DropDown}
          styles={{ border: true }}
          name="estimated_size_engineer_unit"
          testId="estimated_size_engineer_unit"
          options={
            UnitCategoryData[UnitCategory.FLOW_RATE_UNIT].options.map((u) => ({
              label: UnitSymbol[u],
              value: UnitSymbol[u]
            })) as DropDownOption | DropDownOption[]
          }
          label={prefixedT(`ESTIMATED_SIZE_ENGINEER_UNIT`)}
          placeholder={prefixedT(`ESTIMATED_SIZE_ENGINEER_UNIT`)}
          onChange={(option: DropDownOption) => {
            setFieldValue("estimated_size_engineer_unit", option.label);
          }}
          disabled={disabledFields?.estimated_size_engineer_unit}
        />
      </Styled.FormGroup>
      <Styled.FormGroup>
        <Field
          as={TextField}
          disabled={disabledFields?.estimated_size_client}
          error={errors.estimated_size_client}
          label={prefixedT("ESTIMATED_SIZE_CLIENT")}
          name="estimated_size_client"
          placeholder={prefixedT("ESTIMATED_SIZE_CLIENT")}
          styles={{ noPadding: true }}
          testId="estimated_size_client"
          type="number"
        />
        <Field
          as={DropDown}
          styles={{ border: true }}
          name="estimated_size_client_unit"
          testId="estimated_size_client_unit"
          options={
            UnitCategoryData[UnitCategory.FLOW_RATE_UNIT].options.map((u) => ({
              label: UnitSymbol[u],
              value: UnitSymbol[u]
            })) as DropDownOption | DropDownOption[]
          }
          label={prefixedT(`ESTIMATED_SIZE_CLIENT_UNIT`)}
          placeholder={prefixedT(`ESTIMATED_SIZE_CLIENT_UNIT`)}
          onChange={(option: DropDownOption) => {
            setFieldValue("estimated_size_client_unit", option.label);
          }}
          disabled={disabledFields?.estimated_size_client_unit}
        />
      </Styled.FormGroup>
      <Styled.FormGroup>
        <Field
          as={TextField}
          disabled={disabledFields?.estimated_size}
          error={errors.estimated_size}
          label={prefixedT("ESTIMATED_SIZE")}
          name="estimated_size"
          placeholder={prefixedT("ESTIMATED_SIZE")}
          styles={{ noPadding: true }}
          testId="estimated_size"
          type="number"
        />

        <Field
          as={DropDown}
          styles={{ border: true }}
          name="estimated_size_unit"
          testId="estimated_size_unit"
          options={
            UnitCategoryData[UnitCategory.FLOW_RATE_UNIT].options.map((u) => ({
              label: UnitSymbol[u],
              value: UnitSymbol[u]
            })) as DropDownOption | DropDownOption[]
          }
          label={prefixedT(`ESTIMATED_SIZE_UNIT`)}
          placeholder={prefixedT(`ESTIMATED_SIZE_UNIT`)}
          onChange={(option: DropDownOption) => {
            setFieldValue("estimated_size_unit", option.label);
          }}
          disabled={disabledFields?.estimated_size_unit}
        />
      </Styled.FormGroup>
      <Styled.FormGroup>
        <Field
          as={TextField}
          disabled={disabledFields?.diameter}
          error={errors.diameter}
          label={prefixedT("DIAMETER")}
          name="diameter"
          placeholder={prefixedT("DIAMETER")}
          styles={{ noPadding: true }}
          testId="diameter"
          type="number"
        />

        <Field
          as={DropDown}
          styles={{ border: true }}
          name="diameter_unit"
          testId="diameter_unit"
          options={
            UnitCategoryData[UnitCategory.DIAMETER_UNIT].options.map((u) => ({
              label: UnitSymbol[u],
              value: UnitSymbol[u]
            })) as DropDownOption | DropDownOption[]
          }
          label={prefixedT(`DIAMETER_UNIT`)}
          placeholder={prefixedT(`DIAMETER_UNIT`)}
          onChange={(option: DropDownOption) => {
            setFieldValue("diameter_unit", option.label);
          }}
          disabled={disabledFields?.diameter_unit}
        />
      </Styled.FormGroup>
    </Styled.FormWrapper>
  );
};
