import { FC, Fragment } from "react";
import { Formik } from "formik";

// Components
import { Button, Loader } from "@/components";
import {
  AddUserBody,
  FormUserValues,
  useAddUserMutation,
  useGetUserUnitsQuery
} from "@/redux/portalUsers/portalUsers.api";
import * as Styled from "./style";

// Constants
import { FormUser } from "@/components/Form/FormUser";

// Hooks
import { useInitialValuesUser } from "@/hooks/form/useInitialValuesUser";
import useCustomTranslation from "@/localization/useCustomTranslation";
import useNotification from "@/hooks/useNotification";
import { usePartnersAndCompanies } from "@/hooks/query/usePartnersAndCompanies";

// Utils
import { ValidationSchemaFormUser } from "@/components/Form/schema";
import { mapDefaultUnits } from "@/utils/unit";
import { useRoles } from "@/hooks/query/useRoles";

const AddUser: FC = () => {
  const { t, prefixedT } = useCustomTranslation("ADD_USER");
  // const [modalOpen, setModalOpen] = useState<boolean>(false);
  const partnersAndCompanies = usePartnersAndCompanies();
  const roles = useRoles();

  const { showNotification } = useNotification();

  const [addUser, { isLoading: isAddUserLoading, isError, error, reset }] =
    useAddUserMutation();

  const {
    data: defaultUnits,
    isLoading: areDefaultUnitsLoading
    // isFetching: areDefaultUnitsFetching,
    // isSuccess: areDefaultUnitsSuccess,
    // isUninitialized: areDefaultUnitsUninitialized,
    // isError: areDefaultUnitsError,
  } = useGetUserUnitsQuery();

  const initialValues = useInitialValuesUser({
    units: mapDefaultUnits(defaultUnits)
  });

  // useEffect(() => {
  //   if (isSuccess) {
  //     setModalOpen(true);
  //   }
  // }, [isSuccess]);

  const onSubmit = async (values: FormUserValues) => {
    // console.log('[form] submit: ', values);
    const addRedirectValue: AddUserBody = {
      ...values,
      redirectUrl: `${window.location.origin}/auth/recovery`
    };
    await addUser({ body: addRedirectValue });
    await showNotification({
      type: "success",
      message: prefixedT("RESPONSE.SUCCESS")
    });
    reset();
  };

  // const onModalClose = () => {
  //   reset();
  //   setModalOpen(false);
  // };

  if (areDefaultUnitsLoading) {
    return <Loader />;
  }

  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT("TITLE")}</Styled.Title>
      </Styled.TitleContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchemaFormUser}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, submitForm }) => (
          <Fragment>
            <FormUser
              roles={roles}
              partnersAndCompanies={partnersAndCompanies}
            />

            <Styled.ButtonContainer>
              <Button
                type="submit"
                onClick={() => {
                  void (async () => {
                    await submitForm();
                    // resetForm();
                    reset();
                  })();
                }}
                disabled={
                  (values.role_id !== 1 &&
                    !values.partner_id &&
                    !values.company_id) ||
                  isAddUserLoading
                }
              >
                {prefixedT("SUBMIT")}
              </Button>
            </Styled.ButtonContainer>
          </Fragment>
        )}
      </Formik>
      {isError &&
        (error && "status" in error && error.status === 409 ? (
          <Styled.ReturnContainer>
            <Styled.ReturnText>{t("ERROR.DUPLICATE_EMAIL")}</Styled.ReturnText>
          </Styled.ReturnContainer>
        ) : (
          <Styled.ReturnContainer>
            <Styled.ReturnText>{t("ERROR.REQUEST")}</Styled.ReturnText>
          </Styled.ReturnContainer>
        ))}
    </Styled.Wrapper>
  );
};

export default AddUser;
