import React, { Fragment, useState } from "react";
import moment from "moment";

import * as Styled from "./style";
import EditIcon from "@/assets/icons/edit-icon.png";

// Components
import { LuEye, LuCheck, LuX } from "react-icons/lu";
import { Table } from "@/components";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";

// Types
import { Project } from "@/types/portalProject.type";
import { Button, buttonVariants, cn } from "@fido/ui-lib";
import { Link } from "react-router-dom";

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("VIEW_PROJECTS.TITLE");

  return [
    {
      id: "dmas",
      title: prefixedT("DMAS"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "name",
      title: prefixedT("NAME"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "company",
      title: prefixedT("COMPANY"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "start-date",
      title: prefixedT("START_DATE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "end-date",
      title: prefixedT("END_DATE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "action",
      title: "Accept / View / Decline", // prefixedT("ACTION"),
      sortable: false,
      align: TableCellAlignment.center
    }
  ];
};

type RowStructureProps = {
  row: Project;
  idx: number;
  onAccept?: (projectId: number) => void;
  onReject?: (projectId: number) => void;
  isLoading: boolean;
};
export const getRowStructure = ({
  row,
  idx,
  onAccept,
  onReject,
  isLoading
}: RowStructureProps) => {
  const dmasMaxHeight = 250;

  const [show, setShow] = useState(false);
  const { prefixedT } = useCustomTranslation("VIEW_PROJECTS");

  return (
    <Fragment key={`${row.id}-wrapper`}>
      <Table.Row>
        <Table.Cell align={TableCellAlignment.center} headerId="dmas">
          <Button
            data-testid={`project-${idx}-dmas`}
            onClick={() => setShow(!show)}
            size="icon"
            variant="secondary"
          >
            <Styled.ClickableAreaIndicator open={show} />
          </Button>
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="name">
          {row.name}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="company">
          {row.company.name}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="start-date">
          {row.start_date !== null
            ? moment(row.start_date).format("DD/MM/YYYY")
            : ""}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="end-date">
          {row.end_date !== null
            ? moment(row.end_date).format("DD/MM/YYYY")
            : ""}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="action">
          <div className="flex gap-1">
            {row.status === "PENDING" && (
              <Button
                disabled={isLoading}
                variant="ghost"
                size="icon"
                onClick={isLoading ? undefined : () => onAccept(row.id)}
              >
                <LuCheck className="h-4 w-4" />
              </Button>
            )}
            <Link
              to={`/app/user-management/project/${row.company.id}/${row.id}`}
              className={cn(
                buttonVariants({
                  variant: "ghost",
                  size: "icon"
                })
              )}
            >
              <LuEye className="h-4 w-4" />
            </Link>
            {row.status === "PENDING" && (
              <Button
                disabled={isLoading}
                variant="ghost"
                size="icon"
                onClick={isLoading ? undefined : () => onReject(row.id)}
              >
                <LuX className="h-4 w-4" />
              </Button>
            )}
          </div>
        </Table.Cell>
      </Table.Row>

      {/* Empty div is a hack to keep the alternating colours correct in the table */}
      <tr></tr>
      <tr>
        <Styled.DMACell colSpan={6} show={show}>
          <Styled.DMAsContainer show={show} maxHeight={dmasMaxHeight}>
            {row.dmas.length > 0 ? (
              <>
                <Styled.DMAHeader idx={idx}>
                  {prefixedT("TITLE.DMAS_SUBLIST_TITLE")}:
                </Styled.DMAHeader>
                <Styled.DMAList>
                  {row.dmas
                    .toSorted((a, b) => a.name?.localeCompare(b.name))
                    .map((dma, dmaIdx) => (
                      <Styled.DMAListItem key={dmaIdx}>
                        {dma.name}
                      </Styled.DMAListItem>
                    ))}
                </Styled.DMAList>
              </>
            ) : (
              <h3>{prefixedT("TITLE.NO_DMAS")}</h3>
            )}
          </Styled.DMAsContainer>
        </Styled.DMACell>
      </tr>
    </Fragment>
  );
};
