import { useMemo } from "react";

// Types
import type { WaypointInformationType } from "@/types/report.type";
import type { FormWaypointValues } from "@/redux/portalUsers/portalUsers.api";
import { Unit, UnitSymbol } from "@/constants/Unit.constant";

// Constants

// TODO: Refactor - create type that would inclide readonly fields too and use it on the form
export const useInitialValuesWaypoint = (data: WaypointInformationType) => {
  const values = useMemo<FormWaypointValues>(
    () => ({
      actual_leak_type: data?.actual_leak_type || undefined,
      address: data?.address || "",
      AI_size: data?.AI_size ?? undefined,
      comment: data.comment || "",
      current_status: data?.current_status || "",
      dma_key: data?.dma_key || "",
      environmental_notes: data.environmental_notes || "",
      estimated_size_client: data.estimated_size_client ?? undefined,
      estimated_size_engineer: data.estimated_size_engineer ?? undefined,
      estimated_size: data.estimated_size ?? undefined,
      last_scheduled_engineer: data?.last_scheduled_engineer || "",
      last_scheduled_engineer_id: data?.last_scheduled_engineer_id
        ? data?.last_scheduled_engineer_id.toString()
        : undefined,
      pipe_material: data.pipe_material || "",
      predicted_leak_type: data?.predicted_leak_type ?? undefined,
      predicted_leak_type_id: data?.predicted_leak_type_id
        ? data?.predicted_leak_type_id.toString()
        : undefined,
      repair_time: data.repair_time ? new Date(data.repair_time) : undefined,
      status_id: data?.status_id ? data?.status_id.toString() : undefined,
      surface_type: data?.surface_type || "",

      diameter_unit: data?.diameter_unit || UnitSymbol[Unit.INCHES],
      diameter: data?.diameter || 0,
      estimated_size_client_unit:
        data?.estimated_size_client_unit ||
        UnitSymbol[Unit.CUBIC_METERS_PER_HOUR],
      estimated_size_engineer_unit:
        data?.estimated_size_engineer_unit ||
        UnitSymbol[Unit.CUBIC_METERS_PER_HOUR],
      estimated_size_unit:
        data?.estimated_size_unit || UnitSymbol[Unit.LITERS_PER_SECOND]
    }),
    [data]
  );
  // console.log('[initialValues]', { initialValues, values });

  return values;
};
