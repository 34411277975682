import { ReportSummarySettings } from "@/types/report.type";
import { t } from "i18next";
import { v4 as guid } from "uuid";
import * as Yup from "yup";

export type Option = {
  id: string;
  columnId: number | null;
  typeId: number | null;
  title: string;
};

export type InitialSummaryValues = {
  source: number | null;
  options: Option[];
};

export const types = [
  {
    id: 1,
    name: "Count"
  },
  {
    id: 2,
    name: "Sum"
  },
  {
    id: 3,
    name: "Min"
  },
  {
    id: 4,
    name: "Max"
  },
  {
    id: 5,
    name: "Avg"
  }
];

export const getTitle = (dialogState: string) => {
  if (dialogState === "change") {
    return t("SUMMARY.DIALOG.TITLE.CHANGE");
  }
  return t("SUMMARY.DIALOG.TITLE.CLOSE");
};

export const getSubTitle = (dialogState: string) => {
  if (dialogState === "change") {
    return t("SUMMARY.DIALOG.SUBTITLE.CHANGE");
  }
  return t("SUMMARY.DIALOG.SUBTITLE.CLOSE");
};

export const prepareSubmit = (
  values: InitialSummaryValues
): ReportSummarySettings => {
  const settings: ReportSummarySettings = {
    column: [],
    summary_type: [],
    summary_title: [],
    datasource: Number(values.source)
  };
  values.options.forEach((filter) => {
    settings.column.push(filter.columnId as number);
    settings.summary_type.push(filter.typeId as number);
    settings.summary_title.push(filter.title);
  });
  return settings;
};

export const validateSummaryConfig = Yup.object({
  options: Yup.array().of(
    Yup.object({
      id: Yup.string().required("VALIDATION.REQUIRED").nullable(true),
      columnId: Yup.number().required("VALIDATION.REQUIRED").nullable(true),
      typeId: Yup.number().required("VALIDATION.REQUIRED").nullable(true),
      title: Yup.string().required("VALIDATION.REQUIRED").nullable(true)
    })
  ),
  source: Yup.number()
    .required("VALIDATION.REQUIRED")
    .nullable(true)
    .test("is-filled", "Should have options", (_, ctx) =>
      Boolean(ctx.parent.options.length)
    )
});

export const parseSettingsForInitialValues = (
  settings?: ReportSummarySettings
): InitialSummaryValues | null => {
  const options: Option[] = [];

  if (settings?.column) {
    settings.column.forEach((_, index) => {
      options.push({
        id: guid(),
        columnId: settings.column[index],
        typeId: settings.summary_type[index],
        title: settings.summary_title[index]
      });
    });
    return { source: settings.datasource, options };
  }
  return null;
};

export const getSummaryObject = (
  settings: ReportSummarySettings | undefined,
  index: number
) => ({
  name: settings?.summary_title[index] || "",
  value: ""
});

export const getRunningSummaryObject = (
  data: unknown | null,
  index: number
) => {
  if (Array.isArray(data)) {
    const name = Object.keys(data[index])[0];
    const value = data[index][name] ?? " ";
    return {
      name,
      value
    };
  }
};
