import { Fragment } from "react";

// Components
import SearchBar from "@/components/SearchBar/SearchBar";
import { DropDown, Loader, Table } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { Button } from "@fido/ui-lib";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";


// Types
import type { GISAdminPageResponse } from "@/types/GISAdmin.type";
import type { ReactPaginateProps } from "react-paginate";
import { FaSearch } from "react-icons/fa";
import { IBodyProps } from "@/components/Table/Body";
import { ITableHeader } from "@/components/Table/types";

type TableProps = {
  onSearchInputChange: (value: string) => void;
  onSearchClick: () => void;
  onPageChange: ReactPaginateProps["onPageChange"];
  onRowAmountChange?: (value: DropDownOption) => void;

  headers: ITableHeader[];
  getRowStructure: IBodyProps<GISAdminPageResponse>["getRowStructure"];
  // onDeleteClick: (idx: number) => void;
  // onPreviewClick: (idx: number, companyId: number) => void;

  title?: string;

  data: GISAdminPageResponse[];
  isLoading: boolean;
  error?: string;

  currentPage: number;
  perPage: number;
  totalCount: number;
};

const defaultPageCount = 1;
const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];

export const GISUploadsTable = (props: TableProps) => {
  const {
    onSearchInputChange,
    onSearchClick,
    onRowAmountChange,
    onPageChange,

    headers,
    getRowStructure,
    // onDeleteClick,
    // onPreviewClick,

    title,
    data,
    isLoading,
    error,

    currentPage,
    perPage,
    totalCount
  } = props;
  const { t, prefixedT } = useCustomTranslation("GIS_ADMIN");

  const currentPageCount = Math.ceil(
    (totalCount || defaultPageCount) / perPage
  );

  // TODO: Refactor - redo as ShadcnUI table and come up with a better way to handle headers and columns (probably should be separate table components for each unique table there is)

  return (
    <div className="flex flex-col gap-2">
      {!!onSearchInputChange && (
        <div className="flex mb-4 gap-2">
          <SearchBar
            placeholder={prefixedT("SEARCH.PLACEHOLDER")}
            onSearch={onSearchInputChange}
          />
          <Button type="button" onClick={onSearchClick}>
            <FaSearch /> {prefixedT("SEARCH.TEXT")}
          </Button>
        </div>
      )}
      {title && <div className="font-medium">{title}</div>}
      <Table data={data || []} headers={headers}>
        <Table.Head getHeaderStructure={(header) => header.title} />
        {isLoading && <Loader label="Fetching GIS Uploads..." />}
        {!!error && <p>{error}</p>}
        {!isLoading && !error && (
          <Table.Body
            // getRowStructure={(row: GISAdminPageResponse, idx: number) =>
            //   getRowStructure(row, idx, onDeleteClick, onPreviewClick)
            // }
            getRowStructure={getRowStructure}
            striped
          />
        )}
      </Table>
      <div className="flex items-baseline justify-end mt-2">
        <div className="flex items-baseline">
          {!!onRowAmountChange && (
            <div className="flex whitespace-nowrap items-center text-sm gap-2 mr-2">
              <p>{t("VIEW_USERS.PAGINATION.TITLE")}</p>
              <DropDown
                value={perPage.toString()}
                options={pageValues}
                onChange={(value) => onRowAmountChange(value as DropDownOption)}
                placeholder={"Number of rows"}
              />
            </div>
          )}
          <Table.Pagination
            pages={currentPageCount}
            onChange={onPageChange}
            forcePage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};
