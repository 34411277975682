import { ContentLoader } from "@/components";
import {
  useGetOktaCallbackMutation,
  useLoginMutation
} from "@/redux/authorization/authorization.api";
import {
  login,
  setToken,
  setAzureToken
} from "@/redux/authorization/authorization.slice";
import { ILoginValues } from "@/types/auth.type";
import { getRTKError } from "@/utils/rtk";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Login from "./Login";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [mutateCallback, { data: callback, isLoading: callbackLoading }] =
    useGetOktaCallbackMutation();

  const [onLogin, { error, data, isSuccess, isLoading }] = useLoginMutation();
  const loginError = useMemo(() => getRTKError(error), [error]);

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      mutateCallback({ code });
    }
  }, [searchParams]);

  useEffect(() => {
    if (callback && callback.access_token) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 4 * 60 * 60 * 1000);

      dispatch(setAzureToken(callback.azure_token));
      dispatch(setToken(callback.access_token));
      dispatch(login());
    }
  }, [callback]);

  useEffect(() => {
    if (isSuccess) {
      const authToken = data?.access_token;
      const azureToken = data?.azure_token;
      if (authToken) {
        dispatch(setToken(authToken));
        dispatch(setAzureToken(azureToken));
        dispatch(login());
      }
    }
  }, [isSuccess, data, dispatch]);

  const initialValues = useMemo(
    (): ILoginValues => ({
      email: "",
      password: ""
    }),
    []
  );

  const onSubmit = (values: ILoginValues) => {
    const formData = new URLSearchParams();
    formData.append("username", values.email);
    formData.append("password", values.password);

    const formDataString = formData.toString();
    onLogin(formDataString);
  };

  return (
    <>
      <ContentLoader active={isLoading || callbackLoading} />
      <Login
        onSubmit={onSubmit}
        initialValues={initialValues}
        loginError={loginError}
      />
    </>
  );
};
export default LoginContainer;
