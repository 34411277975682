import { DropDownOption } from "@/components/DropDown/DropDown";
import {
  FileSelectedValues,
  GISRoutes,
  SelectedValue,
  Column
} from "@/types/gisUpload.type";
import { deepCopy } from "@/utils/common";

export const createDropdown = (tableColumns: Column[]) => {
  const dropdown: DropDownOption[] = [];
  tableColumns.map((column) =>
    dropdown.push({
      value: column.id.toString(),
      label: column.column_name
    })
  );

  return dropdown;
};

export const updateSelectedValues = (
  prevValues: FileSelectedValues[],
  fileName: string,
  columnName: string,
  option: DropDownOption,
  currentStep: GISRoutes,
  required: boolean = true
): FileSelectedValues[] => {
  const updatedValues = deepCopy(prevValues);

  const fileIndex = updatedValues.findIndex((f) => f.file === fileName);

  const selectedData: SelectedValue = {
    value: option.value,
    title: option.label,
    name: columnName,
    required: required
  };

  if (fileIndex === -1) {
    updatedValues.push({
      file: fileName,
      data: [selectedData],
      material: undefined,
      asset_type: undefined,
      pipe_type: undefined,
      status: undefined,
      units: undefined
    });
  } else {
    let data = [...updatedValues[fileIndex].data];

    data = data.filter(
      (item) => item.title !== option.label && item.name !== columnName
    );

    data.push(selectedData);

    const updatedFile = { ...updatedValues[fileIndex], data };

    if (currentStep === GISRoutes.PROCESS_ASSETS && option.label === "type") {
      updatedFile.asset_type = undefined;
    }

    if (currentStep === GISRoutes.PROCESS_PIPES && option.label === "type") {
      updatedFile.pipe_type = undefined;
    }

    if (currentStep === GISRoutes.PROCESS_PIPES && option.label === "status") {
      updatedFile.status = undefined;
    }

    if (currentStep === GISRoutes.PROCESS_PIPES && option.label === "units") {
      updatedFile.units = undefined;
    }

    if (option.label === "material") {
      updatedFile.material = undefined;
    }

    updatedValues[fileIndex] = updatedFile;
  }

  return updatedValues;
};
