import { useLazyGetProjectsByCompanyQuery } from "@/redux/portalProjects/portalProjects.api";
import { useLazyGetCompanyListQuery } from "@/redux/portalCompanies/portalCompanies.api";
import { useGetProfileQuery } from "@/redux/user/user.api";
import { UserRoles } from "@/types/user.type";
import { useEffect } from "react";

const useFetchFilterOptions = () => {
  const [fetchCompanies, companiesResult] = useLazyGetCompanyListQuery();
  const [fetchBranches, branchesResult] = useLazyGetProjectsByCompanyQuery();

  const companies = companiesResult.data || [];
  const branches = branchesResult.data || [];

  const { data: profile } = useGetProfileQuery();

  const fetchFilterOption = async () => {
    const isSuperAdmin: boolean =
      profile?.role?.role_code === UserRoles.SUPER_ADMIN;
    const isCompanyAdmin: boolean =
      profile?.role?.role_code === UserRoles.COMPANY_ADMIN;

    const requests = [
      isSuperAdmin && fetchCompanies(),
      isCompanyAdmin && fetchBranches(profile?.company_id || "")
    ];
    return await Promise.all(requests);
  };

  useEffect(() => {
    if (profile) {
      fetchFilterOption();
    }
  }, [profile]);

  return { companies, branches };
};
export default useFetchFilterOptions;
