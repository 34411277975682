// Types
import { MapElement } from "@/components/ReportLayout/widgets/MapView/utils/types";
import { UploadId } from "@/redux/gisUpload/gisUpload.slice";

export enum STANDARDISING {
  MATERIAL = "material",
  STATUS = "status",
  TYPE = "type", // type can be used for pipe_type or asset_type
  UNITS = "diameter_units"
}
export interface PostUploadGISFilesParams {
  company_id: number;
}

export interface PostUploadGISDataParams {
  company_id: number;
  upload_id: number;
}

export enum GIS_COLUMN_MAPPING {
  ASSET_TYPE = "asset_type",
  MATERIAL = "material",
  PIPE_TYPE = "pipe_type",
  STATUS = "status",
  UNITS = "units"
}

export interface MappedColumns {
  columns: {
    id: number;
    title: string;
  }[];
  material: Item[] | [];
  asset_type: Item[] | [];
  pipe_type: Item[] | [];
  status: Item[] | [];
  units: number | Item[] | [];
}

export interface ColumnMappingBody {
  upload_id: number | null;
  filename: string;
  mapped_columns: MappedColumns;
}

export interface Column {
  column_name: string;
  column_type?: string;
  id: number;
  required: boolean;
}
export interface TableColumns {
  table_name: string;
  columns: Column[];
}

export interface SampleData {
  file_name: string;
  type: string;
  data: unknown[];
}
export interface ColumnAndSampleDataResponse {
  column_mappings: [];
  table_columns: TableColumns[];
  sample_data: SampleData[];
}

interface AssetType {
  id: number;
  standardised_type: string;
}

interface PipeType {
  id: number;
  standardised_type: string;
  type: string | null;
}
interface PipeMaterial {
  id: number;
  material: string | null;
  standardised_material: string;
}

interface Units {
  id: number;
  standardised_unit: string;
  unit: string;
}

interface PipeStatus {
  id: number;
  standardised_status: string;
  status: string;
}

export interface UniqueValuesResponse {
  unique_values: string[];
  asset_type: AssetType[] | null;
  pipe_material: PipeMaterial[] | null;
  pipe_type: PipeType[] | null;
  pipe_status: PipeStatus[] | null;
  diameter_units: Units[] | null;
}

export interface DiscardFileBody {
  upload_id: UploadId;
  company_id: number | null;
  filename: string;
}

interface Preview {
  data: MapElement[];
}

export type SelectedValue = {
  value: string;
  title: string;
  name: string;
  type?: GIS_COLUMN_MAPPING;
  required?: boolean;
};

export type Item = {
  title: string;
  type: string;
};

export type FileSelectedValues = {
  file: string;
  material?: Item[];
  asset_type?: Item[];
  pipe_type?: Item[];
  status?: Item[];
  units?: Item[] | number;
  data: SelectedValue[];
};

export enum ErrorModalText {
  DEFAULT_ERROR_MESSAGE = 1,
  COLUMNS_NOT_SELECTED = 2,
  SYSTEM_FIELDS_NOT_MAPPED = 3,
  SYSTEM_FIELD_NUMERIC = 4
}

export enum GISRoutes {
  UPLOAD_SHAPEFILE = 0,
  PROCESS_DMA = 1,
  PROCESS_PIPES = 2,
  PROCESS_ASSETS = 3,
  MAP_PREVIEW = 4
}

export type MappedSystemFields = {
  material: boolean;
  asset_type: boolean;
  pipe_type: boolean;
  status: boolean;
  units: boolean;
};

export type GISUploadData = {
  companyId: number | null;
  selectedValues: FileSelectedValues[];
  fileIndex: number;
  currentStep: GISRoutes;
  mappedSystemFields: MappedSystemFields;
  returnToProject: string | null;
  returnToCompany: string | null;
};

export type GISUploadValues = {
  isLoading: boolean;
  // isUploading: boolean; // isLoading
  uploadStatus: "uninitialized" | "uploading" | "success" | "error";
  isErrorModalOpen: boolean; // isErrorModalVisible
  errors: Array<ErrorModalText>;
  data: GISUploadData;
};

export type GISUploadState = {
  gisUpload: {
    [key: number]: GISUploadValues;
  };
};

export type PreviewValuesResponse = {
  map: {
    assets: Preview;
    dmas: Preview;
    pipes: Preview;
  };
  odl_task_id?: string;
};

export type GISMapPreviewProps = {
  companyId: number | null;
  uploadId: UploadId;
  withCache?: boolean;
};