import mapboxgl from "mapbox-gl";
import React from "react";
import { drawClusters, drawLines, drawPolygon } from "../geospatialFeatures";
import { MapElement, ShapeType } from "./types";

const onMapLoad = async (
  sourceData: unknown,
  map: React.RefObject<mapboxgl.Map>,
  onClickModalOpen: (id: string) => void,
  modal?: boolean,
  doNotAllowClickThrough?: boolean
) => {
  const mapElements = sourceData as MapElement[];

  mapElements.forEach((shape: MapElement, idx: number) => {
    if (shape.type === ShapeType.POLYGON) {
      if (map.current) {
        drawPolygon(
          map.current,
          shape,
          onClickModalOpen,
          modal,
          !!doNotAllowClickThrough,
          idx
        );
      }
    }
  });

  const lines = mapElements.filter((shape) => shape.type === ShapeType.LINE);
  const points = mapElements.filter((shape) => shape.type === ShapeType.POINT);

  if (lines.length > 0 && map.current) {
    drawLines(map.current, lines, !!doNotAllowClickThrough);
  }

  if (points.length > 0 && map.current) {
    await drawClusters(map.current, points, !!doNotAllowClickThrough);
  }
};

export default onMapLoad;
