import ArrowIcon from "@/assets/icons/arrow_down.svg?react";
import FilterIconSVG from "@/assets/icons/filter-icon.svg?react";
import SearchIconSVG from "@/assets/icons/search-icon.svg?react";
import styled from "styled-components";

export const ArrowDownIcon = styled(ArrowIcon)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  fill: #000;
`;

export const CloseBtn = styled.button`
  background: #fff;
  border-radius: 25px;
  border: 1px solid;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const Icon = styled.div``;

export const FilterIcon = styled(FilterIconSVG)`
  margin-right: 10px;
  transform: scale(1.7);
`;

export const SearchIcon = styled(SearchIconSVG)`
  margin-right: 5px;
  transform: scale(1.5);
`;

export const Wrapper = styled.div`
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 5px 10px;
`;

export const Input = styled.input`
  outline: none;
  border: 0;
  background: #fff;
  font-size: 14px;
  margin-right: 5px;
  width: 130px;
`;

export const FilterBarContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const CloseBtnContainer = styled.div``;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 5px;
  align-items: center;
  display: flex;
`;

export const Test = styled.tr`
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
`;
