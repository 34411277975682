import { WaypointFilterContext } from "@/Providers/WaypointFilterProvider";
import { usePatchUpdateProjectMutation } from "@/redux/highlandPortal/highlandPortal.api";
import React, { FC, useContext, useState } from "react";
import * as Styled from "./style";
import { usePatchUpdateCompanyMutation } from "@/redux/portalCompanies/portalCompanies.api";

interface TargetEditProps {
  value: number | null;
  type: string | null;
  id: number | null | undefined;
  target: "target_pipeline" | "target_leakage_reduction";
  isCompany?: boolean;
}

export const TargetEdit: FC<TargetEditProps> = ({
  value,
  type,
  id,
  target,
  isCompany = false
}) => {
  const { setTargetLeakageReduction, setTargetPipeline } = useContext(
    WaypointFilterContext
  );
  const [updateProjectMutate] = usePatchUpdateProjectMutation();
  const [updateCompanyMutate] = usePatchUpdateCompanyMutation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [values, changeValues] = React.useState(value);

  const handleClick = async () => {
    setIsEdit(!isEdit);

    if (value !== values && id) {
      const mutator = isCompany ? updateCompanyMutate : updateProjectMutate;
      if (target === "target_leakage_reduction" && values !== null) {
        setTargetLeakageReduction(values);
        await mutator({
          id,
          body: { target_leakage_reduction: values }
        });
      } else if (target === "target_pipeline" && values !== null) {
        setTargetPipeline(values);
        await mutator({
          id,
          body: { target_pipeline: values }
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const newValue = inputValue !== "" ? parseFloat(inputValue) : null;
    changeValues(newValue);
  };

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      await handleClick();
    }
  };

  const clampToCharacterWidthRange = values
    ? Math.min(Math.max(String(values).length, 2), 50) + "ch"
    : "2ch";

  return (
    <Styled.Wrapper data-testid="target-edit">
      <Styled.Container>
        <Styled.TargetImg />
        <Styled.Span>
          <Styled.Input
            data-testid="input"
            style={{ width: clampToCharacterWidthRange }}
            value={values ?? ""}
            disabled={!isEdit}
            type={"number"}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              void (async () => {
                await handleKeyPress(event);
              })();
            }}
            onChange={handleChange}
          />
          {type}
        </Styled.Span>

        {id && <Styled.PencilImg data-testid="edit" onClick={handleClick} />}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default TargetEdit;
