// Components
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@fido/ui-lib";
import { LuLoaderCircle } from "react-icons/lu";

export type ConfirmDialogProps = {
  onConfirm: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  isLoading?: boolean;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { open, setOpen, onConfirm, title, isLoading } = props;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {/* <DialogDescription>
        Make changes to your profile here. Click save when you're done.
      </DialogDescription> */}
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <Button disabled={isLoading} type="submit" onClick={onConfirm}>
            {isLoading && <LuLoaderCircle className="animate-spin" />} Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
