import React, { FC, PropsWithChildren, useState } from "react";

// Redux
import { useGetProfileQuery, useGetRolesQuery } from "@/redux/user/user.api";

// Styles
import * as Styled from "./style";

// Components
import { Header, Sidebar, SplashScreen } from "@/components";
import Chatbot from "@/components/Chatbot/Chatbot";
import { WebsocketManager } from "@/managers/WebsocketManager";

export enum SidebarStates {
  open = "open",
  closed = "closed"
}

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading: isProfileLoading } = useGetProfileQuery();
  const { isLoading: isRolesLoading } = useGetRolesQuery();

  const initialSidebarState: string =
    localStorage.getItem("sidebar-state") || SidebarStates.open;

  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(
    initialSidebarState === SidebarStates.open
  );

  const toggleSidebar = () => {
    localStorage.setItem(
      "sidebar-state",
      String(!isOpenSidebar ? SidebarStates.open : SidebarStates.closed)
    );
    setIsOpenSidebar(!isOpenSidebar);
  };

  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState(false);
  const toggleMobileSidebar = () => {
    setIsOpenMobileSidebar(!isOpenMobileSidebar);
  };

  const sidebarHandler = () => {
    if (window.innerWidth <= 768) {
      toggleMobileSidebar();
    } else {
      toggleSidebar();
    }
  };

  return (
    <Styled.Container>
      <SplashScreen active={isProfileLoading || isRolesLoading} />
      <Sidebar
        sidebarOpen={isOpenSidebar}
        sidebarClose={toggleSidebar}
        mobileSidebarOpen={isOpenMobileSidebar}
        mobileSidebarClose={toggleMobileSidebar}
      />
      <Styled.ContentWrapper isOpenSidebar={isOpenSidebar}>
        <Header sidebarControl={sidebarHandler} />
        <Styled.Content>{children}</Styled.Content>
      </Styled.ContentWrapper>

      <Chatbot />

      <WebsocketManager />
    </Styled.Container>
  );
};

export default AppLayout;
