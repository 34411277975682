import React from "react";

// Components
import Profile from "./Profile";

// Redux
import { useGetProfileQuery } from "@/redux/user/user.api";
import { Loader } from "@/components";
import { Error } from "@/styles/Error";

const ProfileContainer = () => {
  const { data: user, isLoading: isUserLoading } = useGetProfileQuery();

  if (isUserLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Error>Failed loading user data.</Error>;
  }

  return <Profile user={user} />;
};

export default ProfileContainer;
