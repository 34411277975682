import styled, { keyframes } from "styled-components";

const moveForever = keyframes`
 0% {
  transform: translate3d(-90px,0,0);
 }
 100% { 
   transform: translate3d(85px,0,0);
 }
`;

export const Waves = styled.svg`
  min-height: 151px;
`;

export const Parallax = styled.g`
  min-height: 100px;
  max-height: 150px;

  > use {
    animation: ${moveForever} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
`;

export const InnerHeader = styled.div`
  transform: rotate(152deg);
  width: 507px;
  height: 462px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: -258px;
  left: -246px;
  border-radius: 50%;
  background: rgb(1 105 157);
  overflow: hidden;
`;
