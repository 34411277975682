import BackIconSVG from "@/assets/icons/arrow_back.svg?react";
import ExportIconSVG from "@/assets/icons/copy.svg?react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
`;
export const ReportWrapper = styled.div``;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const HeaderTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
`;

export const HeaderControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 50px 15px 0;
  button {
    font-size: 12px;
  }
  & > * {
    margin-right: 15px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const IFrame = styled.iframe`
  border: none;
  width: 100%;
  min-height: calc(100% - 70px);
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackIcon = styled(BackIconSVG)`
  margin-right: 5px;
`;

export const ExportIcon = styled(ExportIconSVG)`
  margin-right: 5px;
`;
