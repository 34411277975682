import styled from "styled-components";

export const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 0;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const ControlsContainer = styled.div`
  width: 300px;
  padding: 30px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RelayGraphContainer = styled.div``;

export const GraphContainer = styled.div`
  background: white;
  padding: 20px;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
