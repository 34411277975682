// Redux
import { useGetColumnAndSampleDataQuery } from "@/redux/gisUpload/gisUpload.api";

type UseColumnAndSampleDataProps = {
  companyId: number | null;
  uploadId: number | null;
};

export const useColumnAndSampleData = ({
  companyId,
  uploadId
}: UseColumnAndSampleDataProps) => {
  const query = useGetColumnAndSampleDataQuery(
    { companyId, uploadId },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyId || !uploadId
    }
  );

  return query;
};
