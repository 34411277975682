import { createSelector } from "reselect";
import { RootState } from "..";

const baseWebsocketSelector = (state: RootState) => state.websocket;

// const makeSelectItemsByCategory = () => {
//   const selectItemsByCategory = createSelector(
//     [(state) => state.items, (state, category) => category],
//     (items, category) => items.filter((item) => item.category === category),
//   );
//   return selectItemsByCategory;
// };

export const websocketIdsSelector = createSelector(
  baseWebsocketSelector,
  ({ websockets }) => websockets.map((ws) => ws.id)
);

export const singleWebsocketSelector = createSelector(
  [baseWebsocketSelector, (_: RootState, websocketId: string) => websocketId],
  ({ websockets }, websocketId) =>
    websockets.find((ws) => ws.id === websocketId)
);
