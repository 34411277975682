import mapboxgl from "mapbox-gl";
import * as Sentry from "@sentry/react";
import getBoundingBox from "./getBoundingBox";
import { MapElement } from "./types";

const adjustMapViewportToData = (
  map: React.RefObject<mapboxgl.Map>,
  sourceData: MapElement[]
) => {
  if (sourceData.length > 0) {
    const getArea = getBoundingBox(sourceData);
    if (getArea) {
      try {
        const boundingBox = new mapboxgl.LngLatBounds(getArea);
        if (map.current) {
          map.current.fitBounds(boundingBox, { maxZoom: 14 });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }
};

export default adjustMapViewportToData;
