import { Button } from "@fido/ui-lib";
import { ProjectFormValues } from "../schema";
import { useFormContext } from "react-hook-form";

type ProjectFormFooterProps = {
  isReadonly?: boolean;
  isLast?: boolean;
  prev?: () => void;
  showPrevious?: boolean;
  showNext?: boolean;
};

export const ProjectFormFooter = (props: ProjectFormFooterProps) => {
  const form = useFormContext<ProjectFormValues>();

  return (
    <div className="flex justify-end items-center gap-2">
      {props.showPrevious && (
        <Button type="button" variant="outline" onClick={props.prev}>
          Previous
        </Button>
      )}

      {props.showNext && (
        <Button type="submit" disabled={form.formState.isSubmitting}>
          {props.isLast ? (props.isReadonly ? "Done" : "Submit") : "Next"}
        </Button>
      )}
    </div>
  );
};
