import { IReactChildren } from "@/types/common.type";
import React, { FC } from "react";
import * as Styled from "./style";

interface AuthLayoutProps {
  children: IReactChildren;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => (
  <Styled.Container>
    <Styled.ImageBlock />
    <Styled.InfoBlock>
      <Styled.FormContainer>{children}</Styled.FormContainer>
    </Styled.InfoBlock>
  </Styled.Container>
);

export default AuthLayout;
