import mapbox, { LngLatLike } from "mapbox-gl";

interface PopupOptions {
  map: mapbox.Map;
  lngLat: { lat: number | null; lng: number | null };
  popupRef: React.RefObject<HTMLDivElement>;
  popup: mapbox.Popup;
}

export const setupPopup = ({ map, lngLat, popup, popupRef }: PopupOptions) => {
  const { lat, lng } = lngLat;

  if (popupRef && popupRef.current) {
    popup.setLngLat([lng, lat] as LngLatLike).addTo(map);

    popup.setDOMContent(popupRef.current);
  }

  map.on("mousedown", () => {
    popup.remove();
  });

  map.on("click", (e) => {
    if (e.defaultPrevented === false) {
      popup.remove();
    }
  });
};
