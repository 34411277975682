import styled from "styled-components";

export const LoadContainer = styled.div``;

export const Wrapper = styled.div`
  overflow-x: auto;
  min-height: auto;
  margin: 0 auto;
  /* background: ${({ theme }) => theme.colors.mascaraTears}; */
  margin-bottom: 20px;
  padding: 10px 2.5% 20px 2.5%;
`;

export const Th = styled.th`
  padding: 15px 0;
  min-width: 200px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Thread = styled.thead`
  position: relative;
  height: 45px;
  border-radius: 8px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
  user-select: none;
  width: calc(100% - 1em);
  display: table;
`;

export const TD = styled.td`
  min-width: 200px;
  font-size: 12px;
  padding: 12px 0px;
  text-align: center;
  /* border-bottom: 5px solid ${({ theme }) => theme.colors.mascaraTears}; */
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* color: ${({ theme }) => theme.colors.historianGrey}; */
`;

export const TR = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  /* background: ${({ theme }) => theme.colors.midnight}; */

  :hover {
    background: #302e36;
  }
`;

export const Tbody = styled.tbody`
  display: block;
  max-height: 300px;
  overflow: auto;
`;

export const HR = styled.hr`
  margin-top: 20px;
`;

export const Title = styled.h2`
  /* color: ${({ theme }) => theme.colors.white}; */
`;

export const BoldTxt = styled.span`
  font-weight: bold;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;
