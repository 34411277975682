import React, { FC, Fragment } from "react";
import { useFormikContext } from "formik";

// Components
import { Button } from "@/components";
import { FormUser } from "@/components/Form/FormUser";

// Styles
import * as Styled from "./style";

// Hooks
import { usePartnersAndCompanies } from "@/hooks/query/usePartnersAndCompanies";
import { FormUserValues } from "@/redux/portalUsers/portalUsers.api";
import useCustomTranslation from "@/localization/useCustomTranslation";

const AccountSettings: FC = () => {
  const { prefixedT } = useCustomTranslation("PROFILE_PAGE");
  const { submitForm, isSubmitting, dirty } =
    useFormikContext<FormUserValues>();

  // console.log('[form][account settings]', {
  //   submitForm,
  //   dirty,
  //   isSubmitting,
  //   btnDisabled: !dirty || isSubmitting,
  // });

  const partnersAndCompanies = usePartnersAndCompanies();

  const handleSubmitForm = async () => {
    await submitForm();
  };

  return (
    <Fragment>
      <FormUser
        disabledFields={{ email: true }}
        partnersAndCompanies={partnersAndCompanies}
      />

      <Styled.ButtonContainer>
        <Button
          type="submit"
          disabled={!dirty || isSubmitting}
          onClick={handleSubmitForm}
        >
          {prefixedT("SUBMIT")}
        </Button>
      </Styled.ButtonContainer>
    </Fragment>
  );
};

export default AccountSettings;
