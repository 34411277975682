import React, { FC, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

// HOCs
import withPageLoader from "@/HOCs/withPageLoader";

// Components
import { MapPreview, ProcessFiles } from ".";
import { Loader } from "@/components";

// Providers & Hooks
import {
  useGISUploadContext,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { FilterProvider } from "@/Providers/FilterProvider";
import { useCurrentStepData } from "./Hooks/useCurrentStepData";

// Types
import { GISRoutes } from "@/types/gisUpload.type";

const GISUploadWrapper: FC = () => {
  const { uploadId } = useParams();
  const { setUploadId } = useGISUploadContext();
  const { currentStep, uploadStatus } = useGISUploadValues();

  const { sampleData, tableColumns, type, isLoading } = useCurrentStepData();

  // const {
  //   data,
  //   isFetching: isQueryLoading,
  //   isError: isQueryError,
  //   refetch,
  // } = useGetColumnAndSampleDataQuery(
  //   { companyId, uploadId },
  //   {
  //     refetchOnMountOrArgChange: true,
  //     skip: !companyId || !uploadId,
  //   },
  // );

  useEffect(() => {
    setUploadId(uploadId ? +uploadId : null);
  }, [uploadId]);

  // useEffect(() => {
  //   if (isQueryError) {
  //     setIsError(
  //       addOrReplaceError(isError, ErrorModalText.DEFAULT_ERROR_MESSAGE),
  //     );
  //   }
  // }, [isQueryError]);

  if (uploadStatus === "uploading") {
    return <Loader label="File is uploading..." />;
  }

  if (isLoading) {
    return <Loader label="File data is loading..." />;
  }

  if (uploadStatus === "error") {
    return <h3>Uploading error, try again.</h3>;
  }

  if (currentStep === GISRoutes.MAP_PREVIEW) {
    return (
      <FilterProvider>
        <MapPreview />
      </FilterProvider>
    );
  }

  if (sampleData && tableColumns && type) {
    return (
      <ProcessFiles
        type={type}
        tableColumns={tableColumns}
        sampleData={sampleData}
      />
    );
  }

  return <Navigate to={"/app/gis-upload"} />;
};

export const GISUploadExisting = withPageLoader(GISUploadWrapper);
