import { SendMessageResponse } from "@/types/aiAssistant.type";
import moment from "moment";
import { ListOfMessages } from "../types";

export const formattedTimestamp = () => {
  const timestamp = moment(new Date());

  return timestamp.format("YYYY-MM-DDTHH:mm:ss");
};

export const timezoneOffset = () => {
  const offsetMinutes = moment().utcOffset();
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);
  const offsetDecimal = offsetHours + offsetMinutesRemainder / 60;

  if (Object.is(offsetDecimal, -0)) {
    return 0;
  }

  return offsetMinutes < 0 ? -offsetDecimal : offsetDecimal;
};

export const updateMessages = (
  prevList: ListOfMessages[],
  returnedMsg: SendMessageResponse
) => {
  const updatedList = [...prevList];
  const lastIndex = updatedList.length - 1;

  updatedList[lastIndex] = {
    ...updatedList[lastIndex],
    assistant_msg: returnedMsg.assistant_msg,
    msgId: returnedMsg.msg_id
  };

  return updatedList;
};
