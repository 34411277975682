import {
  ReportLayout,
  Widget,
  WidgetAction,
  WidgetType
} from "@/types/report.type";
// tslint:disable: prefer-for-of
import { ReportWidget } from "@/types/report.type";
import widgetIcons from "./widgets";

type Id = string | number;

export const manageWidget = (
  items: ReportLayout,
  layoutId: Id,
  widget: ReportWidget<unknown>,
  actionType: WidgetAction
) => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.layoutId === layoutId && item.widgets) {
      if (actionType === WidgetAction.DELETE) {
        item.widgets = item.widgets.filter(
          (el: ReportWidget<unknown>) => el.id !== widget?.id
        );
      }
      if (actionType === WidgetAction.ADD) {
        item.widgets.push(widget);
      }
      if (actionType === WidgetAction.UPDATE) {
        item.widgets = item.widgets.map((el: ReportWidget<unknown>) =>
          el.id === widget?.id ? widget : el
        );
      }
      return;
    }
    if (item.children?.length) {
      manageWidget(item.children, layoutId, widget, actionType);
    }
  }
};

const findWidget = (
  widgetId: Id,
  layoutId: Id,
  items: ReportLayout,
  isDelete?: boolean
): ReportWidget<unknown> | undefined => {
  let widget;

  const findRecursively = (widgets: ReportLayout) => {
    for (let i = 0; i < widgets.length; i++) {
      const item = widgets[i];
      if (item.layoutId === layoutId && item.widgets) {
        const data = item.widgets.find(
          (el: ReportWidget<unknown>) => el.id === widgetId
        );
        if (data) {
          widget = data;
          if (isDelete) {
            item.widgets = item.widgets.filter(
              (el: ReportWidget<unknown>) => el.id !== widgetId
            );
          }
          break;
        }
      }
      if (item.children?.length) {
        findRecursively(item.children);
      }
    }
  };

  findRecursively(items);
  return widget;
};

export const moveWidgetToAnotherTarget = (
  items: ReportLayout,
  from: Id,
  to: Id,
  widgetId: Id
) => {
  const layout = JSON.parse(JSON.stringify(items));
  const widget = findWidget(
    widgetId,
    from,
    layout,
    true
  ) as ReportWidget<unknown>;
  manageWidget(layout, to, widget, WidgetAction.ADD);

  return layout;
};

export const updateWidgets = (
  layout: ReportLayout,
  layoutId: Id,
  newWidgets: ReportWidget<unknown>[]
) => {
  for (let i = 0; i < layout.length; i++) {
    const item = layout[i];
    if (item.layoutId === layoutId) {
      item.widgets = [...newWidgets];
    }
    if (item.children?.length) {
      updateWidgets(item.children, layoutId, newWidgets);
    }
  }
  return layout;
};

type WidgetsAmount = {
  [key in WidgetType]?: number;
};
const findAmountByType = (
  structure: ReportLayout,
  widgetsByType: WidgetsAmount = {}
) => {
  for (let i = 0; i < structure.length; i++) {
    const item = structure[i];
    item.widgets?.forEach((el: ReportWidget<unknown>) => {
      widgetsByType[el.key] = (widgetsByType[el.key] || 0) + 1;
    });
    if (item.children?.length) {
      findAmountByType(item.children, widgetsByType);
    }
  }
  return widgetsByType;
};

export const getWidgetsList = (
  widgets: Widget[] = [],
  structure: ReportLayout
) => {
  const amountOfFilters = findAmountByType(structure);
  const filterWidgets = amountOfFilters[WidgetType.FILTERS] || 0;

  return widgets.map((el: Widget) => ({
    ...el,
    icon: widgetIcons[el.key],
    disabled: el.key === WidgetType.FILTERS && filterWidgets > 0
  }));
};
