import styled from "styled-components";

export const KeyContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 12px;
`;

export const KeyItem = styled.p`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const Block = styled.span<{
  color: string;
}>`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: ${({ color }) => color};
  vertical-align: middle;
`;
