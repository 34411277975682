import {
  PageHeaderSubtitle,
  PageHeaderTitle,
  PageHeaderWrapper
} from "@/layouts/AppLayout/style";
import styled from "styled-components";

export const Wrapper = styled(PageHeaderWrapper)`
  padding: 40px;
`;

export const Title = styled(PageHeaderTitle)``;

export const Text = styled(PageHeaderSubtitle)``;
