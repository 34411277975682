import { Button, DropDown, TextField } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import ImageUploadBox from "@/components/ImageUploadBox/ImageUploadBox";
import withPageLoader from "@/HOCs/withPageLoader";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { usePostCreatePartnerMutation } from "@/redux/portalPartners/portalPartners.api";
import { PostCreatePartnerBody } from "@/types/portalPartner.type";
import { Field, Formik } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import * as Styled from "./style";
import { useCountryCodesDropdown } from "@/hooks/data/useCountryCodesDropdown";

export interface AddPartnerValues {
  name: string;
  address: string;
  code: string;
  country_code: string;
}

const AddPartner: FC = () => {
  const [mutate, { isSuccess, isLoading, isError }] =
    usePostCreatePartnerMutation();
  const { t, prefixedT } = useCustomTranslation("ADD_PARTNER");

  const countryData = useCountryCodesDropdown();

  const [uploadedImage, setUploadedImage] = useState<string>("");

  const handleImageUpload = (imageString: string[]) => {
    setUploadedImage(imageString[0]);
  };

  const onSubmit = async (values: AddPartnerValues) => {
    let body: PostCreatePartnerBody = {
      name: values.name,
      address: values.address,
      code: values.code,
      country_code: values.country_code
    };

    if (uploadedImage) {
      body = { ...body, logo_image: uploadedImage };
    }

    await mutate({ body });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (isSuccess) {
  //       await refetch();
  //     }
  //   };

  //   void fetchData();
  // }, [isSuccess]);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(prefixedT("VALIDATION.NAME")),
    address: Yup.string().required(prefixedT("VALIDATION.ADDRESS")),
    country_code: Yup.string().required(prefixedT("VALIDATION.COUNTRY")),
    code: Yup.string().required(prefixedT("VALIDATION.CODE"))
  });

  const initialValues = useMemo(
    (): AddPartnerValues => ({
      name: "",
      address: "",
      code: "",
      country_code: ""
    }),
    []
  );

  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT("TITLE")}</Styled.Title>
      </Styled.TitleContainer>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors, submitForm, setFieldValue, values }) => {
            useEffect(() => {
              const fetchData = async () => {
                if (uploadedImage) {
                  await setFieldValue("image", true);
                }
              };

              void fetchData();
            }, [uploadedImage]);

            return (
              <div>
                <div>
                  <ImageUploadBox
                    label={prefixedT("LOGO.LABEL")}
                    onImageUpload={handleImageUpload}
                  />
                </div>
                <Styled.FormRow>
                  <Field
                    as={TextField}
                    required
                    name="name"
                    testId="name"
                    error={errors.name}
                    label={prefixedT("NAME.LABEL")}
                  />
                  <Field
                    as={TextField}
                    required
                    name="address"
                    testId="address"
                    error={errors.address}
                    label={prefixedT("ADDRESS.LABEL")}
                  />
                  <Field
                    as={TextField}
                    required
                    testId="code"
                    name="code"
                    error={errors.code}
                    label={prefixedT("CODE.LABEL")}
                  />
                  <Field
                    as={DropDown}
                    required={true}
                    styles={{ border: true }}
                    options={countryData as DropDownOption | DropDownOption[]}
                    label={prefixedT("COUNTRY.LABEL")}
                    placeholder={prefixedT("COUNTRY.PLACEHOLDER")}
                    onChange={(option: DropDownOption) =>
                      setFieldValue("country_code", option.value)
                    }
                    countOptions={4}
                    error={errors.country_code?.toString()}
                    value={values.country_code?.toString()}
                    testId="country"
                  />
                </Styled.FormRow>
                <Styled.ButtonContainer>
                  <Button
                    type="submit"
                    onClick={() => {
                      void (async () => {
                        await submitForm();
                      })();
                    }}
                    disabled={isLoading}
                  >
                    {prefixedT("SUBMIT")}
                  </Button>
                </Styled.ButtonContainer>
                {isSuccess && (
                  <Styled.ReturnContainer>
                    <Styled.ReturnText>
                      {prefixedT("RESPONSE.SUCCESS")}
                    </Styled.ReturnText>
                  </Styled.ReturnContainer>
                )}
                {isError && (
                  <Styled.ReturnContainer>
                    <Styled.ReturnText>{t("ERROR.REQUEST")}</Styled.ReturnText>
                  </Styled.ReturnContainer>
                )}
              </div>
            );
          }}
        </Formik>
      </div>
    </Styled.Wrapper>
  );
};

export default AddPartner;
