import React, { FC, Fragment, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

// Styles
import * as Styled from "./style";

// Types
import type { FormUserValues } from "@/redux/portalUsers/portalUsers.api";
import type { UserRole } from "@/types/portalUsers.type";
import type { PartnerAndCompany } from "../../../layouts/UserManagement";

// Components
import { FormUser } from "@/components/Form/FormUser";
import { Button, Loader } from "@/components";

// Utils
import { isPartnerUser, isCompanyUser } from "@/utils/user";
import { ValidationSchemaFormUser } from "@/components/Form/schema";

// Redux
import { useEditUserMutation, useGetProfileQuery } from "@/redux/user/user.api";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useInitialValuesUser } from "@/hooks/form/useInitialValuesUser";

type EditUserProps = FormUserValues & {
  id: number | undefined;
  roles: UserRole[] | undefined;
  partnersAndCompanies: PartnerAndCompany;
  closeOverlay: (refetchValues: boolean) => Promise<void>;
};

const EditUser: FC<EditUserProps> = ({
  id,
  closeOverlay,
  partnersAndCompanies,
  roles,
  ...userData
}) => {
  const { t } = useTranslation();

  const {
    data: profileData,
    isLoading: isProfileDataLoading,
    isUninitialized: isProfileDataUninitialized
  } = useGetProfileQuery({ user_id: id });

  const [
    editUser,
    { isSuccess, isError: isEditUserError, isLoading: isEditUserLoading, reset }
  ] = useEditUserMutation();

  const { prefixedT } = useCustomTranslation("EDIT_USER");
  const initialValues = useInitialValuesUser({
    first_name: userData.first_name,
    last_name: userData.last_name,
    email: userData.email,
    partner_id: userData.partner_id,
    company_id: userData.company_id,
    role_id: userData.role_id,

    phone: userData?.phone,
    metric_system: userData?.metric_system,
    currency: userData?.currency,
    country_code: userData?.country_code,
    units: profileData?.units
  });

  const isFormDataLoading = isProfileDataLoading && !isProfileDataUninitialized;

  // const convertedRoles = roles?.map((role) => ({
  //   value: role.id.toString(),
  //   label: role.role_name,
  // })) as DropDownOption[];

  const onSubmit = (values: FormUserValues) => {
    if (id) {
      const formattedValues = {
        ...values,
        partner_id: isPartnerUser(values.role_id)
          ? values.partner_id
          : undefined,
        company_id: isCompanyUser(values.role_id)
          ? values.company_id
          : undefined
      };

      void editUser({ id: id, body: formattedValues });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await closeOverlay(true);
        reset();
      }
    };

    void fetchData();
  }, [isSuccess]);

  return (
    <>
      <Styled.Underlay
        data-testid={"overlay"}
        onClick={() => {
          void (async () => {
            await closeOverlay(false);
          })();
        }}
      />
      <Styled.DialogBox open={true}>
        <Styled.Wrapper>
          <Styled.TitleContainer>
            <h3>{prefixedT("PAGE_TITLE")}</h3>
          </Styled.TitleContainer>

          {/* // TODO: Refactor - come up with a component for such pattern */}

          {isFormDataLoading ? (
            <Loader />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={ValidationSchemaFormUser}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ submitForm }) => (
                <Fragment>
                  <FormUser
                    roles={roles}
                    partnersAndCompanies={partnersAndCompanies}
                  />

                  <Styled.ButtonContainer>
                    <Button
                      type="submit"
                      onClick={() => {
                        void (async () => {
                          await closeOverlay(false);
                        })();
                      }}
                    >
                      {prefixedT("RETURN_BTN")}
                    </Button>
                    <Button
                      type="submit"
                      disabled={isEditUserLoading}
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                    >
                      {prefixedT("SUBMIT_BTN")}
                    </Button>
                  </Styled.ButtonContainer>
                  {isEditUserError && (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>{t("ERROR.REQUEST")}</Styled.ErrorText>
                    </Styled.ErrorContainer>
                  )}
                </Fragment>
              )}
            </Formik>
          )}
        </Styled.Wrapper>
      </Styled.DialogBox>
    </>
  );
};

export default EditUser;
