import React from "react";

import { Table } from "@/components";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";

import * as Styled from "./style";
import { arrayEquality } from "@/utils/array";
import { IStockFilter } from "@/types/rtk.type";
import { WaypointJob, WaypointJobRequestBody } from "@/types/report.type";
import moment from "moment";
import useCustomTranslation from "@/localization/useCustomTranslation";

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("WAYPOINT_PAGE.JOB_TABLE.HEADER");
  return [
    {
      id: "created_at",
      title: prefixedT("CREATED"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "work_order",
      title: prefixedT("WORK_ORDER"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "leak_type",
      title: prefixedT("LEAK_TYPE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "job_status",
      title: prefixedT("JOB_STATUS"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "comment",
      title: prefixedT("JOB_COMMENTS"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "estimate_leak_size",
      title: prefixedT("LEAK_SIZE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "agreed_flow_reduction",
      title: prefixedT("FLOW_REDUCTION"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "repair_date",
      title: prefixedT("REPAIR_DATE"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "updated_at",
      title: prefixedT("UPDATED_DATE"),
      sortable: false,
      align: TableCellAlignment.center
    }
  ];
};

export const getRowStructure = (
  job: WaypointJob,
  waypointId: number,
  index: number
) => {
  return (
    <Table.Row key={index}>
      <Table.Cell align={TableCellAlignment.center} headerId="created_at">
        <Styled.WrapCell>
          {job.created_at && (
            <a href={`/app/job?waypoint=${waypointId}&job=${job.id}`}>
              {moment(job.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </a>
          )}
        </Styled.WrapCell>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="work_order">
        <Styled.WrapCell>
          <a href={`/app/job?waypoint=${waypointId}&job=${job.id}`}>
            {job.work_order}
          </a>
        </Styled.WrapCell>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="leak_type">
        {job.leak_type}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="job_status">
        {job.job_status}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="comment">
        <Styled.Overflow>{job.comment}</Styled.Overflow>
      </Table.Cell>
      <Table.Cell
        align={TableCellAlignment.center}
        headerId="estimate_leak_size"
      >
        {job.estimate_leak_size}
      </Table.Cell>
      <Table.Cell
        align={TableCellAlignment.center}
        headerId="agreed_flow_reduction"
      >
        {job.agreed_flow_reduction}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="repair_date">
        <Styled.WrapCell>
          {job.repair_date &&
            moment(job.repair_date).format("YYYY-MM-DD HH:mm:ss")}
        </Styled.WrapCell>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="updated_at">
        <Styled.WrapCell>
          {job.updated_at &&
            moment(job.updated_at).format("YYYY-MM-DD HH:mm:ss")}
        </Styled.WrapCell>
      </Table.Cell>
    </Table.Row>
  );
};

export const filterColumns = ["job_status"];

export const sortColumns = [
  "created_at",
  "work_order",
  "leak_type",
  "job_status",
  "comment",
  "estimate_leak_size",
  "agreed_flow_reduction",
  "repair_date",
  "updated_at"
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

export const isQueryEqual = (
  query1: WaypointJobRequestBody,
  query2: WaypointJobRequestBody
): boolean => {
  const keys1 = Object.keys(query1);
  const keys2 = Object.keys(query2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (key === "filter") {
      if (!isFilterEqual(query1.filter, query2.filter)) {
        return false;
      }
    } else if (query1[key] !== query2[key]) {
      return false;
    }
  }

  return true;
};

const isFilterEqual = (
  filter1: IStockFilter,
  filter2: IStockFilter
): boolean => {
  const properties: (keyof IStockFilter)[] = ["job_status", "leak_type"];

  return properties.every(
    (prop) =>
      filter1[prop] !== undefined &&
      filter2[prop] !== undefined &&
      arrayEquality(filter1[prop] as unknown[], filter2[prop] as unknown[])
  );
};
