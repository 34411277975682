import { ContentLoader } from "@/components";
import { useRecoveryMutation } from "@/redux/authorization/authorization.api";
import { getRTKError } from "@/utils/rtk";
import { QueryStatus } from "@reduxjs/toolkit/query";
import React, { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Recovery, { IDecodedData, RecoveryValues } from "./Recovery";
import { useDecode } from "./useDecode";

const initialValues = {
  password: "",
  repeat: "",
  email: ""
};

const RecoveryContainer: FC = () => {
  const { token = "" } = useParams();
  const navigate = useNavigate();
  const [onRecovery, { error, status, isLoading }] = useRecoveryMutation();
  const recoveryError = useMemo(() => getRTKError(error), [error]);

  const decodedData: IDecodedData = useDecode();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (status === QueryStatus.fulfilled) {
      timeout = setTimeout(() => navigate("/auth/login"), 1000);
    }
    return () => clearTimeout(timeout);
  }, [status, navigate]);

  const handleSubmit = (data: RecoveryValues) => {
    onRecovery({
      email: data.email,
      password: data.password,
      token
    });
  };

  return (
    <>
      <ContentLoader active={isLoading} />
      <Recovery
        onSubmit={handleSubmit}
        error={recoveryError}
        initialValues={{ ...initialValues, email: decodedData?.email || "" }}
        status={status}
        decodedData={decodedData}
      />
    </>
  );
};

export default RecoveryContainer;
