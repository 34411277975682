import { Map } from "mapbox-gl";
import {
  MapFilters,
  SessionRelocationObject
} from "@/Providers/FilterProvider";
import { initializeDragOperation } from "./DragEvents/initializeDragOperation";
import { createPolyArea } from "./DragEvents/createPolyArea";
import { unbindMouseTouchEvents } from "./DragEvents/unbindmouseTouchEvents";
import { cursorEvents } from "./DragEvents/cursorEvents";
import { handleTouchEvents } from "./DragEvents/handleTouchEvents";
import { Position } from "geojson";

export const createDraggableMarker = (
  map: Map,
  mapFilters: MapFilters,
  setSessionRelocationObject: React.Dispatch<
    React.SetStateAction<SessionRelocationObject>
  >,
  isDraggingRef: React.MutableRefObject<boolean>,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  polygonCircleCoordinatesRef: React.MutableRefObject<Position[][] | undefined>,
  originalCoordsRef: React.MutableRefObject<{
    lng: number;
    lat: number;
  } | null>
) => {
  createPolyArea(map);

  unbindMouseTouchEvents(map, draggedFeatureIndexRef, isDraggingRef);

  cursorEvents(map);

  initializeDragOperation(
    isDraggingRef,
    map,
    originalCoordsRef,
    mapFilters,
    draggedFeatureIndexRef,
    polygonCircleCoordinatesRef,
    setSessionRelocationObject
  );

  handleTouchEvents(
    map,
    draggedFeatureIndexRef,
    isDraggingRef,
    polygonCircleCoordinatesRef,
    originalCoordsRef,
    setSessionRelocationObject
  );
};
