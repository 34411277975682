import styled from "styled-components";

export const Container = styled.form`
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Icon = styled.div`
  position: absolute;
  top: 12px;
  right: -30px;
`;

export const DropdownContainer = styled.div<{
  containerWidth: number | null;
}>`
  border: 1px solid #d0cfce;
  width: ${({ containerWidth }) => containerWidth + "px"};
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 10px;
  background: white;
  position: absolute;
  z-index: 1;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    background-color: #f3f3f3;
  }
`;
