import React, { FC, useContext } from "react";
import Cell from "../Cell";
import { TableContext } from "../context";
import Menu from "../Menu";
import Row from "../Row";
import { ITableHeader } from "../types";
import * as Styled from "./style";

export interface IHeadProps {
  showMenu?: boolean;
  getHeaderStructure: (row: ITableHeader) => React.ReactNode;
  onClick?: (headerId: string | undefined) => void;
}

const Head: FC<IHeadProps> = ({ getHeaderStructure, onClick, showMenu }) => {
  const { headers } = useContext(TableContext);
  return (
    <Styled.Container data-testid="table-head">
      {showMenu && <Menu />}
      <Row>
        {headers.map((row: ITableHeader) =>
          !row.hidden ? (
            <Cell
              key={row.id}
              onClick={onClick}
              headerId={row.id}
              heading
              {...row}
            >
              {getHeaderStructure(row)}
            </Cell>
          ) : null
        )}
      </Row>
    </Styled.Container>
  );
};

export default Head;
