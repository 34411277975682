import useCustomTranslation from "@/localization/useCustomTranslation";
import { DailyTypes } from "@/types/portalUsers.type";
import React, { FC, useState } from "react";
import * as Styled from "./style";

interface MetricWidgetProps {
  aiIdentified: DailyTypes;
  forRepair: DailyTypes;
  delivered: DailyTypes;
}

const MetricWidget: FC<MetricWidgetProps> = ({
  aiIdentified,
  forRepair,
  delivered
}) => {
  const { prefixedT } = useCustomTranslation("METRIC_WIDGET");
  const [open, setOpen] = useState<string>("leakage");

  const getType = (data: DailyTypes, metric: string) => {
    switch (metric) {
      case "leakage":
        return data.daily_leakage?.type;
      case "cost":
        return data.daily_cost?.type;
      case "co2":
        return data.daily_co2_usage?.type;
      default:
        return "";
    }
  };

  const getValue = (data: DailyTypes, metric: string) => {
    switch (metric) {
      case "leakage":
        return data.daily_leakage?.value;
      case "cost":
        return data.daily_cost?.value;
      case "co2":
        return data.daily_co2_usage?.value;
      default:
        return null;
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.ButtonContainer>
        <Styled.Button
          data-testid="leakage-btn"
          selected={open === "leakage"}
          onClick={() => setOpen("leakage")}
        >
          <Styled.WaterImg />
        </Styled.Button>
        <Styled.Button
          data-testid="cost-btn"
          selected={open === "cost"}
          onClick={() => setOpen("cost")}
        >
          <Styled.CostImg />
        </Styled.Button>
        <Styled.Button
          data-testid="co2-btn"
          selected={open === "co2"}
          onClick={() => setOpen("co2")}
        >
          <Styled.Co2Img />
        </Styled.Button>
      </Styled.ButtonContainer>
      <Styled.TextContainer>
        <div>
          <Styled.ValueText>
            {open === "cost" && getType(aiIdentified, open)}
            {getValue(aiIdentified, open)}
            {open === "co2" && getType(aiIdentified, open)}
          </Styled.ValueText>
          <Styled.DayText>
            {open === "leakage"
              ? getType(aiIdentified, open)
              : prefixedT("DESCRIPTION")}
          </Styled.DayText>
          <Styled.IdentifierText>{prefixedT("TITLE_A")}</Styled.IdentifierText>
        </div>
        <div>
          <Styled.ValueText>
            {open === "cost" && getType(forRepair, open)}
            {getValue(forRepair, open)}
            {open === "co2" && getType(forRepair, open)}
          </Styled.ValueText>
          <Styled.DayText>
            {open === "leakage"
              ? getType(forRepair, open)
              : prefixedT("DESCRIPTION")}
          </Styled.DayText>
          <Styled.IdentifierText>{prefixedT("TITLE_B")}</Styled.IdentifierText>
        </div>
        <div>
          <Styled.ValueText>
            {open === "cost" && getType(delivered, open)}
            {getValue(delivered, open)}
            {open === "co2" && getType(delivered, open)}
          </Styled.ValueText>
          <Styled.DayText>
            {open === "leakage"
              ? getType(delivered, open)
              : prefixedT("DESCRIPTION")}
          </Styled.DayText>
          <Styled.IdentifierText>{prefixedT("TITLE_C")}</Styled.IdentifierText>
        </div>
      </Styled.TextContainer>
    </Styled.Wrapper>
  );
};

export default MetricWidget;
