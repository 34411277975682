import {
  FormattedStockData,
  GetDeviceDistributionResponse
} from "@/types/stock.type";

/**
 * Formatting the data for the stock distribution chart and cards
 * @param data of type GetDeviceDistributionResponse
 * @returns formatted array of type FormattedStockData
 */
export const formatStockData = (
  data: GetDeviceDistributionResponse
): FormattedStockData[] => {
  return data.data.map((companyStockInfo) => {
    const companyName = Object.keys(companyStockInfo)[0];
    const stockInfo = Object.values(companyStockInfo)[0];
    return { name: companyName, ...stockInfo };
  });
};
