import React, {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo
} from "react";

// Types
import { GISUploadData, GISUploadValues } from "@/types/gisUpload.type";

import { Optional } from "@/types/util.type";

// Redux
import { useAppDispatch, useAppSelector } from "@/redux";
import {
  // initUpload,
  setErrorModalOpen as setErrorModalOpenAction,
  setUploadValues as setUploadValuesAction,
  setErrors as setErrorsAction,
  setCurrentStep as setCurrentStepAction,
  setLoading as setLoadingAction,
  setMappedSystemFields as setMappedSystemFieldsAction,
  setSelectedValues as setSelectedValuesAction,
  resetUpload as resetUploadAction,
  CommonUploadId,
  CommonUploadValues,
  UploadId
} from "@/redux/gisUpload/gisUpload.slice";
import { makeUploadValuesSelector } from "@/redux/gisUpload/gisUpload.selector";
import { makeWatchedUploadValues } from "@/redux/gisUpload/gisUpload.util";

export interface GISUploadContextType {
  // selectedValues: FileSelectedValues[];
  // setSelectedValues: React.Dispatch<React.SetStateAction<FileSelectedValues[]>>;
  // companyId: number | null;
  // setCompanyId: React.Dispatch<React.SetStateAction<number | null>>;
  // fileIndex: number;
  // setFileIndex: React.Dispatch<React.SetStateAction<number>>;
  // currentStep: GISRoutes;
  // setCurrentStep: React.Dispatch<React.SetStateAction<GISRoutes>>;
  uploadId: UploadId;
  setUploadId: React.Dispatch<React.SetStateAction<UploadId>>;
  // isLoading: boolean;
  // setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // isErrorModalVisible: boolean;
  // setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // errors: Array<ErrorModalText> | null;
  // setErrors: React.Dispatch<React.SetStateAction<Array<ErrorModalText> | null>>;
  // mappedSystemFields: MappedSystemFields;
  // setMappedSystemFields: React.Dispatch<
  //   React.SetStateAction<MappedSystemFields>
  // >;
  // resetProvider: () => void;
}

export const GISUploadContext = createContext<GISUploadContextType>({
  uploadId: null,
  setUploadId: () => {}
});

/*
 * Upload React context
 */

export const GISUploadProvider: FC<{ children?: ReactNode }> = ({
  children
}) => {
  // const [selectedValues, setSelectedValues] = useState<FileSelectedValues[]>(
  //   [],
  // );
  // const [companyId, setCompanyId] = useState<number | null>(null);
  // const [fileIndex, setFileIndex] = useState<number>(0);
  // const [currentStep, setCurrentStep] = useState<GISRoutes>(
  //   GISRoutes.UPLOAD_SHAPEFILE,
  // );
  // const [isErrorModalVisible, setIsErrorModalVisible] =
  //   useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [isError, setIsError] = useState<Array<ErrorModalText> | null>(null);
  // const [mappedSystemFields, setMappedSystemFields] =
  //   useState<MappedSystemFields>(defaultSystemFields);
  const [uploadId, setUploadId] = useState<number | null>(null);

  // const resetProvider = () => {
  //   setSelectedValues([]);
  //   setMappedSystemFields(defaultSystemFields);
  //   setUploadId(null);
  //   setCompanyId(null);
  //   setCurrentStep(GISRoutes.UPLOAD_SHAPEFILE);
  //   setIsLoading(false);
  //   setIsError(null);
  //   setFileIndex(0);
  // };

  // useEffect(() => {
  //   if (currentStep === GISRoutes.UPLOAD_SHAPEFILE) {
  //     resetProvider();
  //   }
  // }, [currentStep]);

  return (
    <GISUploadContext.Provider
      value={{
        // isError,
        // setIsError,
        // mappedSystemFields,
        // setMappedSystemFields,
        // fileIndex,
        // setFileIndex,
        // selectedValues,
        // setSelectedValues,
        // setCurrentStep,
        // currentStep,
        // companyId,
        // setCompanyId,
        uploadId,
        setUploadId
        // isLoading,
        // setIsLoading,
        // resetProvider,
        // isErrorModalVisible,
        // setIsErrorModalVisible,
      }}
    >
      {children}
    </GISUploadContext.Provider>
  );
};

export const withUploadContextProvider = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  const WrappedComponent: React.FC<P> = (props) => (
    <GISUploadProvider>
      <Component {...(props as P)} />
    </GISUploadProvider>
  );

  WrappedComponent.displayName = `withUploadContextProvider(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export const useGISUploadContext = () => {
  const context = useContext(GISUploadContext);
  return context;
};

/*
 * Upload values
 */

export const useGISUploadMethods = () => {
  const dispatch = useAppDispatch();
  const context = useContext(GISUploadContext);
  const uploadId = context?.uploadId;

  const setUploadValues = ({
    uploadId: uploadIdProp,
    data,
    ...uploadState
  }: Optional<CommonUploadId, "uploadId"> & CommonUploadValues) => {
    dispatch(
      setUploadValuesAction({
        uploadId: uploadIdProp ?? uploadId,
        data,
        ...uploadState
      })
    );
  };
  const setLoading = (isLoading: boolean) => {
    dispatch(setLoadingAction({ uploadId, isLoading }));
  };
  const setErrors = (errors: GISUploadValues["errors"]) => {
    dispatch(setErrorsAction({ uploadId, errors }));
  };
  const setMappedSystemFields = (
    mappedSystemFields: GISUploadData["mappedSystemFields"]
  ) => {
    dispatch(setMappedSystemFieldsAction({ uploadId, mappedSystemFields }));
  };
  const setSelectedValues = (
    selectedValues: GISUploadData["selectedValues"]
  ) => {
    dispatch(setSelectedValuesAction({ uploadId, selectedValues }));
  };

  const setCurrentStep = (currentStep: number) => {
    dispatch(setCurrentStepAction({ uploadId, currentStep }));
  };
  const setErrorModalOpen = (isOpen: boolean) => {
    dispatch(setErrorModalOpenAction({ uploadId, isOpen }));
  };
  const resetUpload = () => {
    dispatch(resetUploadAction({ uploadId }));
  };

  // console.log('[gis][useGISUploadMethods] render: ', {
  //   uploadId,
  // });

  return {
    setErrors,
    setErrorModalOpen,
    setUploadValues,
    setLoading,
    setCurrentStep,
    setMappedSystemFields,
    setSelectedValues,
    resetUpload
  };
};

export const useGISUploadValues = () => {
  const context = useContext(GISUploadContext);
  const uploadId = context?.uploadId;

  /*
   * Upload state values
   */

  const selectUploadValues = useMemo(makeUploadValuesSelector, [uploadId]);
  const uploadValues = useAppSelector((state) =>
    selectUploadValues(state, uploadId)
  );

  // console.log('[gis][useGISUploadValues] render: ', {
  //   uploadId,
  //   uploadValues,
  //   selectUploadValues,
  // });

  const watchedUploadValues = useMemo(
    () => makeWatchedUploadValues(uploadValues),
    [uploadValues]
  );

  return watchedUploadValues;
};

export type GISUploadValuesType = ReturnType<typeof useGISUploadValues>;
export type GISUploadMethodsType = ReturnType<typeof useGISUploadMethods>;
