import { useFormContext } from "react-hook-form";
import { format } from "date-fns";

// Components
import { FaCalendar } from "react-icons/fa";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Button,
  cn,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Calendar,
  ReactSelect
} from "@fido/ui-lib";

// Types
import type { ProjectFormValues } from "../schema";

// Hooks
import { useGetUnassignedDMAsQuery } from "@/redux/portalProjects/portalProjects.api";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useUploadDMAs } from "@/hooks/query/useUploadDMAs";

export const ProjectData = () => {
  const { prefixedT } = useCustomTranslation("ADD_PROJECT");

  const form = useFormContext<ProjectFormValues>();
  const gisUploadId = form.watch("gisUploadId");

  // const {
  //   data: unassignedDMAsData,
  //   isFetching: areDMAsFetching,
  //   isSuccess: areDMAsSuccess
  // } = useGetUnassignedDMAsQuery(
  //   {
  //     company_id: companyId
  //   },
  //   {
  //     refetchOnMountOrArgChange: true,
  //     skip: !companyId
  //   }
  // );

  const { data: uploadData, isSuccess: isUploadDataSuccess } = useUploadDMAs({
    uploadId: gisUploadId
  });

  const hasDMAs =
    isUploadDataSuccess && uploadData.status === "EXISTING" && uploadData.data;
  const dmaOptions = hasDMAs
    ? Object.values(uploadData.data)
        ?.map((dmaKey) => ({
          value: dmaKey,
          label: dmaKey
        }))
        .toSorted((a, b) => {
          return (a.label as string).localeCompare(b.label as string);
        })
    : [];

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name="costPerDistance"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Cost per distance ($)</FormLabel>
              <FormControl>
                <Input type="number" placeholder="0" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="dmaIds"
          render={({ field }) => (
            <FormItem>
              <FormLabel>DMAs</FormLabel>
              <FormControl>
                <ReactSelect
                  isMulti
                  isClearable={false}
                  id="dma-select-id"
                  inputId="dma-input-id"
                  name="dmaIds"
                  isDisabled={field.disabled || !hasDMAs}
                  options={dmaOptions}
                  noOptionsMessage={() => prefixedT("DMAS.NO_MATCHING")}
                  placeholder={prefixedT("DMAS.PLACEHOLDER")}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name="startDate"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel required>Start date</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "text-left font-normal",
                        !field.value && "text-muted-foreground"
                      )}
                      disabled={field.disabled}
                    >
                      {field.value ? (
                        format(field.value, "dd-MM-yyyy")
                      ) : (
                        <span>Pick a date</span>
                      )}
                      <FaCalendar className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    data-testid="start-date"
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) =>
                      date > new Date() || date < new Date("1900-01-01")
                    }
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="endDate"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel required>End date</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "text-left font-normal",
                        !field.value && "text-muted-foreground"
                      )}
                      disabled={field.disabled}
                    >
                      {field.value ? (
                        format(field.value, "dd-MM-yyyy")
                      ) : (
                        <span>Pick a date</span>
                      )}
                      <FaCalendar className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    data-testid="end-date"
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) => date < form.getValues().startDate}
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name="targetDistance"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target Distance</FormLabel>
              <FormControl>
                <Input type="number" placeholder="0" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="targetLeakage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target Leakage</FormLabel>
              <FormControl>
                <Input type="number" placeholder="0" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </>
  );
};
