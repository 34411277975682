import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldProps, useFormikContext } from "formik";

// Components
import { DropDown, Loader, TextField } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import InputPhone from "@/components/InputPhone/InputPhone";

// Styles
import * as Styled from "./style";

// Redux
import {
  FormUserValues,
  useGetRolesQuery,
  useGetUserUnitsQuery
} from "@/redux/portalUsers/portalUsers.api";
// import * as Styled from './style';
// import { ValidationSchema } from './schema';

// Constants
import { MeasurementSystem } from "@/constants/Measurement.constant";
import { AllCountries } from "@/constants/Country.constant";
import { Currency } from "@/constants/Currency.constant";
import {
  UnitCategory,
  UnitCategoryData,
  UnitSymbol
} from "@/constants/Unit.constant";

// Types
import type { PartnerAndCompany } from "@/features/user-management/layouts/UserManagement";
import type { Unit as UnitType } from "@/types/unit.type";
import type { UserRole } from "@/types/portalUsers.type";

// Utils
import {
  companyAdminId,
  companyRoleIds,
  partnerAdminId,
  partnerRoleIds
} from "@/utils/user";
import { mapDefaultUnits } from "@/utils/unit";
import { MeasurementSystem as MeasurementSystemType } from "@/types/measurement.type";
import { DisabledFields } from "./types";

// TODO: Refactor - mb create generic type for forms FormProps<T> and use it in all forms
type FormUserProps = {
  disabledFields?: DisabledFields<FormUserValues>;
  roles?: UserRole[] | undefined;
  partnersAndCompanies: PartnerAndCompany;
};

export const FormUser = ({
  disabledFields,
  roles: rolesProp,
  partnersAndCompanies
}: FormUserProps) => {
  const { t } = useTranslation();
  const { errors, setFieldValue, values } = useFormikContext<FormUserValues>();

  // const [selectedCountry, setSelectedCountry] = useState<Country>('GB');
  const { data: rolesData, isLoading: areRoleQueryLoading } = useGetRolesQuery(
    undefined,
    {
      skip: !!rolesProp
    }
  );
  const roles = rolesProp || rolesData;
  const areRolesLoading = areRoleQueryLoading && !rolesProp;

  const {
    data: defaultUnitsData
    // isLoading: areUnitsLoading,
    // isFetching: areUnitsFetching,
    // isSuccess: areUnitsSuccess,
    // isUninitialized: areUnitsUninitialized,
    // isError: areUnitsError,
  } = useGetUserUnitsQuery();

  // console.log('[form][user] data: ', {
  //   values,
  //   defaultUnitsData,
  //   areUnitsSuccess,
  // });

  const dropdownRoles = useMemo(
    () =>
      roles?.map((role) => ({
        value: role.id.toString(),
        label: role.role_name
      })) as DropDownOption[],
    [roles]
  );

  const isCompanyAdmin = useMemo(
    () => roles?.some((role) => role.id === companyAdminId),
    [roles]
  );

  const isPartnerAdmin = useMemo(
    () => roles?.some((role) => role.id === partnerAdminId),
    [roles]
  );

  useEffect(() => {
    if (values.role_id === 1) {
      void setFieldValue("partner_id", undefined);
      void setFieldValue("company_id", undefined);
      return;
    }
    if (values.role_id) {
      if (partnerRoleIds.includes(values.role_id)) {
        void setFieldValue("company_id", undefined);
        return;
      }
      if (companyRoleIds.includes(values.role_id)) {
        void setFieldValue("partner_id", undefined);
        return;
      }
    }
  }, [values.role_id]);

  // useEffect(() => {
  //   console.log('[form][units] useeffect - metric systme: ', {
  //     defaultUnitsData,
  //     ms: values.metric_system,
  //     values,
  //   });
  //   if (defaultUnitsData && areUnitsSuccess) {
  //     const defaultUnits = mapDefaultUnits(defaultUnitsData, values.metric_system);
  //     console.log('[form][units] setting units: ', {
  //       defaultUnitsData,
  //       defaultUnits,
  //       ms: values.metric_system,
  //       values,
  //     });
  //     setFieldValue('units', defaultUnits);
  //   }
  // }, [defaultUnitsData, areUnitsSuccess, values.metric_system]);

  if (areRolesLoading) {
    return <Loader />;
  }

  return (
    <Styled.FormWrapper>
      <Styled.FormGroup>
        <Field
          as={TextField}
          styles={{ noPadding: true }}
          required
          disabled={disabledFields?.first_name}
          name="first_name"
          testId="first-name"
          error={errors.first_name}
          label={t("FORM.LABEL.FIRST_NAME")}
        />
        <Field
          as={TextField}
          styles={{ noPadding: true }}
          required
          disabled={disabledFields?.last_name}
          name="last_name"
          testId="last-name"
          error={errors.last_name}
          label={t("FORM.LABEL.LAST_NAME")}
        />
      </Styled.FormGroup>
      <Styled.FormGroup>
        <Field
          as={TextField}
          styles={{ noPadding: true }}
          required
          testId="email"
          disabled={disabledFields?.email}
          name="email"
          error={errors.email}
          label={t("FORM.LABEL.EMAIL")}
        />
        <Field
          as={DropDown}
          required
          disabled={disabledFields?.role_id}
          styles={{ border: true }}
          options={dropdownRoles as DropDownOption | DropDownOption[]}
          label={t("FORM.LABEL.ROLE")}
          placeholder={t("FORM.PLACEHOLDER.ROLE")}
          onChange={(option: DropDownOption) =>
            setFieldValue("role_id", Number(option.value))
          }
          countOptions={4}
          error={errors.role_id?.toString()}
          value={values.role_id?.toString()}
          name="role_id"
          testId="role"
        />
      </Styled.FormGroup>
      {values.role_id !== 1 && (
        <>
          {isPartnerAdmin &&
            values.role_id &&
            partnerRoleIds.includes(values.role_id) && (
              <Styled.FormGroup cols={2}>
                <Field
                  as={DropDown}
                  required={
                    values.role_id && partnerRoleIds.includes(values.role_id)
                  }
                  styles={{ border: true }}
                  options={
                    partnersAndCompanies.partners as
                      | DropDownOption
                      | DropDownOption[]
                  }
                  label={t("FORM.LABEL.PARTNER")}
                  placeholder={t("FORM.PLACEHOLDER.PARTNER")}
                  onChange={(option: DropDownOption) =>
                    setFieldValue("partner_id", Number(option.value))
                  }
                  countOptions={4}
                  error={errors.partner_id?.toString()}
                  value={values.partner_id?.toString()}
                  testId="partner"
                />
              </Styled.FormGroup>
            )}

          {isCompanyAdmin &&
            values.role_id &&
            companyRoleIds.includes(values.role_id) && (
              <Styled.FormGroup cols={2}>
                <Field
                  as={DropDown}
                  required={
                    values.role_id && companyRoleIds.includes(values.role_id)
                  }
                  styles={{ border: true }}
                  options={
                    partnersAndCompanies.companies as
                      | DropDownOption
                      | DropDownOption[]
                  }
                  label={t("FORM.LABEL.COMPANY")}
                  placeholder={t("FORM.PLACEHOLDER.COMPANY")}
                  onChange={(option: DropDownOption) =>
                    setFieldValue("company_id", Number(option.value))
                  }
                  countOptions={4}
                  error={errors.company_id?.toString()}
                  value={values.company_id?.toString()}
                  testId="company"
                />
              </Styled.FormGroup>
            )}
        </>
      )}
      <Styled.FormGroup gridCols="minmax(25ch, 1fr) min(9ch) minmax(19ch, 1fr)">
        <Field disabled={disabledFields?.phone} name="phone" required>
          {({
            field, // { name, value, onChange, onBlur }
            // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta
          }: FieldProps<FormUserValues["phone"]>) => {
            // console.log('[inuput] phone: ', {
            //   field,
            //   form,
            //   meta,
            //   value: field.value,
            //   value2: values.phone,
            //   pr: form.getFieldProps('phone'),
            //   helpers: form.getFieldHelpers('phone'),
            // });
            return (
              <div>
                <Styled.Label
                  as="label"
                  htmlFor="input-phone"
                  isError={!!meta.error}
                >
                  {t("FORM.LABEL.PHONE")}{" "}
                  <span className="text-destructive">*</span>
                </Styled.Label>
                <InputPhone
                  id="input-phone"
                  testId="input-phone"
                  error={meta.error}
                  {...field}
                  value={field.value!}
                  onChange={(value) =>
                    void setFieldValue(field.name, value as string)
                  }
                  // onCountryChange={(country) =>
                  //   setSelectedCountry(country || 'GB')
                  // }
                />
                {Boolean(meta.error) && (
                  <Styled.Error>{meta.error}</Styled.Error>
                )}
              </div>
            );
          }}
        </Field>

        <Field
          as={DropDown}
          required
          styles={{ border: true }}
          options={
            Object.values(Currency).map((c) => ({
              label: c.symbol,
              value: c.code
            })) as DropDownOption | DropDownOption[]
          }
          label={t("FORM.LABEL.CURRENCY")}
          placeholder={t("FORM.PLACEHOLDER.CURRENCY")}
          onChange={(option: DropDownOption) =>
            setFieldValue("currency", option.value)
          }
          countOptions={4}
          error={errors.currency}
          value={values.currency}
          disabled={disabledFields?.currency}
          name="currency"
          testId="currency"
        />

        <Field
          as={DropDown}
          required
          styles={{ border: true }}
          options={
            AllCountries.map((c) => ({
              label: c.name,
              value: c["alpha-3"]
            })) as DropDownOption | DropDownOption[]
          }
          label={t("FORM.LABEL.COUNTRY")}
          placeholder={t("FORM.PLACEHOLDER.COUNTRY")}
          onChange={(option: DropDownOption) => {
            setFieldValue("country_code", option.value);
          }}
          countOptions={4}
          error={errors.country_code}
          value={values.country_code}
          disabled={disabledFields?.country_code}
          name="country_code"
          testId="country_code"
        />
      </Styled.FormGroup>
      <Styled.FormGroup>
        <Field
          as={DropDown}
          required
          styles={{ border: true }}
          options={
            Object.values(MeasurementSystem).map((ms) => ({
              label: ms,
              value: ms
            })) as DropDownOption | DropDownOption[]
          }
          label={t("FORM.LABEL.MEASUREMENT_SYSTEM")}
          placeholder={t("FORM.PLACEHOLDER.MEASUREMENT_SYSTEM")}
          onChange={async (option: DropDownOption) => {
            const newDefaultUnits = mapDefaultUnits(
              defaultUnitsData,
              option.value as MeasurementSystemType
            );

            setFieldValue("metric_system", option.value);
            setFieldValue("units", newDefaultUnits);
          }}
          countOptions={4}
          error={errors.metric_system}
          value={values.metric_system}
          disabled={disabledFields?.metric_system}
          name="metric_system"
          testId="metric_system"
        />
      </Styled.FormGroup>

      <Styled.FormGroup cols={2}>
        {(Object.keys(UnitCategory) as Array<keyof typeof UnitCategory>).map(
          (cKey) => {
            const category = UnitCategory[cKey];

            const { options } = UnitCategoryData[category];

            return (
              <Field
                key={`input-${cKey}`}
                as={DropDown}
                required
                styles={{ border: true }}
                options={
                  options.map((u) => ({
                    label: UnitSymbol[u],
                    value: u
                  })) as DropDownOption | DropDownOption[]
                }
                label={t(`FORM.LABEL.${cKey}`)}
                placeholder={t(`FORM.PLACEHOLDER.${cKey}`)}
                onChange={(option: DropDownOption) => {
                  const newUnits = [...values.units!].map((uObject) => {
                    if (uObject.unit_category === category) {
                      return {
                        ...uObject,
                        unit: option.value,
                        unit_symbol: UnitSymbol[option.value as UnitType]
                      };
                    }

                    return uObject;
                  });

                  setFieldValue("units", newUnits);
                }}
                countOptions={4}
                // error={errors.units}
                value={
                  values.units?.find((uo) => uo.unit_category === category)
                    ?.unit
                }
                testId={`units-${category}`}
                disabled={disabledFields?.units}
                name={`units-${category}`}
              />
            );
          }
        )}
      </Styled.FormGroup>
    </Styled.FormWrapper>
  );
};
