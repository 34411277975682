import styled, { FlattenSimpleInterpolation } from "styled-components";

export const Label = styled.span<{
  labelStyle?: FlattenSimpleInterpolation;
  isError?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ isError, theme }) =>
    theme.colors[isError ? "coral" : "deepDark"]};
  ${({ labelStyle }) => labelStyle || null};
  white-space: nowrap;
  overflow: hidden;
`;
