import { PartnerAndCompany } from "@/features/user-management/layouts/UserManagement";
import { useGetCompanyListQuery } from "@/redux/portalCompanies/portalCompanies.api";
import { useGetPartnerListQuery } from "@/redux/portalPartners/portalPartners.api";
import { useMemo } from "react";

export const usePartnersAndCompanies = () => {
  const { data: partnerData, isSuccess: isPartnerSuccess } =
    useGetPartnerListQuery();
  const { data: companyData, isSuccess: isCompanySuccess } =
    useGetCompanyListQuery();

  const partnersAndCompanies = useMemo(() => {
    const mappedPartnerAndCompanies: PartnerAndCompany = {
      partners: [],
      companies: []
    };

    if (!isPartnerSuccess || !isCompanySuccess) {
      return mappedPartnerAndCompanies;
    }

    const existingCompanySet = new Set();

    partnerData?.forEach((partner) => {
      mappedPartnerAndCompanies.partners.push({
        label: partner.partner_name,
        value: partner.id.toString()
      });

      partner.companies.forEach((company) => {
        const companyId = company.id.toString();

        if (!existingCompanySet.has(companyId)) {
          mappedPartnerAndCompanies.companies.push({
            label: company.company_name,
            value: companyId
          });
          existingCompanySet.add(companyId);
        }
      });
    });

    companyData?.forEach((company) => {
      const companyId = company.id.toString();

      if (!existingCompanySet.has(companyId)) {
        mappedPartnerAndCompanies.companies.push({
          label: company.name,
          value: companyId
        });
        existingCompanySet.add(companyId);
      }
    });

    return mappedPartnerAndCompanies;
  }, [partnerData, companyData, isPartnerSuccess, isCompanySuccess]);

  return partnersAndCompanies;
};
