// Components
import { WebsocketHandler } from "@/components/WebsocketHandler";

// Redux
import { useAppSelector } from "@/redux";
import { websocketIdsSelector } from "@/redux/websocket/websocket.selector";

export const WebsocketManager = () => {
  const websocketIds = useAppSelector(websocketIdsSelector);

  return websocketIds.map((wsId) => (
    <WebsocketHandler key={`ws-${wsId}`} websocketId={wsId} />
  ));
};
