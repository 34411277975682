import { Map } from "mapbox-gl";
import {
  Feature,
  Geometry,
  GeoJsonProperties,
  FeatureCollection,
  Position
} from "geojson";
import * as turf from "@turf/turf";
import { MapMoveEvent } from "./type";

export const updatePolyArea = (
  map: Map,
  event: MapMoveEvent,
  polygonCircleCoordinatesRef: React.MutableRefObject<
    Position[][][] | Position[][] | undefined
  >
) => {
  const coords: Position = [event.lngLat.lng, event.lngLat.lat];
  const clickedPoint = turf.point(coords);
  const bufferedPolygon = turf.buffer(clickedPoint, 100, {
    units: "meters"
  });

  polygonCircleCoordinatesRef.current = bufferedPolygon.geometry.coordinates;

  const feature = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: polygonCircleCoordinatesRef.current
    },
    properties: { id: "bufferedPolygon" }
  } as Feature<Geometry, GeoJsonProperties>;

  const setConfig: FeatureCollection = {
    type: "FeatureCollection",
    features: [feature]
  };

  (map.getSource("bufferedPolygonSource") as mapboxgl.GeoJSONSource).setData(
    setConfig
  );
};
