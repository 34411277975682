import { Button, TextField } from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useChangePasswordMutation } from "@/redux/authorization/authorization.api";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Field, Formik } from "formik";
import React, { FC, useMemo } from "react";
import { ValidationSchema } from "./schema";
import * as Styled from "./style";

interface changePasswordValues {
  email: string;
  password: string;
  newPassword: string;
}

const ChangePassword: FC = () => {
  const { t, prefixedT } = useCustomTranslation("PROFILE_PAGE");
  const [onChangePassword, { status, isLoading }] = useChangePasswordMutation();

  const onSubmit = (values: changePasswordValues) => {
    onChangePassword(values);
  };

  const initialValues = useMemo(
    (): changePasswordValues => ({
      email: "",
      password: "",
      newPassword: ""
    }),
    []
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, submitForm }) => (
        <Styled.Form>
          <Styled.Fields>
            <Field
              type="email"
              name="email"
              as={TextField}
              label={prefixedT("FORM.EMAIL.LABEL")}
              placeholder={prefixedT("FORM.EMAIL.PLACEHOLDER")}
              error={errors.email && t(errors.email)}
              testId="email"
            />
            <Field
              type="password"
              name="password"
              as={TextField}
              label={prefixedT("FORM.PASSWORD.LABEL")}
              placeholder={prefixedT("FORM.PASSWORD.PLACEHOLDER")}
              error={errors.password && t(errors.password)}
              testId="password"
            />
            <Field
              type="password"
              name="newPassword"
              as={TextField}
              label={prefixedT("FORM.NEW_PASSWORD.LABEL")}
              placeholder={prefixedT("FORM.NEW_PASSWORD.PLACEHOLDER")}
              error={errors.newPassword && t(errors.newPassword)}
              testId="newPassword"
            />
          </Styled.Fields>
          <Styled.ButtonContainer>
            <Button
              testId="submit-btn"
              disabled={isLoading}
              width="100%"
              height="50px"
              onClick={submitForm}
            >
              {prefixedT("FORM.CHANGE_PASSWORD.SUBMIT")}
            </Button>
            {status === QueryStatus.fulfilled && (
              <p>{prefixedT("FORM.CHANGE_PASSWORD.SUCCESS")}</p>
            )}
            {status === QueryStatus.rejected && (
              <p>{prefixedT("FORM.CHANGE_PASSWORD.BAD_AUTH")}</p>
            )}
            {isLoading && <p>{prefixedT("FORM.CHANGE_PASSWORD.LOADING")}</p>}
          </Styled.ButtonContainer>
        </Styled.Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
