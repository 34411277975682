import theme from "@/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  border: 3px solid #f9f9f9;
  border-radius: 25px;
  padding: 20px;
`;

export const TableTitle = styled.h1`
  margin-bottom: 0;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const DropDownContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
`;

export const Container = styled.div`
  display: flex;
  align-items: baseline;
`;

export const WrapCell = styled.div`
  text-wrap: wrap;
`;

export const TableHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const TableHeaderTitle = styled.h3`
  margin-bottom: 10px;
  font-weight: 500;
`;

export const TableHeaderSection = styled.div`
  background: ${theme.colors.seashell};
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
`;

export const SearchBarContainer = styled.div`
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const SearchBarContainerInner = styled.div`
  margin-right: 40px;
`;

export const DatePickerContainer = styled.div`
  margin-bottom: -16px;
`;

export const CountContainer = styled.div`
  margin-left: 5px;
  padding: 6px;
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.seashell};
  border-radius: 5px;
`;

export const Input = styled.input`
  margin: 0 auto;
  width: 100%;
  height: 40px;
  padding: 0 14px;
  font-size: 14px;
  border: 1px solid #D0CFCE;
  border-radius: 10px;
  outline: none;
  &:focus{
    border: 1px solid #008ABF;
    transition: 0.35s ease;
    color: ${theme.colors.blue}
    &::-webkit-input-placeholder{
      transition: opacity 0.45s ease;
      opacity: 0;
    }
    &::-moz-placeholder {
      transition: opacity 0.45s ease; 
      opacity: 0;
    }
    &:-ms-placeholder {
      transition: opacity 0.45s ease; 
      opacity: 0;
    }    
  }
`;
