import useCustomTranslation from "@/localization/useCustomTranslation";
import { Largest, NameAndDays } from "@/types/portalUsers.type";
import React, { FC } from "react";
import * as Styled from "./style";

interface WaypointSizeWidgetProps {
  latest: NameAndDays;
  longest: NameAndDays;
  largest: Largest;
}

const WaypointTitleColumn: FC<{
  name: string | null;
  id: number;
  error: string;
}> = ({ name, id, error }) => (
  <Styled.ColumnName isAnimated={name && name.length > 10 ? true : false}>
    {name ? (
      <Styled.Link target="_blank" to={`/app/waypoint?waypoint=${id}`}>
        {name}
      </Styled.Link>
    ) : (
      error
    )}
  </Styled.ColumnName>
);

const WaypointDayColumn: FC<{
  days: number | null;
  dayTitle: string;
  dayTitlePlural: string;
}> = ({ days, dayTitle, dayTitlePlural }) => (
  <>
    {days !== null && (
      <Styled.ColumnDays>
        {days} {days === 1 ? dayTitle : dayTitlePlural}
      </Styled.ColumnDays>
    )}
  </>
);

const WaypointSizeWidget: FC<WaypointSizeWidgetProps> = ({
  latest,
  longest,
  largest
}) => {
  const { prefixedT, t } = useCustomTranslation("WAYPOINT_SIZE_WIDGET");

  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <p>{prefixedT("TITLE")}</p>
      </Styled.TitleContainer>
      <Styled.Row>
        <Styled.Column>
          <Styled.ColumnTitle>{prefixedT("SUBTITLE_A")}</Styled.ColumnTitle>
          <Styled.WaypointWrapper>
            <Styled.LatestImg />
            <Styled.TextContainer>
              <WaypointTitleColumn
                name={latest.name}
                id={latest.id}
                error={prefixedT("NO_NAME")}
              />
              <WaypointDayColumn
                days={latest.days}
                dayTitle={t("WAYPOINT_SIZE_WIDGET.DAY")}
                dayTitlePlural={t("WAYPOINT_SIZE_WIDGET.DAY_other")}
              />
            </Styled.TextContainer>
          </Styled.WaypointWrapper>
        </Styled.Column>
        <Styled.Column>
          <Styled.ColumnTitle>{prefixedT("SUBTITLE_B")}</Styled.ColumnTitle>
          <Styled.WaypointWrapper>
            <Styled.LongestImg />
            <Styled.TextContainer>
              <WaypointTitleColumn
                name={longest.name}
                id={longest.id}
                error={prefixedT("NO_NAME")}
              />
              <WaypointDayColumn
                days={longest.days}
                dayTitle={t("WAYPOINT_SIZE_WIDGET.DAY")}
                dayTitlePlural={t("WAYPOINT_SIZE_WIDGET.DAY_other")}
              />
            </Styled.TextContainer>
          </Styled.WaypointWrapper>
        </Styled.Column>
        <Styled.Column>
          <Styled.ColumnTitle>{prefixedT("SUBTITLE_C")}</Styled.ColumnTitle>
          <Styled.WaypointWrapper>
            <Styled.LargestImg />
            <Styled.TextContainer>
              <WaypointTitleColumn
                name={largest.name}
                id={largest.id}
                error={prefixedT("NO_NAME")}
              />
              {largest?.leak_size !== null &&
                largest.leak_size.value !== null && (
                  <Styled.ColumnDays>
                    {largest?.leak_size.value} {largest?.leak_size.type}
                  </Styled.ColumnDays>
                )}
            </Styled.TextContainer>
          </Styled.WaypointWrapper>
        </Styled.Column>
      </Styled.Row>
    </Styled.Wrapper>
  );
};

export default WaypointSizeWidget;
