import styled from "styled-components";

export const Container = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  width: 100%;
`;

export const Label = styled.span<{
  isError?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ isError, theme }) =>
    theme.colors[isError ? "coral" : "deepDark"]};
  margin-bottom: 8px;
`;

export const Field = styled.div<{
  error?: string;
  disabled?: boolean;
}>`
  width: calc(100% - 26px);
  display: flex;
  font-weight: 400;
  font-size: 14px;
  padding: 12px;
  outline: none;
  border: 1px solid
    ${({ theme, error }) => theme.colors[error ? "coral" : "lightGray"]};
  background-color: ${({ theme, disabled }) =>
    theme.colors[disabled ? "disabled" : "white"]};
  border-radius: 8px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  bottom: 3px;
  left: 0;
`;

export const Separator = styled.span`
  width: 8px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.deepDark};
  border-radius: 2px;
  margin: auto 5px;
`;

export const NumberInput = styled.input`
  display: flex;
  width: calc(50% - 9px);
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
