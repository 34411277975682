import React, { FC } from "react";
import * as Styled from "./style";

export enum LoaderType {
  light = "light",
  dark = "dark"
}

type Props = {
  type?: LoaderType;
  height?: string;
  width?: string;
  label?: string;
};

const Loader: FC<Props> = ({
  type = LoaderType.dark,
  height,
  width,
  label
}) => (
  <Styled.Wrapper data-testid="loading-spinner">
    <Styled.Container type={type} height={height || ""} width={width || ""} />
    {!!label && <Styled.MediumText>{label}</Styled.MediumText>}
  </Styled.Wrapper>
);
export default Loader;
