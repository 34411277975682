import { getGridItemWidth } from "@/utils/style";
import styled from "styled-components";

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: ${getGridItemWidth(2, 20)};
  column-gap: 20px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    grid-template-columns: ${getGridItemWidth(1, 20)};
  }
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 5px;
  justify-content: right;
`;
