import useCustomTranslation from "@/localization/useCustomTranslation";
import { HistoryLog } from "@/types/report.type";
import React, { FC } from "react";
import HistoryItem from "./HistoryItem/HistoryItem";
import * as Styled from "./style";

interface HistoryContainerProps {
  historyLog: HistoryLog[];
}

const HistoryContainer: FC<HistoryContainerProps> = ({ historyLog }) => {
  const { prefixedT } = useCustomTranslation("COMPONENTS.HISTORY_CONTAINER");

  return (
    <Styled.Wrapper>
      <Styled.Heading>{prefixedT("TITLE")}</Styled.Heading>
      <Styled.HistoryLogWrapper>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.TH>
                <Styled.TableHeading>
                  {prefixedT("DATETIME")}
                </Styled.TableHeading>
              </Styled.TH>
              <Styled.TH>
                <Styled.TableHeading>
                  {prefixedT("USER_NAME")}
                </Styled.TableHeading>
              </Styled.TH>
              <Styled.TH>
                <Styled.TableHeading>{prefixedT("ACTION")}</Styled.TableHeading>
              </Styled.TH>
              <Styled.TH>
                <Styled.TableHeading>{prefixedT("NOTES")}</Styled.TableHeading>
              </Styled.TH>
              <Styled.TH>
                <Styled.TableHeading>
                  {prefixedT("TIME_ELAPSED")}
                </Styled.TableHeading>
              </Styled.TH>
            </tr>
          </thead>
          <Styled.TBody>
            {historyLog.map((history: HistoryLog, key: number) => (
              <HistoryItem history={history} key={key} firstChild={key === 0} />
            ))}
          </Styled.TBody>
        </Styled.Table>
      </Styled.HistoryLogWrapper>
    </Styled.Wrapper>
  );
};

export default HistoryContainer;
