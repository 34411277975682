import { Map } from "mapbox-gl";
import { MapMoveEvent } from "./type";
import { updateDraggedFeature } from "./updateDraggedFeature";

export const onMove = (
  map: Map,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  isDraggingRef: React.MutableRefObject<boolean>,
  event: MapMoveEvent
) => {
  if (!isDraggingRef.current) {
    return;
  }

  const { lngLat } = event;

  window.requestAnimationFrame(() => {
    const coordinates = [lngLat.lng, lngLat.lat];
    updateDraggedFeature(map, draggedFeatureIndexRef, coordinates);
  });
};
