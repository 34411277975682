import styled from "styled-components";
import { Link as reactLink } from "react-router-dom";

export const Link = styled(reactLink)`
  text-decoration: none;
  color: #fff;
`;

export const ImgContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const BtnContainer = styled.div``;

export const PlusImg = styled.img`
  margin-left: 10px;
  height: 14px;
  width: 14px;
`;

export const Img = styled.img`
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;
