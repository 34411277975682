import { FC } from "react";
import useCustomTranslation from "@/localization/useCustomTranslation";

// Components
import PlusIcon from "@/assets/icons/plus-icon.png";
import { Button } from "@/components";
import SearchableTable from "@/components/SearchableTable/SearchableTable";
import EditUser from "../../components/popups/EditUser/EditUser";

// Styles
import * as Styled from "./style";
import DeleteUser from "../../components/popups/DeleteUser/DeleteUser";

// Redux
import { useLazyGetUserListQuery } from "@/redux/portalUsers/portalUsers.api";

// Utils
import { getRowStructure, headers } from "./utils/utils";

// Hooks
import { usePartnersAndCompanies } from "@/hooks/query/usePartnersAndCompanies";
import { useUserRole } from "@/hooks/query/useUserRole";
import { useRoles } from "@/hooks/query/useRoles";

const ViewUsers: FC = () => {
  const { prefixedT } = useCustomTranslation("VIEW_USERS");
  const partnersAndCompanies = usePartnersAndCompanies();
  const roles = useRoles();
  const role = useUserRole();

  const [trigger, { data: userListData, isFetching, isError }] =
    useLazyGetUserListQuery();

  return (
    <SearchableTable
      queryResponse={userListData}
      isFetching={isFetching}
      isError={isError}
      ExtraHeaderComponent={
        <Styled.BtnContainer data-testid="add-users-btn">
          <Styled.Link to={"/app/user-management/add-user"}>
            <Button>
              {prefixedT("BUTTON.ADD")}{" "}
              <Styled.PlusImg src={PlusIcon as string} alt="plus-icon" />
            </Button>
          </Styled.Link>
        </Styled.BtnContainer>
      }
      trigger={trigger}
      editModal={(idx, closeOverlay) => {
        const currentUser = userListData?.data[idx];
        if (currentUser !== undefined) {
          return (
            <EditUser
              first_name={currentUser.first_name}
              last_name={currentUser.last_name}
              id={currentUser.id}
              email={currentUser.email}
              role_id={currentUser.role_id}
              partner_id={currentUser.partner_id}
              company_id={currentUser.company?.id}
              phone={currentUser.phone}
              metric_system={currentUser.metric_system!}
              currency={currentUser.currency!}
              country_code={currentUser.country_code!} // TODO: refactor types to not have to use "!" here
              units={undefined!} // TODO: units are not coming from the API
              roles={roles}
              partnersAndCompanies={partnersAndCompanies}
              closeOverlay={closeOverlay}
            />
          );
        }
      }}
      deleteModal={(idx, closeOverlay) => {
        const currentUser = userListData?.data[idx];
        if (currentUser !== undefined) {
          return <DeleteUser id={currentUser.id} closeOverlay={closeOverlay} />;
        }
      }}
      getRowStructure={getRowStructure}
      headers={headers}
      translationPrefix="VIEW_USERS"
      role={role}
    />
  );
};

export default ViewUsers;
