import useCustomTranslation from "@/localization/useCustomTranslation";

// Types
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";
import { GISAdminPageResponse } from "@/types/GISAdmin.type";

// Components
import { Table } from "@/components";
import { Button } from "@fido/ui-lib";
import { LuCheck, LuEye, LuTrash2 } from "react-icons/lu";

// Utils
import { getFormattedDate } from "@/utils/getFormattedDate";

/*
 * Headers
 */

export const headersGISAdmin = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("GIS_ADMIN.TABLE.HEADERS");
  return [
    {
      id: "companyName",
      title: prefixedT("A"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "dateUploaded",
      title: prefixedT("B"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "UploadedBy",
      title: prefixedT("C"),
      sortable: false,
      align: TableCellAlignment.center
    },
    // {
    //   id: "status",
    //   title: "Status",
    //   sortable: false,
    //   align: TableCellAlignment.center
    // },
    {
      id: "action",
      title: prefixedT("D"),
      sortable: false,
      align: TableCellAlignment.center
    }
  ];
};

export const headersGISUploads = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("GIS_ADMIN.TABLE.HEADERS");
  return [
    {
      id: "companyName",
      title: prefixedT("A"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "dateUploaded",
      title: prefixedT("B"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "UploadedBy",
      title: prefixedT("C"),
      sortable: false,
      align: TableCellAlignment.center
    },
    {
      id: "action",
      title: prefixedT("D"),
      sortable: false,
      align: TableCellAlignment.center
    }
  ];
};

/*
 * Row structures
 */

// TODO: Check - is "status" needed?
export const getRowStructureGISAdmin = (
  row: GISAdminPageResponse,
  idx: number,
  handleDeleteClick: (idx: number) => void,
  handleViewClick: (idx: number, companyId: number) => void
) => {
  return (
    <Table.Row key={idx}>
      <Table.Cell align={TableCellAlignment.center} headerId="companyName">
        {row.company_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="dateUploaded">
        {getFormattedDate(row.date_uploaded)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="UploadedBy">
        {row.uploaded_by}
      </Table.Cell>
      {/* <Table.Cell align={TableCellAlignment.center} headerId="status">
        {row.status === null ? "Completed" : row.status}
      </Table.Cell> */}
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <div className="flex gap-2">
          <Button
            data-testid="preview-icon"
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => handleViewClick(row.id, row.company_id)}
          >
            <LuEye className="h-4 w-4" />
          </Button>
          <Button
            data-testid="delete-icon"
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => handleDeleteClick(row.id)}
          >
            <LuTrash2 className="h-4 w-4" />
          </Button>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const getRowStructureGISUploads = (
  row: GISAdminPageResponse,
  idx: number,
  handleViewClick: (id: number, companyId: number) => void
) => {
  return (
    <Table.Row key={idx}>
      <Table.Cell align={TableCellAlignment.center} headerId="companyName">
        {row.company_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="dateUploaded">
        {getFormattedDate(row.date_uploaded)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="UploadedBy">
        {row.uploaded_by}
      </Table.Cell>
      {/* <Table.Cell align={TableCellAlignment.center} headerId="status">
        {row.status === null ? "Completed" : row.status}
      </Table.Cell> */}
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <Button
          data-testid="preview-icon"
          type="button"
          variant="outline"
          size="sm"
          onClick={() => handleViewClick(row.id, row.company_id)}
        >
          <LuCheck className="h-4 w-4 mr-0.5" /> Select
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};
