import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { csvParse } from "d3-dsv";
import GenerateMap from "@/components/ReportLayout/widgets/MapView/GenerateMap";
import LineGraph, { Data } from "./LineGraph/LineGraph";
import { Loader } from "@/components";
import { MapProvider } from "@/Providers/MapProvider";
import { useGetFidoBlobQuery } from "@/redux/genericFetch/genericFetch.api";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  useGetConsumptionProfileInformationQuery,
  useGetDropdownQuery
} from "@/redux/reports/reports.api";
import WaypointHeadings from "../Waypoint/Components/WaypointHeadings/WaypointHeadings";
import * as Styled from "./style";

interface ConditionalListItemProps {
  item: string | number | null;
  text: string;
}

const ConsumptionProfileContainer: FC = () => {
  const { prefixedT } = useCustomTranslation("CONSUMPTION_PROFILE_PAGE");
  const [waypointID, setWaypointID] = useState<string>("");
  const [consumptionProfileID, setConsumptionProfileID] = useState<string>("");
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const location = useLocation();

  const { data: dropDownData } = useGetDropdownQuery(waypointID, {
    refetchOnMountOrArgChange: true,
    skip: !waypointID
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedConsumptionProfileID = queryParams.get(
      "consumptionProfile"
    ) as string;
    const waypoint = queryParams.get("waypoint") as string;
    setConsumptionProfileID(updatedConsumptionProfileID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: consumptionProfileData } =
    useGetConsumptionProfileInformationQuery(consumptionProfileID, {
      refetchOnMountOrArgChange: true,
      skip: !consumptionProfileID
    });

  const ConditionalListItem: React.FC<ConditionalListItemProps> = ({
    item,
    text
  }) => (
    <li>
      {text}: {item && <Styled.ListObject>{item}</Styled.ListObject>}
    </li>
  );

  const csvUrls = consumptionProfileData?.link?.csv_url;
  const graphUrls = consumptionProfileData?.link?.graph_urls;

  const { data: plotData, isLoading } = useGetFidoBlobQuery(
    csvUrls !== undefined && csvUrls.length > 0 ? csvUrls[0] : "",
    {
      refetchOnMountOrArgChange: true,
      skip: !csvUrls
    }
  );

  const [formattedData, setFormattedData] = useState<Data[]>();

  useEffect(() => {
    if (plotData !== undefined) {
      setFormattedData(
        csvParse(plotData).map((row) => ({
          timestamp: new Date(row.Timestamp),
          level: +row.Level
        }))
      );
    }
  }, [plotData]);

  const lineGraph = useMemo(() => {
    if (formattedData === undefined) {
      return null;
    }
    return (
      <LineGraph
        initialData={formattedData}
        setShowNoDataMessage={setShowNoDataMessage}
      />
    );
  }, [formattedData]);

  return (
    <div style={{ padding: "40px" }}>
      {dropDownData && (
        <WaypointHeadings
          correlations={dropDownData.correlations}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessions={dropDownData.sessions}
          jobs={dropDownData.jobs}
          active="Consumption Profile"
        />
      )}

      {consumptionProfileData && consumptionProfileData?.id && (
        <>
          {consumptionProfileData?.map_info?.data && (
            <Styled.MapAndGraphContainer>
              <div className="flex-1 h-[500px] max-h-[650px]">
                <MapProvider>
                  <GenerateMap
                    fullMapData={consumptionProfileData?.map_info?.data}
                    viewportMapData={consumptionProfileData?.map_info?.data}
                    readonly={true}
                    doNotAllowClickThrough={true}
                  />
                </MapProvider>
              </div>
            </Styled.MapAndGraphContainer>
          )}
          {isLoading ? (
            <Loader />
          ) : formattedData !== undefined ? (
            <Styled.GraphWrapper>
              {lineGraph}
              {showNoDataMessage && (
                <Styled.Error>{prefixedT("NO_DATA")}</Styled.Error>
              )}
            </Styled.GraphWrapper>
          ) : graphUrls !== undefined && graphUrls.length > 0 ? (
            <Styled.GraphWrapper>
              <img
                data-testid="consumption-chart-img"
                width="60%"
                src={consumptionProfileData?.link?.graph_urls[0]}
              />
            </Styled.GraphWrapper>
          ) : null}

          <Styled.ListContainer>
            <Styled.ListWrapper>
              <Styled.ListContainerItem>
                <Styled.ListTitle>
                  {prefixedT("CONSUMPTION_INFO.CONSUMPTION_INFO_TITLE")}
                </Styled.ListTitle>
                <Styled.List>
                  <ConditionalListItem
                    item={consumptionProfileData?.id}
                    text={prefixedT("CONSUMPTION_INFO.CONSUMPTION_PROFILING")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.expected_samples}
                    text={prefixedT("CONSUMPTION_INFO.EXPECTED_SAMPLES")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.uploaded_samples}
                    text={prefixedT("CONSUMPTION_INFO.UPLOADED_SAMPLES")}
                  />
                </Styled.List>
              </Styled.ListContainerItem>
              <Styled.ListContainerItem>
                <Styled.ListTitle>
                  {prefixedT("CONSUMPTION_INFO.DATE_TITLE")}
                </Styled.ListTitle>
                <Styled.List>
                  <ConditionalListItem
                    item={consumptionProfileData?.start_time}
                    text={prefixedT("CONSUMPTION_INFO.START_TIME")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.end_time}
                    text={prefixedT("CONSUMPTION_INFO.END_TIME")}
                  />
                </Styled.List>
              </Styled.ListContainerItem>
              <Styled.ListContainerItem>
                <Styled.ListTitle>
                  {prefixedT("LOCATION_INFO.TITLE")}
                </Styled.ListTitle>
                <Styled.List>
                  <ConditionalListItem
                    item={consumptionProfileData?.collected_engineer}
                    text={prefixedT("LOCATION_INFO.COLLECTED_ENGINEER")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.deployed_engineer}
                    text={prefixedT("LOCATION_INFO.DEPLOYED_ENGINEER")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.company}
                    text={prefixedT("LOCATION_INFO.COMPANY")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.location}
                    text={prefixedT("LOCATION_INFO.LOCATION")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.what3words}
                    text={prefixedT("LOCATION_INFO.WHAT3WORDS")}
                  />
                  <ConditionalListItem
                    item={consumptionProfileData?.postcode}
                    text={prefixedT("LOCATION_INFO.POSTCODE")}
                  />
                </Styled.List>
              </Styled.ListContainerItem>
            </Styled.ListWrapper>
          </Styled.ListContainer>
        </>
      )}
    </div>
  );
};

export default ConsumptionProfileContainer;
