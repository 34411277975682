import * as Sentry from "@sentry/react";
import logger from "redux-logger";
import {
  Reducer,
  StoreEnhancer,
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Slices
import activeAccount from "./activeAccount/activeAccount.slice";
import auth from "./authorization/authorization.slice";
import header from "./header/header.slice";
import gisUpload from "./gisUpload/gisUpload.slice";
import request from "./request/request.slice";
import websocket from "./websocket/websocket.slice";

// APIs
import { aiAssistantApi } from "./aiAssistant/aiAssistant.api";
import { authorizationApi } from "./authorization/authorization.api";
import { genericFetchApi } from "./genericFetch/genericFetch.api";
import { highlandPortalApi } from "./highlandPortal/highlandPortal.api";
import { logApi } from "./log/log.api";
import { mobileAppApi } from "./mobileApp/mobileApp.api";
import { portalCompaniesApi } from "./portalCompanies/portalCompanies.api";
import { portalPartnersApi } from "./portalPartners/portalPartners.api";
import { portalProjectsApi } from "./portalProjects/portalProjects.api";
import { portalProjectsGISApi } from "./portalProjects/portalProjectsGIS.api";
import { portalUsersApi } from "./portalUsers/portalUsers.api";
import { reportApi } from "./reports/reports.api";
import { userApi } from "./user/user.api";
import { gisUploadApi } from "./gisUpload/gisUpload.api";
import { GISAdminApi } from "./GISAdmin/GISAdmin.api";

// Utils
import { getApiEntities } from "@/utils/redux";

// Constants
import { IS_DEV } from "@/constants/Dev.constant";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const apiEntities = [
  userApi,
  authorizationApi,
  aiAssistantApi,
  genericFetchApi,
  logApi,
  reportApi,
  portalCompaniesApi,
  gisUploadApi,
  GISAdminApi,
  mobileAppApi,
  portalProjectsApi,
  portalProjectsGISApi,
  portalPartnersApi,
  highlandPortalApi,
  portalUsersApi
];

const { reducers, middlewares } = getApiEntities(apiEntities);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["activeAccount", "auth"]
};

const RESET_STATE = "RESET_STATE";

export const resetState = () => ({
  type: RESET_STATE
});

const appReducer = combineReducers({
  ...reducers,
  auth,
  request,
  activeAccount,
  gisUpload,
  header,
  websocket
});

const rootReducer: Reducer<ReturnType<typeof appReducer>> = (state, action) => {
  if (action.type === RESET_STATE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer() as StoreEnhancer;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: IS_DEV,
    middleware: (getDefaultMiddleware) => {
      const defaultMiddlewares = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"],
          warnAfter: 240
        }
      });

      if (IS_DEV) {
        return defaultMiddlewares.concat(logger, ...middlewares);
      }

      return defaultMiddlewares.concat(...middlewares);
    },
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
    preloadedState: preloadedState as RootState
  });
};

export const store = setupStore();

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof persistedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
