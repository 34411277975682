import React, { useState } from "react";
import * as Styled from "./style";
import { Button, Table } from "@/components";
import { ITableHeader, TableCellAlignment } from "@/components/Table/types";
import useCustomTranslation from "@/localization/useCustomTranslation";

import {
  GetViewWaypointLogsDistinctResponse,
  WaypointLogsData
} from "@/types/portalUsers.type";
import moment from "moment";
import { DropDownOption } from "@/components/DropDown/DropDown";

export const pageValues: DropDownOption[] = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  }
];

export const headerNames = [
  "id",
  "waypoint_id",
  "datetime",
  "user_name",
  "changed_from_status_name",
  "changed_to_status_name",
  "project",
  "dma_key",
  "company",
  "partner",
  "notes"
];

export const headers = (
  columnOrder: { name: string; show: boolean }[]
): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation("LIST_VIEW.WAYPOINT_LOGS.HEADERS");

  const currentColumns = headerNames.map((header) => ({
    id: header,
    title: prefixedT(header),
    sortable: false,
    align: TableCellAlignment.center
  }));

  try {
    const result = columnOrder
      .filter((column) => column.show)
      .map((column) => {
        const matchedColumn = currentColumns.find(
          (col) => col.id === column.name
        );
        if (!matchedColumn) {
          throw new Error(`Column not found`);
        }
        return matchedColumn;
      });

    return result;
  } catch (error) {
    // Something went wrong, reset the columns
    localStorage.removeItem("listView.Waypoints");
    return currentColumns;
  }
};

const ExpandableCell = ({ children }: { children: React.ReactNode }) => {
  const [showChildren, setShowChildren] = useState(false);
  return (
    <Styled.ExpandableCell>
      {children && (
        <Styled.ButtonContainer>
          <Button width={"40px"} onClick={() => setShowChildren(!showChildren)}>
            <Styled.ClickableAreaIndicator open={showChildren} />
          </Button>
        </Styled.ButtonContainer>
      )}
      <Styled.ChildWrapper show={showChildren}>{children}</Styled.ChildWrapper>
    </Styled.ExpandableCell>
  );
};

export const getRowStructure = (
  data: WaypointLogsData,
  index: number,
  columnOrder: { name: string; show: boolean }[]
) => {
  const currentRowOrder = [
    {
      id: "id",
      child: data.id
    },
    {
      id: "waypoint_id",
      child: (
        <a href={`/app/waypoint?waypoint=${data.waypoint_id}`} target="_blank">
          {data.waypoint_name}
        </a>
      )
    },
    {
      id: "datetime",
      child:
        data.datetime !== null &&
        moment(data.datetime).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      id: "user_name",
      child: data.user_name
    },
    {
      id: "changed_from_status_name",
      child: data.changed_from_status_name
    },
    {
      id: "changed_to_status_name",
      child: data.changed_to_status_name
    },
    {
      id: "project",
      child: data.branch_name
    },
    {
      id: "dma_key",
      child: data.dma_key
    },
    {
      id: "company",
      child: data.company_name
    },
    {
      id: "partner",
      child: data.partner_name
    },
    {
      id: "notes",
      child: <ExpandableCell children={data.notes} />
    }
  ];

  const result = columnOrder
    .filter((column) => column.show)
    .map((column) => {
      const matchedColumn = currentRowOrder.find(
        (col) => col.id === column.name
      );
      if (!matchedColumn) {
        throw new Error(`Column not found ${column.name}`);
      }
      return matchedColumn;
    });

  return (
    <Table.Row key={index}>
      {result.map((row, idx) => (
        <Table.Cell
          key={idx}
          align={TableCellAlignment.center}
          headerId={row.id}
        >
          {row.child}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const filterColumns = ["project", "company", "partner"];

export const sortColumns = [
  "id",
  "waypoint_id",
  "waypoint_name",
  "datetime",
  "user_name",
  "changed_from_status_name",
  "changed_to_status_name",
  "project",
  "dma_key",
  "company",
  "partner",
  "notes"
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

/**
 * Formats the response from the API to be used in the filter dropdowns
 */
export const processDistinctValues = (
  distinct: GetViewWaypointLogsDistinctResponse | undefined
) => {
  const getDistinct = (
    value: { id: number | null; name: string | null },
    label: string
  ) => {
    if (value.id === null || value.name === null) {
      return { label: label, value: "null" };
    }
    return {
      label: value.name,
      value: `${value.id}`
    };
  };

  return {
    company:
      distinct?.company
        .map((currentCompany) => getDistinct(currentCompany, "No Company"))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    partner:
      distinct?.partner
        .map((currentPartner) => getDistinct(currentPartner, "No Partner"))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    project:
      distinct?.project
        .map((currentProject) => getDistinct(currentProject, "No Project"))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
  };
};
