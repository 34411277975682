import CheckIcon from "@/assets/icons/check.svg?react";
import CrossIcon from "@/assets/icons/cross.svg?react";
import styled from "styled-components";

export const SvgContainer = styled.div<{
  isSuccess?: boolean;
}>`
  position: absolute;
  top: -85px;
  left: 0;
  background-color: ${({ theme, isSuccess }) =>
    theme.colors[isSuccess ? "lightGreen" : "artyClickRed"]};
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  right: 0;
`;

export const PostiveIcon = styled(CheckIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
`;

export const NegativeIcon = styled(CrossIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
`;

export const Title = styled.h3`
  padding: 40px 0 20px 0;
  font-size: 36px;
  text-align: center;
  color: #676767;
`;

export const Desc = styled.p`
  padding: 20px 0 40px 0;
  font-size: 18px;
  text-align: center;
  color: #959595;
  line-height: 30px;
`;

export const SubmitBtn = styled.button<{
  isSuccess?: boolean;
}>`
  padding: 20px;
  background-color: ${({ isSuccess }) => (isSuccess ? "#38e030" : "#FF0101")};
  width: 100%;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  color: white;
  cursor: pointer;

  :hover {
    background-color: ${({ isSuccess }) => (isSuccess ? "#2eb026" : "#d80000")};
  }
`;
