import DateRange from "@/components/DateRange/DateRange";
import DropDown from "@/components/DropDown/DropDown";
import NumberRange from "@/components/NumberRange/NumberRange";
import TextField from "@/components/TextField/TextField";
import {
  ReportDataSourceColumn,
  ReportFilterSettings,
  ReportSettingsValue
} from "@/types/report.type";
import { format, parseISO } from "date-fns";
import { t } from "i18next";
import { v4 as guid } from "uuid";
import * as Yup from "yup";

export type FilterValue = string | number | (string | number | Date)[] | null;

export type Filter = {
  id: string;
  columnId: number | null;
  typeId: number | null;
  value: FilterValue;
};

export type InitialFilterValues = {
  source: number | null;
  filters: Filter[];
};

export type Options = {
  label: string;
  value: number;
};

export const getFilterComponent = (type: number) => {
  switch (type) {
    case 1:
      return DropDown;
    case 2:
      return DateRange;
    case 3:
      return NumberRange;
    case 4:
      return TextField;
    default:
      return DropDown;
  }
};

export const getFilterValueBasedOnValue = (
  type: number,
  value: FilterValue
) => {
  switch (type) {
    case 1:
      return value;
    case 2:
      return value || undefined;
    case 3:
      return value || [0, 0];
    case 4:
      return value || "";
    default:
      return DropDown;
  }
};

export const getTitle = (dialogState: string) => {
  if (dialogState === "change") {
    return t("FILTERS.DIALOG.TITLE.CHANGE");
  }
  return t("FILTERS.DIALOG.TITLE.CLOSE");
};

export const getSubTitle = (dialogState: string) => {
  if (dialogState === "change") {
    return t("FILTERS.DIALOG.SUBTITLE.CHANGE");
  }
  return t("FILTERS.DIALOG.SUBTITLE.CLOSE");
};

const checkFilterValueBasedOnType = (
  value: unknown,
  context: Yup.TestContext
) => {
  const type = context.parent.typeId;
  if (!value) {
    return false;
  }
  switch (type) {
    case 1:
      return !!(Array.isArray(value) && value.length);
    case 2:
      return Array.isArray(value) && value.length === 2;
    case 3:
      return Array.isArray(value) && value.length === 2 && value[1] >= value[0];
    case 4:
      return typeof value === "string";
  }
  return true;
};

export const validateFilterConfig = Yup.object({
  filters: Yup.array().of(
    Yup.object({
      id: Yup.string().required("VALIDATION.REQUIRED").nullable(true),
      columnId: Yup.number().required("VALIDATION.REQUIRED").nullable(true),
      typeId: Yup.number().required("VALIDATION.REQUIRED").nullable(true),
      value: Yup.mixed().test(
        "branchId",
        "VALIDATION.REQUIRED",
        checkFilterValueBasedOnType
      )
    })
  ),
  source: Yup.number()
    .required("VALIDATION.REQUIRED")
    .nullable(true)
    .test("is-filled", "Should have filters", (_, ctx) =>
      Boolean(ctx.parent.filters.length)
    )
});

export const prepareSubmit = (
  values: InitialFilterValues
): ReportFilterSettings => {
  const settings: ReportFilterSettings = {
    control_field: [],
    filter_type: [],
    filter_options: [],
    datasource: Number(values.source)
  };
  values.filters.forEach((filter) => {
    settings.control_field.push(filter.columnId as number);
    settings.filter_type.push(filter.typeId as number);
    if (filter.typeId === 2) {
      settings.filter_options.push(
        (filter.value as unknown as ReportSettingsValue[]).map((el) =>
          format(new Date(el), "P")
        )
      );
    } else {
      settings.filter_options.push(filter.value as string | number);
    }
  });
  return settings;
};

export const parseSettingsForInitialValues = (
  settings?: ReportFilterSettings
): InitialFilterValues | null => {
  const filters: Filter[] = [];
  if (settings?.control_field) {
    settings.control_field.forEach((_, index) => {
      const type = settings.filter_type[index];
      let value = settings.filter_options[index] as FilterValue;
      if (type === 2) {
        value = (value as ReportSettingsValue[]).map((el) =>
          parseISO(new Date(el as string).toISOString())
        );
      }
      filters.push({
        id: guid(),
        columnId: settings.control_field[index],
        typeId: settings.filter_type[index],
        value
      });
    });
    return { source: settings.datasource, filters };
  }
  return null;
};

export const getFilterData = (
  settings: ReportFilterSettings,
  columns: ReportDataSourceColumn[],
  index: number
) => {
  const typeId = settings.filter_type[index];
  let value: any = settings.filter_options[index];
  let options: Options[] | undefined = [];
  const columnName = columns?.find(
    (el) => el.id === settings.control_field[index]
  );
  if (typeId === 2) {
    value = (value as string[]).map((el: string) => new Date(el));
  }
  if (typeId === 1) {
    options = columnName?.column?.filters.map((el) => ({
      label: el.option,
      value: el.id
    }));
  }
  return { column: columnName?.column?.name, typeId, value, options };
};

export const getInitalFiltersValue = (
  settings: ReportFilterSettings,
  columns: ReportDataSourceColumn[]
) => {
  const result: { [key: string]: unknown } = {};
  settings.control_field.forEach((item, index) => {
    let value: any = settings.filter_options[index];
    const type = settings.filter_type[index];
    if (type === 2) {
      value = (value as ReportSettingsValue[]).map((el) =>
        parseISO(new Date(el as string).toISOString())
      );
    }
    const column = columns?.find((el) => el.column.id === item);
    if (column) {
      result[column.column?.name] = value;
    }
  });
  return result;
};
