import React, { FC, useMemo } from "react";
import { DropDown } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { ErrorModalText, Column } from "@/types/gisUpload.type";
import * as Styled from "./style";
import { createDropdown, updateSelectedValues } from "./utils";
import {
  useGISUploadMethods,
  useGISUploadValues
} from "@/Providers/GISUploadProvider";
import { SelectedItems } from "./SelectedItems/SelectedItems";
import { addOrReplaceError } from "../utils";
import { deepCopy } from "@/utils/common";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@fido/ui-lib";

interface TableProps {
  tableColumns: Column[];
  sampleData: unknown[];
  fileName: string;
}

const TableComponent: FC<TableProps> = ({
  tableColumns,
  sampleData,
  fileName
}) => {
  const { selectedValues, currentStep, errors } = useGISUploadValues();
  const { setUploadValues } = useGISUploadMethods();

  const dropdownOptions = createDropdown(tableColumns);
  const typedSampleData = sampleData as Record<string, any>[];

  const handleChange = (columnName: string, value: string) => {
    const option = dropdownOptions.find((o) => o.value === value);

    if (Array.isArray(option)) {
      return;
    }

    // setSelectedValues((prevValues) =>
    //   updateSelectedValues(
    //     prevValues,
    //     fileName,
    //     columnName,
    //     option,
    //     currentStep,
    //   ),
    // );

    const newSelectedValues = updateSelectedValues(
      selectedValues,
      fileName,
      columnName,
      option,
      currentStep
    );

    let newErrors = deepCopy(errors);

    if (errors?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC)) {
      // setErrors(
      //   addOrReplaceError(errors, ErrorModalText.SYSTEM_FIELD_NUMERIC, true),
      // );
      newErrors = addOrReplaceError(
        errors,
        ErrorModalText.SYSTEM_FIELD_NUMERIC,
        true
      );
    }
    if (errors?.includes(ErrorModalText.COLUMNS_NOT_SELECTED)) {
      // setErrors(
      //   addOrReplaceError(errors, ErrorModalText.COLUMNS_NOT_SELECTED, true),
      // );
      newErrors = addOrReplaceError(
        errors,
        ErrorModalText.COLUMNS_NOT_SELECTED,
        true
      );
    }

    setUploadValues({
      errors: newErrors,
      data: {
        selectedValues: newSelectedValues
      }
    });
  };

  const selectedFileValues = useMemo(
    () => selectedValues.find((f) => f.file === fileName),
    [selectedValues, fileName]
  );

  // console.log("table data render: ", { tableColumns, sampleData, fileName });

  return (
    <>
      <Styled.Wrapper>
        <Table className="border">
          <TableHeader className="bg-muted">
            <TableRow>
              {typedSampleData.length > 0 &&
                Object.keys(typedSampleData[0]).map((key, _index) => (
                  <TableHead key={key} className="text-center text-black">
                    <Select
                      value={
                        selectedFileValues?.data.find(
                          (item) => item.name === key
                        )?.value || ""
                      }
                      onValueChange={(value) => {
                        handleChange(key, value);
                      }}
                      // defaultValue={field.value?.toString()}
                      // disabled={field.disabled}
                    >
                      <SelectTrigger className="border-none h-8 w-auto mx-auto">
                        <SelectValue placeholder={key} />
                        {/* <span>{key}</span> */}
                      </SelectTrigger>
                      <SelectContent align="center">
                        {dropdownOptions.map((o) => (
                          <SelectItem key={o.value} value={o.value}>
                            {o.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {/* <DropDown
                      options={dropdownOptions}
                      label={undefined}
                      placeholder={key}
                      onChange={(option) =>
                        handleChange(key, option as DropDownOption)
                      }
                      value={
                        selectedFileValues?.data.find(
                          (item) => item.name === key
                        )?.value || ""
                      }
                      styles={{
                        center: true, 
                        background: "transparent",
                        textColor: "#ffffff",
                        dropdownFitWidth: true,
                        dropdownTextColor: "#b3b3b3",
                        backgroundHover: "#302e36",
                        backgroundDropdown: "#26252a"
                      }}
                    /> */}
                  </TableHead>
                ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {typedSampleData.map((row, rowIndex) => {
              return (
                <TableRow key={`${fileName}-${rowIndex}`}>
                  {Object.entries(row).map(([_key, value], colIndex) => (
                    <TableCell key={colIndex} className="text-center">
                      {String(value)}
                    </TableCell>
                  ))}
                </TableRow>
              );
              // const isLastRow = rowIndex === typedSampleData.length - 1;
              // return isLastRow ? (
              //   <Styled.LastTR key={rowIndex}>
              //     {Object.entries(row).map(([_key, value], colIndex) => (
              //       <Styled.TD key={colIndex}>{String(value)}</Styled.TD>
              //     ))}
              //   </Styled.LastTR>
              // ) : (
              //   <Styled.TR key={rowIndex}>
              //     {Object.entries(row).map(([_key, value], colIndex) => (
              //       <Styled.TD key={colIndex}>{String(value)}</Styled.TD>
              //     ))}
              //   </Styled.TR>
              // );
            })}
          </TableBody>
        </Table>
      </Styled.Wrapper>
      <SelectedItems fileName={fileName} />
    </>
  );
};

export default TableComponent;
