import { Map } from "mapbox-gl";
import { FeatureCollection } from "geojson";

export const createPolyArea = (map: Map) => {
  const setConfig: FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };

  const source = map.getSource(
    "bufferedPolygonSource"
  ) as mapboxgl.GeoJSONSource;

  if (!source) {
    return;
  }
  source.setData(setConfig);
};
