import {
  useGetViewRelaysDistinctQuery,
  useLazyPostViewRelaysQuery
} from "@/redux/portalUsers/portalUsers.api";
import React, { FC, useEffect, useMemo, useState } from "react";
import * as Styled from "./style";
import {
  Button,
  ContentLoader,
  DatePicker,
  DropDown,
  Loader,
  Table
} from "@/components";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  HeaderShowItem,
  RelayDataKey,
  RowStructure,
  containsInvalidHeader,
  headerNames,
  headers,
  mapFilterName,
  pageValues,
  processDistinctValues
} from "./ListViewUtils";
import FilterColumn from "./FilterColumn/FilterColumn";
import { isFilterColumn, isSortColumn } from "./ListViewUtils";
import { ITablePaginate, TableSortOrders } from "@/components/Table/types";
import {
  PostViewRelaysBody,
  PostViewRelaysFilter,
  PostViewRelaysRequest,
  RelaysData
} from "@/types/portalUsers.type";
import EditView from "./EditView/EditView";
import { DropDownOption } from "@/components/DropDown/DropDown";
import moment from "moment";

type DateType = keyof NonNullable<PostViewRelaysBody["date_filter"]>;

type FullQuery = PostViewRelaysRequest;

const defaultPage = 1;
const defaultPerPage = 25;

const RelaysListView: FC = () => {
  const [trigger, { data, isLoading, isFetching }] =
    useLazyPostViewRelaysQuery();

  const { data: distinct } = useGetViewRelaysDistinctQuery();
  const distinctProcessed = useMemo(() => {
    return processDistinctValues(distinct);
  }, [distinct]);

  const { t, prefixedT } = useCustomTranslation("LIST_VIEW.RELAYS");

  const listViewWaypointsView = localStorage.getItem("listView.Relays.view");
  const listViewWaypointsFullQuery = localStorage.getItem(
    "listView.Relays.fullQuery"
  );
  const listViewWaypointsFullQueryParsed = listViewWaypointsFullQuery
    ? (JSON.parse(listViewWaypointsFullQuery) as FullQuery)
    : null;

  // *NB: for backwards compatability
  const [columnOrder, setColumnOrder] = useState<HeaderShowItem[]>(
    !!listViewWaypointsView &&
      !containsInvalidHeader(
        JSON.parse(listViewWaypointsView) as HeaderShowItem[]
      )
      ? (JSON.parse(listViewWaypointsView) as HeaderShowItem[])
      : headerNames.map((id) => ({ name: id, show: true }))
  );

  const tableHeaders = headers(columnOrder);

  const [page, setPage] = useState<number>(
    listViewWaypointsFullQueryParsed?.query.page || defaultPage
  );
  const [perPage, setPerPage] = useState<number>(
    listViewWaypointsFullQueryParsed?.query.per_page || defaultPerPage
  );

  const [order, setOrder] = useState<TableSortOrders>(
    listViewWaypointsFullQueryParsed?.query.sort_order || TableSortOrders.desc
  );

  // TODO: Refactor - improve approach to "mapping" and "cleaning" of local storage values (since they can change) (preferrably refactor whole table approach)

  // *NB: for backwards compatability
  const [orderBy, setOrderBy] = useState<string>(
    (["last_update", "last_contact"] as DateType[]).includes(
      listViewWaypointsFullQueryParsed?.query.sort_by as DateType
    )
      ? listViewWaypointsFullQueryParsed?.query.sort_by
      : "last_update"
  );

  // TODO: Finish Relay - fix columns
  const [dateType, setDateType] = useState<DateType>("last_update");

  const dateRangeValues = [
    { value: "last_update", label: prefixedT("HEADERS.last_update") },
    { value: "last_contact", label: prefixedT("HEADERS.last_contact") }
  ];

  const [dateFrom, setDateFrom] = useState<Date | null>(
    listViewWaypointsFullQueryParsed?.body.date_filter[dateType]?.date_from
      ? new Date(
          listViewWaypointsFullQueryParsed.body.date_filter[dateType].date_from
        )
      : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    listViewWaypointsFullQueryParsed?.body.date_filter[dateType]?.date_to
      ? new Date(
          listViewWaypointsFullQueryParsed.body.date_filter[dateType].date_to
        )
      : null
  );

  const defaultFilter = {
    company: [],
    partner: [],
    project: [],
    relay_status: [],
    relay_uptime: []
  };

  const [searchQuery, setSearchQuery] = useState<string>(
    listViewWaypointsFullQueryParsed?.query.search || ""
  );
  const [filter, setFilter] = useState<PostViewRelaysFilter>(
    listViewWaypointsFullQueryParsed
      ? { ...defaultFilter, ...listViewWaypointsFullQueryParsed.body.filter }
      : defaultFilter
  );
  const currentQuery: FullQuery = {
    body: {
      filter,
      date_filter: {
        [dateType]: {
          date_from: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : "",
          date_to: dateTo ? moment(dateTo).format("YYYY-MM-DD") : ""
        }
      }
    },
    query: {
      page,
      per_page: perPage,
      sort_by: orderBy,
      sort_order: order,
      search: searchQuery
    }
  };

  const refetchWithArgs = (optionalQuery?: FullQuery) => {
    const thisQuery = optionalQuery || currentQuery;

    localStorage.setItem(
      "listView.Relays.fullQuery",
      JSON.stringify(thisQuery)
    );
    void trigger(thisQuery);
  };

  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleColumnOrderChange = (
    newColumn: string,
    draggedColumn: string
  ) => {
    const newColumnOrder = [...columnOrder];

    const draggedColumnIndex = newColumnOrder.findIndex(
      (column) => column.name === draggedColumn
    );
    const newColumnIndex = newColumnOrder.findIndex(
      (column) => column.name === newColumn
    );

    newColumnOrder.splice(draggedColumnIndex, 1);
    newColumnOrder.splice(newColumnIndex, 0, columnOrder[draggedColumnIndex]);
    setColumnOrder(newColumnOrder);
  };

  useEffect(() => {
    localStorage.setItem("listView.Relays.view", JSON.stringify(columnOrder));
  }, [columnOrder]);

  useEffect(() => {
    refetchWithArgs();
  }, [page, perPage]);

  const reset = () => {
    setFilter(defaultFilter);
    setDateFrom(null);
    setDateTo(null);
    setSearchQuery("");
    setOrder(TableSortOrders.desc);
    setOrderBy("last_update");
    setPage(1);
    setPerPage(25);

    refetchWithArgs({
      body: {
        filter: defaultFilter,
        date_filter: {
          [dateType]: {
            date_from: "",
            date_to: ""
          }
        }
      },
      query: {
        page: 1,
        per_page: 25,
        sort_by: "last_update",
        sort_order: TableSortOrders.desc,
        search: ""
      }
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.TableTitle>{prefixedT("TITLE")}</Styled.TableTitle>
      <ContentLoader active={data?.data && isFetching} />
      {isLoading ? (
        <Loader />
      ) : (
        <div data-testid="stock-table">
          <Styled.TableHeader>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {t("LIST_VIEW.SESSIONS.FILTER_SECTION")}
              </Styled.TableHeaderTitle>
              <Styled.SearchBarContainer>
                <Styled.SearchBarContainer>
                  {t("LIST_VIEW.SESSIONS.DATE_TYPE")}:
                  <Styled.DateTypeContainer>
                    <DropDown
                      options={dateRangeValues}
                      placeholder={""}
                      value={dateType}
                      onChange={(e) => {
                        const newValue = e as DropDownOption;
                        setDateType(newValue.value as DateType);
                      }}
                      multiple={false}
                    />
                  </Styled.DateTypeContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateFrom}
                      onChange={(value) => {
                        if (dateTo && value < dateTo) {
                          setDateFrom(value);
                        } else if (!dateTo) {
                          setDateFrom(value);
                        }
                      }}
                      placeholder={t("LIST_VIEW.SESSIONS.DATE_FROM")}
                    />
                  </Styled.DatePickerContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateTo}
                      onChange={(value) => {
                        if (dateFrom && value > dateFrom) {
                          setDateTo(value);
                        } else if (!dateFrom) {
                          setDateTo(value);
                        }
                      }}
                      placeholder={t("LIST_VIEW.SESSIONS.DATE_TO")}
                    />
                  </Styled.DatePickerContainer>
                </Styled.SearchBarContainer>

                <Styled.SearchBarContainerInner>
                  <Styled.Input
                    placeholder={t("LIST_VIEW.SESSIONS.SEARCH.PLACEHOLDER")}
                    value={searchQuery}
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setPage(1);
                        refetchWithArgs();
                      }
                    }}
                  />
                </Styled.SearchBarContainerInner>
                <Button
                  onClick={() => {
                    setPage(1);
                    refetchWithArgs();
                  }}
                >
                  {t("LIST_VIEW.SESSIONS.SEARCH_BAR.APPLY.FILTER")}
                </Button>
                <Button onClick={reset}>
                  {t("LIST_VIEW.SESSIONS.SEARCH_BAR.RESET")}
                </Button>
              </Styled.SearchBarContainer>
            </Styled.TableHeaderSection>
            <Styled.TableHeaderSection>
              <EditView
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                handleColumnOrderChange={handleColumnOrderChange}
              />
            </Styled.TableHeaderSection>
          </Styled.TableHeader>
          <Table
            data={data?.data || []}
            headers={tableHeaders}
            onSort={setSorting}
            minHeight="350px"
          >
            <Table.Head
              getHeaderStructure={(header) => {
                return (
                  <FilterColumn
                    header={header}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showFilterOption={isFilterColumn(header.id as RelayDataKey)}
                    showSortOption={isSortColumn(header.id as RelayDataKey)}
                    filterOptions={
                      distinctProcessed[
                        mapFilterName(header.id as RelayDataKey)
                      ] || []
                    }
                    filter={filter}
                    setFilter={setFilter}
                    isLastColumn={header.id === "comment"}
                  />
                );
              }}
            />
            {data !== undefined && data.count > 0 ? (
              <Table.Body
                getRowStructure={(data: RelaysData, index: number) => (
                  <RowStructure
                    data={data}
                    index={index}
                    columnOrder={columnOrder}
                  />
                )}
                striped
              />
            ) : (
              <h3>{t("LIST_VIEW.SESSIONS.NO_DATA")}</h3>
            )}
          </Table>
          <Styled.PaginationWrapper key={page}>
            <Styled.DropDownContainer>
              <DropDown
                value={perPage.toString()}
                options={pageValues}
                onChange={(value) => {
                  setPerPage(Number((value as DropDownOption).value));
                  setPage(1);
                }}
                placeholder={t("VIEW_USERS.PAGINATION.TITLE")}
              />
            </Styled.DropDownContainer>
            <Table.Pagination
              pages={Math.ceil((data?.count || 1) / perPage)}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PaginationWrapper>
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default RelaysListView;
