import React, { FC } from "react";
import * as Styled from "./style";

interface ImageContainerProps {
  linkToAssets: string[] | undefined;
}

const ImageContainer: FC<ImageContainerProps> = ({ linkToAssets }) => (
  <Styled.Wrapper>
    {linkToAssets &&
      linkToAssets.map((asset, key) => (
        <Styled.ImageContainer key={key}>
          <Styled.Image src={asset} alt={asset} />
        </Styled.ImageContainer>
      ))}
  </Styled.Wrapper>
);

export default ImageContainer;
