import useCustomTranslation from "@/localization/useCustomTranslation";
import React, { FC } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import * as Styled from "./style";
import { getMimeTypesFromExtensions } from "./utils";

export enum FileUploadStatus {
  NoValue = 1,
  IncorrectFileType = 2
}
interface FileUploadProps {
  label?: string;
  required?: boolean;
  fileTypes?: string[];
  disabled?: boolean;
  files: File[];
  error: FileUploadStatus | null;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void | undefined;
  browseFileType?: string;
}

const FileUpload: FC<FileUploadProps> = ({
  label,
  required,
  files,
  onDrop,
  disabled,
  error,
  fileTypes
}) => {
  const { prefixedT } = useCustomTranslation("COMPONENTS.FILE_UPLOAD");
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: onDrop,
    disabled: disabled,
    ...(fileTypes && { accept: getMimeTypesFromExtensions(fileTypes) })
  });

  const uploadedFiles = files.map((file: File) => {
    return <Styled.FileItem key={file.name}>{file.name}</Styled.FileItem>;
  });

  return (
    <>
      {label && (
        <>
          <Styled.Label>
            {label + " "}
            {required && <Styled.Required>*</Styled.Required>}
          </Styled.Label>
          {files.length === 0 && error == FileUploadStatus.NoValue && (
            <Styled.ErrorLabel>{prefixedT("ERROR")}</Styled.ErrorLabel>
          )}
        </>
      )}
      <Styled.Container error={files.length === 0 && error ? error : null}>
        <Styled.TextContainer {...getRootProps()}>
          <input {...getInputProps()} data-testid="dropzone-input" />
          <Styled.MediumText>{prefixedT("DRAG_TEXT")}</Styled.MediumText>
          <Styled.SmallText>{prefixedT("OR")}</Styled.SmallText>
          <Styled.Button type="button" onClick={open}>
            {prefixedT("BROWSE")}
          </Styled.Button>
          <Styled.SmallText>
            {prefixedT("SUPPORTED") + fileTypes?.map((type) => type).join(", ")}
          </Styled.SmallText>
        </Styled.TextContainer>
      </Styled.Container>
      {uploadedFiles.length > 0 && (
        <Styled.FilesWrapper>
          <h4>{prefixedT("FILES")}</h4>
          <Styled.FileList>{uploadedFiles}</Styled.FileList>
        </Styled.FilesWrapper>
      )}
    </>
  );
};

export default FileUpload;
