import { createContext } from "react";

type OnSubmitFunction = (filters: any) => void;

type MapViewContextType = {
  mapContainerWidth: number;
  onSubmit: OnSubmitFunction;
};

const MapViewContext = createContext<MapViewContextType>({
  mapContainerWidth: 0,
  onSubmit: () => {}
});

export default MapViewContext;
