// Types
import { WebsocketMessageServer, WebsocketType } from "@/types/websocket.type";

type WebsocketCommonBaseProps = { type: WebsocketType; token: string };
type WebsocketCommonUploadProps = {
  id: string;
};

type WebsocketGISUploadURL = WebsocketCommonBaseProps &
  WebsocketCommonUploadProps;
type WebsocketODLGenerationURL = WebsocketCommonBaseProps &
  WebsocketCommonUploadProps;

type BuildWebsocketURLProps = WebsocketGISUploadURL | WebsocketODLGenerationURL;

export const buildWebsocketURL = (props: BuildWebsocketURLProps) => {
  let url = "";

  switch (props.type) {
    case WebsocketType.GIS_UPLOAD:
      url = new URL(
        `/v1/ws/${props.token}/upload_id/${props.id}`,
        import.meta.env.VITE_GIS_API_WS
      ).href;
      break;
    case WebsocketType.ODL_GENERATION:
      url = new URL(
        `/v1/ws/${props.token}/task/${props.id}`,
        import.meta.env.VITE_GIS_API_WS
      ).href;
      break;
    default:
      break;
  }
  return url;
};

export const parseWebsocketMessage = (message: string) => {
  const mappedResponse = {
    type: "",
    data: {}
  } as WebsocketMessageServer;
  if (!message) return mappedResponse;

  const messageJSON = JSON.parse(message) as WebsocketMessageServer;

  if (messageJSON.type) {
    mappedResponse.type = messageJSON.type;
  }
  if (messageJSON.data) {
    mappedResponse.data = messageJSON.data;
  }

  return mappedResponse;
};
