import React, { useMemo } from "react";
import DropDown, { DropDownOption } from "@/components/DropDown/DropDown";
import { addOrReplaceError } from "@/containers/GISUpload/utils";
import {
  ErrorModalText,
  FileSelectedValues,
  SelectedValue,
  GIS_COLUMN_MAPPING,
  UniqueValuesResponse
} from "@/types/gisUpload.type";
import { getUpdateSelectedValues } from "../../Utils/utils";
import useCustomTranslation from "@/localization/useCustomTranslation";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@fido/ui-lib";

interface SystemDropDownProps {
  keyIndex: number;
  el: string;
  selectedFileValues: FileSelectedValues | undefined;
  selectedFileValue: SelectedValue;
  dropdownOptions: DropDownOption[];
  filename: string;
  data: UniqueValuesResponse | undefined;
  errors: ErrorModalText[];
  setErrors: (errors: ErrorModalText[]) => void;
  selectedValues: FileSelectedValues[];
  setSelectedValues: (values: FileSelectedValues[]) => void; //  React.Dispatch<React.SetStateAction<FileSelectedValues[]>>;
}

const SystemDropDown: React.FC<SystemDropDownProps> = ({
  keyIndex,
  el,
  selectedFileValues,
  selectedFileValue,
  dropdownOptions,
  filename,
  data,
  setErrors,
  errors,
  selectedValues,
  setSelectedValues
}) => {
  const { prefixedT } = useCustomTranslation("SYSTEM_FIELDS");

  const getDropdownInfo = useMemo(() => {
    if (!selectedFileValues) return "";

    switch (selectedFileValue.type) {
      case GIS_COLUMN_MAPPING.MATERIAL:
        return (
          selectedFileValues.material?.find((item) => item.title === el)
            ?.type || prefixedT("DROPDOWN.1.PLACEHOLDER")
        );
      case GIS_COLUMN_MAPPING.ASSET_TYPE:
        return (
          selectedFileValues.asset_type?.find((item) => item.title === el)
            ?.type || prefixedT("DROPDOWN.2.PLACEHOLDER")
        );
      case GIS_COLUMN_MAPPING.PIPE_TYPE:
        return (
          selectedFileValues.pipe_type?.find((item) => item.title === el)
            ?.type || prefixedT("DROPDOWN.3.PLACEHOLDER")
        );
      case GIS_COLUMN_MAPPING.STATUS:
        return (
          selectedFileValues.status?.find((item) => item.title === el)?.type ||
          prefixedT("DROPDOWN.4.PLACEHOLDER")
        );
      case GIS_COLUMN_MAPPING.UNITS:
        if (Array.isArray(selectedFileValues.units)) {
          const unit = selectedFileValues.units.find(
            (item) => item.title === el
          );

          return unit?.type || prefixedT("DROPDOWN.5.PLACEHOLDER");
        }
        if (selectedFileValues.units) {
          return selectedFileValues.units.toString();
        }
        return prefixedT("DROPDOWN.5.PLACEHOLDER");
      default:
        return "";
    }
  }, [selectedFileValue, selectedFileValues, el]);

  const handleChange = (option: DropDownOption | DropDownOption[]) => {
    if (Array.isArray(option)) {
      return;
    }

    const valueAsNumber = parseFloat(el);

    if (!isNaN(valueAsNumber)) {
      setErrors(addOrReplaceError(errors, ErrorModalText.SYSTEM_FIELD_NUMERIC));
      return;
    }
    // setSelectedValues((prevValues) =>
    //   getUpdateSelectedValues(
    //     prevValues,
    //     filename,
    //     keyIndex,
    //     option,
    //     data,
    //     selectedFileValue,
    //   ),
    // );

    setSelectedValues(
      getUpdateSelectedValues(
        selectedValues,
        filename,
        keyIndex,
        option,
        data,
        selectedFileValue
      )
    );

    if (errors?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC)) {
      setErrors(
        addOrReplaceError(errors, ErrorModalText.SYSTEM_FIELD_NUMERIC, true)
      );
    }
    if (errors?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)) {
      setErrors(
        addOrReplaceError(errors, ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED, true)
      );
    }
  };

  // useEffect(() => {

  //   const timeout = setTimeout(() => {
  //     setSelectedValues(
  //       getUpdateSelectedValues(
  //         selectedValues,
  //         filename,
  //         keyIndex,
  //         dropdownOptions[0],
  //         data,
  //         selectedFileValue,
  //       ),
  //     );
  //   }, keyIndex * 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  // console.log("drodpwn2", { dropdownOptions, getDropdownInfo });

  if (!getDropdownInfo) {
    return null;
  }

  // return (
  //   <Select
  //     value={getDropdownInfo}
  //     onValueChange={(value) => {
  //       handleChange(value);
  //     }}
  //   >
  //     <SelectTrigger className="border-none h-8 w-auto mx-auto">
  //       <SelectValue placeholder={getDropdownInfo} />
  //     </SelectTrigger>
  //     <SelectContent align="center">
  //       {dropdownOptions.map((o) => (
  //         <SelectItem key={o.label} value={o.label}>
  //           {o.label}
  //         </SelectItem>
  //       ))}
  //     </SelectContent>
  //   </Select>
  // );

  return (
    <DropDown
      options={dropdownOptions}
      label={undefined}
      placeholder={getDropdownInfo}
      onChange={(option) => handleChange(option)}
      value={getDropdownInfo}
      styles={{
        center: true,
        background: "transparent",
        dropdownFitWidth: true
        // backgroundDropdown: "#26252a",
        // textColor: "#ffffff",
        // dropdownTextColor: "#b3b3b3",
        // backgroundHover: "#302e36"
      }}
    />
  );
};

export default SystemDropDown;
