import withPageLoader from "@/HOCs/withPageLoader";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useDashboardContext } from "@/Providers/DashboardProvider";
import { WaypointFilterProvider } from "@/Providers/WaypointFilterProvider";
import { GetStatusResponse } from "@/types/mobileApp.type";
import {
  GetDashboardResponse,
  GetEngineersResponse
} from "@/types/portalUsers.type";
import React, { FC } from "react";
import DashboardInfo from "./Components/DashboardInfo/DashboardInfo";
import StickyMenu from "./Components/StickyMenu/StickyMenu";
import { ActiveDashboard } from "./DashboardContainer";
import * as Styled from "./style";

type DashboardProps = {
  data: GetDashboardResponse[] | undefined;
  statuses: GetStatusResponse[] | undefined;
  error: boolean;
  activeDashboard: ActiveDashboard;
  engineers: GetEngineersResponse[] | undefined;
};

const Dashboard: FC<DashboardProps> = ({
  data,
  statuses,
  activeDashboard,
  error,
  engineers
}) => {
  const { t } = useCustomTranslation("");
  const { search } = useDashboardContext();

  const searchTerm = search ? search.toLowerCase() : "";

  const filteredDashboard = data?.filter((dashboard) =>
    dashboard.name.toLowerCase().includes(searchTerm)
  );

  return (
    <Styled.Wrapper>
      {error && (
        <Styled.ColumnWrapper data-testid="errorDashboard">
          <Styled.Error>{t("ERROR.REQUEST")}</Styled.Error>
        </Styled.ColumnWrapper>
      )}
      {!error && (
        <>
          <StickyMenu />
          <Styled.ColumnWrapper>
            {filteredDashboard &&
              filteredDashboard?.map((dashboard, key) => (
                <div key={key}>
                  <WaypointFilterProvider>
                    <DashboardInfo
                      activeDashboard={activeDashboard}
                      engineers={engineers}
                      statuses={statuses}
                      dashboard={dashboard}
                    />
                  </WaypointFilterProvider>
                </div>
              ))}
          </Styled.ColumnWrapper>
        </>
      )}
    </Styled.Wrapper>
  );
};

export default withPageLoader(Dashboard);
