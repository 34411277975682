import React, { FC, useEffect, useState } from "react";
import * as Styled from "./style";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { Button, DropDown, TextField } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { usePatchUpdateCompanyMutation } from "@/redux/portalCompanies/portalCompanies.api";
import ImageUploadBox from "@/components/ImageUploadBox/ImageUploadBox";
import { useGetPartnerListQuery } from "@/redux/portalPartners/portalPartners.api";
import { PatchUpdateCompanyBody } from "@/types/portalCompany.type";
import theme from "@/theme";
import { currencyOptions } from "@/utils/currency";
import i18n from "@/localization/i18n";
import { dropDownCountryData } from "@/utils/countryData";

interface EditCompanyProps {
  company_id: number;
  company_name: string;
  metric_system: string;
  currency: string;
  country_code: string;
  company_code: string;
  partner_id: number;
  logo: string;
  closeOverlay: (refetchValues: boolean) => Promise<void>;
}

const EditCompany: FC<EditCompanyProps> = ({
  company_id,
  company_name,
  metric_system,
  currency,
  country_code,
  company_code,
  partner_id,
  logo,
  closeOverlay
}) => {
  const [mutate, { isSuccess, isError, reset }] =
    usePatchUpdateCompanyMutation();
  const { data: partnerData } = useGetPartnerListQuery();
  const { t } = useCustomTranslation("EDIT_COMPANY");
  const initialValues: PatchUpdateCompanyBody = {
    company_name,
    metric_system,
    currency,
    country_code,
    company_code,
    partner_id,
    logo
  };

  const [countryCodes, setCountryCodes] = useState<DropDownOption[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const countryData = await dropDownCountryData();

      setCountryCodes(countryData);
    };

    void fetchData();
  }, [i18n.language]);

  const [isNoChange, setIsNoChange] = useState(false);

  const onSubmit = async (values: PatchUpdateCompanyBody) => {
    // check what has changed and mutate those values only
    const changedValues: PatchUpdateCompanyBody = {};

    Object.entries(initialValues).forEach(([key, value]) => {
      const typedKey = key as keyof PatchUpdateCompanyBody;
      if (values[typedKey] !== value) {
        (changedValues[typedKey] as string | number | undefined) =
          values[typedKey];
      }
    });

    if (Object.entries(changedValues).length === 0) {
      setIsNoChange(true);
    } else {
      await mutate({ id: company_id, body: changedValues });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await closeOverlay(true);
        reset();
      }
    };

    void fetchData();
  }, [isSuccess]);

  const ValidationSchema = Yup.object().shape({
    company_name: Yup.string(),
    metric_system: Yup.mixed().oneOf(["Metric", "Imperial"]),
    currency: Yup.string(),
    country_code: Yup.string(),
    company_code: Yup.string(),
    partner_id: Yup.string(),
    logo: Yup.string()
  });

  return (
    <>
      <Styled.Underlay
        data-testid={"overlay"}
        onClick={() => {
          void (async () => {
            await closeOverlay(false);
          })();
        }}
      />
      <Styled.DialogBox open={true}>
        <Styled.Wrapper>
          <Styled.TitleContainer>
            <h3>{t("EDIT_COMPANY_PAGE.TITLE")}</h3>
          </Styled.TitleContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, errors, submitForm, setFieldValue }) => {
              return (
                <div>
                  <div>
                    <ImageUploadBox
                      label={t("ADD_COMPANY.LOGO.LABEL")}
                      onImageUpload={(imageString: string[]) =>
                        void setFieldValue("logo", imageString[0])
                      }
                    />
                  </div>
                  <Styled.FormRow>
                    <Field
                      as={TextField}
                      required
                      name="company_name"
                      testId="company-name"
                      error={errors.company_name}
                      label={t("VIEW_COMPANIES.TITLE.NAME")}
                    />
                    <Field
                      as={TextField}
                      required
                      name="company_code"
                      testId="company-code"
                      error={errors.company_code}
                      label={t("VIEW_COMPANIES.TITLE.COMPANY_CODE")}
                    />
                    <Styled.MarginBottom>
                      <Field
                        as={DropDown}
                        required
                        testId="partner"
                        name="partner_id"
                        styles={{ border: true }}
                        options={partnerData?.map((partner) => {
                          return {
                            label: partner.partner_name,
                            value: partner.id
                          };
                        })}
                        label={t("VIEW_COMPANIES.TITLE.PARTNER")}
                        placeholder={t("FORM.PLACEHOLDER.PARTNER")}
                        onChange={(option: DropDownOption) =>
                          setFieldValue("partner_id", option.value)
                        }
                        error={errors.partner_id?.toString()}
                        value={values.partner_id}
                      />
                    </Styled.MarginBottom>
                    <Styled.MarginBottom>
                      <Field
                        as={DropDown}
                        required
                        name="currency"
                        options={currencyOptions() as DropDownOption[]}
                        styles={{ border: true }}
                        testId="currency"
                        onChange={(option: DropDownOption) =>
                          setFieldValue("currency", option.value)
                        }
                        error={errors.currency}
                        label={t("VIEW_COMPANIES.TITLE.CURRENCY")}
                      />
                    </Styled.MarginBottom>
                    <Field
                      as={DropDown}
                      styles={{ border: true }}
                      options={
                        countryCodes as DropDownOption | DropDownOption[]
                      }
                      label={t("VIEW_COMPANIES.TITLE.COUNTRY")}
                      placeholder={t("ADD_COMPANY.COUNTRY.PLACEHOLDER")}
                      onChange={(option: DropDownOption) =>
                        setFieldValue("country_code", option.value)
                      }
                      countOptions={4}
                      error={errors.country_code?.toString()}
                      value={values.country_code?.toString()}
                    />
                    <Field
                      as={DropDown}
                      required
                      name="metric_system"
                      styles={{ border: true }}
                      options={["Metric", "Imperial"].map((metric) => ({
                        label: metric,
                        value: metric
                      }))}
                      testId="measurement"
                      label={t("VIEW_COMPANIES.TITLE.UNIT")}
                      placeholder={t("FORM.PLACEHOLDER.METRIC_SYSTEM")}
                      onChange={(option: DropDownOption) =>
                        setFieldValue("metric_system", option.value)
                      }
                      error={errors.metric_system}
                      value={values.metric_system}
                    />
                  </Styled.FormRow>
                  <Styled.ButtonContainer>
                    <Button
                      type="submit"
                      color={theme.colors.coral}
                      onClick={() => {
                        void (async () => {
                          await closeOverlay(false);
                        })();
                      }}
                    >
                      {t("EDIT_COMPANY_PAGE.FORM.DISCARD")}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                    >
                      {t("EDIT_COMPANY_PAGE.FORM.SUBMIT")}
                    </Button>
                  </Styled.ButtonContainer>
                  {isError && (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>{t("ERROR.REQUEST")}</Styled.ErrorText>
                    </Styled.ErrorContainer>
                  )}
                  {isNoChange && (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>
                        {t("EDIT_COMPANY_PAGE.FORM.NO_CHANGES")}
                      </Styled.ErrorText>
                    </Styled.ErrorContainer>
                  )}
                </div>
              );
            }}
          </Formik>
        </Styled.Wrapper>
      </Styled.DialogBox>
    </>
  );
};

export default EditCompany;
