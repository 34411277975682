import {
  PageHeaderSubtitle,
  PageHeaderTitle,
  PageHeaderWrapper
} from "@/layouts/AppLayout/style";

import styled from "styled-components";

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;
export const TableHeaderTitleWrapper = styled(PageHeaderWrapper)``;

export const TableHeaderTitle = styled(PageHeaderTitle)``;

export const TableHeaderSubTitle = styled(PageHeaderSubtitle)<{
  visible: boolean;
}>`
  display: ${({ visible }) => (!visible ? "none" : "block")};
`;

export const TableHeaderControlsWrapper = styled.div<{
  visible: boolean;
}>`
  display: ${({ visible }) => (!visible ? "none" : "block")};

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-top: 24px;
`;
