import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";

// Components
import UserManagementDropdown from "../components/UserManagementDropdown";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";
import { useUserRole } from "@/hooks/query/useUserRole";

// Utils
import { CreateUserManagementDropDownRoutes } from "../utils/createUserManagementRoutes";

export interface PartnerAndCompany {
  partners: { label: string; value: string }[];
  companies: { label: string; value: string }[];
}

export const UserManagement: FC = () => {
  const { prefixedT } = useCustomTranslation("USER_MANAGEMENT");
  const role = useUserRole();
  const location = useLocation();
  const pathnameArray = location.pathname.split("/");
  const lastPathname = pathnameArray[pathnameArray.length - 1];
  const selectedOption = lastPathname !== "user-management" ? lastPathname : "";

  const dropDownRoutes = CreateUserManagementDropDownRoutes(role);

  return (
    <div className="flex gap-12 min-h-[calc(100vh-var(--header-height))] bg-white pt-12 px-12 pb-4">
      <div className="min-w-fit w-72">
        <h2 className="mb-2">{prefixedT("DROP_DOWN.TITLE")}</h2>
        {dropDownRoutes.map((dropdownData, index) => (
          <UserManagementDropdown
            key={index}
            keyValue={index}
            selected={selectedOption}
            {...dropdownData}
          />
        ))}
      </div>
      <div className="w-full min-w-0 bg-white">
        <Outlet />
        {/* {selectedOption === "view-users" && (
          <ViewUsers
            roles={listOfRoles}
            partnersAndCompanies={partnersAndCompanies}
            role={role}
          />
        )}
        {selectedOption === "view-partners" &&
          role === UserRoles.SUPER_ADMIN && <ViewPartners />}
        {selectedOption === "view-companies" &&
          (role === UserRoles.SUPER_ADMIN ||
            role === UserRoles.PARTNER_ADMIN) && <ViewCompanies />}
        {selectedOption === "view-projects" && <ViewProjects />}
        {selectedOption === "add-user" && (
          <AddUser partnersAndCompanies={partnersAndCompanies} />
        )}
        {selectedOption === "add-project" && (
          <AddProject partnersAndCompanies={partnersAndCompanies} />
        )}
        {selectedOption === "add-company" &&
          (role === UserRoles.SUPER_ADMIN ||
            role === UserRoles.PARTNER_ADMIN) && (
            <AddCompany
              partnersAndCompanies={partnersAndCompanies}
              countryData={countryCodes}
            />
          )}
        {selectedOption === "add-partner" && role === UserRoles.SUPER_ADMIN && (
          <AddPartner countryData={countryCodes} />
        )} */}
      </div>
    </div>
  );
};
