import React, { FC } from "react";
import * as Styled from "./style";
import { DropDown } from "@/components";
import { DropDownOption } from "@/components/DropDown/DropDown";
import { useNavigate } from "react-router-dom";
import SessionsListView from "./Sessions/ListView";
import WaypointsListView from "./Waypoints/ListView";
import useCustomTranslation from "@/localization/useCustomTranslation";
import JobsListView from "./Jobs/ListView";
import WaypointLogsListView from "./WaypointLogs/ListView";
import RelaysListView from "./Relays/ListView";

interface BrowseContainerProps {
  dataType: "waypoints" | "sessions" | "jobs" | "waypoint-logs" | "relays" | "";
}

const BrowseContainer: FC<BrowseContainerProps> = ({ dataType }) => {
  const navigate = useNavigate();

  const { prefixedT } = useCustomTranslation("LIST_VIEW");

  const options = [
    { value: "waypoints", label: prefixedT("WAYPOINTS") },
    { value: "sessions", label: prefixedT("SESSIONS") },
    { value: "jobs", label: prefixedT("JOBS") },
    { value: "waypoint-logs", label: prefixedT("WAYPOINT_LOGS") },
    { value: "relays", label: prefixedT("RELAYS") }
  ];

  return (
    <Styled.Wrapper>
      <Styled.SelectionWrapper>
        {prefixedT("SELECT_DATA_TYPE")}
        <DropDown
          value={dataType}
          options={options}
          onChange={(option) => {
            const thisOption = option as DropDownOption;
            const newUrl = `/app/browse/${thisOption.value}`;
            navigate(newUrl);
          }}
          placeholder={"Select data type"}
          styles={{ border: true }}
        />
      </Styled.SelectionWrapper>
      {dataType === "waypoints" ? (
        <WaypointsListView />
      ) : dataType === "sessions" ? (
        <SessionsListView />
      ) : dataType === "jobs" ? (
        <JobsListView />
      ) : dataType === "waypoint-logs" ? (
        <WaypointLogsListView />
      ) : dataType === "relays" ? (
        <RelaysListView />
      ) : (
        <p>Select a data type to begin browsing.</p>
      )}
    </Styled.Wrapper>
  );
};

export default BrowseContainer;
