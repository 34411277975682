import {
  DMAsResponse,
  GetDMAsParams,
  GetDMAsResponse,
  GetProjectParams,
  GetProjectResponse,
  ProjectFetchParams,
  PatchUpdateProjectBody,
  Project,
  ProjectCreateData
} from "@/types/portalProject.type";
import { buildQueryParams } from "@/utils/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "../api";
import {
  IPaginationResponse,
  iSearchProps,
  IPaginationProps,
  ISortBy
} from "@/types/rtk.type";
import { ProjectByCompany } from "@/types/branches.type";
import { Tag } from "../tag";

export const portalProjectsApi = createApi({
  reducerPath: "portalProjectsQuery",
  tagTypes: [Tag.PROJECT, Tag.PROJECT_LIST],
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    getProject: builder.query<ProjectByCompany, ProjectFetchParams>({
      query: (params: ProjectFetchParams) => ({
        url: `/portal_branches/${params.company_id}/${params.project_id}`,
        method: "GET"
      }),
      providesTags: (res) =>
        res
          ? [{ type: Tag.PROJECT }, { type: Tag.PROJECT, id: res.id }]
          : [{ type: Tag.PROJECT }]
    }),
    getProjects: builder.query<GetProjectResponse, GetProjectParams>({
      query: (params: GetProjectParams) =>
        `/portal_branches/project/dropdown/?${buildQueryParams(params || {})}`
    }),
    getProjectsList: builder.query<
      IPaginationResponse<Project>,
      IPaginationProps & iSearchProps & ISortBy
    >({
      query: (params) =>
        `/portal_branches/branches/?${buildQueryParams(params || {})}`,
      providesTags: (res, error, params) =>
        res
          ? [
              { type: Tag.PROJECT_LIST },
              { type: Tag.PROJECT_LIST, id: buildQueryParams(params || {}) }
            ]
          : [{ type: Tag.PROJECT_LIST }]
    }),
    getProjectsByCompany: builder.query<ProjectByCompany[], number | string>({
      query: (companyId: number | string) => `/portal_branches/${companyId}`
    }),
    createProject: builder.mutation<
      ProjectCreateData,
      { companyId: number; projectName: string }
    >({
      query: ({ companyId, projectName }) => ({
        url: `/highland_portal/create_project/${companyId}?project_name=${projectName}`,
        method: "POST"
      })
    }),
    updateProject: builder.mutation<
      undefined,
      { id: number; body: PatchUpdateProjectBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_branches/${id}`,
        method: "PATCH",
        body
      })
    }),
    getDMAs: builder.query<GetDMAsResponse, GetDMAsParams>({
      query: (params: GetDMAsParams) =>
        `/portal_branches/dma/dropdown/?${buildQueryParams(params || {})}`
    }),
    getUnassignedDMAs: builder.query<DMAsResponse[], ProjectFetchParams>({
      query: (params: ProjectFetchParams) =>
        `/portal_branches/dmas/unassigned/?${buildQueryParams(params || {})}`
    }),
    PostUploadHealthCheckUrl: builder.mutation<
      undefined,
      { id: number; body: string }
    >({
      query: ({ id, body }) => ({
        url: `/portal_branches/report/${id}`,
        method: "POST",
        body
      })
    })
  })
});

export const {
  useGetProjectQuery,
  useLazyGetProjectsByCompanyQuery,
  useCreateProjectMutation,
  useGetProjectsQuery,
  useGetDMAsQuery,
  useGetProjectsListQuery,
  useLazyGetProjectsListQuery,
  useGetUnassignedDMAsQuery,
  useUpdateProjectMutation,
  usePostUploadHealthCheckUrlMutation
} = portalProjectsApi;
