import { Map } from "mapbox-gl";
import { MapElement } from "../../utils/types";
import {
  handleClickWithTooltipCheck,
  polygonLayers,
  setCursorPointer
} from "./utils";

const drawPolygon = (
  map: Map,
  item: MapElement,
  onClickModalOpen: (id: string) => void,
  modal?: boolean | undefined,
  doNotAllowClickThrough?: boolean,
  idx = 0
): void => {
  if (map.getSource(item.id)) {
    return;
  }

  polygonLayers(map, item, idx);
  setCursorPointer(map, item);
  if (!doNotAllowClickThrough) {
    handleClickWithTooltipCheck(map, item, onClickModalOpen, modal);
  }
};

export default drawPolygon;
