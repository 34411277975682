import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const PlotContainer = styled.div`
  position: relative;
`;

export const SummaryDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const List = styled.ul`
  margin: 10px 0 0;
  list-style: none;
`;

export const Item = styled.li``;
