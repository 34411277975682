import { useState } from "react";

export interface ModalStatus {
  show: () => void;
  hide: () => void;
  isOpen: boolean;
}

const useModalStatus = (): ModalStatus => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return {
    show: () => setOpen(true),
    hide: () => setOpen(false),
    isOpen
  };
};

export default useModalStatus;
