import styled from "styled-components";
import ArrowDown from "@/assets/icons/arrow_down.svg?react";
import theme from "@/theme";
import { Link as reactLink } from "react-router-dom";

export const Link = styled(reactLink)`
  text-decoration: none;
  color: #fff;
`;

export const BtnContainer = styled.div``;

export const PlusImg = styled.img`
  margin-left: 10px;
  height: 14px;
  width: 14px;
`;

export const ClickableAreaIndicator = styled(ArrowDown)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  fill: #fff;
`;

export const DMACell = styled.td<{ show: boolean }>`
  lineheight: ${({ show }) => (show ? "150%" : "0%")};
`;

export const DMAsContainer = styled.div<{ show: boolean; maxHeight: number }>`
  position: relative;
  margin: ${({ show }) => (show ? "10px 0 10px 7%" : "0 7%")};
  padding: 0 20px;
  max-height: ${({ show, maxHeight }) => (show ? maxHeight + "px" : "0px")};
  transition: all 0.2s ease-in-out;
  overflow-y: ${({ show }) => (show ? "auto" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  border-left: 3px ${theme.colors.deepBlue} solid;
`;

export const DMAHeader = styled.h3<{ idx: number }>`
  position: sticky;
  top: 0;
  background-color: ${({ idx }) =>
    idx % 2 === 0 ? "#f2fafe" : theme.colors.white};
  padding: 10px;
`;

export const DMAList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const DMAListItem = styled.li`
  list-style-type: none;
`;
