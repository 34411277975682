import ArrowDown from "@/assets/icons/arrow_down.svg?react";

import styled from "styled-components";

export const Dropdown = styled.ul<{
  show: boolean;
  depth: boolean;
}>`
    position: absolute;
    top: ${({ depth }) => (depth ? "-7px;" : "auto")};
    left: ${({ depth }) => (depth ? "100%" : "0")};
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    display: ${({ show }) => (show ? "show" : "none")};
  }
`;

export const MenuItems = styled.li`
  position: relative;
  font-size: 14px;
`;

export const Arrow = styled(ArrowDown)<{
  open: boolean;
}>`
  fill: #00699d;
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  padding-left: 10px;

  path {
    fill: #00699d !important;
  }
`;

export const ArrowRight = styled(ArrowDown)`
  transform: rotate(-90deg);
  transition: 0.3s ease transform;
`;

export const DropdownBtn = styled.button`
  text-align: left;
  padding: 0.7rem 1rem;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: ${({ theme }) => theme.colors.lightBlue}33;
  }
`;
