import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: calc(100vh - 70px);
  background: white;
  padding: 40px;
`;

export const Container = styled.div`
  display: flex;
`;

export const NavWrapper = styled.div``;
