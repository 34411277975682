import { EmailValidator, PhoneValidator } from "@/utils/validators";
import { t } from "i18next";
import * as Yup from "yup";

export const ValidationSchemaFormUser = Yup.object().shape({
  first_name: Yup.string().required(t("FORM.VALIDATION.FIRST_NAME")),
  last_name: Yup.string().required(t("FORM.VALIDATION.LAST_NAME")),
  email: EmailValidator.required(),
  role_id: Yup.number().required(t("FORM.VALIDATION.ROLE")),
  phone: PhoneValidator.required(t("FORM.VALIDATION.PHONE")),
  metric_system: Yup.string().required(t("FORM.VALIDATION.MEASUREMENT_SYSTEM")), // TODO: add validation for correct value
  currency: Yup.string().required(t("FORM.VALIDATION.CURRENCY")), // TODO: add validation for correct value
  country_code: Yup.string().required(t("FORM.VALIDATION.COUNTRY")) // TODO: add validation for correct value
});
