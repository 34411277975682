import { useContext, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

// Components
import GenerateMap from "@/components/ReportLayout/widgets/MapView/GenerateMap";
import { Loader } from "@/components";

// Redux
import { useGetPreviewGISQuery } from "@/redux/gisUpload/gisUpload.api";

// Providers
import {
  FilterContext,
  FilterName,
  withFilterProvider
} from "@/Providers/FilterProvider";
import { MapProvider } from "@/Providers/MapProvider";

// Hooks
import useCustomTranslation from "@/localization/useCustomTranslation";
import useNotification from "@/hooks/useNotification";
import { useGISMapPreview } from "@/hooks/query/useGISMapPreview";

// Utils
import { formatMapData } from "../../../../../user-management/utils/formatMapData";

// Types
import { ProjectFormValues } from "../schema";
import {
  useGetGeneratedODLsQuery,
  useGetGeneratedODLsTaskQuery,
  useGetGeneratedVirtualDMAsQuery
} from "@/redux/portalProjects/portalProjectsGIS.api";
import { useUploadODLs } from "@/hooks/query/useUploadODLs";
import { useDMAData } from "@/hooks/query/useDMAData";
import { WebsocketMessageTypeBase } from "@/types/websocket.type";
import { useUploadDMAs } from "@/hooks/query/useUploadDMAs";

type ProjectGISPreviewMapProps = {
  shouldShowGeoJSON: boolean;
};

// const geoPath1 = new URL(
//   "../../../../../../components/data/1.geojson", // cwDMA_polygon_polygon.geojson",
//   import.meta.url
// ).href;

// const geoPath2 = new URL(
//   "../../../../../../components/data/2.geojson", // cwConValve_point_point.geojson",
//   import.meta.url
// ).href;

// const geoPath3 = new URL(
//   "../../../../../../components/data/3.geojson", // cwPressMain_polyline_line.geojson",
//   import.meta.url
// ).href;

// const testGeo = {
//   0: geoPath1,
//   1: geoPath2,
//   2: geoPath3
// };

export const ProjectGISPreviewMap = withFilterProvider(
  (props: ProjectGISPreviewMapProps) => {
    const { prefixedT } = useCustomTranslation("GIS_UPLOAD.MAP_PREVIEW");

    const form = useFormContext<ProjectFormValues>();

    const gisUploadId = form.watch("gisUploadId");
    const gisUploadCompanyId = form.watch("gisUploadCompanyId");

    const levelODL = form.watch("levelODL");
    const [geojsonLayer, setGeojsonLayer] = useState<string[]>([]);

    const { setFilterMapValues, setMapFilters, mapFilters } =
      useContext(FilterContext);

    const defaultFilterMapValues: FilterName[] = ["DMAS", "PIPES", "ASSETS"];

    const {
      data,
      isLoading: isPreviewLoading,
      isFetching: isPreviewFetching,
      isError: isPreviewError,
      isSuccess: isPreviewSuccess
    } = useGISMapPreview({
      companyId: gisUploadCompanyId,
      uploadId: gisUploadId
    });

    // const { data: ODLData, isSuccess: isODLSuccess } = useGetGeneratedODLsQuery(
    //   { uploadId: gisUploadId },
    //   { skip: !isPreviewSuccess }
    // );

    const { data: ODLData, isSuccess: isODLSuccess } = useUploadODLs({
      taskId: data?.odl_task_id
    });

    const { data: DMAData, isSuccess: isDMASuccess } = useUploadDMAs(
      { uploadId: gisUploadId },
      { skip: !isPreviewSuccess }
    );

    const isMapLoading = isPreviewLoading || isPreviewFetching;

    useEffect(() => {
      setFilterMapValues(defaultFilterMapValues);
      setMapFilters({ filters: ["DMAS", "PIPES", "ASSETS"] });
    }, []);

    useEffect(() => {
      if (props.shouldShowGeoJSON) {
        const geojsonLayer = [];

        if (isDMASuccess && DMAData.status === "SUCCESS" && !!DMAData.url) {
          geojsonLayer.push(DMAData.url);
        }
        if (isODLSuccess && ODLData.type === WebsocketMessageTypeBase.SUCCESS) {
          geojsonLayer.push(ODLData.data.map((v) => v.url)[levelODL - 1]);
        }

        setGeojsonLayer(geojsonLayer);
      }
    }, [
      props.shouldShowGeoJSON,
      ODLData,
      DMAData,
      isODLSuccess,
      isDMASuccess,
      levelODL
    ]);

    // useEffect(() => {
    //   props.setPreviewMapLoaded(isPreviewSuccess);
    // }, [isPreviewSuccess]);

    const mapData = useMemo(
      () => formatMapData(data?.map, mapFilters),
      [data, mapFilters]
    );

    

    // TODO: Finish - adjust map geojson approach (1. create base query for fetching files, 2. loop file data and change each property to have different color for DMA file)

    return (
      <div
        data-testid="gis-map-preview"
        className="h-full w-full overflow-x-auto min-h-0"
      >
        {isPreviewError && <p>{prefixedT("API.GENERAL_ERROR")}</p>}
        <div
          data-testid="map-preview"
          className="h-full border border-input rounded-md"
        >
          <MapProvider>
            <GenerateMap
              readonly
              fullMapData={mapData ?? []}
              geojsonMapData={
                props.shouldShowGeoJSON ? geojsonLayer : undefined
              }
              viewportMapData={mapData}
              isLoading={isMapLoading}
            />
          </MapProvider>
        </div>
        {!data && !gisUploadId && (
          <p className="text-sm text-destructive">No GIS data found...</p>
        )}
      </div>
    );
  }
);
