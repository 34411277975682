import { createApi } from "@reduxjs/toolkit/query/react";
import { gisApiBaseQuery } from "../api";

// Types
import type {
  ColumnAndSampleDataResponse,
  ColumnMappingBody,
  DiscardFileBody,
  GISMapPreviewProps,
  PostUploadGISDataParams,
  PostUploadGISFilesParams,
  PreviewValuesResponse,
  STANDARDISING,
  UniqueValuesResponse
} from "@/types/gisUpload.type";
import type { UploadId } from "./gisUpload.slice";

// Constants
import { Tag } from "../tag";

// Utils
import { buildQueryParams } from "@/utils/rtk";

export const gisUploadApi = createApi({
  reducerPath: "gisUploadApi",
  baseQuery: gisApiBaseQuery,
  tagTypes: [Tag.GIS_COLUMN_AND_SAMPLE_DATA],
  endpoints: (builder) => ({
    PostUploadGISData: builder.mutation<
      undefined,
      { params: PostUploadGISDataParams }
    >({
      query: ({ params }) => {
        return {
          url: `/gis_upload/upload_gis_data?${buildQueryParams(params || {})}`,
          method: "POST"
        };
      }
    }),
    PostUploadGISFiles: builder.mutation<
      number,
      { params: PostUploadGISFilesParams; file: File }
    >({
      query: ({ params, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/gis_upload/upload_gis_files?${buildQueryParams(params || {})}`,
          method: "POST",
          body: formData
        };
      }
    }),
    PostColumnMapping: builder.mutation<undefined, { body: ColumnMappingBody }>(
      {
        query: ({ body }) => {
          return {
            url: `/column_mapping/`,
            method: "POST",
            body
          };
        }
      }
    ),
    GetColumnAndSampleData: builder.query<
      ColumnAndSampleDataResponse,
      { companyId: number | null; uploadId: UploadId }
    >({
      query: ({ companyId, uploadId }) =>
        `/gis_upload/data_init/company_id/${companyId}/upload_id/${uploadId}`,
      providesTags: (_, __, args) =>
        args.uploadId
          ? [
              { type: Tag.GIS_COLUMN_AND_SAMPLE_DATA },
              { type: Tag.GIS_COLUMN_AND_SAMPLE_DATA, id: args.uploadId }
            ]
          : [{ type: Tag.GIS_COLUMN_AND_SAMPLE_DATA }]
    }),
    GetUniqueValues: builder.query<
      UniqueValuesResponse,
      {
        companyId: number | null;
        uploadId: UploadId;
        filename: string;
        columnTitle: string;
        standardising: STANDARDISING;
      }
    >({
      query: ({ companyId, uploadId, filename, columnTitle, standardising }) =>
        `/gis_upload/unique_values/company_id/${companyId}/upload_id/${uploadId}/filename/${filename}/column_title/${columnTitle}/standardising/${standardising}`
    }),
    GetPreviewGIS: builder.query<PreviewValuesResponse, GISMapPreviewProps>({
      query: ({ companyId, uploadId, withCache }) =>
        `/gis_upload/preview_gis?${buildQueryParams({
          company_id: companyId,
          upload_id: uploadId,
          with_cache: withCache // read preview from cache, instead of generating
        })}`
    }),
    PostDiscardFile: builder.mutation<undefined, { body: DiscardFileBody }>({
      query: ({ body }) => {
        return {
          url: `/gis_upload/discard_file`,
          method: "POST",
          body
        };
      },
      invalidatesTags: (_result, _error, { body }) => [
        { type: Tag.GIS_COLUMN_AND_SAMPLE_DATA, id: body.upload_id as number }
      ]
    })
  })
});

export const {
  usePostUploadGISDataMutation,
  usePostUploadGISFilesMutation,
  useGetColumnAndSampleDataQuery,
  useGetUniqueValuesQuery,
  useGetPreviewGISQuery,
  usePostColumnMappingMutation,
  usePostDiscardFileMutation
} = gisUploadApi;
