import { DatePicker, DropDown } from "@/components";
import Button from "@/components/Button/Button";
import { DropDownOption } from "@/components/DropDown/DropDown";
import TextField from "@/components/TextField/TextField";
import withPageLoader from "@/HOCs/withPageLoader";
import useCustomTranslation from "@/localization/useCustomTranslation";
import { usePatchUpdateWaypointMutation } from "@/redux/highlandPortal/highlandPortal.api";
import { FormWaypointValues } from "@/redux/portalUsers/portalUsers.api";
import { PatchUpdateWaypointPortalBody } from "@/types/highlandPortal";
import { WaypointInformationType } from "@/types/report.type";
import { Field, Formik, FormikErrors } from "formik";
import moment from "moment";
import React, { FC, Fragment, useEffect } from "react";
import * as Styled from "./style";
import TextArea from "@/components/TextArea/TextArea";
import { FormWaypoint } from "@/components/Form/FormWaypoint";
import { FormWrapper } from "@/components/Form/style";
import { Error } from "@/styles/Error";
import { useInitialValuesWaypoint } from "@/hooks/form/useInitialValuesWaypoint";

export interface OptionProps {
  title: string;
  option: string | number | null;
  edit: boolean;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<PatchUpdateWaypointPortalBody>>;
  placeholder?: string;
  testid: string;
  dropdown?: boolean;
  dropdownValues?: DropDownOption[];
  date?: boolean;
  name?: string;
  input?: boolean;
  type?: string;
}

export const Option = ({
  title,
  dropdown,
  dropdownValues,
  option,
  type,
  edit,
  input,
  placeholder,
  date,
  testid,
  name,
  setFieldValue
}: OptionProps) => {
  const renderOption = () => {
    if (!edit && !date) {
      return <Styled.ListObject>{option}</Styled.ListObject>;
    } else if (!edit && date && option) {
      return (
        <Styled.ListObject>
          {moment(option).format("YYYY-MM-DD")}
        </Styled.ListObject>
      );
    }
    return null;
  };

  const renderTextArea = () => {
    if (input && edit && setFieldValue && name && type === "textarea") {
      return (
        <Field
          as={TextArea}
          name={name}
          type={type || "text"}
          testId={testid}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
        />
      );
    }
    return null;
  };

  const renderDateField = () => {
    if (date && edit && setFieldValue && name) {
      return (
        <Field
          as={DatePicker}
          name={name}
          placeholder={placeholder}
          onChange={(dateValue: Date) => {
            void setFieldValue(name, dateValue ? dateValue : null);
          }}
          testId={testid}
        />
      );
    }
    return null;
  };

  const renderInputField = () => {
    if (input && edit && setFieldValue && name && type !== "textarea") {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (type === "number") {
          void setFieldValue(name, value === "" ? null : value);
        } else {
          void setFieldValue(name, value);
        }
      };
      return (
        <Field
          as={TextField}
          onChange={handleChange}
          name={name}
          type={type || "text"}
          testId={testid}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
        />
      );
    }
    return null;
  };

  const renderDropdownField = () => {
    if (dropdown && edit && setFieldValue && name && dropdownValues) {
      return (
        <Styled.DropDownContainer>
          <Field
            name={name}
            as={DropDown}
            testId={testid}
            styles={{ border: true }}
            options={dropdownValues.map((value: DropDownOption) => ({
              value: value.value,
              label: value.label
            }))}
            placeholder={option || placeholder}
            onChange={(optionDropdown: DropDownOption) =>
              setFieldValue(name, optionDropdown.value)
            }
          />
        </Styled.DropDownContainer>
      );
    }
    return null;
  };

  return (
    <Styled.OptionList edit={edit}>
      {title}: {renderOption()}
      <Styled.Option>
        {renderInputField()}
        {renderDropdownField()}
        {renderDateField()}
        {renderTextArea()}
      </Styled.Option>
    </Styled.OptionList>
  );
};

const DisplayOption = ({
  title,
  value = ""
}: {
  title: string;
  value?: string | number;
}) => {
  return (
    <div>
      <Styled.Bold>{title}</Styled.Bold>: <span>{value}</span>
    </div>
  );
};

type EditWaypointProps = {
  data: WaypointInformationType;
  isEditMode: boolean;
  waypointId: string;
  hideEdit: () => void;
  onSuccess: () => void;
};

// TODO: Refactor - 1. inputs and display values 2. initial values 3. check RTK query
const EditWaypoint: FC<EditWaypointProps> = ({
  waypointId,
  isEditMode,
  data,
  hideEdit,
  onSuccess
}) => {
  const [updateWaypoint, { isSuccess, isError }] =
    usePatchUpdateWaypointMutation();
  const { t, prefixedT } = useCustomTranslation("WAYPOINT_PAGE.WAYPOINT_INFO");

  const initialValues = useInitialValuesWaypoint(data);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const handleCancel = () => {
    hideEdit();
  };

  const onSubmit = async (values: FormWaypointValues) => {
    // console.log("[form] waypoint edit submit: ", {
    //   waypointId,
    //   values,
    //   initialValues,
    //   updateWaypoint
    // });

    await updateWaypoint({
      query: waypointId,
      body: {
        material: values.pipe_material,
        predicted_leak_type_id: +values.predicted_leak_type_id,
        actual_leak_type_id: +values.actual_leak_type_id,
        last_scheduled_engineer_id: +values.last_scheduled_engineer_id,
        status_id: +values.status_id,
        surface_type: values.surface_type,
        address: values.address,
        estimated_size_engineer_unit: values.estimated_size_engineer_unit,
        environmental_notes: values.environmental_notes,
        comment: values.comment,
        estimated_size_client_unit: values.estimated_size_client_unit,
        estimated_size: values.estimated_size,
        estimated_size_unit: values.estimated_size_unit,
        diameter: values.diameter,
        diameter_unit: values.diameter_unit
      }
    });
  };

  console.log("[form] waypoint edit", { data, initialValues });

  // if (false) {
  //   return <Loader />;
  // }

  return (
    <FormWrapper>
      <DisplayOption title="DMA Reference" value={data?.dma_key || ""} />
      <DisplayOption
        title="AI predicted leak size"
        value={data?.AI_size || ""}
      />
      <DisplayOption title="What 3 Words" value={data?.what3words || ""} />

      {
        isEditMode ? (
          <>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ submitForm, dirty, isSubmitting }) => (
                <Fragment>
                  <FormWaypoint />

                  {isError && <Error>{t("ERROR.REQUEST")}</Error>}

                  <Styled.SaveContainer>
                    <Button onClick={handleCancel}>
                      {prefixedT("CANCEL")}
                    </Button>
                    <Button
                      disabled={!dirty || isSubmitting}
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                      type="submit"
                    >
                      {prefixedT("SUBMIT")}
                    </Button>
                  </Styled.SaveContainer>
                </Fragment>
              )}
            </Formik>
          </>
        ) : (
          <Fragment>
            <DisplayOption
              title={prefixedT("LAST_SCHEDULED_ENGINEER")}
              value={data?.last_scheduled_engineer || ""}
            />

            <DisplayOption
              title={prefixedT("CURRENT_STATUS")}
              value={data?.current_status || ""}
            />

            <DisplayOption
              title={prefixedT("PIPE_MATERIAL")}
              value={data?.pipe_material || ""}
            />

            <DisplayOption
              title={prefixedT("ADDRESS")}
              value={data?.address || ""}
            />
            <DisplayOption
              title={prefixedT("COMMENT")}
              value={data?.comment || ""}
            />

            <DisplayOption
              title={prefixedT("ENVIRONMENTAL_NOTES")}
              value={data?.environmental_notes || ""}
            />

            <DisplayOption
              title={prefixedT("PREDICTED_LEAK_SIZE")}
              value={data?.predicted_leak_type || ""}
            />

            <DisplayOption
              title={prefixedT("ACTUAL_LEAK_TYPE")}
              value={data?.actual_leak_type || ""}
            />

            <DisplayOption
              title={prefixedT("ESTIMATED_SIZE_ENGINEER")}
              value={
                data?.estimated_size_engineer
                  ? `${data?.estimated_size_engineer} ${data?.estimated_size_engineer_unit}`
                  : ""
              }
            />

            <DisplayOption
              title={prefixedT("ESTIMATED_SIZE_CLIENT")}
              value={
                data?.estimated_size_client
                  ? `${data?.estimated_size_client} ${data?.estimated_size_client_unit}`
                  : ""
              }
            />

            <DisplayOption
              title={prefixedT("ESTIMATED_SIZE")}
              value={
                data?.estimated_size
                  ? `${data?.estimated_size} ${data?.estimated_size_unit}`
                  : ""
              }
            />

            <DisplayOption
              title={prefixedT("DIAMETER")}
              value={
                data?.diameter ? `${data?.diameter} ${data?.diameter_unit}` : ""
              }
            />
          </Fragment>
        )

        // (
        //   <Formik
        //     validateOnChange={false}
        //     validateOnBlur={false}
        //     onSubmit={onSubmit}
        //     initialValues={initialValues}
        //     enableReinitialize
        //   >
        //     {({ submitForm, setFieldValue, values }) => {
        //       const changedValues = getChangedValues(values, initialValues);
        //       return (
        //         <>
        //           <Styled.List>
        //             {/* <Option
        //                 edit={false}
        //                 testid="dma"
        //                 title={'DMA Reference'}
        //                 option={data?.dma_key}
        //               />
        //               <Option
        //                 edit={false}
        //                 testid="ai_leak"
        //                 title={'AI predicted leak size'}
        //                 option={data?.AI_size}
        //               />
        //               <Option
        //                 edit={false}
        //                 testid="w3w"
        //                 title={'What 3 Words'}
        //                 option={data?.what3words}
        //               /> */}
        //             <Option
        //               edit={isEditMode}
        //               setFieldValue={setFieldValue}
        //               testid="last scheduled engineer"
        //               dropdown
        //               dropdownValues={engineerDropDown}
        //               placeholder={prefixedT('PLACEHOLDER.ENGINEER')}
        //               name={'last_scheduled_engineer_id'}
        //               title={prefixedT('LAST_SCHEDULED_ENGINEER')}
        //               option={data?.last_scheduled_engineer}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               setFieldValue={setFieldValue}
        //               testid="status"
        //               dropdown
        //               dropdownValues={statusDropDown}
        //               placeholder={prefixedT('PLACEHOLDER.STATUS')}
        //               name={'status_id'}
        //               title={prefixedT('CURRENT_STATUS')}
        //               option={data?.current_status}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'material'}
        //               placeholder={prefixedT('PLACEHOLDER.MATERIAL')}
        //               testid={'material'}
        //               input
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('PIPE_MATERIAL')}
        //               option={data?.pipe_material}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'surface_type'}
        //               placeholder={prefixedT('SURFACE_TYPE')}
        //               testid={'surface_type'}
        //               input
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('SURFACE_TYPE')}
        //               option={data?.surface_type}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'address'}
        //               placeholder={prefixedT('ADDRESS')}
        //               testid={'address'}
        //               input
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('ADDRESS')}
        //               option={data?.address}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'estimated_size_engineer'}
        //               placeholder={prefixedT('ESTIMATED_SIZE_ENGINEER')}
        //               testid={'estimated_size_engineer'}
        //               input
        //               type={'number'}
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('ESTIMATED_SIZE_ENGINEER')}
        //               option={data?.estimated_size_engineer}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'comment'}
        //               placeholder={prefixedT('COMMENT')}
        //               testid={'comment'}
        //               input
        //               type={'textarea'}
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('COMMENT')}
        //               option={data?.comment}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'environmental_notes'}
        //               placeholder={prefixedT('ENVIRONMENTAL_NOTES')}
        //               testid={'environmental-notes'}
        //               input
        //               type={'textarea'}
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('ENVIRONMENTAL_NOTES')}
        //               option={data?.environmental_notes}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               setFieldValue={setFieldValue}
        //               testid="leak size"
        //               placeholder={prefixedT('PLACEHOLDER.LEAK_TYPE')}
        //               dropdown
        //               dropdownValues={leakTypeDropDown}
        //               name={'predicted_leak_type_id'}
        //               title={prefixedT('PREDICTED_LEAK_SIZE')}
        //               option={data?.predicted_leak_type}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               setFieldValue={setFieldValue}
        //               testid="actual leak type"
        //               placeholder={prefixedT('PLACEHOLDER.LEAK_TYPE')}
        //               dropdown
        //               dropdownValues={leakTypeDropDown}
        //               name={'actual_leak_type_id'}
        //               title={prefixedT('ACTUAL_LEAK_TYPE')}
        //               option={data?.actual_leak_type}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'estimated_size_client'}
        //               placeholder={prefixedT('ESTIMATED_SIZE_CLIENT')}
        //               testid={'estimated_size_client'}
        //               input
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('ESTIMATED_SIZE_CLIENT')}
        //               option={data?.estimated_size_client}
        //               type={'number'}
        //             />
        //             <Option
        //               edit={isEditMode}
        //               name={'estimated_size'}
        //               placeholder={prefixedT('ESTIMATED_SIZE')}
        //               testid={'estimated_size'}
        //               input
        //               setFieldValue={setFieldValue}
        //               title={prefixedT('ESTIMATED_SIZE')}
        //               option={data?.estimated_size}
        //               type={'number'}
        //             />
        //           </Styled.List>
        //           {isError && (
        //             <div>
        //               <p>{t('ERROR.REQUEST')}</p>
        //             </div>
        //           )}
        //           {isEditMode && (
        //             <Styled.SaveContainer>
        //               <Button onClick={handleCancel}>
        //                 {prefixedT('CANCEL')}
        //               </Button>
        //               <Button
        //                 disabled={isEmptyObject(changedValues)}
        //                 onClick={() => {
        //                   void (async () => {
        //                     await submitForm();
        //                   })();
        //                 }}
        //                 type="submit"
        //               >
        //                 {prefixedT('SUBMIT')}
        //               </Button>
        //             </Styled.SaveContainer>
        //           )}
        //         </>
        //       );
        //     }}
        //   </Formik>
        // )
      }
    </FormWrapper>
  );
};

export default withPageLoader(EditWaypoint);
