import { Map } from "mapbox-gl";
import { layerID } from "../utils";
import { handleDragStart } from "./handleDragStart";
import { onMove } from "./onMove";
import { onUp } from "./onUp";
import { SessionRelocationObject } from "@/Providers/FilterProvider";
import { Position } from "geojson";

export const handleTouchEvents = (
  map: Map,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  isDraggingRef: React.MutableRefObject<boolean>,
  polygonCircleCoordinatesRef: React.MutableRefObject<Position[][] | undefined>,
  originalCoordsRef: React.MutableRefObject<{
    lng: number;
    lat: number;
  } | null>,
  setSessionRelocationObject: React.Dispatch<
    React.SetStateAction<SessionRelocationObject>
  >
) => {
  map.on("touchstart", layerID, (event) => {
    handleDragStart(event, map, draggedFeatureIndexRef);

    map.on("touchmove", (e) =>
      onMove(map, draggedFeatureIndexRef, isDraggingRef, e)
    );
    map.once("touchend", (e) =>
      onUp(
        map,
        isDraggingRef,
        polygonCircleCoordinatesRef,
        e,
        originalCoordsRef,
        draggedFeatureIndexRef,
        setSessionRelocationObject
      )
    );
  });
};
