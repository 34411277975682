import * as React from "react";
const SvgRelay = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "221.2595 138.077 79.5495 89.389", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "displayColor",
  paintOrder: "fill"
}, cx: 262.062, cy: 182.874, rx: 4.472, ry: 4.813, transform: "matrix(1, 0, 0, 1, 0, -7.105427357601002e-15)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "displayColor"
}, transform: "matrix(0.7216199636459351, 0, 0, 1.5596940517425537, -388.1819152832031, -356.0685119628906)", d: "M 931.649 323.884 A 21.453 21.453 0 1 1 931.649 366.658 A 23.061 23.061 0 0 0 931.649 323.884 Z" }), /* @__PURE__ */ React.createElement("rect", { x: 258.831, y: 138.077, width: 6.69, height: 31.117, rx: 4, ry: 4, style: {
  fill: "displayColor"
}, transform: "matrix(1, 0, 0, 1, 0, -7.105427357601002e-15)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "displayColor"
}, transform: "matrix(-0.5358989834785461, 0.005975000094622374, -0.011661001481115818, -1.0458430051803589, 734.009033203125, 541.044677734375)", d: "M 896.617 326.044 A 22.385 22.385 0 1 1 896.617 370.675 A 24.063 24.063 0 0 0 896.617 326.044 Z" }), /* @__PURE__ */ React.createElement("rect", { x: 258.889, y: 196.349, width: 6.69, height: 31.117, rx: 4, ry: 4, style: {
  fill: "displayColor"
}, transform: "matrix(1, 0, 0, 1, 0, -7.105427357601002e-15)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "displayColor",
  transformOrigin: "908.691px 348.36px"
}, transform: "matrix(0.535887897015, 0.006874999963, -0.013416999951, 1.045822024345, -629.037100143055, -166.084371552655)", d: "M 896.617 326.044 A 22.385 22.385 0 1 1 896.617 370.675 A 24.063 24.063 0 0 0 896.617 326.044 Z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "displayColor",
  transformOrigin: "943.216px 345.271px"
}, transform: "matrix(-0.721372842789, -0.018870003521, 0.040784008801, -1.559161186218, -712.737753190305, -162.678369069869)", d: "M 931.649 323.884 A 21.453 21.453 0 1 1 931.649 366.658 A 23.061 23.061 0 0 0 931.649 323.884 Z" }));
export default SvgRelay;
