import useCustomTranslation from "@/localization/useCustomTranslation";

export const currencyOptions = () => {
  const { prefixedT } = useCustomTranslation("CURRENCY");

  return [
    {
      value: "USD",
      label: prefixedT("USD")
    },
    {
      value: "GBP",
      label: prefixedT("GBP")
    },
    {
      value: "EUR",
      label: prefixedT("EUR")
    }
  ];
};
