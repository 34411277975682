import {
  ArchiveConversationQuery,
  CreateConversationResponse,
  MessageStarRatingQuery,
  SendMessageBody,
  SendMessageQuery,
  SendMessageResponse
} from "@/types/aiAssistant.type";
import { createApi } from "@reduxjs/toolkit/query/react";
import { aiAssistantBaseQuery } from "../api";

export const aiAssistantApi = createApi({
  reducerPath: "aiAssistantQuery",
  baseQuery: aiAssistantBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    createConversation: builder.mutation<CreateConversationResponse, void>({
      query: () => ({
        url: "/askfido/conversations",
        method: "POST"
      })
    }),
    sendMessage: builder.mutation<
      SendMessageResponse,
      { query: SendMessageQuery; body: SendMessageBody }
    >({
      query: ({ query, body }) => ({
        url: `/askfido/conversations/${query.convo_id}/messages`,
        method: "POST",
        body
      })
    }),
    archiveConversation: builder.mutation<void, ArchiveConversationQuery>({
      query: (query) => ({
        url: `/askfido/conversations/${query.convo_id}?confirm=true`,
        method: "DELETE"
      })
    }),
    messageStarRating: builder.mutation<void, MessageStarRatingQuery>({
      query: (query) => ({
        url: `/askfido/conversations/${query.convo_id}/messages${query.msg_id}?star_rating=${query.star_rating}`,
        method: "PATCH"
      })
    })
  })
});

export const {
  useCreateConversationMutation,
  useSendMessageMutation,
  useArchiveConversationMutation,
  useMessageStarRatingMutation
} = aiAssistantApi;
